import React from "react";

import Modal from "@cloudscape-design/components/modal";
import Input from "@cloudscape-design/components/input";
import Button from "@cloudscape-design/components/button";
import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Spinner from "@cloudscape-design/components/spinner";
import Toggle from "@cloudscape-design/components/toggle";

/* Recoil */
import { useRecoilValue, useSetRecoilState } from "recoil";
import { chatBanUserModalStore } from "../stores/chatBanUserModal";
/* ------- */

class chatBanUserModal extends React.Component {
	banUser() {
		this.props.Interface.request("set", { class: "data", module: "chat", method: "ban" }, { uid: this.props.chatBanUserModal.uid, reason: this.props.chatBanUserModal.reason, date: this.props.chatBanUserModal.date })
			.then(() => {
				this.props.Interface.addNoty("Success", "User #" + this.props.chatBanUserModal.uid + " banned");
				this.props.setChatBanUserModal({ ...this.props.chatBanUserModal, checked: false, show: false, reason: "", data: "", date: "", uid: 0 });
			})
			.catch((e) => {
				console.log(e);
				this.props.setChatBanUserModal({ ...this.props.chatBanUserModal, checked: true });
				//this.props.Interface.addNoty("Error", e.text);
			});
	}

	render() {
		return (
			<>
				<Modal
					visible={this.props.chatBanUserModal.show}
					onDismiss={() => {
						this.props.setChatBanUserModal({ ...this.props.chatBanUserModal, checked: false, show: false, reason: "", data: "", date: "", uid: 0 });
					}}
					header="Ban user"
					children={
						<>
							<SpaceBetween direction="vertical" size="m">
								<Box variant="span">reason: </Box>
								<Input
									invalid={(this.props.chatBanUserModal.reason.length > 256 || this.props.chatBanUserModal.reason.length < 5) && this.props.chatBanUserModal.checked ? true : false}
									inputMode="text"
									type="text"
									disableBrowserAutocorrect={true}
									placeholder="Ban reason"
									value={this.props.chatBanUserModal.reason}
									onChange={(input) => {
										this.props.setChatBanUserModal({ ...this.props.chatBanUserModal, reason: input.detail.value });
									}}
								/>
								<Box variant="span">till date: </Box>
								<Input
									invalid={isNaN(parseInt(this.props.chatBanUserModal.date)) && this.props.chatBanUserModal.checked ? true : false}
									inputMode="date"
									type="date"
									disableBrowserAutocorrect={true}
									placeholder="Date"
									value={this.props.chatBanUserModal.date}
									onChange={(input) => {
										this.props.setChatBanUserModal({ ...this.props.chatBanUserModal, date: input.detail.value });
									}}
								/>
							</SpaceBetween>
						</>
					}
					footer={
						<>
							<Box
								float="right"
								children={
									<>
										<Box
											variant="span"
											margin={{ right: "s" }}
											children={
												<Button
													variant="normal"
													onClick={() => {
														this.props.setChatBanUserModal({ ...this.props.chatBanUserModal, show: false });
													}}
													children="Close"
												/>
											}
										/>
										<Box
											variant="span"
											children={
												<Button
													variant="primary"
													onClick={() => {
														this.banUser();
													}}
													children="Ok"
												/>
											}
										/>
									</>
								}
							/>
						</>
					}
				/>
			</>
		);
	}
}

function withRecoil(Component) {
	return function WrappedComponent(props) {
		const chatBanUserModal = useRecoilValue(chatBanUserModalStore);
		const setChatBanUserModal = useSetRecoilState(chatBanUserModalStore);

		return <Component {...props} chatBanUserModal={chatBanUserModal} setChatBanUserModal={setChatBanUserModal} />;
	};
}

export default withRecoil(chatBanUserModal);
