import React from "react";

import cloneDeep from "lodash/cloneDeep";

import Modal from "@cloudscape-design/components/modal";
import Input from "@cloudscape-design/components/input";
import Button from "@cloudscape-design/components/button";
import Box from "@cloudscape-design/components/box";
import Select from "@cloudscape-design/components/select";
import Multiselect from "@cloudscape-design/components/multiselect";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Toggle from "@cloudscape-design/components/toggle";
import Spinner from "@cloudscape-design/components/spinner";

/* Recoil */
import { useRecoilValue, useSetRecoilState } from "recoil";
import { configStore } from "../stores/config";
import { gameStore } from "../stores/games";
import { gamesAddModalStore } from "../stores/gamesAddModal";
/* ------- */

class GamesAddModal extends React.Component {
	loadProviderParams() {
		this.props.setGamesAddModal({ ...this.props.gamesAddModal, loaded: false });

		this.props.Interface.request("get", { class: "data", module: "games", method: "params" }, { providerID: this.props.gamesAddModal.providerID })
			.then((params) => {
				this.props.setGamesAddModal({ ...this.props.gamesAddModal, loaded: true, params, newPoster: false, newBigPoster: false });
			})
			.catch((e) => {
				this.props.setGamesAddModal({ ...this.props.gamesAddModal, loaded: true });
			});
	}

	addGame() {
		this.props.setGamesAddModal({ ...this.props.gamesAddModal, loaded: false });

		let params = {};

		Object.keys(this.props.gamesAddModal).forEach((key) => {
			if (key === "categoryID") {
				params[key] = [];
				this.props.gamesAddModal[key].forEach((category) => {
					params[key].push(category.type);
				});
			} else if (key === "rtp" || key === "volatility") params[key] = parseInt(this.props.gamesAddModal[key]);
			else if (key !== "show" && key !== "loaded") params[key] = this.props.gamesAddModal[key];
		});

		this.props.Interface.request("get", { class: "data", module: "games", method: "add" }, { ...params })
			.then(() => {
				this.props.setGamesAddModal({ ...this.props.gamesAddModal, loaded: true });
				setTimeout(() => {
					this.props.setGamesAddModal({ ...this.props.gamesAddModal, show: false });
				}, 0);

				setTimeout(() => {
					this.props.Interface.loadCasinoData();
				}, 500);
			})
			.catch((e) => {
				this.props.setGamesAddModal({ ...this.props.gamesAddModal, loaded: true });
				console.log(e);
			});
	}

	getBase64(file, key) {
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			this.updateParam(key, reader.result);
		};
	}

	updateParam(key, value) {
		let params = cloneDeep(this.props.gamesAddModal);
		params[key] = value;
		this.props.setGamesAddModal({ ...params });
	}

	render() {
		return (
			<>
				<Modal
					visible={this.props.gamesAddModal.show}
					onDismiss={() => {
						this.props.setGamesAddModal({ ...this.props.gamesAddModal, show: false });
					}}
					header="Add game"
					children={
						<>
							<SpaceBetween direction="vertical" size="m">
								<Select
									filteringType="auto"
									disabled={!this.props.game.loaded}
									placeholder="Provider"
									selectedOption={this.props.game.currentOption.provider}
									onChange={(option) => {
										setTimeout(() => {
											this.loadProviderParams();
										}, 0);
										this.props.setGamesAddModal({ ...this.props.gamesAddModal, providerID: option.detail.selectedOption.type });
										option.detail.selectedOption.type !== this.props.game.currentProvider && this.props.setGame({ ...this.props.game, currentProvider: option.detail.selectedOption.type, currentOption: { ...this.props.game.currentOption, provider: option.detail.selectedOption } });
									}}
									options={this.props.config.game.options.provider}
								/>
								<Multiselect
									filteringType="auto"
									selectedOptions={this.props.gamesAddModal.categoryID}
									onChange={(select) => {
										this.props.setGamesAddModal({ ...this.props.gamesAddModal, categoryID: select.detail.selectedOptions });
									}}
									disabled={!this.props.game.loaded}
									placeholder="Category"
									options={this.props.config.game.options.category.slice(1)}
								/>

								{this.props.gamesAddModal.loaded && this.props.gamesAddModal.params.length ? (
									<>
										{this.props.gamesAddModal.params
											.filter((item) => {
												return item !== "bigPoster" && item !== "poster" && item !== "new" && item !== "newYear" && item !== "top" && item !== "rtpUp" && item !== "select";
											})
											.map((item) => (
												<>
													<Box variant="span">{item}: </Box>
													<Input
														inputMode={item === "rtp" || item === "volatility" ? "number" : "text"}
														type={item === "rtp" || item === "volatility" ? "number" : "text"}
														disableBrowserAutocorrect={true}
														placeholder={item}
														value={this.props.gamesAddModal[item]}
														onChange={(input) => {
															this.updateParam(item, input.detail.value);
														}}
													/>
												</>
											))}

										<Box variant="span">poster:</Box>
										<input
											type="file"
											onChange={(ev) => {
												this.getBase64(ev.target.files[0], "newPoster");
											}}></input>
										<Box variant="span">big poster:</Box>
										<input
											type="file"
											onChange={(ev) => {
												this.getBase64(ev.target.files[0], "newBigPoster");
											}}></input>

										<SpaceBetween direction="horizontal" size="m">
											{this.props.gamesAddModal.params
												.filter((item) => {
													return item === "new" || item === "top";
												})
												.map((item) => (
													<>
														<Toggle
															checked={this.props.gamesAddModal[item]}
															onChange={(checkbox) => {
																this.updateParam(item, checkbox.detail.checked);
															}}>
															{item}
														</Toggle>
													</>
												))}
											<Toggle
												checked={this.props.gamesAddModal["select"]}
												onChange={(checkbox) => {
													this.updateParam("select", checkbox.detail.checked);
												}}>
												select
											</Toggle>
											<Toggle
												checked={this.props.gamesAddModal["newYear"]}
												onChange={(checkbox) => {
													this.updateParam("newYear", checkbox.detail.checked);
												}}>
												newYear
											</Toggle>
										</SpaceBetween>
										<Input
											inputMode={"number"}
											type="number"
											disableBrowserAutocorrect={true}
											placeholder="rtpUp"
											value={this.props.gamesAddModal["rtpUp"]}
											onChange={(input) => {
												this.updateParam("rtpUp", input.detail.value);
											}}
										/>
									</>
								) : !this.props.gamesAddModal.loaded ? (
									<Box variant="div" textAlign="center">
										<Spinner size="large" />
									</Box>
								) : (
									<></>
								)}
							</SpaceBetween>
						</>
					}
					footer={
						<>
							<Box
								float="right"
								children={
									<>
										<Box
											variant="span"
											margin={{ right: "s" }}
											children={
												<Button
													variant="normal"
													onClick={() => {
														this.props.setGamesAddModal({ ...this.props.gamesAddModal, show: false });
													}}
													children="Close"
												/>
											}
										/>
										<Box
											variant="span"
											children={
												<Button
													variant="primary"
													onClick={() => {
														this.addGame();
													}}
													children="Ok"
												/>
											}
										/>
									</>
								}
							/>
						</>
					}
				/>
			</>
		);
	}
}

function withRecoil(Component) {
	return function WrappedComponent(props) {
		const gamesAddModal = useRecoilValue(gamesAddModalStore);
		const setGamesAddModal = useSetRecoilState(gamesAddModalStore);
		const game = useRecoilValue(gameStore);
		const setGame = useSetRecoilState(gameStore);
		const config = useRecoilValue(configStore);

		return <Component {...props} gamesAddModal={gamesAddModal} setGamesAddModal={setGamesAddModal} game={game} setGame={setGame} config={config} />;
	};
}

export default withRecoil(GamesAddModal);
