import { atom } from "recoil";

export const gamesAutoManageModalStore = atom({
	key: "gamesAutoManageModalStore",
	default: {
		show: false,
		loaded: true,
		managedInfo: false,
		currentOption: {
			type: false,
		},
		currentType: "qtech",
	},
});
