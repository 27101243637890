import { atom } from "recoil";

export const userStore = atom({
	key: "userStore",
	default: {
		process: false,
		brand: JSON.parse(localStorage.getItem("access.brand")) || false,
		token: localStorage.getItem("access.token") || false,
		level: localStorage.getItem("access.level") || 0,
		login: localStorage.getItem("access.login") || "",
		password: "",
		brandsList: JSON.parse(localStorage.getItem("access.brandsList")) || [],
		mode: localStorage.getItem("access.mode") || "brand",
	},
});
