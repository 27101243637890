import React from "react";

import Modal from "@cloudscape-design/components/modal";
import Header from "@cloudscape-design/components/header";

/* Recoil */
import { useRecoilValue, useSetRecoilState } from "recoil";
import { documentStore } from "../stores/document";
/* ------- */

class PlayerDocumentModal extends React.Component {
	render() {
		return (
			<>
				<Modal
					size="max"
					visible={this.props.document.show}
					onDismiss={() => {
						this.props.setDocument({ ...this.props.document, show: false });
					}}
					header={<Header description={this.props.document.name} counter={"(" + this.props.document.uid + ")"} children="Player's document" />}
					children={
						<>
							<img className={this.props.styles["document"]} src={this.props.document.source.toString()}></img>
						</>
					}
				/>
			</>
		);
	}
}

function withRecoil(Component) {
	return function WrappedComponent(props) {
		const document = useRecoilValue(documentStore);
		const setDocument = useSetRecoilState(documentStore);

		return <Component {...props} document={document} setDocument={setDocument} />;
	};
}

export default withRecoil(PlayerDocumentModal);
