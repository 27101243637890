import React from "react";
import Box from "@cloudscape-design/components/box";
import Input from "@cloudscape-design/components/input";
import Container from "@cloudscape-design/components/container";
import Header from "@cloudscape-design/components/header";
import Grid from "@cloudscape-design/components/grid";
import { Link, StatusIndicator, Table } from "@cloudscape-design/components";
import Logo from "../assets/logo.svg";
import { SpaceBetween } from "@cloudscape-design/components";

/* Recoil */
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userStore } from "../stores/access";
import { configStore } from "../stores/config";
/* ------- */

const eventsDefinition = [
	{
		id: "name",
		header: "Service",
		cell: (item) => item.name,
		minWidth: 135,
	},
	{
		id: "status",
		header: "Status",
		cell: ({ statusText, status }) => <StatusIndicator type={status}>{statusText}</StatusIndicator>,
		minWidth: 120,
	},
	{
		id: "type",
		header: "Uptime",
		cell: (item) => item.uptime,
		minWidth: 130,
	},
];
const eventsItems = [
	{
		name: "Casino provider",
		uptime: "99.9%",
		statusText: "Running",
		status: "success",
	},
	{
		name: "Casino api",
		uptime: "99.9%",
		statusText: "Running",
		status: "success",
	},
	{
		name: "Frontend",
		uptime: "99.9%",
		statusText: "Running",
		status: "success",
	},
	{
		name: "Backend",
		uptime: "99.9%",
		statusText: "Running",
		status: "success",
	},
	{
		name: "Ecorpay",
		uptime: "99.9%",
		statusText: "Running",
		status: "success",
	},
	{
		name: "AWS",
		uptime: "99.9%",
		statusText: "Running",
		status: "success",
	},
	{
		name: "RKN",
		uptime: "99.9%",
		statusText: "Running",
		status: "success",
	},
	{
		name: "NetEnt",
		uptime: "99.9%",
		statusText: "Running",
		status: "success",
	},
	{
		name: "Quickspin",
		uptime: "99.9%",
		statusText: "Running",
		status: "success",
	},
	{
		name: "EGT",
		uptime: "0.0%",
		statusText: "Error",
		status: "error",
	},
	{
		name: "Relax Gaming",
		uptime: "99.9%",
		statusText: "Running",
		status: "success",
	},
	{
		name: "Big Time Gaming",
		uptime: "99.9%",
		statusText: "Running",
		status: "success",
	},
	{
		name: "Push Gaming",
		uptime: "99.9%",
		statusText: "Running",
		status: "success",
	},
	{
		name: "Red Tiger",
		uptime: "99.9%",
		statusText: "Running",
		status: "success",
	},
	{
		name: "Reelplay",
		uptime: "0.0%",
		statusText: "Maintenance",
		status: "pending",
	},
	{
		name: "Yggdrasil",
		uptime: "99.9%",
		statusText: "Running",
		status: "success",
	},
	{
		name: "Play\n GO",
		uptime: "99.9%",
		statusText: "Running",
		status: "success",
	},
	{
		name: "Cayetano",
		uptime: "99.9%",
		statusText: "Running",
		status: "success",
	},
	{
		name: "Greentube",
		uptime: "99.9%",
		statusText: "Running",
		status: "success",
	},
	{
		name: "Goldenrock",
		uptime: "99.9%",
		statusText: "Running",
		status: "success",
	},
	{
		name: "Playtech",
		uptime: "99.9%",
		statusText: "Running",
		status: "success",
	},
	{
		name: "Rabcat",
		uptime: "99.9%",
		statusText: "Running",
		status: "success",
	},
	{
		name: "Foxium",
		uptime: "99.9%",
		statusText: "Running",
		status: "success",
	},
	{
		name: "Microgaming",
		uptime: "99.9%",
		statusText: "Running",
		status: "success",
	},
	{
		name: "JFTW",
		uptime: "99.9%",
		statusText: "Running",
		status: "success",
	},
	{
		name: "Endorphina",
		uptime: "99.9%",
		statusText: "Running",
		status: "success",
	},
	{
		name: "Slotegrator",
		uptime: "99.9%",
		statusText: "Running",
		status: "success",
	},
	{
		name: "Sportegrator",
		uptime: "99.9%",
		statusText: "Running",
		status: "success",
	},
	{
		name: "QTech",
		uptime: "99.9%",
		statusText: "Running",
		status: "success",
	},
	{
		name: "Softswiss",
		uptime: "99.9%",
		statusText: "Running",
		status: "success",
	},
];

class Flat extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		if (parseFloat(this.props.config.version) < 5.7) {
			localStorage.removeItem("access.brand");
			localStorage.removeItem("access.brandsList");
		}
	}

	render() {
		return (
			<Box
				className={this.props.styles["logoBlock"]}
				margin="l"
				children={
					<>
						<SpaceBetween size="xxl">
							<Container header={<Header description="Casino control panel"> Welcome to XPanel 7!</Header>}>
								<Grid
									gridDefinition={[{ colspan: { default: 12, m: 6 } }, { colspan: { default: 12, m: 6 } }]}
									children={
										<>
											<div className={[this.props.styles["brandCard"], this.props.styles["noHover"], this.props.styles["flat"]].join(" ")}>
												<img src={this.props.user.mode === "global" ? Logo : document.body.classList[0] === "awsui-dark-mode" ? this.props.user.brand.logoDark : this.props.user.brand.logo} />
											</div>
											<SpaceBetween size="xs">
												<Box>Account name:</Box>
												<Input disabled={true} value={this.props.user.login} />
												<Box>Access level:</Box>
												<Input disabled={true} value={this.props.user.level} />
												<Box>Software version:</Box>
												<Input disabled={true} value={this.props.config.version} />
												<Box>Last software update:</Box>
												<Input disabled={true} value={new Date(this.props.config.updated).toLocaleDateString()} />
											</SpaceBetween>
										</>
									}
								/>
							</Container>
							{/* <Table
                            className="custom-dashboard-container"
                            resizableColumns={true}
                            header={<Header counter={"(" +eventsItems.length + ")"}>Status</Header>}
                            items={eventsItems}
                            columnDefinitions={eventsDefinition}
                            disableContentPaddings={true}
                        /> */}
						</SpaceBetween>
					</>
				}
			/>
		);
	}
}

function withRecoil(Component) {
	return function WrappedComponent(props) {
		const user = useRecoilValue(userStore);
		const config = useRecoilValue(configStore);
		return <Component {...props} config={config} user={user} />;
	};
}

export default withRecoil(Flat);
