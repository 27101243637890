import React from "react";

import Modal from "@cloudscape-design/components/modal";
import Input from "@cloudscape-design/components/input";
import Button from "@cloudscape-design/components/button";
import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Spinner from "@cloudscape-design/components/spinner";

/* Recoil */
import { useRecoilValue, useSetRecoilState } from "recoil";
import { configStore } from "../stores/config";
import { staffAddModalStore } from "../stores/staffAddModal";
import { userStore } from "../stores/access";
import { staffStore } from "../stores/staff";
/* ------- */

class StaffAddUserModal extends React.Component {
	addUser() {
		this.props.setStaffAddModal({ ...this.props.staffAddModal, loaded: false });

		this.props.Interface.request("set", { class: "settings", module: "staff", method: "create" }, { name: this.props.staffAddModal.name, password: this.props.staffAddModal.password, level: parseInt(this.props.staffAddModal.level) })
			.then(() => {
				this.props.Interface.addNoty("Success", "User `" + this.props.staffAddModal.name + "` was successfully created with level " + this.props.staffAddModal.level);
				this.props.setStaffAddModal({ ...this.props.staffAddModal, checked: false, loaded: true, show: false, name: "", password: "", level: 1 });

				this.props.setStaff({ ...this.props.staff, selectedItems: [], loaded: false });

				this.props.Interface.request("get", { class: "data", module: "info", method: "staff" })
					.then((data) => {
						this.props.setStaff({
							...this.props.staff,
							data,
							loaded: true,
							pagination: { current: 1, total: Math.ceil(data.length / this.props.config.staff.perPage) },
							sorting: {
								sortingColumn: {
									sortingField: "",
								},
								sortingDescending: false,
							},
						});
					})
					.catch((e) => {
						this.props.setStaff({ ...this.props.staff, loaded: true, error: e });
					});
			})
			.catch((e) => {
				this.props.setStaffAddModal({ ...this.props.staffAddModal, checked: true, loaded: true });
				console.log(e);
			});
	}

	render() {
		return (
			<>
				<Modal
					visible={this.props.staffAddModal.show}
					onDismiss={() => {
						this.props.setStaffAddModal({ ...this.props.staffAddModal, checked: false, name: "", password: "", level: 1, show: false });
					}}
					header="Add user"
					children={
						<>
							<SpaceBetween direction="vertical" size="m">
								{this.props.staffAddModal.loaded ? (
									<>
										<Box variant="span">name: </Box>
										<Input
											invalid={(this.props.staffAddModal.name.length > 16 || this.props.staffAddModal.name.length < 4) && this.props.staffAddModal.checked ? true : false}
											inputMode="text"
											type="text"
											disableBrowserAutocorrect={true}
											placeholder="user login"
											value={this.props.staffAddModal.name}
											onChange={(input) => {
												this.props.setStaffAddModal({ ...this.props.staffAddModal, name: input.detail.value });
											}}
										/>
										<Box variant="span">password: </Box>
										<Input
											invalid={(this.props.staffAddModal.password.length > 16 || this.props.staffAddModal.password.length < 4) && this.props.staffAddModal.checked ? true : false}
											inputMode="text"
											type="password"
											disableBrowserAutocorrect={true}
											placeholder="user password"
											value={this.props.staffAddModal.password}
											onChange={(input) => {
												this.props.setStaffAddModal({ ...this.props.staffAddModal, password: input.detail.value });
											}}
										/>
										<Box variant="span">level: </Box>
										<Input
											inputMode="numeric"
											type="number"
											disableBrowserAutocorrect={true}
											placeholder="access level"
											value={this.props.staffAddModal.level}
											onChange={(input) => {
												this.props.setStaffAddModal({ ...this.props.staffAddModal, level: input.detail.value > this.props.user.level - 1 ? this.props.user.level - 1 : input.detail.value < 1 ? 1 : input.detail.value });
											}}
										/>
									</>
								) : (
									<Box variant="div" textAlign="center">
										<Spinner size="large" />
									</Box>
								)}
							</SpaceBetween>
						</>
					}
					footer={
						<>
							<Box
								float="right"
								children={
									<>
										<Box
											variant="span"
											margin={{ right: "s" }}
											children={
												<Button
													variant="normal"
													onClick={() => {
														this.props.setStaffAddModal({ ...this.props.staffAddModal, show: false });
													}}
													children="Close"
												/>
											}
										/>
										<Box
											variant="span"
											children={
												<Button
													variant="primary"
													onClick={() => {
														this.addUser();
													}}
													children="Ok"
												/>
											}
										/>
									</>
								}
							/>
						</>
					}
				/>
			</>
		);
	}
}

function withRecoil(Component) {
	return function WrappedComponent(props) {
		const staff = useRecoilValue(staffStore);
		const setStaff = useSetRecoilState(staffStore);
		const staffAddModal = useRecoilValue(staffAddModalStore);
		const setStaffAddModal = useSetRecoilState(staffAddModalStore);
		const config = useRecoilValue(configStore);
		const user = useRecoilValue(userStore);

		return <Component {...props} staff={staff} setStaff={setStaff} user={user} staffAddModal={staffAddModal} setStaffAddModal={setStaffAddModal} config={config} />;
	};
}

export default withRecoil(StaffAddUserModal);
