import { getRecoil, setRecoil } from "recoil-nexus";
import { userStore } from "../stores/access";
import { configStore } from "../stores/config";
import { notyStore } from "../stores/notys";
import cloneDeep from "lodash/cloneDeep";
import { paymentStore } from "../stores/payments";
import { playerPageStore } from "../stores/playerPage";
import { routeStore } from "../stores/route";
import { playerGameLogStore } from "../stores/playerGameLog";
import { gameStore } from "../stores/games";
import { loaderStore, topMenuStore } from "../stores/interface";

import { applyMode, applyDensity, Density, Mode } from "@cloudscape-design/global-styles";

/* Modules */
import Flat from "../modules/Flat";
import Stats from "../modules/Stats";
import Reports from "../modules/Reports";
import PlayersStats from "../modules/PlayersStats";
import Withdrawals from "../modules/Withdraws";
import Games from "../modules/Games";
import Players from "../modules/Players";
import Chat from "../modules/Chat";
import Staff from "../modules/Staff";
import Settings from "../modules/Settings";
import Bonuses from "../modules/Bonuses";
import Exports from "../modules/Exports";
import Domains from "../modules/Domains";
import { playerSessionsModalStore } from "../stores/playerSessionsModal";
import * as pako from "pako";

class Interface {
	constructor() {
		this.routes = [
			{ title: "Home", path: "/", level: 1, component: Flat, global: true },
			{ title: "Stats", path: "/stats", level: 6, component: Stats, group: "Finance", global: false },
			{ title: "Reports", path: "/reports", level: 6, component: Reports, group: "Finance", global: true },
			{ title: "Player's stats", path: "/pstats", level: 6, component: PlayersStats, group: "Finance", global: false },
			{ title: "Withdrawals", path: "/withdrawals", level: 4, component: Withdrawals, group: "Payments", global: false },
			{ title: "Bonuses", path: "/bonuses", level: 3, component: Bonuses, group: "Promo", global: false },
			{ title: "Exports", path: "/exports", level: 6, component: Exports, group: "Data", global: false },
			{ title: "Games", path: "/games", level: 1, component: Games, group: "Data", global: true },
			{ title: "Players", path: "/players", level: 1, component: Players, group: "Data", global: false },
			{ title: "Chat", path: "/chat", level: 2, component: Chat, group: "Control", global: false },
			{ title: "Staff", path: "/staff", level: 6, component: Staff, group: "Control", global: true },
			{ title: "Domains", path: "/domains", level: 6, component: Domains, group: "Control", global: false },
			{ title: "Settings", path: "/settings", level: 6, component: Settings, group: "Control", global: false },
			{ title: "Dark mode", level: 1, path: "/mode-dark", group: "Mode", global: true },
			{ title: "Light mode", level: 1, path: "/mode-light", group: "Mode", global: true },
		];
	}

	getRoutes() {
		return this.routes;
	}

	decompressString(compressedString) {
		const binaryString = window.atob(compressedString);
		const charData = binaryString.split("").map((c) => c.charCodeAt(0));
		const binaryArray = new Uint8Array(charData);

		return pako.inflate(binaryArray, { to: "string" });
	}

	loadBrands() {
		setRecoil(userStore, { ...getRecoil(userStore), process: true });

		this.request("get", { class: "data", module: "brands", method: "list" })
			.then((brands) => {
				localStorage.setItem("access.brandsList", JSON.stringify(brands));
				setRecoil(userStore, { ...getRecoil(userStore), brandsList: brands, process: false });
			})
			.catch(() => {
				setRecoil(userStore, { ...getRecoil(userStore), process: false });
			});
	}

	checkMenuAction(action) {
		switch (action) {
			case "logout":
				setRecoil(userStore, { ...getRecoil(userStore), token: false, level: 0, password: "" });
				localStorage.removeItem("access.token");
				localStorage.removeItem("access.level");
				localStorage.removeItem("menu.top");
				setRecoil(topMenuStore, []);
				setRecoil(routeStore, { currentPath: "/" });
				break;
			case "mode-dark":
				localStorage.setItem("mode", "dark");
				//applyMode(Mode.Dark);
				window.location.reload();
				break;
			case "mode-light":
				localStorage.setItem("mode", "light");
				//applyMode(Mode.Light);
				window.location.reload();
				break;
		}
	}

	initData() {
		fetch(getRecoil(configStore).routerApi.servlet + "/?method=domain&project=" + getRecoil(configStore).routerApi.id)
			.then((response) => response.json())
			.then((data) => {
				fetch(getRecoil(configStore).routerApi.servlet + "/?method=domain&project=" + getRecoil(configStore).routerApi.id + "_5")
					.then((response) => response.json())
					.then((mData) => {
						fetch(getRecoil(configStore).routerApi.servlet + "/?method=domain&project=" + getRecoil(configStore).routerApi.id + "_3")
							.then((response) => response.json())
							.then((tgData) => {
								fetch(getRecoil(configStore).routerApi.servlet + "/?method=domain&project=" + getRecoil(configStore).routerApi.id + "_4")
									.then((response) => response.json())
									.then((vkData) => {
										let link = data.success ? data.data : "#",
											mirror = mData.success ? mData.data : "#",
											tgLink = tgData.success ? tgData.data : "#",
											vkLink = vkData.success ? vkData.data : "#";

										setRecoil(configStore, { ...getRecoil(configStore), domain: link });

										let menu = cloneDeep(getRecoil(topMenuStore));

										let groups = {};

										this.routes.forEach((route) => {
											if (typeof route.group !== "undefined") {
												if (typeof groups[route.group] === "undefined")
													groups[route.group] = {
														type: "menu-dropdown",
														variant: "link",
														onItemClick: (item) => {
															this.checkMenuAction(item.detail.id);
														},
														routing: true,
														items: [],
														text: route.group,
													};

												if (route.group === "Mode") {
													if (route.title === "Dark mode") {
														if (document.body.classList[0] === "awsui-dark-mode") {
															groups[route.group].items.push({ type: "button", variant: "link", text: route.title, disabled: true, disabledReason: "Already using", badge: true, id: route.path.slice(1) });
														} else groups[route.group].items.push({ type: "button", variant: "link", text: route.title, badge: true, id: route.path.slice(1) });
													} else {
														if (typeof document.body.classList[0] !== "undefined") {
															groups[route.group].items.push({ type: "button", variant: "link", text: route.title, badge: true, id: route.path.slice(1) });
														} else groups[route.group].items.push({ type: "button", variant: "link", text: route.title, disabled: true, disabledReason: "Already using", badge: true, id: route.path.slice(1) });
													}
												} else {
													if (getRecoil(userStore).level >= route.level && (getRecoil(userStore).mode === "brand" || route.global)) groups[route.group].items.push({ type: "button", variant: "link", text: route.title, badge: true, path: route.path, id: route.path.slice(1) });
												}
											} else getRecoil(userStore).level >= route.level && menu.push({ type: "button", variant: "link", text: route.title, badge: true, path: route.path });
										});

										Object.keys(groups).forEach((key) => {
											if (groups[key].items.length) menu.push(groups[key]);
										});

										if (getRecoil(userStore).mode === "brand")
											menu.push({
												type: "menu-dropdown",
												variant: "link",
												items: [
													{ id: "1", external: true, text: getRecoil(userStore).brand.name, href: "https://" + link },
													{ id: "1", external: true, text: "Mirror", href: "https://" + mirror },
													{ id: "3", external: true, text: "VK group", href: "https://" + vkLink },
													{ id: "4", external: true, text: "Telegram channel", href: "https://" + tgLink },
												],
												text: "Links",
												badge: true,
											});

										menu.push({
											type: "menu-dropdown",
											onItemClick: (item) => {
												this.checkMenuAction(item.detail.id);
											},
											variant: "link",
											items: [{ id: "logout", external: true, text: "Logout" }],
											text: localStorage.getItem("access.login"),
										});

										//localStorage.setItem('menu.top', JSON.stringify([...getRecoil(topMenuStore), ...menu]));
										setRecoil(topMenuStore, [...getRecoil(topMenuStore), ...menu]);
									});
							});
					});
			});
	}

	updateGameData(gameID, data) {
		let games = cloneDeep(getRecoil(gameStore).data);
		games.forEach((game) => {
			if (parseInt(game.gameID) === parseInt(gameID)) {
				game.name = data.name;
				game.providerID = data.providerID;
				game.poster = game.poster;
				game.bigPoster = game.bigPoster;
				game.categoryID = data.categoryID;
				game.active = data.active ? 1 : 0;
				game.rtp = data.rtp;
				game.biases = { ...game.biases, bias: data.bias };
			}
		});

		setRecoil(gameStore, { ...getRecoil(gameStore), data: games });
	}

	loadCasinoData() {
		setRecoil(gameStore, { ...getRecoil(gameStore), loaded: false, selectedItems: [] });
		this.request("get", { class: "casino", module: "game", method: "list" }, { providerID: 0 }).then((list) => {
			let games = [];
			list.data = JSON.parse(this.decompressString(list.data));

			if (getRecoil(userStore).level >= 6)
				this.request("get", { class: "data", module: "games", method: "stats" }, { gameID: "all", limit: 1, offset: 0 }).then((stats) => {
					list.data.forEach((game) => {
						if (typeof stats[0] !== "undefined" && typeof stats[0].data !== "undefined")
							stats[0].data.forEach((stat) => {
								if (parseInt(stat.gameID) === parseInt(game.id)) {
									game.bets = {};
									game.wins = {};

									game.bets.total = parseFloat(parseFloat(stat.bets.total / 100).toFixed(2));
									game.wins.total = parseFloat(parseFloat(stat.wins.total / 100).toFixed(2));
									game.bets.count = stat.bets.count;
									game.rtp = parseFloat((parseFloat(stat.wins.total / stat.bets.total) * 100).toFixed(2));
								}
							});

						if (typeof game.rtp === "undefined") game.rtp = 0;

						if (typeof game.bets === "undefined") game.bets = { total: 0, count: 0 };

						if (typeof game.wins === "undefined") game.wins = { total: 0, count: 0 };

						games.push({ name: game.params.name, integrator: this.getProviderIntegratorByID(game.providerID), bets: game.bets, wins: game.wins, rtp: game.rtp, poster: game.params.poster, bigPoster: game.params.bigPoster, gameID: game.id, providerID: game.providerID, categoryID: game.categoryID, biases: game.biases, date: game.date * 1000, active: game.active, filtered: true });
					});

					setRecoil(gameStore, { ...getRecoil(gameStore), loaded: true, data: games, pagination: { current: getRecoil(gameStore).pagination.current, total: Math.ceil(games.length / getRecoil(configStore).game.perPage) } });

					let status = cloneDeep(getRecoil(configStore).game.options.status);
					status[0].value = "All games (" + games.length + ")";
					status[1].value =
						"Enabled (" +
						games.filter((game) => {
							if (game.active === 1) return true;
						}).length +
						")";
					status[2].value =
						"Disabled (" +
						games.filter((game) => {
							if (game.active !== 1) return true;
						}).length +
						")";

					setRecoil(configStore, { ...getRecoil(configStore), game: { ...getRecoil(configStore).game, options: { ...getRecoil(configStore).game.options, status } } });

					this.request("get", { class: "casino", module: "provider", method: "list" }, { providerID: 0 }).then((list) => {
						let providers = [],
							providersOptions = [];

						providersOptions.push({ value: "All providers (" + list.data.length + ")", type: false });

						list.data.forEach((provider) => {
							providers.push({ id: provider.id, name: provider.name, path: provider.path, integrator: provider.integrator });
							providersOptions.push({
								path: provider.path,
								value:
									provider.name +
									" (" +
									games.filter((game) => {
										if (game.providerID === provider.id) return true;
									}).length +
									")",
								labelTag: "#" + provider.id,
								type: provider.id,
							});
						});

						setRecoil(gameStore, { ...getRecoil(gameStore), providers });
						setRecoil(configStore, { ...getRecoil(configStore), game: { ...getRecoil(configStore).game, options: { ...getRecoil(configStore).game.options, provider: providersOptions } } });

						this.request("get", { class: "casino", module: "category", method: "list" }, { providerID: 0 }).then((list) => {
							let categories = [],
								categoriesOptions = [];

							categoriesOptions.push({ value: "All categories (" + list.data.length + ")", type: false });

							list.data.forEach((category) => {
								categories.push({ id: category.id, name: category.name });
								categoriesOptions.push({
									value:
										category.name +
										" (" +
										games.filter((game) => {
											if (game.categoryID.toString().indexOf(category.id) !== -1) return true;
										}).length +
										")",
									labelTag: "#" + category.id,
									type: category.id,
								});
							});

							setRecoil(gameStore, { ...getRecoil(gameStore), categories });
							setRecoil(configStore, { ...getRecoil(configStore), game: { ...getRecoil(configStore).game, options: { ...getRecoil(configStore).game.options, category: categoriesOptions } } });
						});
					});
				});
			else {
				list.data.forEach((game) => {
					if (typeof game.rtp === "undefined") game.rtp = 0;

					if (typeof game.bets === "undefined") game.bets = { total: 0, count: 0 };

					if (typeof game.wins === "undefined") game.wins = { total: 0, count: 0 };

					games.push({ name: game.params.name, bets: game.bets, integrator: this.getProviderIntegratorByID(game.providerID), wins: game.wins, rtp: game.rtp, poster: game.params.poster, bigPoster: game.params.bigPoster, gameID: game.id, providerID: game.providerID, categoryID: game.categoryID, date: game.date * 1000, biases: game.biases, active: game.active, filtered: true });
				});

				setRecoil(gameStore, { ...getRecoil(gameStore), loaded: true, data: games, pagination: { current: 1, total: Math.ceil(games.length / getRecoil(configStore).game.perPage) } });

				let status = cloneDeep(getRecoil(configStore).game.options.status);
				status[0].value = "All games (" + games.length + ")";
				status[1].value =
					"Enabled (" +
					games.filter((game) => {
						if (game.active === 1) return true;
					}).length +
					")";
				status[2].value =
					"Disabled (" +
					games.filter((game) => {
						if (game.active !== 1) return true;
					}).length +
					")";

				setRecoil(configStore, { ...getRecoil(configStore), game: { ...getRecoil(configStore).game, options: { ...getRecoil(configStore).game.options, status } } });

				this.request("get", { class: "casino", module: "provider", method: "list" }, { providerID: 0 }).then((list) => {
					let providers = [],
						providersOptions = [];

					providersOptions.push({ value: "All providers (" + list.data.length + ")", type: false });

					list.data.forEach((provider) => {
						providers.push({ id: provider.id, name: provider.name, path: provider.path, integrator: provider.integrator });
						providersOptions.push({
							path: provider.path,
							value:
								provider.name +
								" (" +
								games.filter((game) => {
									if (game.providerID === provider.id) return true;
								}).length +
								")",
							labelTag: "#" + provider.id,
							type: provider.id,
						});
					});

					setRecoil(gameStore, { ...getRecoil(gameStore), providers });
					setRecoil(configStore, { ...getRecoil(configStore), game: { ...getRecoil(configStore).game, options: { ...getRecoil(configStore).game.options, provider: providersOptions } } });

					this.request("get", { class: "casino", module: "category", method: "list" }, { providerID: 0 }).then((list) => {
						let categories = [],
							categoriesOptions = [];

						categoriesOptions.push({ value: "All categories (" + list.data.length + ")", type: false });

						list.data.forEach((category) => {
							categories.push({ id: category.id, name: category.name });
							categoriesOptions.push({
								value:
									category.name +
									" (" +
									games.filter((game) => {
										if (game.categoryID.toString().indexOf(category.id) !== -1) return true;
									}).length +
									")",
								labelTag: "#" + category.id,
								type: category.id,
							});
						});

						setRecoil(gameStore, { ...getRecoil(gameStore), categories });
						setRecoil(configStore, { ...getRecoil(configStore), game: { ...getRecoil(configStore).game, options: { ...getRecoil(configStore).game.options, category: categoriesOptions } } });
					});
				});
			}
		});
	}

	loadPaymentData(brand) {
		if (typeof brand === "undefined") brand = getRecoil(userStore).brand.id;

		this.request("get", { class: "data", module: "wallet", method: "payways" }, { brand })
			.then((list) => {
				let payways = [{ value: "All methods", type: "all" }];
				list.forEach((payway) => {
					payways.push({ value: payway.method, type: payway.method });
				});

				setRecoil(loaderStore, { ...getRecoil(loaderStore), paymentData: true });
				setRecoil(configStore, { ...getRecoil(configStore), stats: { ...getRecoil(configStore).stats, options: { ...getRecoil(configStore).stats.options, payway: payways } }, pstats: { ...getRecoil(configStore).pstats, options: { ...getRecoil(configStore).pstats.options, payway: payways } } });
			})
			.catch(() => {
				setRecoil(loaderStore, { ...getRecoil(loaderStore), paymentData: true });
			});
	}

	getGameByID(gameID) {
		let name = "Game";

		if (getRecoil(gameStore).data.length)
			getRecoil(gameStore).data.forEach((game) => {
				if (game.gameID === gameID) name = game.name + " (" + gameID + ")";
			});

		return name;
	}

	getProviderByID(providerID) {
		let name = "Provider (" + providerID + ")";

		if (getRecoil(gameStore).providers.length)
			getRecoil(gameStore).providers.forEach((provider) => {
				if (provider.id === providerID) name = provider.name;
			});

		return name;
	}

	getProviderIntegratorByID(providerID) {
		let name = "Integrator (other)";

		if (getRecoil(gameStore).providers.length)
			getRecoil(gameStore).providers.forEach((provider) => {
				if (provider.id === providerID) {
					name = provider.integrator;
				}
			});

		return name;
	}

	getCategoryByID(categoryID) {
		let name = "Category (" + categoryID + ")";

		if (getRecoil(gameStore).categories.length)
			getRecoil(gameStore).categories.forEach((category) => {
				if (category.id.toString() === categoryID.toString()) name = category.name;
			});

		return name;
	}

	addNoty(name, text) {
		let notys = cloneDeep(getRecoil(notyStore));

		let id = notys.data.length === 0 ? 1 : notys.data[notys.data.length - 1].id + 1;
		notys.data.push({ id, name, text });
		setRecoil(notyStore, { ...notys });

		setTimeout(() => {
			this.removeNoty(id);
		}, 5000);
	}

	removeNoty(id) {
		let notys = cloneDeep(getRecoil(notyStore));

		notys.data.forEach((noty, i) => {
			if (noty.id === id) {
				notys.data.splice(i, 1);
				setRecoil(notyStore, { ...notys });
			}
		});
	}

	request(type, method, data) {
		const params = {
			type,
			class: method.class,
			module: method.module,
			method: method.method,
		};

		if (typeof data !== "undefined") params.data = data;

		return new Promise((resolve, reject) => {
			fetch(getRecoil(configStore).apiServlet, {
				method: "POST",
				headers: {
					Authorization: getRecoil(userStore).token ? "Bearer " + getRecoil(userStore).token : "",
					"X-Project-ID": getRecoil(userStore).brand ? getRecoil(userStore).brand.id : 0,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					...params,
				}),
			})
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					if (data.success) resolve(data.data);
					else {
						this.addNoty("Error", typeof data.error !== "string" ? JSON.stringify(data.error) : data.error);
						reject({ text: data.error, code: data.code, timestamp: data.timestamp });
					}
				});
		});
	}

	isUserLoaded() {
		return new Promise(function (resolve) {
			(function isUser() {
				if (getRecoil(userStore).level) return resolve();
				setTimeout(isUser, 200);
			})();
		});
	}

	isTopMenuLoaded() {
		return new Promise(function (resolve) {
			(function isTopMenu() {
				if (getRecoil(topMenuStore).length) return resolve();
				setTimeout(isTopMenu, 200);
			})();
		});
	}

	isPaymentDataLoaded() {
		return new Promise(function (resolve) {
			(function isPaymentData() {
				if (getRecoil(loaderStore).paymentData) return resolve();
				setTimeout(isPaymentData, 200);
			})();
		});
	}

	loadPayments(uid, type, project) {
		setRecoil(paymentStore, { ...getRecoil(paymentStore), currentSearch: "", show: true, loaded: false, list: {}, data: [], type, pagination: { current: 1, total: 1 } });
		setRecoil(playerPageStore, { ...getRecoil(playerPageStore), uid });

		this.request("get", { class: "users", module: "data", method: "payments" }, { uid, project: typeof project !== "undefined" ? project : 0 })
			.then((list) => {
				let data = [];

				list.deposits.forEach((item) => {
					item.filtered = true;
				});

				list.withdrawals.forEach((item) => {
					item.filtered = true;
				});

				if (type === 1) data = cloneDeep(list.deposits);
				else data = cloneDeep(list.withdrawals);

				setRecoil(paymentStore, { ...getRecoil(paymentStore), loaded: true, ...list, data, pagination: { current: 1, total: Math.ceil(data.length / getRecoil(configStore).players.payments.perPage) } });
			})
			.catch((e) => {
				setRecoil(paymentStore, { ...getRecoil(paymentStore), loaded: true, error: e });
			});
	}

	loadInfo(uid, project) {
		setRecoil(playerPageStore, { ...getRecoil(playerPageStore), loaded: false, show: true, uid });

		this.request("get", { class: "users", module: "data", method: "info" }, { uid, light: false, project: typeof project !== "undefined" ? project : 0 })
			.then((data) => {
				if (typeof data.logCasino === "undefined") data.logCasino = [];

				data.logCasino.forEach((item) => {
					item.filtered = true;
				});

				setRecoil(playerPageStore, { ...getRecoil(playerPageStore), data: { ...data, project: typeof project !== "undefined" ? project : 0, initialPromocodes: data.promoCodes, initialRegData: data.regdata, initialUserStats: data.userStats }, gameLog: { pagination: { current: 1, total: typeof data.logCasino !== "undefined" ? Math.ceil(data.logCasino.length / getRecoil(configStore).players.gameLogShort.perPage) : 0 } }, loaded: true });
				setTimeout(() => {
					this.loadLog(uid, project);
				}, 250);
			})
			.catch((e) => {
				setRecoil(playerPageStore, { ...getRecoil(playerPageStore), loaded: true, error: e });
			});
	}

	loadLog(uid, project) {
		setRecoil(playerPageStore, { ...getRecoil(playerPageStore), uid, log: { ...getRecoil(playerPageStore).log, loaded: false } });

		this.request("get", { class: "users", module: "wallet", method: "log" }, { uid, page: getRecoil(routeStore).currentPath.slice(1), type: getRecoil(playerPageStore).log.type, min: getRecoil(playerPageStore).log.min, max: getRecoil(playerPageStore).log.max, limit: getRecoil(playerPageStore).log.limit, project: typeof project !== "undefined" ? project : 0 })
			.then((data) => {
				setRecoil(playerPageStore, {
					...getRecoil(playerPageStore),
					pagination: {
						current: 1,
						total: Math.ceil(
							data.filter((item) => {
								return typeof item.mainItem === "undefined";
							}).length / getRecoil(configStore).players.log.perPage,
						),
					},
					log: { ...getRecoil(playerPageStore).log, loaded: true, data },
				});
			})
			.catch((e) => {
				console.log(e);
				setRecoil(playerPageStore, { ...getRecoil(playerPageStore), log: { ...getRecoil(playerPageStore).log, loaded: true, error: e } });
			});
	}

	loadGameLog(casinoID, sessionID, gameID) {
		setRecoil(playerGameLogStore, { ...getRecoil(playerGameLogStore), sessionID, gameID, show: true, count: 50 });

		this.request("get", { class: "users", module: "data", method: "log" }, { casinoID, sessionID })
			.then((data) => {
				setRecoil(playerGameLogStore, { ...getRecoil(playerGameLogStore), loaded: true, pagination: { current: 1, total: Math.ceil(data.length / getRecoil(configStore).players.gameLog.perPage) }, data: data.data, from: data.from, to: data.to, initialData: data.data });
			})
			.catch((e) => {
				setRecoil(playerPageStore, { ...getRecoil(playerPageStore), loaded: true, error: e });
			});
	}

	loadSessionsLog(casinoID, gameID, project) {
		setRecoil(playerSessionsModalStore, { ...getRecoil(playerSessionsModalStore), gameID, show: true });

		this.request("get", { class: "users", module: "data", method: "sessions" }, { casinoID, gameID, project: typeof project !== "undefined" ? project : 0 })
			.then((data) => {
				setRecoil(playerSessionsModalStore, { ...getRecoil(playerSessionsModalStore), loaded: true, pagination: { current: 1, total: Math.ceil(data.length / getRecoil(configStore).players.gameLog.perPage) }, data: data });
			})
			.catch((e) => {
				console.log(e);
				setRecoil(playerSessionsModalStore, { ...getRecoil(playerSessionsModalStore), loaded: true, error: e });
			});
	}
}

export default new Interface();
