import React from "react";

import Modal from "@cloudscape-design/components/modal";
import Table from "@cloudscape-design/components/table";
import Pagination from "@cloudscape-design/components/pagination";
import Preferences from "@cloudscape-design/components/collection-preferences";
import Badge from "@cloudscape-design/components/badge";
import Box from "@cloudscape-design/components/box";
import Alert from "@cloudscape-design/components/alert";
import Header from "@cloudscape-design/components/header";
import Link from "@cloudscape-design/components/link";

/* Recoil */
import { useRecoilValue, useSetRecoilState } from "recoil";
import { configStore } from "../stores/config";
import { staffSessionsModalStore } from "../stores/staffSessionsModal";
import { staffLogModalStore } from "../stores/staffLogModal";
/* ------- */

class StaffSessionsModal extends React.Component {
	loadSessionLog(token) {
		this.props.setStaffLog({ ...this.props.staffLog, token, show: true });
		this.props.Interface.request("get", { class: "data", module: "staff", method: "log" }, { token })
			.then((data) => {
				data.forEach((item) => {
					item.filtered = true;
				});

				this.props.setStaffLog({ ...this.props.staffLog, data: data.reverse(), pagination: { current: 1, total: Math.ceil(data.length / this.props.config.staff.log.perPage) }, loaded: true });
			})
			.catch((e) => {
				this.props.setStaffLog({ ...this.props.staffLog, loaded: true, error: e });
			});
	}

	getStatusColor(status) {
		switch (status) {
			case "active":
				return "green";
			case "expired":
				return "red";
			default:
				return "black";
		}
	}

	render() {
		return (
			<>
				<Modal
					size="max"
					visible={this.props.staffSessions.show}
					onDismiss={() => {
						this.props.setStaffSessions({ ...this.props.staffSessions, show: false, loaded: false, data: [], pagination: { current: 1, total: 0 } });
					}}
					header={<Header children="Sessions" counter={<>({this.props.staffSessions.data.length})</>} description="Below listed selected user's sessions" />}
					children={
						<>
							{this.props.staffSessions.loaded && this.props.staffSessions.error.text.length ? (
								<Box
									margin={{ bottom: "m" }}
									children={
										<Alert
											type="error"
											header="Error loading data:"
											children={
												<>
													<Box variant="p" children={this.props.staffSessions.error.text} />
													<hr />
													<Box
														variant="p"
														children={
															<>
																request error code: {this.props.staffSessions.error.code} | request timestamp: {this.props.staffSessions.error.timestamp}
															</>
														}
													/>
												</>
											}
										/>
									}
								/>
							) : (
								<></>
							)}
							<Table
								stickyHeader={true}
								wrapLines={true}
								loading={!this.props.staffSessions.loaded}
								columnDefinitions={[
									{
										id: "token",
										header: "Token",
										cell: (item) => (
											<Link
												onFollow={() => {
													this.loadSessionLog(item.token);
												}}>
												{item.token}
											</Link>
										),
									},
									{ id: "date", header: "Granted", cell: (item) => new Date(item.date).toLocaleDateString() + " " + new Date(item.date).toLocaleTimeString() },
									{
										id: "status",
										header: "Status",
										cell: (item) => <Badge className={[this.props.styles["badge"], this.props.styles[this.getStatusColor(item.status)]].join(" ")}>{item.status}</Badge>,
									},
								]}
								items={this.props.staffSessions.data.slice((this.props.staffSessions.pagination.current - 1) * this.props.config.staff.sessions.perPage, this.props.staffSessions.pagination.current * this.props.config.staff.sessions.perPage)}
								pagination={
									<Pagination
										disabled={!this.props.staffSessions.loaded}
										currentPageIndex={this.props.staffSessions.pagination.current}
										pagesCount={this.props.staffSessions.pagination.total}
										onChange={(page) => {
											this.props.setStaffSessions({ ...this.props.staffSessions, pagination: { ...this.props.staffSessions.pagination, current: page.detail.currentPageIndex } });
										}}
									/>
								}
								preferences={
									<Preferences
										cancelLabel="Cancel"
										confirmLabel="Confirm"
										pageSizePreference={{ title: "Sessions per page", options: this.props.config.staff.sessions.preferences.perPage }}
										preferences={{ pageSize: this.props.config.staff.sessions.perPage }}
										title="Preferences"
										onConfirm={(preferences) => {
											localStorage.setItem("staff.sessions.perPage", preferences.detail.pageSize);
											this.props.setConfig({ ...this.props.config, staff: { ...this.props.config.staff, sessions: { ...this.props.config.staff.sessions, perPage: preferences.detail.pageSize } } });
											this.props.setStaffSessions({ ...this.props.staffSessions, pagination: { current: 1, total: Math.ceil(this.props.staffSessions.data.length / preferences.detail.pageSize) } });
										}}
									/>
								}
								empty="No sessions found with requested filter"
							/>
						</>
					}
				/>
			</>
		);
	}
}

function withRecoil(Component) {
	return function WrappedComponent(props) {
		const config = useRecoilValue(configStore);
		const setConfig = useSetRecoilState(configStore);
		const staffSessions = useRecoilValue(staffSessionsModalStore);
		const setStaffSessions = useSetRecoilState(staffSessionsModalStore);
		const staffLog = useRecoilValue(staffLogModalStore);
		const setStaffLog = useSetRecoilState(staffLogModalStore);

		return <Component {...props} staffLog={staffLog} setStaffLog={setStaffLog} config={config} setConfig={setConfig} staffSessions={staffSessions} setStaffSessions={setStaffSessions} />;
	};
}

export default withRecoil(StaffSessionsModal);
