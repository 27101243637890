import { atom } from "recoil";

export const reportStore = atom({
	key: "reportStore",
	default: {
		loaded: false,
		brand: false,
		error: {
			text: "",
			code: 0,
			timestamp: 0,
		},
		data: [],
		chart: { bets: { total: [], count: [] }, wins: { total: [], count: [] }, rtp: [] },
		average: 0,
		total: 0,
		period: 0,
		range: false,
		offset: 0,
		limit: 0,
		sorting: {
			sortingColumn: {
				sortingField: "deposits",
			},
			sortingDescending: false,
		},
		currentOption: {
			group: false,
			groupFull: false,
			groupProvider: false,
			payWay: false,
			period: false,
			currency: false,
			costsGroup: false,
			integrator: false,
			sortTags: [],
		},
		currentGroup: false,
		currentGroupFull: false,
		currentGroupProvider: false,
		currentPayWay: false,
		currentPeriod: false,
		currentCostsGroup: false,
		currentIntegrator: false,
		currentSortTags: [],
		currency: false,
		currentReport: "monitoring",
		visibleSeries: {},
	},
});

export const reportsAddCostsModalStore = atom({
	key: "reportsAddCostsModalStore",
	default: {
		show: false,
		loaded: true,
		brand: false,
		group: false,
		currency: false,
		name: "",
		amount: "",
		brand: false,
		data: "",
		date: "",
	},
});

export const reportsAddSettlementsModalStore = atom({
	key: "reportsAddSettlementsModalStore",
	default: {
		show: false,
		loaded: true,
		brand: false,
		name: "",
		amount: "",
		gateAmount: "",
		gateCurrency: "RUB",
		brand: false,
		data: "",
		date: "",
	},
});
