import React from "react";

import cloneDeep from "lodash/cloneDeep";

import Table from "@cloudscape-design/components/table";
import Pagination from "@cloudscape-design/components/pagination";
import Header from "@cloudscape-design/components/header";
import Input from "@cloudscape-design/components/input";
import Button from "@cloudscape-design/components/button";
import Box from "@cloudscape-design/components/box";
import Grid from "@cloudscape-design/components/grid";
import Badge from "@cloudscape-design/components/badge";
import Preferences from "@cloudscape-design/components/collection-preferences";
import Alert from "@cloudscape-design/components/alert";
import Spinner from "@cloudscape-design/components/spinner";
import Select from "@cloudscape-design/components/select";

/* Recoil */
import { useRecoilValue, useSetRecoilState } from "recoil";
import { configStore } from "../stores/config";
import SpaceBetween from "@cloudscape-design/components/space-between";
import { playerModalStore } from "../stores/playerModal";
import { bonusesStore } from "../stores/bonuses";
import { bonusesAddModalStore } from "../stores/bonusesAddModal";
/* ------- */

class Bonuses extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.loadBonuses("promocodes");
	}

	loadBonuses() {
		this.props.setBonuses({ ...this.props.bonuses, data: [], loaded: false });

		const params = {
			order: this.props.bonuses.currentOrder,
			searchType: this.props.bonuses.currentSearchType,
			direction: this.props.bonuses.currentDirection,
		};

		this.props.Interface.request("get", { class: "data", module: "bonuses", method: "list" }, { ...params })
			.then((list) => {
				list.forEach((item) => {
					item.filtered = true;
				});

				list.sort((a, b) => a.date - b.date);

				/*let uidWithdrawals = {}, listFormatted = [];
      
            list.forEach((item) => {
              if (typeof uidWithdrawals['w' + item.uid] === "undefined")
                uidWithdrawals['w' + item.uid] = [];
      
              uidWithdrawals['w' + item.uid].push(item);
            });
      
            Object.keys(uidWithdrawals).forEach((key) => {
              uidWithdrawals[key].forEach((item) => {
                listFormatted.push(item);
              });
            });*/

				this.props.setBonuses({ ...this.props.bonuses, loaded: true, currentSearch: this.currentSearch, data: list, pagination: { current: 1, total: Math.ceil(list.length / this.props.config.bonuses.perPage) } });
			})
			.catch((e) => {
				this.props.setBonuses({ ...this.props.bonuses, loaded: true, error: e });
			});
	}

	filter() {
		let data = cloneDeep(this.props.bonuses.data);

		if (!this.props.bonuses.currentSearch.length) {
			data.forEach((item) => {
				item.filtered = true;
			});
			this.props.setBonuses({ ...this.props.bonuses, data });

			setTimeout(() => {
				let total = Math.ceil(
					this.props.bonuses.data.filter((item) => {
						return item.filtered;
					}).length / this.props.config.withdrawals.perPage,
				);
				this.props.setBonuses({ ...this.props.bonuses, pagination: { current: 1, total } });
			}, 0);
		} else {
			data.forEach((item) => {
				if (item.amount.toString().indexOf(this.props.bonuses.currentSearch) !== -1 || item.type.toUpperCase().indexOf(this.props.bonuses.currentSearch.toUpperCase()) !== -1 || item.id.toString().indexOf(this.props.bonuses.currentSearch) !== -1 || item.uid.toString().indexOf(this.props.bonuses.currentSearch) !== -1 || item.wallet.toString().indexOf(this.props.bonuses.currentSearch) !== -1) item.filtered = true;
				else item.filtered = false;
			});

			this.props.setBonuses({ ...this.props.bonuses, data });

			setTimeout(() => {
				let total = Math.ceil(
					this.props.bonuses.data.filter((item) => {
						return item.filtered;
					}).length / this.props.config.withdrawals.perPage,
				);
				this.props.setBonuses({ ...this.props.bonuses, pagination: { current: 1, total } });
			}, 0);
		}
	}

	compare(a, b) {
		if (a[this.props.bonuses.sorting.sortingColumn.sortingField] < b[this.props.bonuses.sorting.sortingColumn.sortingField]) {
			return this.props.bonuses.sorting.isDescending ? 1 : -1;
		}

		if (a[this.props.bonuses.sorting.sortingColumn.sortingField] > b[this.props.bonuses.sorting.sortingColumn.sortingField]) {
			return this.props.bonuses.sorting.isDescending ? -1 : 1;
		}

		return 0;
	}

	managePayment(action, id, uid, note) {
		let withdrawals = cloneDeep(this.props.bonuses.data);
		let withdrawalsToProcess = typeof id === "undefined" ? cloneDeep(this.props.bonuses.selectedItems) : [{ id, uid }];

		withdrawalsToProcess.forEach((withdrawal, i) => {
			withdrawals.forEach((item, j) => {
				if (item.id === withdrawal.id) item.process = true;
			});
		});

		this.props.setBonuses({ ...this.props.bonuses, selectedItems: [], data: withdrawals });

		let wirhdrawalsStatuses = {},
			checker = setInterval(() => {
				let withdrawals = cloneDeep(this.props.bonuses.data);

				Object.keys(wirhdrawalsStatuses).forEach((processedWithdrawal) => {
					withdrawals.forEach((item) => {
						if (item.id === parseInt(processedWithdrawal)) {
							item.status = wirhdrawalsStatuses[processedWithdrawal].status;

							if (typeof wirhdrawalsStatuses[processedWithdrawal].balance !== "undefined") item.balance = wirhdrawalsStatuses[processedWithdrawal].balance;

							item.process = false;
						}
					});
				});

				this.props.setBonuses({ ...this.props.bonuses, data: withdrawals });

				if (Object.keys(wirhdrawalsStatuses).length >= withdrawalsToProcess.length) clearInterval(checker);
			}, 500);

		withdrawalsToProcess.forEach((withdrawal, i) => {
			setTimeout(
				() => {
					const params = {
						id: withdrawal.id,
						uid: withdrawal.uid,
						note: typeof note !== "undefined" ? note : "default",
					};

					this.props.Interface.request("set", { class: "data", module: "withdrawals", method: action }, { ...params })
						.then((data) => {
							wirhdrawalsStatuses[withdrawal.id] = { status: data.status };

							if (typeof data.balance !== "undefined") wirhdrawalsStatuses[withdrawal.id].balance = data.balance;
						})
						.catch((e) => {
							wirhdrawalsStatuses[withdrawal.id] = { status: e.text };
							// this.props.setBonuses({ ...this.props.bonuses, loaded: true, error: e });
						});
				},
				(i + 1) * 1000,
			);
		});
	}

	handleKeyPress = (event) => {
		if (event.detail.key === "Enter")
			setTimeout(() => {
				this.loadBonuses();
			}, 0);
	};

	getTypeColor(type) {
		switch (type) {
			case 1:
				return "purple";
			case 0:
				return "blue";
			case 2:
				return "green";
			default:
				return "black";
		}
	}

	getTypeByCode(type) {
		switch (type) {
			case 1:
				return "Affiliative";
			case 0:
				return "Public";
			case 2:
				return "Dedicated";
			default:
				return "Unknown";
		}
	}

	handleUIDClick(event, uid) {
		if (!event.detail.shiftKey) this.props.Interface.loadInfo(uid);
		else {
			let hasProperty = false,
				selectedItemsCurrent = cloneDeep(this.props.bonuses.selectedItems);

			selectedItemsCurrent.forEach((item) => {
				if (item.uid === uid) hasProperty = true;
			});

			let j = 0,
				withdrawals = cloneDeep(this.props.bonuses.data).slice((this.props.bonuses.pagination.current - 1) * this.props.config.withdrawals.perPage, this.props.bonuses.pagination.current * this.props.config.withdrawals.perPage),
				selectedItems = cloneDeep(this.props.bonuses.selectedItems);

			withdrawals.forEach((item) => {
				if (item.uid === uid) {
					if (hasProperty)
						selectedItems.forEach((itemSelected, i) => {
							if (itemSelected.id === item.id) {
								selectedItems.splice(i, 1);
								j++;
							}
						});
					else {
						selectedItems.push(item);
						j++;
					}
				}
			});

			/* if (hasProperty)
              this.props.Interface.addNoty("Info", "Deselected " + j + " withdrawals of User with UID #" + uid);
            else
              this.props.Interface.addNoty("Info", "Selected " + j + " withdrawals of User with UID #" + uid);
            */

			this.props.setBonuses({ ...this.props.bonuses, selectedItems });
		}
	}

	makeAction(action) {
		switch (action) {
			case 1:
				let list = cloneDeep(this.props.bonuses.data);

				list.sort((a, b) => a.date - b.date);

				let uidWithdrawals = {},
					listFormatted = [];

				list.forEach((item) => {
					if (typeof uidWithdrawals["w" + item.uid] === "undefined") uidWithdrawals["w" + item.uid] = [];

					uidWithdrawals["w" + item.uid].push(item);
				});

				Object.keys(uidWithdrawals).forEach((key) => {
					uidWithdrawals[key].forEach((item) => {
						listFormatted.push(item);
					});
				});

				this.props.Interface.addNoty("Info", "Sort type was set to basic");
				this.props.setBonuses({ ...this.props.bonuses, data: listFormatted });
				break;
			case 2:
				let i = 0,
					withdrawals = cloneDeep(this.props.bonuses.data).slice((this.props.bonuses.pagination.current - 1) * this.props.config.withdrawals.perPage, this.props.bonuses.pagination.current * this.props.config.withdrawals.perPage),
					selectedItems = cloneDeep(this.props.bonuses.selectedItems);

				withdrawals.forEach((item) => {
					if (item.amount > item.balance / 100) {
						selectedItems.push(item);
						i++;
					}
				});

				if (!i) this.props.Interface.addNoty("Info", "No withdrawals below balance was found");
				else {
					this.props.Interface.addNoty("Success", "Selected " + i + " withdrawals below balance on this page");
					this.props.setBonuses({ ...this.props.bonuses, selectedItems });
				}
				break;
		}
	}

	render() {
		return (
			<>
				{this.props.bonuses.loaded && this.props.bonuses.error.text.length ? (
					<Box
						margin={{ bottom: "m" }}
						children={
							<Alert
								type="error"
								header="Error loading data:"
								children={
									<>
										<Box variant="p" children={this.props.bonuses.error.text} />
										<hr />
										<Box
											variant="p"
											children={
												<>
													request error code: {this.props.bonuses.error.code} | request timestamp: {this.props.bonuses.error.timestamp}
												</>
											}
										/>
									</>
								}
							/>
						}
					/>
				) : (
					<></>
				)}

				<Table
					stickyHeader="true"
					trackBy="id"
					selectionType="multi"
					onSelectionChange={(selection) => {
						this.props.setBonuses({ ...this.props.bonuses, selectedItems: selection.detail.selectedItems });
					}}
					selectedItems={this.props.bonuses.selectedItems}
					loading={!this.props.bonuses.loaded}
					sortingColumn={{ sortingField: this.props.bonuses.sorting.sortingColumn.sortingField }}
					sortingDescending={this.props.bonuses.sorting.isDescending}
					onSortingChange={(sorting) => {
						this.props.setBonuses({ ...this.props.bonuses, sorting: sorting.detail });
						setTimeout(() => {
							this.props.setBonuses({
								...this.props.bonuses,
								data: cloneDeep(this.props.bonuses.data).sort((a, b) => {
									return this.compare(a, b);
								}),
							});
						}, 0);
					}}
					columnDefinitions={[
						{
							id: "id",
							header: "ID",
							sortingField: "id",
							cell: (item) => (
								<Button
									iconName={"status-info"}
									className={this.props.styles["btnUID"]}
									variant="link"
									onClick={() => {
										this.props.Interface.loadPayments(item.uid, 1);
									}}
									children={item.id}
								/>
							),
						},
						{ id: "code", header: "Code", sortingField: "code", cell: (item) => item.code },
						{
							id: "uid",
							header: "UID",
							sortingField: "uid",
							cell: (item) =>
								item.uid ? (
									<Button
										iconName={"status-in-progress"}
										className={[this.props.styles["btnUID"], this.props.styles["blue"]].join(" ")}
										variant="normal"
										onClick={(ev) => {
											this.handleUIDClick(ev, item.uid);
										}}
										children={item.uid}
									/>
								) : (
									"none"
								),
						},
						{ id: "value", header: "Value", sortingField: "value", cell: (item) => <>{new Intl.NumberFormat("ru-RU").format(item.value)} RUB.</> },
						{ id: "count", header: "Count", sortingField: "count", cell: (item) => item.count },
						{ id: "type", header: "Type", sortingField: "type", cell: (item) => <Badge className={[this.props.styles["badge"], this.props.styles[this.getTypeColor(item.type)]].join(" ")}>{this.getTypeByCode(item.type)}</Badge> },
						{ id: "date", header: "Date", sortingField: "date", cell: (item) => <>{new Date(item.date).toLocaleDateString() + " " + new Date(item.date).toLocaleTimeString()}</> },
						{
							id: "actions",
							header: "Actions",
							width: 355,
							minWidth: 355,
							cell: (item) => (
								<>
									{item.process ? (
										<Spinner className={this.props.styles["withdrawalSpinner"]} variant="normal" size="big" />
									) : (
										<SpaceBetween direction="horizontal" size="l">
											<Button
												disabled={!item.count}
												className={[this.props.styles["players-search-button"], this.props.styles["btn"], item.count ? this.props.styles["red"] : ""].join(" ")}
												onClick={() => {
													setTimeout(() => {
														this.toggleValueModal(5, item);
													}, 0);
												}}
												variant="primary"
												children="Wipe off"
											/>
											<Button
												className={[this.props.styles["players-search-button"], this.props.styles["btn"], this.props.styles["gray"]].join(" ")}
												onClick={() => {
													setTimeout(() => {
														this.toggleValueModal(4, item);
													}, 0);
												}}
												variant="primary"
												children="Edit"
											/>
										</SpaceBetween>
									)}
								</>
							),
						},
					]}
					items={this.props.bonuses.data
						.filter((item) => {
							return item.filtered;
						})
						.slice((this.props.bonuses.pagination.current - 1) * this.props.config.withdrawals.perPage, this.props.bonuses.pagination.current * this.props.config.withdrawals.perPage)}
					pagination={
						<Pagination
							disabled={!this.props.bonuses.loaded}
							currentPageIndex={this.props.bonuses.pagination.current}
							pagesCount={this.props.bonuses.pagination.total}
							onChange={(page) => {
								this.props.setBonuses({ ...this.props.bonuses, pagination: { ...this.props.bonuses.pagination, current: page.detail.currentPageIndex } });
							}}
						/>
					}
					preferences={
						<Preferences
							cancelLabel="Cancel"
							confirmLabel="Confirm"
							pageSizePreference={{ title: "Logs per page", options: this.props.config.withdrawals.preferences.perPage }}
							preferences={{ pageSize: this.props.config.withdrawals.perPage }}
							title="Preferences"
							onConfirm={(preferences) => {
								localStorage.setItem("withdrawals.perPage", preferences.detail.pageSize);
								this.props.setConfig({ ...this.props.config, withdrawals: { ...this.props.config.withdrawals, perPage: preferences.detail.pageSize } });
								this.props.setBonuses({
									...this.props.bonuses,
									pagination: {
										current: 1,
										total: Math.ceil(
											this.props.bonuses.data.filter((item) => {
												return item.filtered;
											}).length / preferences.detail.pageSize,
										),
									},
								});
							}}
						/>
					}
					filter={
						<Box
							margin={{ bottom: "m" }}
							children={
								<Grid
									gridDefinition={[{ colspan: 3 }, { colspan: 3 }, { colspan: 3 }, { colspan: 3 }]}
									children={
										<>
											<Select
												filteringType="auto"
												disabled={!this.props.bonuses.loaded || this.props.bonuses.currentOption.order.type === "deposits" || this.props.bonuses.currentOption.order.type === "withdrawals" ? true : false}
												placeholder="Direction"
												selectedOption={this.props.bonuses.currentOption.direction}
												onChange={(option) => {
													option.detail.selectedOption.type !== this.props.bonuses.currentDirection && this.props.setPlayers({ ...this.props.bonuses, currentDirection: option.detail.selectedOption.type, currentOption: { ...this.props.bonuses.currentOption, direction: option.detail.selectedOption } });
												}}
												options={this.props.config.players.options.direction}
											/>
											<Select
												filteringType="auto"
												disabled={!this.props.bonuses.loaded}
												placeholder="Order"
												selectedOption={this.props.bonuses.currentOption.order}
												onChange={(option) => {
													option.detail.selectedOption.order !== this.props.bonuses.currentOrder && this.props.setPlayers({ ...this.props.bonuses, currentOrder: option.detail.selectedOption.type, currentOption: { ...this.props.bonuses.currentOption, order: option.detail.selectedOption } });
													option.detail.selectedOption.order !== this.props.bonuses.currentOrder &&
														setTimeout(() => {
															this.loadPlayers();
														}, 0);
												}}
												options={this.props.config.players.options.order}
											/>
											<Select
												filteringType="auto"
												disabled={!this.props.bonuses.loaded}
												placeholder="Type"
												selectedOption={this.props.bonuses.currentOption.type}
												onChange={(option) => {
													option.detail.selectedOption.type !== this.props.bonuses.currentSearchType && this.props.setPlayers({ ...this.props.bonuses, currentSearchType: option.detail.selectedOption.type, currentOption: { ...this.props.bonuses.currentOption, type: option.detail.selectedOption } });
												}}
												options={this.props.config.players.options.type}
											/>
											<Input
												disabled={!this.props.bonuses.loaded}
												onKeyDown={this.handleKeyPress}
												disableBrowserAutocorrect={true}
												placeholder="UID, name or something"
												inputMode="search"
												type="search"
												value={this.props.bonuses.currentSearch}
												onChange={(input) => {
													this.props.setPlayers({ ...this.props.bonuses, currentSearch: input.detail.value });
													this.currentSearch = input.detail.value;
												}}
											/>
										</>
									}
								/>
							}
						/>
					}
					header={
						<Box
							margin={{ bottom: "m" }}
							children={
								<Header
									children="Promo codes"
									counter={
										<>
											(
											{
												this.props.bonuses.data.filter((item) => {
													return item.filtered;
												}).length
											}
											)
										</>
									}
									actions={
										<SpaceBetween direction="horizontal" size="l">
											<Button
												iconName="add-plus"
												disabled={!this.props.bonuses.loaded}
												className={[this.props.styles["players-search-button"], this.props.styles["btn"]].join(" ")}
												onClick={() => {
													setTimeout(() => {
														this.props.setBonusesAddModal({ ...this.props.bonusesAddModal, show: true });
													}, 0);
												}}
												variant="primary"
												children="Create code"
											/>
											<Button
												iconName="status-stopped"
												disabled={!this.props.bonuses.loaded || !this.props.bonuses.selectedItems.length}
												className={[this.props.styles["players-search-button"], this.props.styles["btn"], !this.props.bonuses.loaded || !this.props.bonuses.selectedItems.length ? "" : this.props.styles["red"]].join(" ")}
												onClick={() => {
													setTimeout(() => {
														this.toggleValueModal(5);
													}, 0);
												}}
												variant="primary"
												children="Wipe off"
											/>
											<Button
												iconName="status-negative"
												disabled={!this.props.bonuses.loaded || !this.props.bonuses.selectedItems.length}
												className={[this.props.styles["players-search-button"], this.props.styles["btn"], !this.props.bonuses.loaded || !this.props.bonuses.selectedItems.length ? "" : this.props.styles["gray"]].join(" ")}
												onClick={() => {
													setTimeout(() => {
														this.toggleValueModal(4);
													}, 0);
												}}
												variant="primary"
												children="Edit"
											/>
										</SpaceBetween>
									}
									description={
										<>
											<Box variant="span" fontWeight="normal">
												Selected promocodes:
											</Box>{" "}
											<Box variant="span" fontWeight="bold">
												{this.props.bonuses.selectedItems.length}
											</Box>
										</>
									}
								/>
							}
						/>
					}
					empty="No withdrawals found with requested filter"
				/>
			</>
		);
	}
}

function withRecoil(Component) {
	return function WrappedComponent(props) {
		const bonuses = useRecoilValue(bonusesStore);
		const setBonuses = useSetRecoilState(bonusesStore);
		const config = useRecoilValue(configStore);
		const setConfig = useSetRecoilState(configStore);
		const playerModal = useRecoilValue(playerModalStore);
		const setPlayerModal = useSetRecoilState(playerModalStore);
		const bonusesAddModal = useRecoilValue(bonusesAddModalStore);
		const setBonusesAddModal = useSetRecoilState(bonusesAddModalStore);

		return <Component {...props} bonuses={bonuses} setBonuses={setBonuses} config={config} setConfig={setConfig} playerModal={playerModal} setPlayerModal={setPlayerModal} bonusesAddModal={bonusesAddModal} setBonusesAddModal={setBonusesAddModal} />;
	};
}

export default withRecoil(Bonuses);
