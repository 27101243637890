import React from "react";

import cloneDeep from "lodash/cloneDeep";

import Modal from "@cloudscape-design/components/modal";
import Input from "@cloudscape-design/components/input";
import Button from "@cloudscape-design/components/button";
import Box from "@cloudscape-design/components/box";
import Select from "@cloudscape-design/components/select";
import Multiselect from "@cloudscape-design/components/multiselect";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Toggle from "@cloudscape-design/components/toggle";
import Spinner from "@cloudscape-design/components/spinner";

/* Recoil */
import { useRecoilValue, useSetRecoilState } from "recoil";
import { configStore } from "../stores/config";
import { bonusesStore } from "../stores/bonuses";
import { bonusesAddModalStore } from "../stores/bonusesAddModal";
/* ------- */

class BonusesAddModal extends React.Component {
	componentWillUnmount() {
		this.props.setBonusesAddModal({ ...this.props.bonusesAddModal, show: false, count: "", value: "", type: false, note: "" });
	}

	addCode() {
		this.props.setBonusesAddModal({ ...this.props.bonusesAddModal, loaded: false, checked: false });

		let params = {};

		if (this.props.bonusesAddModal.type.type >= 1) params.uid = this.props.bonusesAddModal.uid;

		params.count = this.props.bonusesAddModal.count;
		params.value = this.props.bonusesAddModal.value;
		params.note = this.props.bonusesAddModal.note;

		params.type = this.props.bonusesAddModal.type.type;

		this.props.Interface.request("set", { class: "data", module: "bonuses", method: "add" }, { ...params })
			.then(() => {
				this.props.setBonusesAddModal({ ...this.props.bonusesAddModal, loaded: true });
				setTimeout(() => {
					this.props.setBonusesAddModal({ ...this.props.bonusesAddModal, show: false });
				}, 0);
			})
			.catch((e) => {
				this.props.setBonusesAddModal({ ...this.props.bonusesAddModal, loaded: true, checked: true });
				console.log(e);
			});
	}

	updateParam(key, value) {
		let params = cloneDeep(this.props.bonusesAddModal);
		params[key] = value;

		this.props.setBonusesAddModal({ ...params });
	}

	render() {
		return (
			<>
				<Modal
					visible={this.props.bonusesAddModal.show}
					onDismiss={() => {
						this.props.setBonusesAddModal({ ...this.props.bonusesAddModal, show: false });
					}}
					header="Add promocode"
					children={
						<>
							<SpaceBetween direction="vertical" size="m">
								<Select
									filteringType="auto"
									invalid={typeof this.props.bonusesAddModal.type.type === "undefined" && this.props.bonusesAddModal.checked ? true : false}
									disabled={!this.props.bonuses.loaded}
									placeholder="Type"
									selectedOption={this.props.bonusesAddModal.type}
									onChange={(option) => {
										this.props.setBonusesAddModal({ ...this.props.bonusesAddModal, type: option.detail.selectedOption });
									}}
									options={this.props.config.bonuses.options.type}
								/>
								<Input
									invalid={isNaN(parseInt(this.props.bonusesAddModal.count)) && this.props.bonusesAddModal.checked ? true : false}
									inputMode="number"
									type="number"
									disableBrowserAutocorrect={true}
									placeholder="Count"
									value={this.props.bonusesAddModal.count}
									onChange={(input) => {
										this.updateParam("count", input.detail.value);
									}}
								/>
								<Input
									invalid={isNaN(parseInt(this.props.bonusesAddModal.value)) && this.props.bonusesAddModal.checked ? true : false}
									inputMode="number"
									type="number"
									disableBrowserAutocorrect={true}
									placeholder="Value (RUB.)"
									value={this.props.bonusesAddModal.value}
									onChange={(input) => {
										this.updateParam("value", input.detail.value);
									}}
								/>
								<Input
									invalid={isNaN(parseInt(this.props.bonusesAddModal.note)) && this.props.bonusesAddModal.checked ? true : false}
									inputMode="text"
									type="text"
									disableBrowserAutocorrect={true}
									placeholder="Reason for creating"
									value={this.props.bonusesAddModal.note}
									onChange={(input) => {
										this.updateParam("note", input.detail.value);
									}}
								/>
								{this.props.bonusesAddModal.type.type >= 1 ? (
									<Input
										invalid={this.props.bonusesAddModal.type.type >= 1 && isNaN(parseInt(this.props.bonusesAddModal.uid)) && this.props.bonusesAddModal.checked ? true : false}
										inputMode="number"
										type="number"
										disableBrowserAutocorrect={true}
										placeholder="UID"
										value={this.props.bonusesAddModal.uid}
										onChange={(input) => {
											this.updateParam("uid", input.detail.value);
										}}
									/>
								) : (
									<></>
								)}
							</SpaceBetween>
						</>
					}
					footer={
						<>
							<Box
								float="right"
								children={
									<>
										<Box
											variant="span"
											margin={{ right: "s" }}
											children={
												<Button
													variant="normal"
													onClick={() => {
														this.props.setBonusesAddModal({ ...this.props.bonusesAddModal, show: false });
													}}
													children="Close"
												/>
											}
										/>
										<Box
											variant="span"
											children={
												<Button
													variant="primary"
													onClick={() => {
														this.addCode();
													}}
													children="Ok"
												/>
											}
										/>
									</>
								}
							/>
						</>
					}
				/>
			</>
		);
	}
}

function withRecoil(Component) {
	return function WrappedComponent(props) {
		const bonuses = useRecoilValue(bonusesStore);
		const setBonuses = useSetRecoilState(bonusesStore);
		const config = useRecoilValue(configStore);
		const setConfig = useSetRecoilState(configStore);
		const bonusesAddModal = useRecoilValue(bonusesAddModalStore);
		const setBonusesAddModal = useSetRecoilState(bonusesAddModalStore);
		return <Component {...props} bonuses={bonuses} setBonuses={setBonuses} setConfig={setConfig} config={config} bonusesAddModal={bonusesAddModal} setBonusesAddModal={setBonusesAddModal} />;
	};
}

export default withRecoil(BonusesAddModal);
