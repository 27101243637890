import { atom } from "recoil";

export const domainsStore = atom({
	key: "domainsStore",
	default: {
		loaded: false,
		error: {
			text: "",
			code: 0,
			timestamp: 0,
		},
		currentSearch: "",
		selectedItems: [],
		sorting: {
			sortingColumn: {
				sortingField: "",
			},
			sortingDescending: false,
		},
		pagination: {
			current: 1,
			total: 0,
		},
		data: [],
	},
});
