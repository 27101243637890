import { atom } from "recoil";

export const gameStore = atom({
	key: "gameStore",
	default: {
		loaded: false,
		data: [],
		providers: [],
		categories: [],
		error: {
			text: "",
			code: 0,
			timestamp: 0,
		},
		currentProvider: false,
		currentCategory: false,
		currentStatus: false,
		currentSearch: "",
		sorting: {
			sortingColumn: {
				sortingField: "",
			},
			sortingDescending: false,
		},
		rtp: {},
		pagination: {
			current: 1,
			total: 0,
		},
		currentOption: {
			type: false,
			provider: false,
			direction: false,
		},
		selectedItems: [],
	},
});
