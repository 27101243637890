import { atom } from "recoil";

export const domainsAddModalStore = atom({
	key: "domainsAddModalStore",
	default: {
		show: false,
		loaded: true,
		domain: "",
		currentOption: {
			type: false,
		},
	},
});
