import React from "react";

import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

import RangePicker from "@cloudscape-design/components/date-range-picker";
import Alert from "@cloudscape-design/components/alert";
import Table from "@cloudscape-design/components/table";
import Box from "@cloudscape-design/components/box";
import Header from "@cloudscape-design/components/header";
import LineChart from "@cloudscape-design/components/line-chart";
import BarChart from "@cloudscape-design/components/bar-chart";
import Container from "@cloudscape-design/components/container";
import Grid from "@cloudscape-design/components/grid";
import Tabs from "@cloudscape-design/components/tabs";
import Select from "@cloudscape-design/components/select";
import Button from "@cloudscape-design/components/button";
import Link from "@cloudscape-design/components/link";
import Spinner from "@cloudscape-design/components/spinner";
import Multiselect from "@cloudscape-design/components/multiselect";

import { colorChartsStatusNeutral, colorChartsStatusHigh, colorChartsStatusPositive, colorChartsStatusMedium, colorChartsStatusLow, colorChartsStatusInfo } from "@cloudscape-design/design-tokens";

/* Recoil */
import { useRecoilValue, useSetRecoilState } from "recoil";
import { configStore } from "../stores/config";
import { reportsAddCostsModalStore, reportsAddSettlementsModalStore, reportStore } from "../stores/reports";
import { SpaceBetween } from "@cloudscape-design/components";
import { userStore } from "../stores/access";
/* ------- */

class Reports extends React.PureComponent {
	constructor(props) {
		super(props);
		this.rawData = [];
		this.rawGameData = [];
		this.netReportData = [];
	}

	componentDidMount() {
		this.props.setReports({ ...this.props.reports, brand: this.getBrandsAsOptions()[0] });
		this.props.setReportsAddCostsModal({ ...this.props.reportsAddCostsModal, group: this.props.config.reports.options.group[1], brand: this.getBrandsAsOptions()[0], currency: this.props.config.reports.options.currency[0] });

		setTimeout(() => {
			this.props.Interface.loadPaymentData(this.getBrandsAsOptions()[0].value);

			this.props.Interface.isPaymentDataLoaded().then(() => {
				let currentPeriod = this.props.config.stats.options.period[0].type;

				setTimeout(() => {
					this.props.setReports({
						...this.props.reports,
						brand: this.getBrandsAsOptions()[0],
						loaded: false,
						data: [],
						limit: currentPeriod.limit,
						offset: currentPeriod.offset,
						range: { type: "absolute", startDate: this.formatLimitOffset(currentPeriod.limit, currentPeriod.offset).from, endDate: this.formatLimitOffset(this.props.reports.limit, this.props.reports.offset).to },
						currency: this.props.config.reports.options.currency[0].type,
						currentGroup: this.props.config.stats.options.delimiter[0].type,
						currentSortTags: [this.getProviderSortTags()[0].value, this.getProviderSortTags()[1].value],
						currentIntegrator: this.props.config.game.options.integrator[0].type,
						currentCostsGroup: this.props.config.reports.options.group[0].type,
						currentGroupProvider: this.props.config.stats.options.delimiterProvider[0].type,
						currentGroupFull: this.props.config.stats.options.delimiterFull[0].type,
						currentOption: {
							...this.props.reports.currentOption,
							sortTags: [this.getProviderSortTags()[0], this.getProviderSortTags()[1]],
							group: this.props.config.stats.options.delimiter[0],
							integrator: this.props.config.game.options.integrator[0],
							groupProvider: this.props.config.stats.options.delimiterProvider[0],
							groupFull: this.props.config.stats.options.delimiterFull[0],
							period: this.props.config.stats.options.period[0],
							currency: this.props.config.reports.options.currency[0],
							costsGroup: this.props.config.reports.options.group[0],
						},
					});
				}, 0);

				setTimeout(() => {
					this.loadReport("monitoring");
				}, 100);
			});
		}, 0);
	}

	componentWillUnmount() {
		this.props.setReports({ ...this.props.reports, loaded: false, data: [] });
	}

	buildChart(data, report, param) {
		data = cloneDeep(data);

		switch (report) {
			case "monitoring":
				const monitoringChart = { requestCount: [], cpuUtilization: [], environmentHealth: [], databaseConnections: [], freeableMemory: [] };
				const totalColumn = { requestCount: 0, cpuUtilization: 0, environmentHealth: 0, databaseConnections: 0, freeableMemory: 0 };

				data.reverse().forEach((item) => {
					monitoringChart.requestCount.push({ x: new Date(item.date).toLocaleTimeString() + " " + new Date(item.date).toLocaleDateString(), y: item.requestCount });
					totalColumn.requestCount += item.requestCount;

					monitoringChart.cpuUtilization.push({ x: new Date(item.date).toLocaleTimeString() + " " + new Date(item.date).toLocaleDateString(), y: item.cpuUtilization });
					totalColumn.cpuUtilization += parseFloat(item.cpuUtilization) > 0 ? item.cpuUtilization : 0;

					monitoringChart.environmentHealth.push({ x: new Date(item.date).toLocaleTimeString() + " " + new Date(item.date).toLocaleDateString(), y: item.environmentHealth });
					totalColumn.environmentHealth += parseInt(item.environmentHealth) > 0 ? item.environmentHealth : 0;

					monitoringChart.databaseConnections.push({ x: new Date(item.date).toLocaleTimeString() + " " + new Date(item.date).toLocaleDateString(), y: item.databaseConnections });
					totalColumn.databaseConnections += item.databaseConnections;

					monitoringChart.freeableMemory.push({ x: new Date(item.date).toLocaleTimeString() + " " + new Date(item.date).toLocaleDateString(), y: parseFloat(item.freeableMemory / 1024 / 1024 / 1024).toFixed(2) });
					totalColumn.freeableMemory += item.freeableMemory / 1024 / 1024 / 1024;
				});

				totalColumn.cpuUtilization = parseFloat(totalColumn.cpuUtilization / data.length).toFixed(2);
				totalColumn.environmentHealth = parseFloat(totalColumn.environmentHealth / data.length).toFixed(2);
				totalColumn.databaseConnections = parseFloat(totalColumn.databaseConnections / data.length).toFixed(2);
				totalColumn.freeableMemory = parseFloat(totalColumn.freeableMemory / data.length).toFixed(2);

				return { chart: monitoringChart, totalColumn };
			case "costs":
				const costsChart = {};
				this.props.config.reports.options.group.forEach((item) => {
					costsChart[item.type] = [];
				});
				let uniqueDates = [];

				data.reverse().forEach((item) => {
					if (!uniqueDates.includes(item.date)) uniqueDates.push(item.date);
				});

				uniqueDates.forEach((item) => {
					this.props.config.reports.options.group.forEach((item2) => {
						costsChart[item2.type].push({ x: new Date(item).toLocaleDateString(), y: 0 });
					});
				});

				data.forEach((item) => {
					let found = false;
					costsChart[item.group].forEach((item2) => {
						if (item2.x === new Date(item.date).toLocaleDateString()) {
							item2.y += item.amount;
							found = true;
						}
					});
				});

				return { chart: costsChart };
			case "providers":
				const providersChart = {};
				this.props.config.game.options.integrator.forEach((item) => {
					providersChart[item.type] = [];
				});
				let formattedChart = {};

				data.reverse().forEach((item) => {
					let integrator = "",
						providerID = 0;

					Object.keys(item).forEach((key) => {
						if (key === "integrator") integrator = item[key].value.toLowerCase();

						if (key === "provider") providerID = item[key].value;
					});

					if (typeof formattedChart[integrator] === "undefined") formattedChart[integrator] = [];

					Object.keys(item)
						.reverse()
						.forEach((key) => {
							if (key.split("_")[key.split("_").length - 1] === param) {
								if (typeof providersChart[integrator] === "undefined") providersChart[integrator] = {};

								if (typeof providersChart[integrator][providerID] === "undefined") providersChart[integrator][providerID] = [];

								providersChart[integrator][providerID].push({ x: new Date(item[key].date).toLocaleTimeString() + " " + new Date(item[key].date).toLocaleDateString(), y: item[key].value });
							}
						});
				});

				Object.keys(providersChart).forEach((key) => {
					Object.keys(providersChart[key]).forEach((key2) => {
						if (param === "ggr" || param === "bets" || param === "fee" || param === "wins") formattedChart[key].push({ title: key2, type: "line", data: providersChart[key][key2], valueFormatter: (e) => new Intl.NumberFormat("ru-RU").format(e) + " RUB." });
						else if (param === "feeUsed") formattedChart[key].push({ title: key2, type: "line", data: providersChart[key][key2], valueFormatter: (e) => e + "%" });
						else formattedChart[key].push({ title: key2, type: "line", data: providersChart[key][key2] });
					});
				});

				if (this.props.reports.currentIntegrator === "all") {
					formattedChart.global = [];
					Object.keys(formattedChart).forEach((key) => {
						if (key !== "global") formattedChart.global = formattedChart.global.concat(formattedChart[key]);
					});
				}

				Object.keys(formattedChart).forEach((key) => {
					formattedChart[key] = formattedChart[key].sort((a, b) => {
						let aSum = 0,
							bSum = 0;

						a.data.forEach((item) => {
							aSum += item.y;
						});

						b.data.forEach((item) => {
							bSum += item.y;
						});

						return bSum - aSum;
					});
				});

				return { chart: formattedChart };
		}
	}

	getVisibleSeriesByData(data) {
		let visibleSeries = [];
		Object.keys(data).forEach((key) => {
			visibleSeries[key] = data[key].slice(0, 10);
		});

		return visibleSeries;
	}

	loadReport(report) {
		if (this.props.reports.limit) {
			this.props.setReports({ ...this.props.reports, loaded: false, data: [] });

			if (report === "games")
				this.props.Interface.request("get", { class: "data", module: "games", method: "stats" }, { brand: this.props.reports.brand.value, offset: this.props.reports.offset, limit: this.props.reports.limit, gameID: "all", total: true })
					.then((rawGameData) => {
						rawGameData.forEach((item) => {
							item.wins = cloneDeep(item.data.wins);
							item.bets = cloneDeep(item.data.bets);
							delete item.data;
						});

						this.rawGameData = rawGameData;
						this.rebuildGamesStats();
					})
					.catch((e) => {
						this.props.setReports({ ...this.props.reports, loaded: true });
						console.log(e);
					});
			else if (report === "net")
				this.props.Interface.request("get", { class: "data", module: "reports", method: "net" }, { brand: this.props.reports.brand.value, offset: this.props.reports.offset, limit: this.props.reports.limit, gameID: "all", total: true })
					.then((data) => {
						this.netReportData = data;
						this.rebuildNetReport();
					})
					.catch((e) => {
						this.props.setReports({ ...this.props.reports, loaded: true });
						console.log(e);
					});
			else if (report === "costs")
				this.props.Interface.request("get", { class: "data", module: "reports", method: "costs" }, { offset: this.props.reports.offset, limit: this.props.reports.limit, brand: this.props.reports.brand.value, group: this.props.reports.currentCostsGroup })
					.then((data) => {
						this.props.setReports({ ...this.props.reports, loaded: true, data, chart: this.buildChart(data, "costs").chart });
					})
					.catch((e) => {
						this.props.setReports({ ...this.props.reports, loaded: true });
						console.log(e);
					});
			else if (report === "settlements")
				this.props.Interface.request("get", { class: "data", module: "reports", method: "settlements" }, { brand: this.props.reports.brand.value })
					.then((data) => {
						this.props.setReports({ ...this.props.reports, loaded: true, data });
					})
					.catch((e) => {
						this.props.setReports({ ...this.props.reports, loaded: true });
						console.log(e);
					});
			else if (report === "providers")
				this.props.Interface.request("get", { class: "data", module: "reports", method: "providers" }, { integrator: this.props.reports.currentIntegrator, offset: this.props.reports.offset, limit: this.props.reports.limit, brand: this.props.reports.brand.value, group: this.props.reports.currentGroupProvider, tags: this.props.reports.currentSortTags })
					.then((data) => {
						let ggrChart = this.buildChart(data, "providers", "ggr").chart;
						let ggrVisibleSeries = this.getVisibleSeriesByData(ggrChart);

						let feeChart = this.buildChart(data, "providers", "fee").chart;
						let feeVisibleSeries = this.getVisibleSeriesByData(feeChart);

						let betsChart = this.buildChart(data, "providers", "bets").chart;
						let betsVisibleSeries = this.getVisibleSeriesByData(betsChart);

						let winsChart = this.buildChart(data, "providers", "wins").chart;
						let winsVisibleSeries = this.getVisibleSeriesByData(winsChart);

						let betsCountChart = this.buildChart(data, "providers", "betsCount").chart;
						let betsCountVisibleSeries = this.getVisibleSeriesByData(betsCountChart);

						let feeUsedChart = this.buildChart(data, "providers", "feeUsed").chart;
						let feeUsedVisibleSeries = this.getVisibleSeriesByData(feeUsedChart);

						const uniqueKeys = {};
						data.forEach((item) => {
							Object.keys(item).forEach((key) => {
								if (typeof uniqueKeys[key] === "undefined") uniqueKeys[key] = { date: item[key].date, name: item[key].name, value: 0 };
							});
						});

						data.forEach((item) => {
							Object.keys(uniqueKeys).forEach((key) => {
								if (typeof item[key] === "undefined") item[key] = uniqueKeys[key];
							});
						});

						this.props.setReports({ ...this.props.reports, loaded: true, data, chart: { ggr: ggrChart, fee: feeChart, bets: betsChart, wins: winsChart, betsCount: betsCountChart, feeUsed: feeUsedChart }, visibleSeries: { ggr: ggrVisibleSeries, fee: feeVisibleSeries, bets: betsVisibleSeries, wins: winsVisibleSeries, betsCount: betsCountVisibleSeries, feeUsed: feeUsedVisibleSeries } });
					})
					.catch((e) => {
						this.props.setReports({ ...this.props.reports, loaded: true });
						console.log(e);
					});
			else if (report === "monitoring")
				this.props.Interface.request("get", { class: "data", module: "reports", method: "monitoring" }, { brand: this.props.reports.brand.value, group: this.props.reports.currentGroupFull, offset: this.props.reports.offset, limit: this.props.reports.limit })
					.then((data) => {
						this.props.setReports({ ...this.props.reports, loaded: true, data, chart: this.buildChart(data, "monitoring").chart, totalColumn: this.buildChart(data, "monitoring").totalColumn });
					})
					.catch((e) => {
						this.props.setReports({ ...this.props.reports, loaded: true });
						console.log(e);
					});
		}
	}

	getWeekStart(date) {
		const weekStart = new Date(date);
		weekStart.setDate(weekStart.getDate() - ((weekStart.getDay() + 6) % 7));
		weekStart.setHours(0, 0, 0, 0);
		return weekStart;
	}

	getMonthStart(date) {
		const monthStart = new Date(date);
		monthStart.setDate(1);
		monthStart.setHours(0, 0, 0, 0);
		return monthStart;
	}

	getYearStart(date) {
		const yearStart = new Date(date);
		yearStart.setMonth(0, 1);
		yearStart.setHours(0, 0, 0, 0);
		return yearStart;
	}

	rebuildNetReport() {
		let total = 0,
			average = 0,
			chart = { exchanged: { total: [], count: [], correlation: [] }, deposits: { total: [], count: [], conversion: [] }, withdrawals: { total: [], count: [] }, profit: [] },
			totalColumn = { profit: { RUB: 0, USD: 0 }, client: { deposits: { total: { RUB: 0, USD: 0 }, count: 0, fee: { RUB: 0, USD: 0 }, ceff: 0 }, bonuses: { total: { RUB: 0, USD: 0 }, count: 0 }, withdrawals: { total: { RUB: 0, USD: 0 }, count: 0, fee: { RUB: 0, USD: 0 } } }, exchanged: { RUB: 0, USD: 0, USDE: 0, RUBE: 0 }, costs: { total: { RUB: 0, USD: 0 }, count: 0 }, settlements: { total: { RUB: 0, USD: 0 }, count: 0 } };
		let data = [],
			j = 0;

		let rawData = cloneDeep(this.netReportData);

		let lastPeriod = false;

		rawData.reverse().forEach((stat, i) => {
			if (!i) {
				if (this.props.reports.currentGroup === 365) {
					lastPeriod = this.getYearStart(stat.date).getTime();
				} else if (this.props.reports.currentGroup === 30) {
					lastPeriod = this.getMonthStart(stat.date).getTime();
				} else if (this.props.reports.currentGroup === 7) {
					lastPeriod = this.getWeekStart(stat.date).getTime();
				}
			}

			if (i) {
				if (this.props.reports.currentGroup === 365) {
					if (!lastPeriod || lastPeriod !== this.getYearStart(stat.date).getTime()) {
						lastPeriod = this.getYearStart(stat.date).getTime();
						j++;
					}
				} else if (this.props.reports.currentGroup === 30) {
					if (!lastPeriod || lastPeriod !== this.getMonthStart(stat.date).getTime()) {
						lastPeriod = this.getMonthStart(stat.date).getTime();
						j++;
					}
				} else if (this.props.reports.currentGroup === 7) {
					if (!lastPeriod || lastPeriod !== this.getWeekStart(stat.date).getTime()) {
						lastPeriod = this.getWeekStart(stat.date).getTime();
						j++;
					}
				} else j++;
			}

			if (typeof data[j] === "undefined")
				data[j] = {
					date: stat.date,
					profit: { RUB: 0, USD: 0 },
					client: { deposits: { total: { RUB: 0, USD: 0 }, count: 0, ceff: 0, fee: { RUB: 0, USD: 0 } }, bonuses: { total: { RUB: 0, USD: 0 }, count: 0 }, withdrawals: { total: { RUB: 0, USD: 0 }, count: 0, fee: { RUB: 0, USD: 0 } } },
					exchanged: { RUB: 0, USD: 0, USDE: 0, RUBE: 0 },
					costs: { total: { RUB: 0, USD: 0 }, count: 0 },
					settlements: { total: { RUB: 0, USD: 0 }, count: 0 },
				};

			data[j].days++;

			data[j].client.deposits.total.RUB += stat.client.deposits.total.RUB;
			data[j].client.deposits.total.USD += stat.client.deposits.total.USD;
			data[j].client.deposits.count += stat.client.deposits.count;

			data[j].client.deposits.ceff += stat.client.deposits.ceff;

			data[j].client.bonuses.total.RUB += stat.client.bonuses.total.RUB;
			data[j].client.bonuses.total.USD += stat.client.bonuses.total.USD;
			data[j].client.bonuses.count += stat.client.bonuses.count;

			data[j].client.withdrawals.total.RUB += stat.client.withdrawals.total.RUB;
			data[j].client.withdrawals.total.USD += stat.client.withdrawals.total.USD;
			data[j].client.withdrawals.count += stat.client.withdrawals.count;

			data[j].client.deposits.fee.RUB += stat.client.deposits.fee.RUB;
			data[j].client.deposits.fee.USD += stat.client.deposits.fee.USD;

			data[j].client.withdrawals.fee.RUB += stat.client.withdrawals.fee.RUB;
			data[j].client.withdrawals.fee.USD += stat.client.withdrawals.fee.USD;

			data[j].exchanged.RUB += stat.exchanged.RUB;
			data[j].exchanged.USD += stat.exchanged.USD;
			data[j].exchanged.USDE += stat.exchanged.USDE;
			data[j].exchanged.RUBE += stat.exchanged.RUBE;

			data[j].costs.total.RUB += stat.costs.total.RUB;
			data[j].costs.total.USD += stat.costs.total.USD;
			data[j].costs.count += stat.costs.count;

			data[j].settlements.total.RUB += stat.settlements.total.RUB;
			data[j].settlements.total.USD += stat.settlements.total.USD;
			data[j].settlements.count += stat.settlements.count;

			if (typeof data[j].profit === "undefined") {
				data[j].profit = {};

				data[j].profit.RUB = ((-stat.client.withdrawals.total.RUB - stat.client.withdrawals.fee.RUB - stat.client.deposits.fee.RUB - stat.costs.total.RUB + stat.client.deposits.total.RUB) / 100) * 88;
				data[j].profit.USD = ((-stat.client.withdrawals.total.USD - stat.client.withdrawals.fee.USD - stat.client.deposits.fee.USD - stat.costs.total.USD + stat.client.deposits.total.USD) / 100) * 88;
			} else {
				data[j].profit.RUB += ((-stat.client.withdrawals.total.RUB - stat.client.withdrawals.fee.RUB - stat.client.deposits.fee.RUB - stat.costs.total.RUB + stat.client.deposits.total.RUB) / 100) * 88;
				data[j].profit.USD += ((-stat.client.withdrawals.total.USD - stat.client.withdrawals.fee.USD - stat.client.deposits.fee.USD - stat.costs.total.USD + stat.client.deposits.total.USD) / 100) * 88;
			}

			data[j].dateEnd = stat.date;
		});

		data.forEach((stat) => {
			Object.keys(stat).forEach((key1) => {
				if (typeof stat[key1] !== "object") totalColumn[key1] += stat[key1];
				else
					Object.keys(stat[key1]).forEach((key2) => {
						if (typeof stat[key1][key2] !== "object") totalColumn[key1][key2] += stat[key1][key2];
						else
							Object.keys(stat[key1][key2]).forEach((key3) => {
								if (typeof stat[key1][key2][key3] !== "object") totalColumn[key1][key2][key3] += stat[key1][key2][key3];
								else
									Object.keys(stat[key1][key2][key3]).forEach((key4) => {
										if (typeof stat[key1][key2][key3][key4] !== "object") totalColumn[key1][key2][key3][key4] += stat[key1][key2][key3][key4];
										else
											Object.keys(stat[key1][key2][key3][key4]).forEach((key5) => {
												totalColumn[key1][key2][key3][key4][key5] += stat[key1][key2][key3][key4][key5];
											});
									});
							});
					});
			});
		});

		totalColumn.client.deposits.ceff = parseFloat(totalColumn.client.deposits.ceff / data.length).toFixed(2);

		totalColumn.date = "Total";

		data.reverse().push(totalColumn);

		this.props.setReports({ ...this.props.reports, data, loaded: true });
	}

	rebuildGamesStats() {
		let chart = { bets: { total: [], count: [] }, wins: { total: [], count: [] }, rtp: [] },
			totalColumn = { days: 0, bets: { total: 0, count: 0 }, wins: { total: 0, count: 0 }, rtp: 0 };
		let data = [],
			j = 0;

		let rawGameData = this.rawGameData;
		rawGameData.reverse().forEach((stat, i) => {
			stat.rtp = parseFloat(((stat.wins.total / stat.bets.total) * 100).toFixed(2));
			if (isNaN(stat.rtp)) stat.rtp = 0;

			if (i && i % this.props.reports.currentGroup === 0) j++;

			if (typeof data[j] === "undefined") data[j] = { bets: { total: 0, count: 0 }, wins: { total: 0, count: 0 }, rtp: 0, days: 0, date: stat.date };

			if (stat.rtp) data[j].days++;

			data[j].bets.total += parseFloat(parseFloat(stat.bets.total / 100).toFixed(2));
			data[j].bets.count += stat.bets.count;
			data[j].wins.total += parseFloat(parseFloat(stat.wins.total / 100).toFixed(2));
			data[j].wins.count += stat.wins.count;

			data[j].rtp += stat.rtp;

			data[j].dateEnd = stat.date;
		});

		data.forEach((stat) => {
			let formattedRangeDate = new Date(stat.date).toLocaleDateString().indexOf("/") !== -1 ? new Date(stat.date).toLocaleDateString().split("/").slice(0, -1).join("/") + " - " + new Date(stat.dateEnd).toLocaleDateString().split("/").slice(0, -1).join("/") : new Date(stat.date).toLocaleDateString().split(".").slice(0, -1).join(".") + " - " + new Date(stat.dateEnd).toLocaleDateString().split(".").slice(0, -1).join(".");

			totalColumn.bets.total += stat.bets.total;
			totalColumn.bets.count += stat.bets.count;
			totalColumn.wins.total += stat.wins.total;
			totalColumn.wins.count += stat.wins.count;
			totalColumn.rtp += stat.rtp;
			totalColumn.days += stat.days;

			if (typeof stat.days !== "undefined" && stat.days) stat.rtp = parseFloat((stat.rtp / stat.days).toFixed(2));

			chart.bets.total.push({ x: typeof stat.dateEnd === "undefined" || stat.date === stat.dateEnd ? new Date(stat.date).toLocaleDateString() : formattedRangeDate, y: stat.bets.total });
			chart.bets.count.push({ x: typeof stat.dateEnd === "undefined" || stat.date === stat.dateEnd ? new Date(stat.date).toLocaleDateString() : formattedRangeDate, y: stat.bets.count });

			chart.wins.total.push({ x: typeof stat.dateEnd === "undefined" || stat.date === stat.dateEnd ? new Date(stat.date).toLocaleDateString() : formattedRangeDate, y: stat.wins.total });
			chart.wins.count.push({ x: typeof stat.dateEnd === "undefined" || stat.date === stat.dateEnd ? new Date(stat.date).toLocaleDateString() : formattedRangeDate, y: stat.wins.count });

			chart.rtp.push({ x: typeof stat.dateEnd === "undefined" || stat.date === stat.dateEnd ? new Date(stat.date).toLocaleDateString() : formattedRangeDate, y: stat.rtp });
		});

		totalColumn.rtp = parseFloat(totalColumn.rtp / totalColumn.days).toFixed(2);

		totalColumn.date = "Total";

		data.reverse().push(totalColumn);
		this.rawGameData.reverse();

		this.props.setReports({ ...this.props.reports, data, chart, loaded: true });
	}

	saveData(data, report) {
		const element = document.createElement("a");
		const file = new Blob([JSON.stringify(data)], {
			type: "text/plain",
		});
		element.href = URL.createObjectURL(file);
		element.download = this.props.config.app.name.toLowerCase() + "_report_" + report + "_" + +new Date() + ".json";
		document.body.appendChild(element);
		element.click();
	}

	compare(a, b) {
		if (this.props.reports.currentReport === "providers") {
			if (a[this.props.reports.sorting.sortingColumn.sortingField].value < b[this.props.reports.sorting.sortingColumn.sortingField].value) {
				return this.props.reports.sorting.isDescending ? 1 : -1;
			}

			if (a[this.props.reports.sorting.sortingColumn.sortingField].value > b[this.props.reports.sorting.sortingColumn.sortingField].value) {
				return this.props.reports.sorting.isDescending ? -1 : 1;
			}
		} else if (this.props.reports.sorting.sortingColumn.sortingField.indexOf(".") === -1) {
			if (a[this.props.reports.sorting.sortingColumn.sortingField] < b[this.props.reports.sorting.sortingColumn.sortingField]) {
				return this.props.reports.sorting.isDescending ? 1 : -1;
			}

			if (a[this.props.reports.sorting.sortingColumn.sortingField] > b[this.props.reports.sorting.sortingColumn.sortingField]) {
				return this.props.reports.sorting.isDescending ? -1 : 1;
			}
		} else {
			if (a[this.props.reports.sorting.sortingColumn.sortingField.split(".")[0]][this.props.reports.sorting.sortingColumn.sortingField.split(".")[1]] < b[this.props.reports.sorting.sortingColumn.sortingField.split(".")[0]][this.props.reports.sorting.sortingColumn.sortingField.split(".")[1]]) {
				return this.props.reports.sorting.isDescending ? 1 : -1;
			}

			if (a[this.props.reports.sorting.sortingColumn.sortingField.split(".")[0]][this.props.reports.sorting.sortingColumn.sortingField.split(".")[1]] > b[this.props.reports.sorting.sortingColumn.sortingField.split(".")[0]][this.props.reports.sorting.sortingColumn.sortingField.split(".")[1]]) {
				return this.props.reports.sorting.isDescending ? -1 : 1;
			}
		}

		return 0;
	}

	formatRange(startDate, stopDate) {
		startDate = startDate.split("-").join("/");
		stopDate = stopDate.split("-").join("/");

		let limit = 0,
			offset = [],
			currentDate = +new Date(startDate);

		while (currentDate <= +new Date(stopDate)) {
			currentDate = +new Date(currentDate).setDate(new Date(currentDate).getDate() + 1);
			limit++;
		}

		offset = (+new Date(new Date().getFullYear() + "/" + (new Date().getMonth() + 1) + "/" + new Date().getDate()) - +new Date(stopDate)) / 1000 / 60 / 60 / 24;
		return { limit, offset };
	}

	formatLimitOffset(limit, offset) {
		let startDate = +new Date().setDate(new Date().getDate() - offset),
			endDate = +new Date().setDate(new Date().getDate() - offset - limit + 1);
		return { from: new Date(endDate).toLocaleDateString().split("/").reverse().join("-"), to: new Date(startDate).toLocaleDateString().split("/").reverse().join("-") };
	}

	getBrandsAsOptions() {
		const options = [];
		this.props.user.brandsList.forEach((item) => {
			options.push({ label: item.name, value: item.id });
		});

		return options;
	}

	getCostsReportBarChartOptions() {
		const options = [];
		const colors = ["red", "green", "blue", "yellow", "orange", "purple", "pink", "brown", "grey", "black"];

		this.props.config.reports.options.group.slice(1).forEach((item) => {
			if (typeof this.props.reports.chart[item.type] !== "undefined") options.push({ title: item.value, type: "bar", data: this.props.reports.chart[item.type], color: colors[options.length], valueFormatter: (e) => new Intl.NumberFormat("ru-RU").format(e) + " USD." });
		});

		return options;
	}

	getProvidersReportColumns() {
		let uniqueKeys = [];
		const columns = [];

		const names = {};
		this.props.reports.data.forEach((item) => {
			Object.keys(item).forEach((key) => {
				if (!uniqueKeys.includes(key)) {
					uniqueKeys.push(key);

					if (typeof item[key].date !== "undefined") {
						const itemDate = new Date(item[key].date);
						const weekNumber = Math.ceil(((itemDate - new Date(itemDate.getFullYear(), itemDate.getMonth(), 1)) / 86400000 + new Date(itemDate.getFullYear(), itemDate.getMonth(), 1).getDay() + 1) / 7);

						if (this.props.reports.currentGroupProvider === "hour") names[key] = itemDate.toLocaleTimeString() + " " + itemDate.toLocaleDateString() + " | " + item[key].name;
						else if (this.props.reports.currentGroupProvider === 1) names[key] = itemDate.toLocaleDateString(undefined, { day: "2-digit", month: "2-digit", year: "numeric" }) + " | " + item[key].name;
						else if (this.props.reports.currentGroupProvider === 7) names[key] = itemDate.toLocaleDateString(undefined, { month: "long", year: "numeric" }) + " | " + `Week #${weekNumber}` + " | " + item[key].name;
						else if (this.props.reports.currentGroupProvider === 30) names[key] = itemDate.toLocaleDateString(undefined, { month: "long", year: "numeric" }) + " | " + item[key].name;
						else names[key] = itemDate.toLocaleDateString(undefined, { year: "numeric" }) + " | " + item[key].name;
					} else names[key] = item[key].name;
				}
			});
		});

		uniqueKeys = uniqueKeys.sort();

		const lastElements = uniqueKeys.splice(uniqueKeys.length - 2, 2);
		uniqueKeys.unshift(...lastElements);

		uniqueKeys.forEach((key) => {
			if (key.split("_")[key.split("_").length - 1] === "feeUsed") columns.push({ id: key, header: names[key], sortingField: key, cell: (item) => item[key].value + "%" });
			else if (key.split("_")[key.split("_").length - 1] === "ggr" || key.split("_")[key.split("_").length - 1] === "bets" || key.split("_")[key.split("_").length - 1] === "wins") columns.push({ id: key, header: names[key], sortingField: key, cell: (item) => new Intl.NumberFormat("ru-RU").format(item[key].value) + " RUB." });
			else columns.push({ id: key, header: names[key], sortingField: key, cell: (item) => item[key].value });
		});

		return columns;
	}

	updateVisibleCharts(key, key2, data) {
		this.props.setReports({ ...this.props.reports, visibleSeries: { ...this.props.reports.visibleSeries, [key]: { ...this.props.reports.visibleSeries[key], [key2]: data } } });
	}

	getProviderSortTags() {
		return [
			{
				label: "GGR",
				value: "ggr",
			},
			{
				label: "Bets",
				value: "bets",
			},
			{
				label: "Bets count",
				value: "betsCount",
			},
			{
				label: "Wins",
				value: "wins",
			},
			{
				label: "Fee",
				value: "fee",
			},
			{
				label: "Fee used",
				value: "feeUsed",
			},
		];
	}

	render() {
		return (
			<>
				{this.props.reports.loaded && !this.props.reports.data.length && this.props.reports.error.text.length ? (
					<Box
						margin={{ bottom: "m" }}
						children={
							<Alert
								type="error"
								header="Error loading data:"
								children={
									<>
										<Box variant="p" children={this.props.reports.error.text} />
										<hr />
										<Box
											variant="p"
											children={
												<>
													request error code: {this.props.reports.error.code} | request timestamp: {this.props.reports.error.timestamp}
												</>
											}
										/>
									</>
								}
							/>
						}
					/>
				) : (
					<></>
				)}

				<Container
					header={
						<Box
							margin={{ bottom: "m" }}
							children={
								<Header
									children="Reports"
									actions={
										<SpaceBetween direction="horizontal" size="s">
											{this.props.user.mode === "global" ? (
												<Select
													filteringType="auto"
													disabled={!this.props.reports.loaded || !this.props.reports.data.length}
													placeholder="Select brand"
													selectedOption={this.props.reports.brand}
													onChange={({ detail }) => {
														this.props.setReports({ ...this.props.reports, brand: detail.selectedOption });
														setTimeout(() => {
															this.loadReport(this.props.reports.currentReport);
														});
													}}
													options={this.getBrandsAsOptions()}
													selectedAriaLabel="Selected"
												/>
											) : (
												<></>
											)}
											{this.props.reports.currentReport === "costs" ? (
												<Button
													disabled={!this.props.reports.loaded}
													className={this.props.styles["players-search-button"]}
													onClick={() => {
														this.props.setReportsAddCostsModal({ ...this.props.reportsAddCostsModal, show: true });
													}}
													variant="primary"
													children="Add item"
												/>
											) : this.props.reports.currentReport == "settlements" ? (
												<Button
													disabled={!this.props.reports.loaded}
													className={this.props.styles["players-search-button"]}
													onClick={() => {
														this.props.setReportsAddSettlementsModal({ ...this.props.reportsAddSettlementsModal, show: true });
													}}
													variant="primary"
													children="Add item"
												/>
											) : (
												<></>
											)}{" "}
											<Button
												disabled={!this.props.reports.loaded || !this.props.reports.data.length}
												className={this.props.styles["players-search-button"]}
												onClick={() => {
													this.saveData(this.props.reports.data, this.props.reports.currentReport);
												}}
												variant="primary"
												children="Export"
											/>{" "}
											<Button
												iconName="refresh"
												disabled={!this.props.reports.loaded}
												className={[this.props.styles["players-search-button"], this.props.styles["btn"]].join(" ")}
												onClick={() => {
													setTimeout(() => {
														this.loadReport(this.props.reports.currentReport);
													}, 0);
												}}
												variant="normal"
											/>
										</SpaceBetween>
									}
									description="Below you can choose one of the financial statements"
								/>
							}
						/>
					}>
					<Tabs
						onChange={(tab) => {
							this.loadReport(tab.detail.activeTabId);
							setTimeout(() => {
								this.props.setReports({ ...this.props.reports, currentReport: tab.detail.activeTabId, loaded: false });
							}, 0);
						}}
						tabs={[
							{
								id: "monitoring",
								disabled: !this.props.reports.loaded && this.props.reports.currentReport !== "monitoring",
								label: "Monitoring report",
								content: (
									<Container
										header={<Box margin={{ bottom: "m" }} children={<Header children="Monitoring report" counter={<>({this.props.reports.data.length ? this.props.reports.data.length : 0})</>} description="Below listed project monitoring reports" />} />}
										children={
											<>
												<Grid
													gridDefinition={[{ colspan: 12 }, { colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}
													children={
														<>
															<RangePicker
																disabled={!this.props.reports.loaded}
																relativeOptions={[]}
																clearButtonLabel="Clear"
																dateOnly={true}
																placeholder="Select range for show stats"
																onChange={(range) => {
																	this.props.setReports({ ...this.props.reports, ...this.formatRange(range.detail.value.startDate, range.detail.value.endDate), range: range.detail.value });
																	setTimeout(() => {
																		this.loadReport("monitoring");
																	}, 250);
																}}
																value={this.props.reports.range}
																i18nStrings={{
																	customRelativeRangeUnitLabel: "",
																	formatRelativeRange: (value) => {
																		return value.amount;
																	},
																	formatUnit: () => {
																		return "";
																	},
																	nextMonthAriaLabel: "Next",
																	applyButtonLabel: "Ok",
																}}
															/>
															<Select
																filteringType="auto"
																disabled={!this.props.reports.loaded}
																placeholder="Group by"
																selectedOption={this.props.reports.currentOption.groupFull}
																onChange={(option) => {
																	option.detail.selectedOption.type !== this.props.reports.currentGroupFull && this.props.setReports({ ...this.props.reports, currentGroupFull: option.detail.selectedOption.type, currentOption: { ...this.props.reports.currentOption, groupFull: option.detail.selectedOption } });
																	setTimeout(() => {
																		this.loadReport("monitoring");
																	}, 0);
																}}
																options={this.props.config.stats.options.delimiterFull}
															/>

															<Select
																filteringType="auto"
																disabled={!this.props.reports.loaded}
																placeholder="Period"
																selectedOption={this.props.reports.currentOption.period}
																onChange={(option) => {
																	!isEqual(option.detail.selectedOption.type, this.props.reports.currentPeriod) &&
																		this.props.setReports({
																			...this.props.reports,
																			currentPeriod: option.detail.selectedOption.type,
																			currentOption: { ...this.props.reports.currentOption, period: option.detail.selectedOption },
																			limit: option.detail.selectedOption.type.limit,
																			offset: option.detail.selectedOption.type.offset,
																			range: { type: "absolute", startDate: this.formatLimitOffset(option.detail.selectedOption.type.limit, option.detail.selectedOption.type.offset).from, endDate: this.formatLimitOffset(option.detail.selectedOption.type.limit, option.detail.selectedOption.type.offset).to },
																		});
																	setTimeout(() => {
																		this.loadReport("monitoring");
																	}, 0);
																}}
																options={this.props.config.stats.options.period}
															/>
														</>
													}
												/>
												<Tabs
													tabs={[
														{
															id: "table",
															label: "Table view",
															content:
																this.props.reports.currentReport === "monitoring" ? (
																	<Table
																		stickyHeader={true}
																		loading={!this.props.reports.loaded}
																		sortingColumn={{ sortingField: this.props.reports.sorting.sortingColumn.sortingField }}
																		sortingDescending={this.props.reports.sorting.isDescending}
																		onSortingChange={(sorting) => {
																			this.props.setReports({ ...this.props.reports, sorting: sorting.detail });
																			setTimeout(() => {
																				this.props.setReports({
																					...this.props.reports,
																					data: cloneDeep(this.props.reports.data).sort((a, b) => {
																						return this.compare(a, b);
																					}),
																				});
																			}, 0);
																		}}
																		columnDefinitions={[
																			{ id: "date", header: "Date", sortingField: "date", cell: (item) => new Date(item.date).toLocaleTimeString() + " " + new Date(item.date).toLocaleDateString() },
																			{ id: "cpuUtilization", header: "CPU", sortingField: "cpuUtilization", cell: (item) => parseFloat(item.cpuUtilization).toFixed(2) + "%" },
																			{ id: "requestCount", header: "Requests count", sortingField: "requestCount", cell: (item) => item.requestCount },
																			{ id: "environmentHealth", header: "Health", sortingField: "environmentHealth", cell: (item) => item.environmentHealth },
																			{ id: "databaseConnections", header: "DB Connections", sortingField: "databaseConnections", cell: (item) => item.databaseConnections },
																			{ id: "freeableMemory", header: "DB Freeable memory", sortingField: "freeableMemory", cell: (item) => parseFloat(item.freeableMemory / 1024 / 1024 / 1024).toFixed(2) + " GB." },
																		]}
																		empty="No stats was loaded"
																		items={this.props.reports.data}
																	/>
																) : (
																	<></>
																),
														},
														{
															id: "chart",
															label: "Chart view",
															content: this.props.reports.loaded ? (
																<Grid
																	gridDefinition={[{ colspan: 12 }, { colspan: 12 }, { colspan: 12 }, { colspan: 12 }, { colspan: 12 }]}
																	children={
																		<>
																			<Grid
																				gridDefinition={[{ colspan: { default: 12, m: 6 } }, { colspan: { default: 12, m: 6 } }]}
																				children={
																					<>
																						<Container
																							header={
																								<Box margin={{ bottom: "m" }}>
																									<Header children={"Requests count chart"} counter={"(" + (this.props.reports.data.length ? this.props.reports.totalColumn.requestCount : 0) + ")"} />
																								</Box>
																							}
																							children={<LineChart hideLegend={true} hideFilter={true} statusType={this.props.reports.loaded && typeof this.props.reports.chart.requestCount !== "undefined" ? "finished" : "loading"} yScaleType="linear" xScaleType="categorical" series={[{ title: "Requests count", type: "bar", data: this.props.reports.chart.requestCount, color: colorChartsStatusLow }]} errorText="Error building chart" />}
																						/>
																						<Container
																							header={
																								<Box margin={{ bottom: "m" }}>
																									<Header children={"CPU Utilization chart"} counter={"(" + (this.props.reports.data.length ? this.props.reports.totalColumn.cpuUtilization : 0) + "%)"} />
																								</Box>
																							}
																							children={<LineChart hideLegend={true} hideFilter={true} statusType={this.props.reports.loaded && typeof this.props.reports.chart.cpuUtilization !== "undefined" ? "finished" : "loading"} yScaleType="linear" xScaleType="categorical" series={[{ title: "CPU Utilization", type: "bar", data: this.props.reports.chart.cpuUtilization, color: colorChartsStatusPositive }]} errorText="Error building chart" />}
																						/>
																					</>
																				}
																			/>

																			<Grid
																				gridDefinition={[{ colspan: { default: 12, m: 6 } }, { colspan: { default: 12, m: 6 } }]}
																				children={
																					<>
																						<Container
																							header={
																								<Box margin={{ bottom: "m" }}>
																									<Header children={"DB Connections chart"} counter={"(" + (this.props.reports.data.length ? this.props.reports.totalColumn.databaseConnections : 0) + ")"} />
																								</Box>
																							}
																							children={<LineChart hideLegend={true} hideFilter={true} statusType={this.props.reports.loaded && typeof this.props.reports.chart.databaseConnections !== "undefined" ? "finished" : "loading"} yScaleType="linear" xScaleType="categorical" series={[{ title: "DB Connections", type: "bar", data: this.props.reports.chart.databaseConnections, color: colorChartsStatusMedium }]} errorText="Error building chart" />}
																						/>
																						<Container
																							header={
																								<Box margin={{ bottom: "m" }}>
																									<Header children={"DB Freeable memory chart"} counter={"(" + (this.props.reports.data.length ? this.props.reports.totalColumn.freeableMemory : 0) + " GB.)"} />
																								</Box>
																							}
																							children={<LineChart hideLegend={true} hideFilter={true} statusType={this.props.reports.loaded && typeof this.props.reports.chart.freeableMemory !== "undefined" ? "finished" : "loading"} yScaleType="linear" xScaleType="categorical" series={[{ title: "DB Freeable memory", type: "bar", data: this.props.reports.chart.freeableMemory, color: colorChartsStatusInfo }]} errorText="Error building chart" />}
																						/>
																					</>
																				}
																			/>

																			<Container
																				header={
																					<Box margin={{ bottom: "m" }}>
																						<Header children={"Environment health chart"} counter={"(" + (this.props.reports.data.length ? this.props.reports.totalColumn.environmentHealth : 0) + ")"} />
																					</Box>
																				}
																				children={<LineChart hideLegend={true} hideFilter={true} statusType={this.props.reports.loaded && typeof this.props.reports.chart.environmentHealth !== "undefined" ? "finished" : "loading"} yScaleType="linear" xScaleType="categorical" series={[{ title: "Environment health", type: "bar", data: this.props.reports.chart.environmentHealth, color: colorChartsStatusHigh }]} errorText="Error building chart" />}
																			/>
																		</>
																	}
																/>
															) : (
																<Box variant="div" margin={{ top: "l" }} textAlign="center">
																	<Spinner size="normal" />
																</Box>
															),
														},
													]}
												/>
											</>
										}
									/>
								),
							},
							{
								id: "net",
								disabled: !this.props.reports.loaded && this.props.reports.currentReport !== "net",
								label: "Net report",
								content: (
									<Container
										header={<Box margin={{ bottom: "m" }} children={<Header children="Net report" counter={<>({this.props.reports.data.length ? this.props.reports.data.length : 0})</>} description="Below listed stats for selected date" />} />}
										children={
											<>
												<Grid
													gridDefinition={[{ colspan: 12 }, { colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}
													children={
														<>
															<RangePicker
																disabled={!this.props.reports.loaded}
																relativeOptions={[]}
																clearButtonLabel="Clear"
																dateOnly={true}
																placeholder="Select range for show stats"
																onChange={(range) => {
																	this.props.setReports({ ...this.props.reports, ...this.formatRange(range.detail.value.startDate, range.detail.value.endDate), range: range.detail.value });
																	setTimeout(() => {
																		this.loadReport("net");
																	}, 250);
																}}
																value={this.props.reports.range}
																i18nStrings={{
																	customRelativeRangeUnitLabel: "",
																	formatRelativeRange: (value) => {
																		return value.amount;
																	},
																	formatUnit: () => {
																		return "";
																	},
																	nextMonthAriaLabel: "Next",
																	applyButtonLabel: "Ok",
																}}
															/>
															<Select
																filteringType="auto"
																disabled={!this.props.reports.loaded}
																placeholder="Group by"
																selectedOption={this.props.reports.currentOption.group}
																onChange={(option) => {
																	option.detail.selectedOption.type !== this.props.reports.currentGroup && this.props.setReports({ ...this.props.reports, currentGroup: option.detail.selectedOption.type, currentOption: { ...this.props.reports.currentOption, group: option.detail.selectedOption } });
																	setTimeout(() => {
																		this.rebuildNetReport();
																	}, 0);
																}}
																options={this.props.config.stats.options.delimiter}
															/>

															<Select
																filteringType="auto"
																disabled={!this.props.reports.loaded}
																placeholder="Period"
																selectedOption={this.props.reports.currentOption.period}
																onChange={(option) => {
																	!isEqual(option.detail.selectedOption.type, this.props.reports.currentPeriod) &&
																		this.props.setReports({
																			...this.props.reports,
																			currentPeriod: option.detail.selectedOption.type,
																			currentOption: { ...this.props.reports.currentOption, period: option.detail.selectedOption },
																			limit: option.detail.selectedOption.type.limit,
																			offset: option.detail.selectedOption.type.offset,
																			range: { type: "absolute", startDate: this.formatLimitOffset(option.detail.selectedOption.type.limit, option.detail.selectedOption.type.offset).from, endDate: this.formatLimitOffset(option.detail.selectedOption.type.limit, option.detail.selectedOption.type.offset).to },
																		});
																	setTimeout(() => {
																		this.loadReport("net");
																	}, 0);
																}}
																options={this.props.config.stats.options.period}
															/>

															<Select
																filteringType="auto"
																disabled={!this.props.reports.loaded}
																placeholder="Currency"
																selectedOption={this.props.reports.currentOption.currency}
																onChange={(option) => {
																	option.detail.selectedOption.type !== this.props.reports.currency && this.props.setReports({ ...this.props.reports, currency: option.detail.selectedOption.type, currentOption: { ...this.props.reports.currentOption, currency: option.detail.selectedOption } });
																	setTimeout(() => {
																		this.rebuildNetReport();
																	}, 0);
																}}
																options={this.props.config.reports.options.currency}
															/>
														</>
													}
												/>
												<Tabs
													tabs={[
														{
															id: "table",
															label: "Table view",
															content:
																this.props.reports.currentReport === "net" && this.props.reports.loaded ? (
																	<Table
																		stickyHeader={true}
																		loading={!this.props.reports.loaded}
																		sortingColumn={{ sortingField: this.props.reports.sorting.sortingColumn.sortingField }}
																		sortingDescending={this.props.reports.sorting.isDescending}
																		onSortingChange={(sorting) => {
																			this.props.setReports({ ...this.props.reports, sorting: sorting.detail });
																			setTimeout(() => {
																				this.props.setReports({
																					...this.props.reports,
																					data: cloneDeep(this.props.reports.data).sort((a, b) => {
																						return this.compare(a, b);
																					}),
																				});
																			}, 0);
																		}}
																		columnDefinitions={[
																			{ id: "date", header: "Date", sortingField: "date", cell: (item) => (typeof item.date === "string" ? <b>{item.date}</b> : typeof item.dateEnd !== "undefined" && item.date !== item.dateEnd ? new Date(item.date).toLocaleDateString() + " - " + new Date(item.dateEnd).toLocaleDateString() : new Date(item.date).toLocaleDateString()) },
																			{ id: "client_d_ep", header: "Deposits", sortingField: "client.deposits.total." + this.props.reports.currency, cell: (item) => new Intl.NumberFormat("ru-RU").format(item.client.deposits.total[this.props.reports.currency]) + " " + this.props.reports.currency + "." },
																			{ id: "client_d_ot_rub", header: "Deposits fee", sortingField: "client.deposits.fee." + this.props.reports.currency, cell: (item) => new Intl.NumberFormat("ru-RU").format(item.client.deposits.fee[this.props.reports.currency]) + " " + this.props.reports.currency + "." },
																			{ id: "client_w_rub", header: "Withdrawals", sortingField: "client.withdrawals.total." + this.props.reports.currency, cell: (item) => new Intl.NumberFormat("ru-RU").format(item.client.withdrawals.total[this.props.reports.currency]) + " " + this.props.reports.currency + "." },
																			{ id: "client_w_f", header: "Withdrawals fee", sortingField: "client.withdrawals.fee." + this.props.reports.currency, cell: (item) => new Intl.NumberFormat("ru-RU").format(item.client.withdrawals.fee[this.props.reports.currency]) + " " + this.props.reports.currency + "." },
																			{ id: "client_b_rub", header: "Bonuses", sortingField: "client.bonuses.total." + this.props.reports.currency, cell: (item) => new Intl.NumberFormat("ru-RU").format(item.client.bonuses.total[this.props.reports.currency]) + " " + this.props.reports.currency + "." },
																			{ id: "costs", header: "Costs", sortingField: "costs.total." + this.props.reports.currency, cell: (item) => new Intl.NumberFormat("ru-RU").format(item.costs.total[this.props.reports.currency]) + " " + this.props.reports.currency + "." },
																			{ id: "settlements", header: "Settlements", sortingField: "settlements.total." + this.props.reports.currency, cell: (item) => new Intl.NumberFormat("ru-RU").format(item.settlements.total[this.props.reports.currency]) + " " + this.props.reports.currency + "." },
																			{ id: "profit", header: "Profit", sortingField: "profit." + this.props.reports.currency, cell: (item) => new Intl.NumberFormat("ru-RU").format(item.profit[this.props.reports.currency]) + " " + this.props.reports.currency + "." },
																		]}
																		empty="No stats was loaded"
																		items={this.props.reports.data}
																	/>
																) : (
																	<Box variant="div" margin={{ top: "l" }} textAlign="center">
																		<Spinner size="normal" />
																	</Box>
																),
														},
													]}
												/>
											</>
										}
									/>
								),
							},
							{
								id: "costs",
								disabled: !this.props.reports.loaded && this.props.reports.currentReport !== "costs",
								label: "Costs report",
								content: (
									<Container
										header={<Box margin={{ bottom: "m" }} children={<Header children="Costs report" counter={<>({this.props.reports.data.length ? this.props.reports.data.length : 0})</>} description="Below listed project costs" />} />}
										children={
											<>
												<Grid
													gridDefinition={[{ colspan: 12 }, { colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}
													children={
														<>
															<RangePicker
																disabled={!this.props.reports.loaded}
																relativeOptions={[]}
																clearButtonLabel="Clear"
																dateOnly={true}
																placeholder="Select range for show stats"
																onChange={(range) => {
																	this.props.setReports({ ...this.props.reports, ...this.formatRange(range.detail.value.startDate, range.detail.value.endDate), range: range.detail.value });
																	setTimeout(() => {
																		this.loadReport("costs");
																	}, 250);
																}}
																value={this.props.reports.range}
																i18nStrings={{
																	customRelativeRangeUnitLabel: "",
																	formatRelativeRange: (value) => {
																		return value.amount;
																	},
																	formatUnit: () => {
																		return "";
																	},
																	nextMonthAriaLabel: "Next",
																	applyButtonLabel: "Ok",
																}}
															/>

															<Select
																filteringType="auto"
																disabled={!this.props.reports.loaded}
																placeholder="Period"
																selectedOption={this.props.reports.currentOption.period}
																onChange={(option) => {
																	!isEqual(option.detail.selectedOption.type, this.props.reports.currentPeriod) &&
																		this.props.setReports({
																			...this.props.reports,
																			currentPeriod: option.detail.selectedOption.type,
																			currentOption: { ...this.props.reports.currentOption, period: option.detail.selectedOption },
																			limit: option.detail.selectedOption.type.limit,
																			offset: option.detail.selectedOption.type.offset,
																			range: { type: "absolute", startDate: this.formatLimitOffset(option.detail.selectedOption.type.limit, option.detail.selectedOption.type.offset).from, endDate: this.formatLimitOffset(option.detail.selectedOption.type.limit, option.detail.selectedOption.type.offset).to },
																		});
																	setTimeout(() => {
																		this.loadReport("costs");
																	}, 0);
																}}
																options={this.props.config.stats.options.period}
															/>
															<Select
																filteringType="auto"
																disabled={!this.props.reports.loaded}
																placeholder="Group"
																selectedOption={this.props.reports.currentOption.costsGroup}
																onChange={(option) => {
																	option.detail.selectedOption.type !== this.props.reports.currentCostsGroup && this.props.setReports({ ...this.props.reports, currentCostsGroup: option.detail.selectedOption.type, currentOption: { ...this.props.reports.currentOption, costsGroup: option.detail.selectedOption } });
																	setTimeout(() => {
																		this.loadReport("costs");
																	}, 0);
																}}
																options={this.props.config.reports.options.group}
															/>
														</>
													}
												/>
												<Tabs
													tabs={[
														{
															id: "table",
															label: "Table view",
															content:
																this.props.reports.currentReport === "costs" ? (
																	<Table
																		stickyHeader={true}
																		loading={!this.props.reports.loaded}
																		sortingColumn={{ sortingField: this.props.reports.sorting.sortingColumn.sortingField }}
																		sortingDescending={this.props.reports.sorting.isDescending}
																		onSortingChange={(sorting) => {
																			this.props.setReports({ ...this.props.reports, sorting: sorting.detail });
																			setTimeout(() => {
																				this.props.setReports({
																					...this.props.reports,
																					data: cloneDeep(this.props.reports.data).sort((a, b) => {
																						return this.compare(a, b);
																					}),
																				});
																			}, 0);
																		}}
																		columnDefinitions={[
																			{ id: "date", header: "Date", sortingField: "date", cell: (item) => (typeof item.date === "string" ? <b>{item.date}</b> : typeof item.dateEnd !== "undefined" && item.date !== item.dateEnd ? new Date(item.date).toLocaleDateString() + " - " + new Date(item.dateEnd).toLocaleDateString() : new Date(item.date).toLocaleDateString()) },
																			{ id: "name", header: "Name", sortingField: "name", cell: (item) => item.name },
																			{ id: "group", header: "Group", sortingField: "group", cell: (item) => this.props.config.reports.options.group.find((config) => config.type === item.group).value },
																			{
																				id: "description",
																				header: "Data",
																				sortingField: "data",
																				cell: (item) => (
																					<Link variant="normal" target="_blank" href={item.data}>
																						Open
																					</Link>
																				),
																			},
																			{ id: "brand", header: "Brand", sortingField: "brand", cell: (item) => (item.brand === 0 ? "All" : item.brand === 1 ? "Selector" : item.brand === 2 ? "Brillx" : item.brand === 3 ? "Friends" : "Turbo") },
																			{ id: "currency", header: "Currency", sortingField: "currency", cell: (item) => item.currency },
																			{ id: "currencyAmount", header: "Currency amount", sortingField: "currencyAmount", cell: (item) => new Intl.NumberFormat("ru-RU").format(item.currencyAmount) + " " + item.currency + "." },
																			{ id: "amount", header: "Amount", sortingField: "amount", cell: (item) => new Intl.NumberFormat("ru-RU").format(item.amount) + " USD." },
																		]}
																		empty="No stats was loaded"
																		items={this.props.reports.data}
																	/>
																) : (
																	<></>
																),
														},
														{
															id: "chart",
															label: "Chart view",
															content: this.props.reports.loaded ? (
																<Grid
																	gridDefinition={[{ colspan: 12 }, { colspan: 12 }, { colspan: 12 }, { colspan: 12 }, { colspan: 12 }]}
																	children={
																		<>
																			<Container
																				header={
																					<Box margin={{ bottom: "m" }}>
																						<Header children={"Costs chart"} counter={"(" + (this.props.reports.data.length ? this.props.reports.data.length : 0) + ")"} />
																					</Box>
																				}
																				children={
																					<BarChart
																						stackedBars
																						hideLegend={true}
																						i18nStrings={{
																							filterLabel: "Filter displayed data",
																							filterPlaceholder: "Filter data",
																							filterSelectedAriaLabel: "selected",
																							detailPopoverDismissAriaLabel: "Dismiss",
																							legendAriaLabel: "Legend",
																							chartAriaRoleDescription: "line chart",
																						}}
																						yTitle="Costs (USD)"
																						statusType={this.props.reports.loaded && this.props.reports.chart && Object.keys(this.props.reports.chart).length !== "undefined" ? "finished" : "loading"}
																						xScaleType="categorical"
																						series={this.getCostsReportBarChartOptions()}
																						errorText="Error building chart"
																					/>
																				}
																			/>
																		</>
																	}
																/>
															) : (
																<Box variant="div" margin={{ top: "l" }} textAlign="center">
																	<Spinner size="normal" />
																</Box>
															),
														},
													]}
												/>
											</>
										}
									/>
								),
							},
							{
								id: "settlements",
								disabled: !this.props.reports.loaded && this.props.reports.currentReport !== "settlements",
								label: "Settlements report",
								content: (
									<Container
										header={<Box margin={{ bottom: "m" }} children={<Header children="Settlements report" counter={<>({this.props.reports.data.length ? this.props.reports.data.length : 0})</>} description="Below listed project settlements" />} />}
										children={
											<>
												<Tabs
													tabs={[
														{
															id: "table",
															label: "Table view",
															content:
																this.props.reports.currentReport === "settlements" ? (
																	<Table
																		stickyHeader={true}
																		loading={!this.props.reports.loaded}
																		sortingColumn={{ sortingField: this.props.reports.sorting.sortingColumn.sortingField }}
																		sortingDescending={this.props.reports.sorting.isDescending}
																		onSortingChange={(sorting) => {
																			this.props.setReports({ ...this.props.reports, sorting: sorting.detail });
																			setTimeout(() => {
																				this.props.setReports({
																					...this.props.reports,
																					data: cloneDeep(this.props.reports.data).sort((a, b) => {
																						return this.compare(a, b);
																					}),
																				});
																			}, 0);
																		}}
																		columnDefinitions={[
																			{ id: "date", header: "Date", sortingField: "date", cell: (item) => (typeof item.date === "string" ? <b>{item.date}</b> : typeof item.dateEnd !== "undefined" && item.date !== item.dateEnd ? new Date(item.date).toLocaleDateString() + " - " + new Date(item.dateEnd).toLocaleDateString() : new Date(item.date).toLocaleDateString()) },
																			{ id: "name", header: "Name", sortingField: "name", cell: (item) => item.name },
																			{
																				id: "description",
																				header: "Data",
																				sortingField: "data",
																				cell: (item) => (
																					<Link variant="normal" target="_blank" href={item.data}>
																						Open
																					</Link>
																				),
																			},
																			{ id: "brand", header: "Brand", sortingField: "brand", cell: (item) => (item.brand === 0 ? "All" : item.brand === 1 ? "Selector" : item.brand === 2 ? "Brillx" : item.brand === 3 ? "Friends" : "Turbo") },
																			{ id: "amount", header: "Amount", sortingField: "amount", cell: (item) => new Intl.NumberFormat("ru-RU").format(item.amount) + " USD." },
																		]}
																		empty="No stats was loaded"
																		items={this.props.reports.data}
																	/>
																) : (
																	<></>
																),
														},
													]}
												/>
											</>
										}
									/>
								),
							},
							{
								id: "games",
								disabled: !this.props.reports.loaded && this.props.reports.currentReport !== "games",
								label: "Games report",
								content:
									this.props.reports.currentReport === "games" ? (
										<Container
											header={<Box margin={{ bottom: "m" }} children={<Header children="Games stats" counter={<>({this.props.reports.data.length ? this.props.reports.data.length : 0})</>} description="Below listed stats for selected date" />} />}
											children={
												<>
													<Grid
														gridDefinition={[{ colspan: 12 }, { colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}
														children={
															<>
																<RangePicker
																	disabled={!this.props.reports.loaded}
																	relativeOptions={[]}
																	clearButtonLabel="Clear"
																	dateOnly={true}
																	placeholder="Select range for show stats"
																	onChange={(range) => {
																		this.props.setReports({ ...this.props.reports, ...this.formatRange(range.detail.value.startDate, range.detail.value.endDate), range: range.detail.value });
																		setTimeout(() => {
																			this.loadReport("games");
																		}, 250);
																	}}
																	value={this.props.reports.range}
																	i18nStrings={{
																		customRelativeRangeUnitLabel: "",
																		formatRelativeRange: (value) => {
																			return value.amount;
																		},
																		formatUnit: () => {
																			return "";
																		},
																		nextMonthAriaLabel: "Next",
																		applyButtonLabel: "Ok",
																	}}
																/>
																<Select
																	filteringType="auto"
																	disabled={!this.props.reports.loaded}
																	placeholder="Group by"
																	selectedOption={this.props.reports.currentOption.group}
																	onChange={(option) => {
																		option.detail.selectedOption.type !== this.props.reports.currentGroup && this.props.setReports({ ...this.props.reports, currentGroup: option.detail.selectedOption.type, currentOption: { ...this.props.reports.currentOption, group: option.detail.selectedOption } });
																		setTimeout(() => {
																			this.rebuildGamesStats();
																		}, 0);
																	}}
																	options={this.props.config.stats.options.delimiter}
																/>

																<Select
																	filteringType="auto"
																	disabled={!this.props.reports.loaded}
																	placeholder="Period"
																	selectedOption={this.props.reports.currentOption.period}
																	onChange={(option) => {
																		!isEqual(option.detail.selectedOption.type, this.props.reports.currentPeriod) &&
																			this.props.setReports({
																				...this.props.reports,
																				currentPeriod: option.detail.selectedOption.type,
																				currentOption: { ...this.props.reports.currentOption, period: option.detail.selectedOption },
																				limit: option.detail.selectedOption.type.limit,
																				offset: option.detail.selectedOption.type.offset,
																				range: { type: "absolute", startDate: this.formatLimitOffset(option.detail.selectedOption.type.limit, option.detail.selectedOption.type.offset).from, endDate: this.formatLimitOffset(option.detail.selectedOption.type.limit, option.detail.selectedOption.type.offset).to },
																			});
																		setTimeout(() => {
																			this.loadReport("games");
																		}, 0);
																	}}
																	options={this.props.config.stats.options.period}
																/>
															</>
														}
													/>
													<Tabs
														tabs={[
															{
																id: "table",
																label: "Table view",
																content: this.props.reports.loaded ? (
																	<Table
																		stickyHeader={true}
																		loading={!this.props.reports.loaded}
																		sortingColumn={{ sortingField: this.props.reports.sorting.sortingColumn.sortingField }}
																		sortingDescending={this.props.reports.sorting.isDescending}
																		onSortingChange={(sorting) => {
																			this.props.setReports({ ...this.props.reports, sorting: sorting.detail });
																			setTimeout(() => {
																				this.props.setReports({
																					...this.props.reports,
																					data: cloneDeep(this.props.reports.data).sort((a, b) => {
																						return this.compare(a, b);
																					}),
																				});
																			}, 0);
																		}}
																		columnDefinitions={[
																			{ id: "date", header: "Date", sortingField: "date", cell: (item) => (typeof item.date === "string" ? <b>{item.date}</b> : typeof item.dateEnd !== "undefined" && item.date !== item.dateEnd ? new Date(item.date).toLocaleDateString() + " - " + new Date(item.dateEnd).toLocaleDateString() : new Date(item.date).toLocaleDateString()) },
																			{ id: "betsTotal", header: "Bets total", sortingField: "bets.total", cell: (item) => new Intl.NumberFormat("ru-RU").format(item.bets.total) + " RUB." },
																			{ id: "winsTotal", header: "Wins total", sortingField: "wins.total", cell: (item) => new Intl.NumberFormat("ru-RU").format(item.wins.total) + " RUB." },
																			{ id: "Bets count", header: "Bets count", sortingField: "bets.count", cell: (item) => item.bets.count },
																			{ id: "winsCount", header: "Wins count", sortingField: "wins.count", cell: (item) => item.wins.count },
																			{ id: "rtp", header: "RTP", sortingField: "rtp", cell: (item) => <Box variant="span" color={item.rtp < 98 ? (item.rtp < 85 ? "text-status-success" : "text-body-secondary") : "text-status-error"} children={item.rtp + "%"} /> },
																		]}
																		empty="No stats was loaded"
																		items={this.props.reports.data}
																	/>
																) : (
																	<Box variant="div" margin={{ top: "l" }} textAlign="center">
																		<Spinner size="normal" />
																	</Box>
																),
															},
															{
																id: "chart",
																label: "Chart view",
																content: this.props.reports.loaded ? (
																	<Grid
																		gridDefinition={[{ colspan: 12 }, { colspan: 12 }, { colspan: 12 }, { colspan: 12 }, { colspan: 12 }]}
																		children={
																			<>
																				<Grid
																					gridDefinition={[{ colspan: { default: 12, m: 6 } }, { colspan: { default: 12, m: 6 } }]}
																					children={
																						<>
																							<Container
																								header={<Header children={"Bets total chart (" + (this.props.reports.data.length ? new Intl.NumberFormat("ru-RU").format(this.props.reports.data[this.props.reports.data.length - 1].bets.total) : 0) + " RUB.)"} />}
																								children={<LineChart hideLegend={true} hideFilter={true} statusType={this.props.reports.loaded && typeof this.props.reports.chart.bets !== "undefined" ? "finished" : "loading"} yScaleType="linear" xScaleType="categorical" series={[{ title: "Bets total", type: "bar", data: this.props.reports.chart.bets.total }]} errorText="Error building chart" />}
																							/>
																							<Container
																								header={<Header children={"Bets count chart (" + (this.props.reports.data.length ? this.props.reports.data[this.props.reports.data.length - 1].bets.count : 0) + ")"} />}
																								children={<LineChart hideLegend={true} hideFilter={true} statusType={this.props.reports.loaded && typeof this.props.reports.chart.bets !== "undefined" ? "finished" : "loading"} yScaleType="linear" xScaleType="categorical" series={[{ title: "Bets count", color: "#1f8205", type: "line", data: this.props.reports.chart.bets.count }]} errorText="Error building chart" />}
																							/>
																						</>
																					}
																				/>

																				<Grid
																					gridDefinition={[{ colspan: { default: 12, m: 6 } }, { colspan: { default: 12, m: 6 } }]}
																					children={
																						<>
																							<Container
																								header={<Header children={"Wins total chart (" + (this.props.reports.data.length ? new Intl.NumberFormat("ru-RU").format(this.props.reports.data[this.props.reports.data.length - 1].wins.total) : 0) + " RUB.)"} />}
																								children={<LineChart hideLegend={true} hideFilter={true} statusType={this.props.reports.loaded && typeof this.props.reports.chart.wins !== "undefined" ? "finished" : "loading"} yScaleType="linear" xScaleType="categorical" series={[{ title: "Wins total", type: "bar", data: this.props.reports.chart.wins.total }]} errorText="Error building chart" />}
																							/>
																							<Container
																								header={<Header children={"Wins count chart (" + (this.props.reports.data.length ? this.props.reports.data[this.props.reports.data.length - 1].wins.count : 0) + ")"} />}
																								children={<LineChart hideLegend={true} hideFilter={true} statusType={this.props.reports.loaded && typeof this.props.reports.chart.wins !== "undefined" ? "finished" : "loading"} yScaleType="linear" xScaleType="categorical" series={[{ title: "Wins count", color: "#1f8205", type: "line", data: this.props.reports.chart.wins.count }]} errorText="Error building chart" />}
																							/>
																						</>
																					}
																				/>

																				<Grid
																					gridDefinition={[{ colspan: 12 }]}
																					children={
																						<>
																							<Container
																								header={<Header children={"RTP chart (" + (this.props.reports.data.length ? this.props.reports.data[this.props.reports.data.length - 1].rtp : 0) + ")"} />}
																								children={<LineChart hideLegend={true} hideFilter={true} statusType={this.props.reports.loaded && typeof this.props.reports.chart.rtp !== "undefined" ? "finished" : "loading"} yScaleType="linear" xScaleType="categorical" series={[{ title: "RTP", color: "#1f8205", type: "line", data: this.props.reports.chart.rtp }]} errorText="Error building chart" />}
																							/>
																						</>
																					}
																				/>
																			</>
																		}
																	/>
																) : (
																	<Box variant="div" margin={{ top: "l" }} textAlign="center">
																		<Spinner size="normal" />
																	</Box>
																),
															},
														]}
													/>
												</>
											}
										/>
									) : (
										<></>
									),
							},
							{
								id: "providers",
								disabled: !this.props.reports.loaded && this.props.reports.currentReport !== "providers",
								label: "Providers report",
								content: (
									<Container
										header={<Box margin={{ bottom: "m" }} children={<Header children="Providers report" counter={<>({this.props.reports.data.length ? this.props.reports.data.length : 0})</>} description="Below listed project providers reports" />} />}
										children={
											<>
												<Grid
													gridDefinition={[{ colspan: 12 }, { colspan: 12 }, { colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}
													children={
														<>
															<RangePicker
																disabled={!this.props.reports.loaded}
																relativeOptions={[]}
																clearButtonLabel="Clear"
																dateOnly={true}
																placeholder="Select range for show stats"
																onChange={(range) => {
																	this.props.setReports({ ...this.props.reports, ...this.formatRange(range.detail.value.startDate, range.detail.value.endDate), range: range.detail.value });
																	setTimeout(() => {
																		this.loadReport("providers");
																	}, 0);
																}}
																value={this.props.reports.range}
																i18nStrings={{
																	customRelativeRangeUnitLabel: "",
																	formatRelativeRange: (value) => {
																		return value.amount;
																	},
																	formatUnit: () => {
																		return "";
																	},
																	nextMonthAriaLabel: "Next",
																	applyButtonLabel: "Ok",
																}}
															/>
															<Multiselect
																selectedOptions={this.props.reports.currentOption.sortTags}
																disabled={!this.props.reports.loaded}
																onChange={({ detail }) => {
																	this.props.setReports({ ...this.props.reports, currentSortTags: detail.selectedOptions.map((item) => item.value), currentOption: { ...this.props.reports.currentOption, sortTags: detail.selectedOptions } });
																	setTimeout(() => {
																		this.loadReport("providers");
																	}, 0);
																}}
																deselectAriaLabel={(e) => `Remove ${e.label}`}
																options={this.getProviderSortTags()}
																placeholder="Choose data to load"
																selectedAriaLabel="Selected"
															/>
															<Select
																filteringType="auto"
																disabled={!this.props.reports.loaded}
																placeholder="Group by"
																selectedOption={this.props.reports.currentOption.groupProvider}
																onChange={(option) => {
																	option.detail.selectedOption.type !== this.props.reports.currentGroupProvider && this.props.setReports({ ...this.props.reports, currentGroupProvider: option.detail.selectedOption.type, currentOption: { ...this.props.reports.currentOption, groupProvider: option.detail.selectedOption } });
																	setTimeout(() => {
																		this.loadReport("providers");
																	}, 0);
																}}
																options={this.props.config.stats.options.delimiterProvider}
															/>
															<Select
																filteringType="auto"
																disabled={!this.props.reports.loaded}
																placeholder="Period"
																selectedOption={this.props.reports.currentOption.period}
																onChange={(option) => {
																	!isEqual(option.detail.selectedOption.type, this.props.reports.currentPeriod) &&
																		this.props.setReports({
																			...this.props.reports,
																			currentPeriod: option.detail.selectedOption.type,
																			currentOption: { ...this.props.reports.currentOption, period: option.detail.selectedOption },
																			limit: option.detail.selectedOption.type.limit,
																			offset: option.detail.selectedOption.type.offset,
																			range: { type: "absolute", startDate: this.formatLimitOffset(option.detail.selectedOption.type.limit, option.detail.selectedOption.type.offset).from, endDate: this.formatLimitOffset(option.detail.selectedOption.type.limit, option.detail.selectedOption.type.offset).to },
																		});
																	setTimeout(() => {
																		this.loadReport("providers");
																	}, 0);
																}}
																options={this.props.config.stats.options.period}
															/>
															<Select
																filteringType="auto"
																disabled={!this.props.reports.loaded}
																placeholder="Integrator"
																selectedOption={this.props.reports.currentOption.integrator}
																onChange={(option) => {
																	option.detail.selectedOption.type !== this.props.reports.currentIntegrator && this.props.setReports({ ...this.props.reports, currentIntegrator: option.detail.selectedOption.type, currentOption: { ...this.props.reports.currentOption, integrator: option.detail.selectedOption } });
																	setTimeout(() => {
																		this.loadReport("providers");
																	}, 0);
																}}
																options={this.props.config.game.options.integrator}
															/>
														</>
													}
												/>
												<Tabs
													tabs={[
														{
															id: "table",
															label: "Table view",
															content:
																this.props.reports.currentReport === "providers" ? (
																	<Table
																		stickyHeader={true}
																		loading={!this.props.reports.loaded}
																		sortingColumn={{ sortingField: this.props.reports.sorting.sortingColumn.sortingField }}
																		sortingDescending={this.props.reports.sorting.isDescending}
																		onSortingChange={(sorting) => {
																			this.props.setReports({ ...this.props.reports, sorting: sorting.detail });
																			setTimeout(() => {
																				this.props.setReports({
																					...this.props.reports,
																					data: cloneDeep(this.props.reports.data).sort((a, b) => {
																						return this.compare(a, b);
																					}),
																				});
																			}, 0);
																		}}
																		columnDefinitions={this.getProvidersReportColumns()}
																		empty="No stats was loaded"
																		items={this.props.reports.data}
																	/>
																) : (
																	<></>
																),
														},
														{
															id: "chart",
															label: "Chart view",
															content:
																this.props.reports.loaded && this.props.reports.currentReport === "providers" ? (
																	<Grid
																		gridDefinition={[
																			{ colspan: 12 },
																			{ colspan: 12 },
																			{ colspan: 12 },
																			{ colspan: 12 },
																			{ colspan: 12 },
																			{ colspan: 12 },
																			{ colspan: 12 },
																			{ colspan: 12 },
																			{ colspan: 12 },
																			{ colspan: 12 },
																			{ colspan: 12 },
																			{ colspan: 12 },
																			{ colspan: 12 },
																			{ colspan: 12 },
																			{ colspan: 12 },
																			{ colspan: 12 },
																			{ colspan: 12 },
																			{ colspan: 12 },
																			{ colspan: 12 },
																			{ colspan: 12 },
																			{ colspan: 12 },
																			{ colspan: 12 },
																			{ colspan: 12 },
																			{ colspan: 12 },
																			{ colspan: 12 },
																			{ colspan: 12 },
																			{ colspan: 12 },
																			{ colspan: 12 },
																			{ colspan: 12 },
																			{ colspan: 12 },
																			{ colspan: 12 },
																			{ colspan: 12 },
																			{ colspan: 12 },
																			{ colspan: 12 },
																			{ colspan: 12 },
																			{ colspan: 12 },
																		]}
																		children={
																			<>
																				{Object.keys(this.props.reports.chart).map((key) =>
																					Object.keys(this.props.reports.chart[key]).map((key2) => (
																						<>
																							{this.props.reports.currentSortTags.includes(key) ? (
																								<Container
																									header={
																										<Box margin={{ bottom: "m" }}>
																											<Header children={key2 + " " + key + " chart"} counter={"(" + (typeof this.props.reports.chart[key][key2] !== "undefined" ? this.props.reports.chart[key][key2].length : 0) + ")"} />
																										</Box>
																									}
																									children={
																										<LineChart
																											hideLegend={true}
																											statusType={this.props.reports.loaded && typeof this.props.reports.chart[key][key2] !== "undefined" ? "finished" : "loading"}
																											onFilterChange={(filter) => {
																												this.updateVisibleCharts(key, key2, filter.detail.visibleSeries);
																											}}
																											visibleSeries={typeof this.props.reports.visibleSeries[key][key2] !== "undefined" ? this.props.reports.visibleSeries[key][key2] : []}
																											yScaleType="linear"
																											xScaleType="categorical"
																											i18nStrings={{
																												filterLabel: "Filter displayed data",
																												filterPlaceholder: "Filter data",
																												filterSelectedAriaLabel: "selected",
																												detailPopoverDismissAriaLabel: "Dismiss",
																												legendAriaLabel: "Legend",
																												chartAriaRoleDescription: "line chart",
																											}}
																											yTitle="GGR (RUB)"
																											series={this.props.reports.chart[key][key2]}
																											errorText="Error building chart"
																										/>
																									}
																								/>
																							) : (
																								<></>
																							)}
																						</>
																					)),
																				)}
																			</>
																		}
																	/>
																) : (
																	<Box variant="div" margin={{ top: "l" }} textAlign="center">
																		<Spinner size="normal" />
																	</Box>
																),
														},
													]}
												/>
											</>
										}
									/>
								),
							},
						]}
					/>
				</Container>
			</>
		);
	}
}

function withRecoil(Component) {
	return function WrappedComponent(props) {
		const reports = useRecoilValue(reportStore);
		const setReports = useSetRecoilState(reportStore);
		const config = useRecoilValue(configStore);
		const reportsAddCostsModal = useRecoilValue(reportsAddCostsModalStore);
		const setReportsAddCostsModal = useSetRecoilState(reportsAddCostsModalStore);
		const reportsAddSettlementsModal = useRecoilValue(reportsAddSettlementsModalStore);
		const setReportsAddSettlementsModal = useSetRecoilState(reportsAddSettlementsModalStore);
		const user = useRecoilValue(userStore);
		const setUser = useSetRecoilState(userStore);

		return <Component {...props} user={user} setUser={setUser} reports={reports} setReports={setReports} config={config} reportsAddCostsModal={reportsAddCostsModal} setReportsAddCostsModal={setReportsAddCostsModal} reportsAddSettlementsModal={reportsAddSettlementsModal} setReportsAddSettlementsModal={setReportsAddSettlementsModal} />;
	};
}

export default withRecoil(Reports);
