import React from "react";

import Modal from "@cloudscape-design/components/modal";
import Table from "@cloudscape-design/components/table";
import Pagination from "@cloudscape-design/components/pagination";
import Preferences from "@cloudscape-design/components/collection-preferences";
import Box from "@cloudscape-design/components/box";
import Alert from "@cloudscape-design/components/alert";
import Header from "@cloudscape-design/components/header";
import Link from "@cloudscape-design/components/link";

/* Recoil */
import { useRecoilValue, useSetRecoilState } from "recoil";
import { configStore } from "../stores/config";
import { playerSessionsModalStore } from "../stores/playerSessionsModal";
import { playerPageStore } from "../stores/playerPage";
/* ------- */

class playerSessionsModal extends React.Component {
	getStatusColor(status) {
		switch (status) {
			case "active":
				return "green";
			case "expired":
				return "red";
			default:
				return "black";
		}
	}

	render() {
		return (
			<>
				<Modal
					size="max"
					visible={this.props.playerSessions.show}
					onDismiss={() => {
						this.props.setPlayerSessions({ ...this.props.playerSessions, show: false, loaded: false, data: [], pagination: { current: 1, total: 0 } });
					}}
					header={<Box margin={{ bottom: "m" }} children={<Header children="Sessions" counter={<>({this.props.playerSessions.data.length})</>} description="Below listed selected user's sessions" />} />}
					children={
						<>
							{this.props.playerSessions.loaded && this.props.playerSessions.error.text.length ? (
								<Box
									margin={{ bottom: "m" }}
									children={
										<Alert
											type="error"
											header="Error loading data:"
											children={
												<>
													<Box variant="p" children={this.props.playerSessions.error.text} />
													<hr />
													<Box
														variant="p"
														children={
															<>
																request error code: {this.props.playerSessions.error.code} | request timestamp: {this.props.playerSessions.error.timestamp}
															</>
														}
													/>
												</>
											}
										/>
									}
								/>
							) : (
								<></>
							)}
							<Table
								stickyHeader={true}
								wrapLines={true}
								loading={!this.props.playerSessions.loaded}
								columnDefinitions={[
									{ maxWidth: 290, id: "game", header: "Game", cell: (item) => this.props.Interface.getGameByID(item.gameID) },
									{ id: "operations", header: "Operations", cell: (item) => item.operations },
									{
										id: "profit",
										header: "Profit",
										cell: (item) => (
											<Box variant="span" color={item.profit >= 0 ? (item.profit > 0 ? "text-status-success" : "text-body-secondary") : "text-status-error"}>
												{new Intl.NumberFormat("ru-RU").format((item.profit / 100).toFixed(2))} RUB.
											</Box>
										),
									},
									{
										id: "sessionID",
										header: "Session ID",
										cell: (item) => (
											<Link
												onFollow={() => {
													this.props.Interface.loadGameLog(this.props.playerPage.data.casinoID, item.sessionID, item.gameID);
												}}>
												{item.sessionID}
											</Link>
										),
									},
									{ id: "created", header: "Created", cell: (item) => new Date(item.date).toLocaleDateString() + " " + new Date(item.date).toLocaleTimeString() },
									{ id: "last", header: "Last activity", cell: (item) => new Date(item.last).toLocaleDateString() + " " + new Date(item.last).toLocaleTimeString() },
								]}
								items={this.props.playerSessions.data.slice((this.props.playerSessions.pagination.current - 1) * this.props.config.staff.sessions.perPage, this.props.playerSessions.pagination.current * this.props.config.staff.sessions.perPage)}
								pagination={
									<Pagination
										disabled={!this.props.playerSessions.loaded}
										currentPageIndex={this.props.playerSessions.pagination.current}
										pagesCount={this.props.playerSessions.pagination.total}
										onChange={(page) => {
											this.props.setPlayerSessions({ ...this.props.playerSessions, pagination: { ...this.props.playerSessions.pagination, current: page.detail.currentPageIndex } });
										}}
									/>
								}
								preferences={
									<Preferences
										cancelLabel="Cancel"
										confirmLabel="Confirm"
										pageSizePreference={{ title: "Sessions per page", options: this.props.config.staff.sessions.preferences.perPage }}
										preferences={{ pageSize: this.props.config.staff.sessions.perPage }}
										title="Preferences"
										onConfirm={(preferences) => {
											this.props.setConfig({ ...this.props.config, staff: { ...this.props.config.staff, sessions: { ...this.props.config.staff.sessions, perPage: preferences.detail.pageSize } } });
											this.props.setPlayerSessions({ ...this.props.playerSessions, pagination: { current: 1, total: Math.ceil(this.props.playerSessions.data.length / preferences.detail.pageSize) } });
										}}
									/>
								}
								empty="No sessions found with requested filter"
							/>
						</>
					}
				/>
			</>
		);
	}
}

function withRecoil(Component) {
	return function WrappedComponent(props) {
		const config = useRecoilValue(configStore);
		const setConfig = useSetRecoilState(configStore);
		const playerSessions = useRecoilValue(playerSessionsModalStore);
		const setPlayerSessions = useSetRecoilState(playerSessionsModalStore);
		const playerPage = useRecoilValue(playerPageStore);
		const setPlayerPage = useSetRecoilState(playerPageStore);

		return <Component {...props} config={config} setConfig={setConfig} playerSessions={playerSessions} setPlayerSessions={setPlayerSessions} playerPage={playerPage} setPlayerPage={setPlayerPage} />;
	};
}

export default withRecoil(playerSessionsModal);
