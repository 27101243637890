import React from "react";

import cloneDeep from "lodash/cloneDeep";

import Table from "@cloudscape-design/components/table";
import Pagination from "@cloudscape-design/components/pagination";
import Header from "@cloudscape-design/components/header";
import Input from "@cloudscape-design/components/input";
import Select from "@cloudscape-design/components/select";
import Button from "@cloudscape-design/components/button";
import Grid from "@cloudscape-design/components/grid";
import Box from "@cloudscape-design/components/box";
import Link from "@cloudscape-design/components/link";
import Preferences from "@cloudscape-design/components/collection-preferences";
import Alert from "@cloudscape-design/components/alert";

/* Recoil */
import { useRecoilValue, useSetRecoilState } from "recoil";
import { configStore } from "../stores/config";
import { gameStore } from "../stores/games";
import SpaceBetween from "@cloudscape-design/components/space-between";
import { gamesAddModalStore } from "../stores/gamesAddModal";
import { gamesStatsModalStore } from "../stores/gamesStatsModal";
import { gamesEditModalStore } from "../stores/gamesEditModal";
import { userStore } from "../stores/access";
import { gamesAutoManageModalStore } from "../stores/gamesAutomanageModal";
/* ------- */

import ProgressiveImage from "react-progressive-graceful-image";
import Poster from "../assets/poster.png";

class Games extends React.Component {
	constructor(props) {
		super(props);
		this.scrollPosition = 0;
		this.currentSearch = "";
		this.query = {};
		this.loadTimeout = false;
		window.location.search
			.substr(1)
			.split("&")
			.forEach((item) => {
				this.query[item.split("=")[0]] = item.split("=")[1];
			});
	}

	loadGames() {
		let data = cloneDeep(this.props.game.data);

		data.forEach((item) => {
			if (
				(!this.props.game.currentStatus || (item.active === 1 && this.props.game.currentStatus === "enabled") || (item.active !== 1 && this.props.game.currentStatus === "disabled")) &&
				(!this.props.game.currentCategory || item.categoryID.toString().indexOf(this.props.game.currentCategory.toString()) !== -1) &&
				(!this.props.game.currentProvider || item.providerID === this.props.game.currentProvider) &&
				(!this.props.game.currentSearch.length || item.gameID.toString().indexOf(this.props.game.currentSearch) !== -1 || item.name.toUpperCase().indexOf(this.props.game.currentSearch.toUpperCase()) !== -1 || item.providerID.toString().indexOf(this.props.game.currentSearch) !== -1)
			)
				item.filtered = true;
			else item.filtered = false;
		});

		this.props.setGame({ ...this.props.game, data });

		setTimeout(() => {
			let total = Math.ceil(
				this.props.game.data.filter((item) => {
					return item.filtered;
				}).length / this.props.config.game.perPage,
			);
			this.props.setGame({ ...this.props.game, pagination: { current: 1, total } });
		}, 0);

		/*
         this.props.setGame({ ...this.props.game, data: [], loaded: false });

         const params = {
             provider: this.props.game.currentProvider,
             searchType: this.props.game.currentSearchType,
             direction: this.props.game.currentDirection
         };

         if (this.props.game.currentSearch.length)
             params.search = this.props.game.currentSearch;

         this.props.setGame({ ...this.props.game, loaded: true, currentSearch: this.currentSearch, data: this.props.game.data, pagination: { current: 1, total: Math.ceil(this.props.game.data.length / this.props.config.game.perPage) } });*/
	}

	compare(a, b) {
		if (this.props.game.sorting.sortingColumn.sortingField.indexOf(".") === -1) {
			if (a[this.props.game.sorting.sortingColumn.sortingField] < b[this.props.game.sorting.sortingColumn.sortingField]) {
				return this.props.game.sorting.isDescending ? 1 : -1;
			}

			if (a[this.props.game.sorting.sortingColumn.sortingField] > b[this.props.game.sorting.sortingColumn.sortingField]) {
				return this.props.game.sorting.isDescending ? -1 : 1;
			}
		} else {
			if (a[this.props.game.sorting.sortingColumn.sortingField.split(".")[0]][this.props.game.sorting.sortingColumn.sortingField.split(".")[1]] < b[this.props.game.sorting.sortingColumn.sortingField.split(".")[0]][this.props.game.sorting.sortingColumn.sortingField.split(".")[1]]) {
				return this.props.game.sorting.isDescending ? 1 : -1;
			}

			if (a[this.props.game.sorting.sortingColumn.sortingField.split(".")[0]][this.props.game.sorting.sortingColumn.sortingField.split(".")[1]] > b[this.props.game.sorting.sortingColumn.sortingField.split(".")[0]][this.props.game.sorting.sortingColumn.sortingField.split(".")[1]]) {
				return this.props.game.sorting.isDescending ? -1 : 1;
			}
		}

		return 0;
	}

	handleKeyPress = (event) => {
		if (event.detail.key === "Enter")
			setTimeout(() => {
				this.loadGames();
			}, 0);
	};

	getEconomicScoreColor(score) {
		return score < 0 ? "red" : score < 5 ? "orange" : score < 50 ? "purple" : "green";
	}

	getVisibleColumns() {
		let columns = [];
		columns.push("poster");
		columns.push("bigPoster");
		columns.push("gameID");
		columns.push("name");
		columns.push("provider");
		columns.push("category");
		columns.push("status");
		columns.push("date");
		columns.push("integrator");
		columns.push("biases.bias");

		if (this.props.user.level >= 6) {
			columns.push("betsTotal");
			columns.push("betsCount");
			columns.push("winsTotal");
			columns.push("winsCount");
			columns.push("rtp");
		}

		return columns;
	}

	render() {
		const now = new Date();
		const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
		const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);

		const isBiasActive = (from, to) => {
			const fromDate = from ? new Date(new Date(from).getFullYear(), new Date(from).getMonth(), new Date(from).getDate(), 0, 0, 0, 0) : null;
			const toDate = to ? new Date(new Date(to).getFullYear(), new Date(to).getMonth(), new Date(to).getDate(), 23, 59, 59, 999) : null;

			return (!from && !to) || (from && !to && fromDate <= startOfDay) || (!from && to && toDate >= endOfDay) || (from && to && fromDate <= startOfDay && toDate >= endOfDay);
		};

		return (
			<>
				{this.props.game.loaded && this.props.game.error.text.length ? (
					<Box
						margin={{ bottom: "m" }}
						children={
							<Alert
								type="error"
								header="Error loading data:"
								children={
									<>
										<Box variant="p" children={this.props.game.error.text} />
										<hr />
										<Box
											variant="p"
											children={
												<>
													request error code: {this.props.game.error.code} | request timestamp: {this.props.game.error.timestamp}
												</>
											}
										/>
									</>
								}
							/>
						}
					/>
				) : (
					<></>
				)}

				<Table
					onSelectionChange={(selection) => {
						this.props.setGame({ ...this.props.game, selectedItems: selection.detail.selectedItems });
					}}
					selectedItems={this.props.game.selectedItems}
					visibleColumns={this.getVisibleColumns()}
					stickyHeader="true"
					loading={!this.props.game.loaded}
					sortingColumn={{ sortingField: this.props.game.sorting.sortingColumn.sortingField }}
					sortingDescending={this.props.game.sorting.isDescending}
					onSortingChange={(sorting) => {
						this.props.setGame({ ...this.props.game, sorting: sorting.detail });
						setTimeout(() => {
							this.props.setGame({
								...this.props.game,
								data: cloneDeep(this.props.game.data).sort((a, b) => {
									return this.compare(a, b);
								}),
							});
						}, 0);
					}}
					columnDefinitions={[
						{
							width: 70,
							id: "bigPoster",
							header: "Poster",
							cell: (item) => (
								<ProgressiveImage src={item.bigPoster} placeholder={Poster}>
									{(src) => <img style={{ width: "70px" }} src={src} alt={item.name} />}
								</ProgressiveImage>
							),
						},
						{
							id: "gameID",
							header: "Game ID",
							sortingField: "gameID",
							cell: (item) => (
								<Button
									onClick={() => {
										console.log("this.props.gamesEditModal", this.props.gamesEditModal)
										this.props.setGamesEditModal({ ...this.props.gamesEditModal, biases: item.biases, show: true, loaded: false, providerID: item.providerID, id: item.gameID, editID: item.gameID });
									}}
									iconName="">
									{item.gameID}
								</Button>
							),
						},
						{ id: "name", header: "Name", sortingField: "name", cell: (item) => item.name },
						{ id: "provider", header: "Provider", sortingField: "providerID", cell: (item) => this.props.Interface.getProviderByID(item.providerID) },
						{ id: "integrator", header: "Integrator", sortingField: "integrator", cell: (item) => this.props.Interface.getProviderIntegratorByID(item.providerID) },
						{
							id: "category",
							header: "Category",
							cell: (item) =>
								item.categoryID
									.toString()
									.split(",")
									.map((category, i) => {
										return (
											<>
												{this.props.Interface.getCategoryByID(category)}
												{i + 1 < item.categoryID.toString().split(",").length ? ", " : ""}
											</>
										);
									}),
						},
						{ id: "betsTotal", header: "Bets total", sortingField: "bets.total", cell: (item) => <>{item.bets.total} RUB.</> },
						{ id: "betsCount", header: "Bets count", sortingField: "bets.count", cell: (item) => item.bets.count },
						{ id: "winsTotal", header: "Wins total", sortingField: "wins.total", cell: (item) => <>{item.wins.total} RUB.</> },
						{
							id: "rtp",
							header: "RTP",
							sortingField: "rtp",
							cell: (item) => (
								<Link
									onFollow={() => {
										this.props.setGamesStatsModal({ ...this.props.gamesStatsModal, show: 1, needLoad: true, gameID: item.gameID });
									}}>
									{item.rtp}%
								</Link>
							),
						},
						{ id: "status", header: "Status", sortingField: "active", cell: (item) => <>{item.active === 1 ? "Enabled" : item.active === 0 ? "Disabled" : "Undefined"} </> },
						{ id: "date", header: "Date", sortingField: "date", cell: (item) => new Date(item.date).toLocaleDateString() },
						{
							id: "biases.bias",
							header: "Bias",
							sortingField: "biases.bias",
							cell: (item) => (
								<Box
									key={item.id}
									variant="span"
									color={isBiasActive(item.biases.from, item.biases.to) ? "text-status-success" : "text-status-error"}
									children={
										<>
											{item.biases.bias}
											<br></br>({item.biases.from && item.biases.to ? `${item.biases.from.split("T")[0]} - ${item.biases.to.split("T")[0]}` : item.biases.from ? `${item.biases.from.split("T")[0]} - ...` : item.biases.to ? `... - ${item.biases.to.split("T")[0]}` : "..."})
										</>
									}
								/>
							),
						},
					]}
					items={this.props.game.data
						.filter((item) => {
							return item.filtered;
						})
						.slice((this.props.game.pagination.current - 1) * this.props.config.game.perPage, this.props.game.pagination.current * this.props.config.game.perPage)}
					pagination={
						<Pagination
							disabled={!this.props.game.loaded}
							currentPageIndex={this.props.game.pagination.current}
							pagesCount={this.props.game.pagination.total}
							onChange={(page) => {
								this.props.setGame({ ...this.props.game, pagination: { ...this.props.game.pagination, current: page.detail.currentPageIndex } });
							}}
						/>
					}
					preferences={
						<Preferences
							cancelLabel="Cancel"
							confirmLabel="Confirm"
							pageSizePreference={{ title: "Games per page", options: this.props.config.game.preferences.perPage }}
							preferences={{ pageSize: this.props.config.game.perPage }}
							title="Preferences"
							onConfirm={(preferences) => {
								localStorage.setItem("games.perPage", preferences.detail.pageSize);
								this.props.setConfig({ ...this.props.config, game: { ...this.props.config.game, perPage: preferences.detail.pageSize } });
								this.props.setGame({ ...this.props.game, pagination: { current: 1, total: Math.ceil(this.props.game.data.length / preferences.detail.pageSize) } });
							}}
						/>
					}
					filter={
						<Box
							margin={{ bottom: "m" }}
							children={
								<Grid
									gridDefinition={[{ colspan: 3 }, { colspan: 3 }, { colspan: 3 }, { colspan: 3 }]}
									children={
										<>
											<Select
												filteringType="auto"
												disabled={!this.props.game.loaded}
												placeholder="Status"
												selectedOption={this.props.game.currentOption.status}
												onChange={(option) => {
													option.detail.selectedOption.type !== this.props.game.currentStatus && this.props.setGame({ ...this.props.game, currentStatus: option.detail.selectedOption.type, currentOption: { ...this.props.game.currentOption, status: option.detail.selectedOption } });
													setTimeout(() => {
														this.loadGames();
													}, 0);
												}}
												options={this.props.config.game.options.status}
											/>
											<Select
												filteringType="auto"
												disabled={!this.props.game.loaded}
												placeholder="Category"
												selectedOption={this.props.game.currentOption.category}
												onChange={(option) => {
													option.detail.selectedOption.type !== this.props.game.currentCategory && this.props.setGame({ ...this.props.game, currentCategory: option.detail.selectedOption.type, currentOption: { ...this.props.game.currentOption, category: option.detail.selectedOption } });
													setTimeout(() => {
														this.loadGames();
													}, 0);
												}}
												options={this.props.config.game.options.category}
											/>
											<Select
												filteringType="auto"
												disabled={!this.props.game.loaded}
												placeholder="Provider"
												selectedOption={this.props.game.currentOption.provider}
												onChange={(option) => {
													option.detail.selectedOption.type !== this.props.game.currentProvider && this.props.setGame({ ...this.props.game, currentProvider: option.detail.selectedOption.type, currentOption: { ...this.props.game.currentOption, provider: option.detail.selectedOption } });
													setTimeout(() => {
														this.loadGames();
													}, 0);
												}}
												options={this.props.config.game.options.provider}
											/>
											<Input
												disabled={!this.props.game.loaded}
												onKeyDown={this.handleKeyPress}
												disableBrowserAutocorrect={true}
												placeholder="Game ID, name or something"
												inputMode="search"
												type="search"
												value={this.props.game.currentSearch}
												onChange={(input) => {
													this.props.setGame({ ...this.props.game, currentSearch: input.detail.value });
													clearTimeout(this.loadTimeout);
													this.loadTimeout = setTimeout(() => {
														this.loadGames();
													}, 1000);
													this.currentSearch = input.detail.value;
												}}
											/>
										</>
									}
								/>
							}
						/>
					}
					header={
						<Box
							margin={{ bottom: "m" }}
							children={
								<Header
									children="Games"
									counter={<>({this.props.game.data.length})</>}
									actions={
										this.props.user.level >= 3 ? (
											<SpaceBetween direction="horizontal" size="l">
												<Button
													disabled={!this.props.game.loaded}
													className={this.props.styles["players-search-button"]}
													onClick={() => {
														this.props.setGamesAutomanageModal({ ...this.props.gamesAutoManageModal, show: 1 });
													}}
													variant="primary">
													Automanage
												</Button>
												<Button
													disabled={!this.props.game.loaded}
													className={this.props.styles["players-search-button"]}
													onClick={() => {
														this.props.setGamesAddModal({ ...this.props.gamesAddModal, show: 1 });
													}}
													variant="primary">
													Add game
												</Button>
											</SpaceBetween>
										) : (
											<></>
										)
									}
									description="Below listed games list"
								/>
							}
						/>
					}
					empty="No games found with requested filter"
				/>
			</>
		);
	}
}

function withRecoil(Component) {
	return function WrappedComponent(props) {
		const user = useRecoilValue(userStore);
		const game = useRecoilValue(gameStore);
		const setGame = useSetRecoilState(gameStore);
		const config = useRecoilValue(configStore);
		const setConfig = useSetRecoilState(configStore);
		const gamesAddModal = useRecoilValue(gamesAddModalStore);
		const setGamesAddModal = useSetRecoilState(gamesAddModalStore);
		const gamesStatsModal = useRecoilValue(gamesStatsModalStore);
		const setGamesStatsModal = useSetRecoilState(gamesStatsModalStore);
		const gamesEditModal = useRecoilValue(gamesEditModalStore);
		const setGamesEditModal = useSetRecoilState(gamesEditModalStore);
		const gamesAutoManageModal = useRecoilValue(gamesAutoManageModalStore);
		const setGamesAutomanageModal = useSetRecoilState(gamesAutoManageModalStore);

		return <Component {...props} user={user} game={game} setGame={setGame} config={config} setConfig={setConfig} gamesAddModal={gamesAddModal} setGamesAddModal={setGamesAddModal} gamesStatsModal={gamesStatsModal} setGamesStatsModal={setGamesStatsModal} gamesEditModal={gamesEditModal} setGamesEditModal={setGamesEditModal} setGamesAutomanageModal={setGamesAutomanageModal} gamesAutoManageModal={gamesAutoManageModal} />;
	};
}

export default withRecoil(Games);
