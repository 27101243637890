import { atom } from "recoil";

export const chatBanUserModalStore = atom({
	key: "chatBanUserModalStore",
	default: {
		show: false,
		uid: 0,
		reason: "",
		date: "",
	},
});
