import React from "react";

import Modal from "@cloudscape-design/components/modal";
import Input from "@cloudscape-design/components/input";
import Button from "@cloudscape-design/components/button";
import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Spinner from "@cloudscape-design/components/spinner";
import Select from "@cloudscape-design/components/select";

/* Recoil */
import { useRecoilValue, useSetRecoilState } from "recoil";
import { configStore } from "../stores/config";
import { userStore } from "../stores/access";
import { domainsStore } from "../stores/domains";
import { domainsAddModalStore } from "../stores/domainsAddModal";
/* ------- */

class DomainsAddDomainModal extends React.Component {
	addUser() {
		this.props.setDomainsAddModal({ ...this.props.domainsAddModal, loaded: false });

		this.props.Interface.request("set", { class: "settings", module: "domains", method: "create" }, { domain: this.props.domainsAddModal.domain, type: this.props.domainsAddModal.currentOption.type.type })
			.then(() => {
				this.props.Interface.addNoty("Success", "Domain `" + this.props.domainsAddModal.domain + "` was successfully added");
				this.props.setDomainsAddModal({ ...this.props.domainsAddModal, checked: false, loaded: true, show: false, domain: "", currentOption: { ...this.props.domainsAddModal.currentOption, type: "" } });

				this.props.setDomains({ ...this.props.domains, selectedItems: [], loaded: false });

				this.props.Interface.request("get", { class: "data", module: "info", method: "domains" })
					.then((data) => {
						data.forEach((item) => {
							item.filtered = true;
						});

						this.props.setDomains({
							...this.props.domains,
							data,
							loaded: true,
							currentSearch: "",
							pagination: { current: 1, total: Math.ceil(data.length / this.props.config.domains.perPage) },
							sorting: {
								sortingColumn: {
									sortingField: "",
								},
								sortingDescending: false,
							},
						});
					})
					.catch((e) => {
						this.props.setDomains({ ...this.props.domains, loaded: true, error: e });
					});
			})
			.catch((e) => {
				this.props.setDomainsAddModal({ ...this.props.domainsAddModal, checked: true, loaded: true });
				console.log(e);
			});
	}

	render() {
		return (
			<>
				<Modal
					visible={this.props.domainsAddModal.show}
					onDismiss={() => {
						this.props.setDomainsAddModal({ ...this.props.domainsAddModal, checked: false, loaded: true, show: false, domain: "", currentOption: { ...this.props.domainsAddModal.currentOption, type: "" } });
					}}
					header="Add domain"
					children={
						<>
							<SpaceBetween direction="vertical" size="m">
								{this.props.domainsAddModal.loaded ? (
									<>
										<Box variant="span">domain: </Box>
										<Input
											autoFocus="true"
											name="domain"
											autoComplete="off"
											invalid={(this.props.domainsAddModal.domain.length > 32 || this.props.domainsAddModal.domain.length < 4) && this.props.domainsAddModal.checked ? true : false}
											inputMode="url"
											type="text"
											disableBrowserAutocorrect={true}
											placeholder="domain with no protocol"
											value={this.props.domainsAddModal.domain}
											onChange={(input) => {
												this.props.setDomainsAddModal({ ...this.props.domainsAddModal, domain: input.detail.value });
											}}
										/>
										<Box variant="span">type: </Box>
										<Select
											filteringType="auto"
											placeholder="domain type"
											selectedOption={this.props.domainsAddModal.currentOption.type}
											onChange={(option) => {
												this.props.setDomainsAddModal({ ...this.props.domainsAddModal, currentOption: { ...this.props.domainsAddModal, type: option.detail.selectedOption } });
											}}
											options={this.props.config.domains.options.type}
										/>
									</>
								) : (
									<Box variant="div" textAlign="center">
										<Spinner size="large" />
									</Box>
								)}
							</SpaceBetween>
						</>
					}
					footer={
						<>
							<Box
								float="right"
								children={
									<>
										<Box
											variant="span"
											margin={{ right: "s" }}
											children={
												<Button
													variant="normal"
													onClick={() => {
														this.props.setDomainsAddModal({ ...this.props.domainsAddModal, show: false });
													}}
													children="Close"
												/>
											}
										/>
										<Box
											variant="span"
											children={
												<Button
													variant="primary"
													onClick={() => {
														this.addUser();
													}}
													children="Ok"
												/>
											}
										/>
									</>
								}
							/>
						</>
					}
				/>
			</>
		);
	}
}

function withRecoil(Component) {
	return function WrappedComponent(props) {
		const domains = useRecoilValue(domainsStore);
		const setDomains = useSetRecoilState(domainsStore);
		const domainsAddModal = useRecoilValue(domainsAddModalStore);
		const setDomainsAddModal = useSetRecoilState(domainsAddModalStore);
		const config = useRecoilValue(configStore);
		const user = useRecoilValue(userStore);

		return <Component {...props} domains={domains} setDomains={setDomains} user={user} domainsAddModal={domainsAddModal} setDomainsAddModal={setDomainsAddModal} config={config} />;
	};
}

export default withRecoil(DomainsAddDomainModal);
