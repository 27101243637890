import { atom } from "recoil";

export const settingsStore = atom({
	key: "settingsStore",
	default: {
		tab: "frontend",
		changes: false,
		loaded: false,
		error: {
			text: "",
			code: 0,
			timestamp: 0,
		},
	},
});

export const frontendStore = atom({
	key: "frontendStore",
	default: {
		data: {},
		initialData: {},
		loaded: false,
	},
});

export const languageStore = atom({
	key: "languageStore",
	default: {
		data: {},
		initialData: {},
		loaded: false,
	},
});

export const backendStore = atom({
	key: "backendStore",
	default: {
		data: {},
		initialData: {},
		loaded: false,
	},
});
