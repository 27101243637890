import React from "react";

import cloneDeep from "lodash/cloneDeep";

import Modal from "@cloudscape-design/components/modal";
import Table from "@cloudscape-design/components/table";
import Pagination from "@cloudscape-design/components/pagination";
import Preferences from "@cloudscape-design/components/collection-preferences";
import Box from "@cloudscape-design/components/box";
import Alert from "@cloudscape-design/components/alert";
import Header from "@cloudscape-design/components/header";
import Input from "@cloudscape-design/components/input";

/* Recoil */
import { useRecoilValue, useSetRecoilState } from "recoil";
import { configStore } from "../stores/config";
import { staffLogModalStore } from "../stores/staffLogModal";
/* ------- */

class StaffLogModal extends React.Component {
	filter() {
		let data = cloneDeep(this.props.staffLog.data);
		if (!this.props.staffLog.currentSearch.length) {
			data.forEach((item) => {
				item.filtered = true;
			});
			this.props.setStaffLog({ ...this.props.staffLog, data });

			setTimeout(() => {
				let total = Math.ceil(
					this.props.staffLog.data.filter((item) => {
						return item.filtered;
					}).length / this.props.config.players.payments.perPage,
				);
				this.props.setStaffLog({ ...this.props.staffLog, pagination: { current: 1, total } });
			}, 0);
		} else {
			data.forEach((item) => {
				if (item.request.toString().indexOf(this.props.staffLog.currentSearch) !== -1 || item.method.toUpperCase().indexOf(this.props.staffLog.currentSearch.toUpperCase()) !== -1 || item.ip.toUpperCase().indexOf(this.props.staffLog.currentSearch.toUpperCase()) !== -1) item.filtered = true;
				else item.filtered = false;
			});

			this.props.setStaffLog({ ...this.props.staffLog, data });

			setTimeout(() => {
				let total = Math.ceil(
					this.props.staffLog.data.filter((item) => {
						return item.filtered;
					}).length / this.props.config.players.payments.perPage,
				);
				this.props.setStaffLog({ ...this.props.staffLog, pagination: { current: 1, total } });
			}, 0);
		}
	}

	render() {
		return (
			<>
				<Modal
					size="max"
					visible={this.props.staffLog.show}
					onDismiss={() => {
						this.props.setStaffLog({ ...this.props.staffLog, show: false, loaded: false, data: [], pagination: { current: 1, total: 0 } });
					}}
					header={
						<Header
							children="Logs"
							counter={
								<>
									(
									{
										this.props.staffLog.data.filter((item) => {
											return item.filtered;
										}).length
									}
									)
								</>
							}
							description="Below listed selected session log"
						/>
					}
					children={
						<>
							{this.props.staffLog.loaded && this.props.staffLog.error.text.length ? (
								<Box
									margin={{ bottom: "m" }}
									children={
										<Alert
											type="error"
											header="Error loading data:"
											children={
												<>
													<Box variant="p" children={this.props.staffLog.error.text} />
													<hr />
													<Box
														variant="p"
														children={
															<>
																request error code: {this.props.staffLog.error.code} | request timestamp: {this.props.staffLog.error.timestamp}
															</>
														}
													/>
												</>
											}
										/>
									}
								/>
							) : (
								<></>
							)}
							<Table
								stickyHeader={true}
								wrapLines={true}
								loading={!this.props.staffLog.loaded}
								columnDefinitions={[
									{ id: "method", header: "Method", cell: (item) => item.method },
									{ id: "request", header: "Request", cell: (item) => item.request },
									{ id: "ip", header: "IP", cell: (item) => item.ip },
									{ id: "date", header: "Date", cell: (item) => new Date(item.date).toLocaleDateString() + " " + new Date(item.date).toLocaleTimeString() },
								]}
								items={this.props.staffLog.data
									.filter((item) => {
										return item.filtered;
									})
									.slice((this.props.staffLog.pagination.current - 1) * this.props.config.staff.log.perPage, this.props.staffLog.pagination.current * this.props.config.staff.log.perPage)}
								pagination={
									<Pagination
										disabled={!this.props.staffLog.loaded}
										currentPageIndex={this.props.staffLog.pagination.current}
										pagesCount={this.props.staffLog.pagination.total}
										onChange={(page) => {
											this.props.setStaffLog({ ...this.props.staffLog, pagination: { ...this.props.staffLog.pagination, current: page.detail.currentPageIndex } });
										}}
									/>
								}
								preferences={
									<Preferences
										cancelLabel="Cancel"
										confirmLabel="Confirm"
										pageSizePreference={{ title: "Logs per page", options: this.props.config.staff.log.preferences.perPage }}
										preferences={{ pageSize: this.props.config.staff.log.perPage }}
										title="Preferences"
										onConfirm={(preferences) => {
											localStorage.setItem("staff.log.perPage", preferences.detail.pageSize);
											this.props.setConfig({ ...this.props.config, staff: { ...this.props.config.staff, log: { ...this.props.config.staff.log, perPage: preferences.detail.pageSize } } });
											this.props.setStaffLog({
												...this.props.staffLog,
												pagination: {
													current: 1,
													total: Math.ceil(
														this.props.staffLog.data.filter((item) => {
															return item.filtered;
														}).length / preferences.detail.pageSize,
													),
												},
											});
										}}
									/>
								}
								filter={
									<Input
										disableBrowserAutocorrect={true}
										placeholder="Method, IP or request part"
										type="search"
										inputMode="search"
										value={this.props.staffLog.currentSearch}
										onChange={(input) => {
											this.props.setStaffLog({ ...this.props.staffLog, currentSearch: input.detail.value });
											setTimeout(() => {
												this.filter();
											}, 100);
										}}
									/>
								}
								empty="No logs found with requested filter"
							/>
						</>
					}
				/>
			</>
		);
	}
}

function withRecoil(Component) {
	return function WrappedComponent(props) {
		const config = useRecoilValue(configStore);
		const setConfig = useSetRecoilState(configStore);
		const staffLog = useRecoilValue(staffLogModalStore);
		const setStaffLog = useSetRecoilState(staffLogModalStore);

		return <Component {...props} config={config} setConfig={setConfig} staffLog={staffLog} setStaffLog={setStaffLog} />;
	};
}

export default withRecoil(StaffLogModal);
