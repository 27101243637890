import { atom } from "recoil";

export const gamesAddModalStore = atom({
	key: "gamesAddModalStore",
	default: {
		show: false,
		loaded: true,
		providerID: false,
		categoryID: [],
		new: true,
		top: false,
		newYear: false,
		rtpUp: "",
		select: false,
		name: "",
		poster: "",
		volatility: 65,
		rtp: 95,
		params: [],
		newPoster: false,
		newBigPoster: false,
	},
});
