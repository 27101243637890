import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { RecoilRoot } from "recoil";
import RecoilNexus from "recoil-nexus";

//import Snowfall from 'react-snowfall'

import Index from "./modules/Index";

ReactDOM.render(
	<RecoilRoot>
		<RecoilNexus />
		<Router>
			<Index />
		</Router>
	</RecoilRoot>,
	document.getElementById("app"),
);
