import React from "react";

import cloneDeep from "lodash/cloneDeep";

import Table from "@cloudscape-design/components/table";
import Pagination from "@cloudscape-design/components/pagination";
import Header from "@cloudscape-design/components/header";
import Input from "@cloudscape-design/components/input";
import Button from "@cloudscape-design/components/button";
import Box from "@cloudscape-design/components/box";
import Link from "@cloudscape-design/components/link";
import Badge from "@cloudscape-design/components/badge";
import Preferences from "@cloudscape-design/components/collection-preferences";
import Alert from "@cloudscape-design/components/alert";
import Spinner from "@cloudscape-design/components/spinner";
import Icon from "@cloudscape-design/components/icon";

/* Recoil */
import { useRecoilValue, useSetRecoilState } from "recoil";
import { configStore } from "../stores/config";
import { withdrawalStore } from "../stores/withdrawals";
import SpaceBetween from "@cloudscape-design/components/space-between";
import { playerModalStore } from "../stores/playerModal";
import { userStore } from "../stores/access";
/* ------- */

class Withdrawals extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.loadWithdrawals();
	}

	toggleValueModal(type, item) {
		switch (type) {
			case 4:
				this.props.setPlayerModal({ ...this.props.playerModal, show: 1, type, title: "Enter reason", description: "Reason", units: "Refer to tos", item: typeof item !== "undefined" ? item : false });
				break;
			case 5:
				this.props.setPlayerModal({ ...this.props.playerModal, show: 1, type, title: "Enter reason", description: "Reason", units: "Refer to tos", item: typeof item !== "undefined" ? item : false });
				break;
			case 8:
				this.props.setPlayerModal({ ...this.props.playerModal, show: 1, type, title: "Enter button text", description: "Text", units: "Reason" });
				break;
			case 9:
				this.props.setPlayerModal({ ...this.props.playerModal, show: 1, type, title: "Remove button", description: "Text", units: "Reason" });
				break;
			default:
				this.props.setPlayerModal({ ...this.props.playerModal, show: 0, type });
				break;
		}
	}

	loadWithdrawals() {
		this.props.setWithdrawals({ ...this.props.withdrawals, data: [], loaded: false });

		const params = {
			order: this.props.withdrawals.currentOrder,
			searchType: this.props.withdrawals.currentSearchType,
			direction: this.props.withdrawals.currentDirection,
		};

		this.props.Interface.request("get", { class: "data", module: "withdrawals", method: "list" })
			.then((list) => {
				list.forEach((item) => {
					item.filtered = true;
				});

				list.sort((a, b) => a.date - b.date);

				let uidWithdrawals = {},
					listFormatted = [];

				list.forEach((item) => {
					if (typeof uidWithdrawals["w" + item.uid] === "undefined") uidWithdrawals["w" + item.uid] = [];

					uidWithdrawals["w" + item.uid].push(item);
				});

				Object.keys(uidWithdrawals).forEach((key) => {
					uidWithdrawals[key].forEach((item) => {
						listFormatted.push(item);
					});
				});

				this.props.setWithdrawals({ ...this.props.withdrawals, loaded: true, currentSearch: this.currentSearch, data: listFormatted, pagination: { current: 1, total: Math.ceil(list.length / this.props.config.withdrawals.perPage) } });
			})
			.catch((e) => {
				this.props.setWithdrawals({ ...this.props.withdrawals, loaded: true, error: e });
			});
	}

	filter() {
		let data = cloneDeep(this.props.withdrawals.data);

		if (!this.props.withdrawals.currentSearch.length) {
			data.forEach((item) => {
				item.filtered = true;
			});
			this.props.setWithdrawals({ ...this.props.withdrawals, data });

			setTimeout(() => {
				let total = Math.ceil(
					this.props.withdrawals.data.filter((item) => {
						return item.filtered;
					}).length / this.props.config.withdrawals.perPage,
				);
				this.props.setWithdrawals({ ...this.props.withdrawals, pagination: { current: 1, total } });
			}, 0);
		} else {
			data.forEach((item) => {
				if (item.amount.toString().indexOf(this.props.withdrawals.currentSearch) !== -1 || item.type.toUpperCase().indexOf(this.props.withdrawals.currentSearch.toUpperCase()) !== -1 || item.id.toString().indexOf(this.props.withdrawals.currentSearch) !== -1 || item.uid.toString().indexOf(this.props.withdrawals.currentSearch) !== -1 || item.wallet.toString().indexOf(this.props.withdrawals.currentSearch) !== -1) item.filtered = true;
				else item.filtered = false;
			});

			this.props.setWithdrawals({ ...this.props.withdrawals, data });

			setTimeout(() => {
				let total = Math.ceil(
					this.props.withdrawals.data.filter((item) => {
						return item.filtered;
					}).length / this.props.config.withdrawals.perPage,
				);
				this.props.setWithdrawals({ ...this.props.withdrawals, pagination: { current: 1, total } });
			}, 0);
		}
	}

	compare(a, b) {
		if (a[this.props.withdrawals.sorting.sortingColumn.sortingField] < b[this.props.withdrawals.sorting.sortingColumn.sortingField]) {
			return this.props.withdrawals.sorting.isDescending ? 1 : -1;
		}

		if (a[this.props.withdrawals.sorting.sortingColumn.sortingField] > b[this.props.withdrawals.sorting.sortingColumn.sortingField]) {
			return this.props.withdrawals.sorting.isDescending ? -1 : 1;
		}

		return 0;
	}

	managePayment(action, id, uid, note, project) {
		let withdrawals = cloneDeep(this.props.withdrawals.data);
		let withdrawalsToProcess = typeof id === "undefined" ? cloneDeep(this.props.withdrawals.selectedItems) : [{ id, uid, project }];

		withdrawalsToProcess.forEach((withdrawal, i) => {
			withdrawals.forEach((item, j) => {
				if (item.id === withdrawal.id) item.process = true;
			});
		});

		this.props.setWithdrawals({ ...this.props.withdrawals, selectedItems: [], data: withdrawals });

		let wirhdrawalsStatuses = {},
			checker = setInterval(() => {
				let withdrawals = cloneDeep(this.props.withdrawals.data);

				Object.keys(wirhdrawalsStatuses).forEach((processedWithdrawal) => {
					withdrawals.forEach((item) => {
						if (item.id === parseInt(processedWithdrawal)) {
							item.status = wirhdrawalsStatuses[processedWithdrawal].status;

							if (typeof wirhdrawalsStatuses[processedWithdrawal].balance !== "undefined") item.balance = wirhdrawalsStatuses[processedWithdrawal].balance;

							item.process = false;
						}
					});
				});

				this.props.setWithdrawals({ ...this.props.withdrawals, data: withdrawals });

				if (Object.keys(wirhdrawalsStatuses).length >= withdrawalsToProcess.length) clearInterval(checker);
			}, 500);

		withdrawalsToProcess.forEach((withdrawal, i) => {
			setTimeout(
				() => {
					const params = {
						id: withdrawal.id,
						uid: withdrawal.uid,
						project: withdrawal.project,
						note: typeof note !== "undefined" ? note : "default",
					};

					this.props.Interface.request("set", { class: "data", module: "withdrawals", method: action }, { ...params })
						.then((data) => {
							wirhdrawalsStatuses[withdrawal.id] = { status: data.status };

							if (typeof data.balance !== "undefined") wirhdrawalsStatuses[withdrawal.id].balance = data.balance;
						})
						.catch((e) => {
							wirhdrawalsStatuses[withdrawal.id] = { status: e.text };
							// this.props.setWithdrawals({ ...this.props.withdrawals, loaded: true, error: e });
						});
				},
				(i + 1) * 1000,
			);
		});
	}

	handleKeyPress = (event) => {
		if (event.detail.key === "Enter")
			setTimeout(() => {
				this.loadWithdrawals();
			}, 0);
	};

	getStatusColor(status) {
		switch (status) {
			case "success":
				return "green";
			case "error":
				return "red";
			case "waiting":
				return "blue";
			case "process":
				return "yellow";
			default:
				return "black";
		}
	}

	handleUIDClick(event, uid, project) {
		if (!event.detail.shiftKey) this.props.Interface.loadInfo(uid, project);
		else {
			let hasProperty = false,
				selectedItemsCurrent = cloneDeep(this.props.withdrawals.selectedItems);

			selectedItemsCurrent.forEach((item) => {
				if (item.uid === uid && item.project === project) hasProperty = true;
			});

			let j = 0,
				withdrawals = cloneDeep(this.props.withdrawals.data).slice((this.props.withdrawals.pagination.current - 1) * this.props.config.withdrawals.perPage, this.props.withdrawals.pagination.current * this.props.config.withdrawals.perPage),
				selectedItems = cloneDeep(this.props.withdrawals.selectedItems);

			withdrawals.forEach((item) => {
				if (item.uid === uid && item.project === project) {
					if (hasProperty)
						selectedItems.forEach((itemSelected, i) => {
							if (itemSelected.id === item.id) {
								selectedItems.splice(i, 1);
								j++;
							}
						});
					else {
						selectedItems.push(item);
						j++;
					}
				}
			});

			/* if (hasProperty)
        this.props.Interface.addNoty("Info", "Deselected " + j + " withdrawals of User with UID #" + uid);
      else
        this.props.Interface.addNoty("Info", "Selected " + j + " withdrawals of User with UID #" + uid);
      */

			this.props.setWithdrawals({ ...this.props.withdrawals, selectedItems });
		}
	}

	makeAction(action) {
		switch (action) {
			case 1:
				let list = cloneDeep(this.props.withdrawals.data);

				list.sort((a, b) => a.date - b.date);

				let uidWithdrawals = {},
					listFormatted = [];

				list.forEach((item) => {
					if (typeof uidWithdrawals["w" + item.uid] === "undefined") uidWithdrawals["w" + item.uid] = [];

					uidWithdrawals["w" + item.uid].push(item);
				});

				Object.keys(uidWithdrawals).forEach((key) => {
					uidWithdrawals[key].forEach((item) => {
						listFormatted.push(item);
					});
				});

				this.props.Interface.addNoty("Info", "Sort type was set to basic");
				this.props.setWithdrawals({ ...this.props.withdrawals, data: listFormatted });
				break;
			case 2:
				let i = 0,
					withdrawals = cloneDeep(this.props.withdrawals.data).slice((this.props.withdrawals.pagination.current - 1) * this.props.config.withdrawals.perPage, this.props.withdrawals.pagination.current * this.props.config.withdrawals.perPage),
					selectedItems = cloneDeep(this.props.withdrawals.selectedItems);

				withdrawals.forEach((item) => {
					if (item.amount > item.balance / 100) {
						selectedItems.push(item);
						i++;
					}
				});

				if (!i) this.props.Interface.addNoty("Info", "No withdrawals below balance was found");
				else {
					this.props.Interface.addNoty("Success", "Selected " + i + " withdrawals below balance on this page");
					this.props.setWithdrawals({ ...this.props.withdrawals, selectedItems });
				}
				break;
		}
	}

	checkItem(id) {
		this.props.setWithdrawals({ ...this.props.withdrawals, lastChecked: id });
	}

	render() {
		return (
			<>
				{this.props.withdrawals.loaded && this.props.withdrawals.error.text.length ? (
					<Box
						margin={{ bottom: "m" }}
						children={
							<Alert
								type="error"
								header="Error loading data:"
								children={
									<>
										<Box variant="p" children={this.props.withdrawals.error.text} />
										<hr />
										<Box
											variant="p"
											children={
												<>
													request error code: {this.props.withdrawals.error.code} | request timestamp: {this.props.withdrawals.error.timestamp}
												</>
											}
										/>
									</>
								}
							/>
						}
					/>
				) : (
					<></>
				)}

				<Table
					stickyHeader="true"
					trackBy="id"
					isItemDisabled={(item) => {
						return item.status !== "waiting";
					}}
					selectionType="multi"
					onSelectionChange={(selection) => {
						this.props.setWithdrawals({ ...this.props.withdrawals, selectedItems: selection.detail.selectedItems });
					}}
					selectedItems={this.props.withdrawals.selectedItems}
					loading={!this.props.withdrawals.loaded}
					sortingColumn={{ sortingField: this.props.withdrawals.sorting.sortingColumn.sortingField }}
					sortingDescending={this.props.withdrawals.sorting.isDescending}
					onSortingChange={(sorting) => {
						this.props.setWithdrawals({ ...this.props.withdrawals, sorting: sorting.detail });
						setTimeout(() => {
							this.props.setWithdrawals({
								...this.props.withdrawals,
								data: cloneDeep(this.props.withdrawals.data).sort((a, b) => {
									return this.compare(a, b);
								}),
							});
						}, 0);
					}}
					columnDefinitions={[
						{
							id: "id",
							header: "ID",
							sortingField: "id",
							cell: (item) => (
								<Button
									iconName={"status-info"}
									className={[this.props.styles["btnUID"], this.props.withdrawals.lastChecked === item.id ? this.props.styles["lastCheckedItem"] : "", document.body.classList[0] === "awsui-dark-mode" ? this.props.styles["dark"] : ""].join(" ")}
									variant="link"
									onClick={() => {
										this.checkItem(item.id);
										this.props.Interface.loadPayments(item.uid, 1, item.project);
									}}
									children={item.id}
								/>
							),
						},
						{ id: "project", header: "Project", sortingField: "project", cell: (item) => <img className={this.props.styles["withdrawalsBrand"]} src={document.body.classList[0] === "awsui-dark-mode" ?   this.props.user.brandsList[process.env.REACT_APP_IS_PRODUCTION === "false" ? 0 : item.project - 1].logoDark : this.props.user.brandsList[process.env.REACT_APP_IS_PRODUCTION === "false" ? 0 : item.project - 1].logo}></img> },
						{
							id: "uid",
							header: "UID",
							sortingField: "uid",
							cell: (item) => (
								<Button
									iconName={typeof item.verify !== "undefined" && item.verify.status === 3 ? "status-stopped" : typeof item.verify !== "undefined" && item.verify.verified ? "status-positive" : "status-in-progress"}
									className={[this.props.styles["btnUID"], typeof item.verify !== "undefined" && item.verify.verified ? this.props.styles["green"] : typeof item.verify !== "undefined" && item.verify.need ? this.props.styles["red"] : this.props.styles["blue"]].join(" ")}
									variant="normal"
									onClick={(ev) => {
										this.checkItem(item.id);
										this.handleUIDClick(ev, item.uid, item.project);
									}}
									children={item.uid}
								/>
							),
						},
						{ id: "payway", header: "Payway", sortingField: "type", cell: (item) => <Box fontWeight="bold">{item.type}</Box> },
						{ id: "amount", header: "Amount", sortingField: "amount", cell: (item) => <>{new Intl.NumberFormat("ru-RU").format(item.amount)} RUB.</> },
						{
							id: "balance",
							header: "Balance",
							sortingField: "balance",
							cell: (item) => (
								<Box variant="span" color={item.balance / 100 >= item.amount ? "text-status-success" : "text-status-error"}>
									{new Intl.NumberFormat("ru-RU").format(item.balance / 100)} RUB.
								</Box>
							),
						},
						{ id: "wallet", header: "Wallet", sortingField: "wallet", cell: (item) => item.wallet },
						{ id: "status", header: "Status", sortingField: "status", cell: (item) => <Badge className={[this.props.styles["badge"], this.props.styles[this.getStatusColor(item.status)]].join(" ")}>{item.status}</Badge> },
						{ id: "date", header: "Date", sortingField: "date", cell: (item) => <>{new Date(item.date).toLocaleDateString() + " " + new Date(item.date).toLocaleTimeString()}</> },
						{
							id: "actions",
							header: "Actions",
							width: 355,
							minWidth: 355,
							cell: (item) => (
								<>
									{item.process ? (
										<Spinner className={this.props.styles["withdrawalSpinner"]} variant="normal" size="big" />
									) : (
										<SpaceBetween direction="horizontal" size="xs">
											<Button
												disabled={!this.props.withdrawals.loaded || item.status !== "waiting"}
												className={[this.props.styles["players-search-button"], this.props.styles["btn"], item.status === "waiting" ? this.props.styles["red"] : ""].join(" ")}
												onClick={() => {
													this.checkItem(item.id);
													setTimeout(() => {
														this.toggleValueModal(5, item);
													}, 0);
												}}
												variant="primary"
												children="Reject"
											/>
											<Button
												disabled={!this.props.withdrawals.loaded || item.status !== "waiting"}
												className={[this.props.styles["players-search-button"], this.props.styles["btn"], item.status === "waiting" ? this.props.styles["gray"] : ""].join(" ")}
												onClick={() => {
													this.checkItem(item.id);
													setTimeout(() => {
														this.toggleValueModal(4, item);
													}, 0);
												}}
												variant="primary"
												children="Cancel"
											/>
											<Button
												disabled={!this.props.withdrawals.loaded || item.status !== "waiting"}
												className={[this.props.styles["players-search-button"], this.props.styles["btn"], item.status === "waiting" ? this.props.styles["yellow"] : ""].join(" ")}
												onClick={() => {
													this.checkItem(item.id);
													setTimeout(() => {
														this.managePayment("approve", item.id, item.uid, "", item.project);
													}, 0);
												}}
												variant="primary"
												children="Approve"
											/>
										</SpaceBetween>
									)}
								</>
							),
						},
					]}
					items={this.props.withdrawals.data
						.filter((item) => {
							return item.filtered;
						})
						.slice((this.props.withdrawals.pagination.current - 1) * this.props.config.withdrawals.perPage, this.props.withdrawals.pagination.current * this.props.config.withdrawals.perPage)}
					pagination={
						<Pagination
							disabled={!this.props.withdrawals.loaded}
							currentPageIndex={this.props.withdrawals.pagination.current}
							pagesCount={this.props.withdrawals.pagination.total}
							onChange={(page) => {
								this.props.setWithdrawals({ ...this.props.withdrawals, pagination: { ...this.props.withdrawals.pagination, current: page.detail.currentPageIndex } });
							}}
						/>
					}
					preferences={
						<Preferences
							cancelLabel="Cancel"
							confirmLabel="Confirm"
							pageSizePreference={{ title: "Logs per page", options: this.props.config.withdrawals.preferences.perPage }}
							preferences={{ pageSize: this.props.config.withdrawals.perPage }}
							title="Preferences"
							onConfirm={(preferences) => {
								localStorage.setItem("withdrawals.perPage", preferences.detail.pageSize);
								this.props.setConfig({ ...this.props.config, withdrawals: { ...this.props.config.withdrawals, perPage: preferences.detail.pageSize } });
								this.props.setWithdrawals({
									...this.props.withdrawals,
									pagination: {
										current: 1,
										total: Math.ceil(
											this.props.withdrawals.data.filter((item) => {
												return item.filtered;
											}).length / preferences.detail.pageSize,
										),
									},
								});
							}}
						/>
					}
					filter={
						<Input
							disabled={!this.props.withdrawals.loaded}
							disableBrowserAutocorrect={true}
							autocomplete={false}
							placeholder="Wallet, payway, uid or something"
							type="search"
							inputMode="search"
							value={this.props.withdrawals.currentSearch}
							onChange={(input) => {
								this.props.setWithdrawals({ ...this.props.withdrawals, currentSearch: input.detail.value });
								setTimeout(() => {
									this.filter();
								}, 100);
							}}
						/>
					}
					header={
						<Box
							margin={{ bottom: "m" }}
							children={
								<Header
									children="Withdrawals"
									counter={
										<>
											(
											{
												this.props.withdrawals.data.filter((item) => {
													return item.filtered;
												}).length
											}
											)
										</>
									}
									actions={
										<SpaceBetween direction="horizontal" size="l">
											<Button
												iconName="status-negative"
												disabled={!this.props.withdrawals.loaded || !this.props.config.withdrawals.buttons.length}
												className={[this.props.styles["players-search-button"], this.props.styles["btn"]].join(" ")}
												onClick={() => {
													setTimeout(() => {
														this.toggleValueModal(9);
													}, 0);
												}}
												variant="primary"
												children="Remove button"
											/>
											<Button
												iconName="add-plus"
												disabled={!this.props.withdrawals.loaded}
												className={[this.props.styles["players-search-button"], this.props.styles["btn"]].join(" ")}
												onClick={() => {
													setTimeout(() => {
														this.toggleValueModal(8);
													}, 0);
												}}
												variant="primary"
												children="Add button"
											/>
											<Button
												iconName="caret-down"
												disabled={!this.props.withdrawals.loaded}
												className={[this.props.styles["players-search-button"], this.props.styles["btn"]].join(" ")}
												onClick={() => {
													setTimeout(() => {
														this.makeAction(2);
													}, 0);
												}}
												variant="primary"
												children="Select low balance"
											/>
											<Button
												iconName="status-stopped"
												disabled={!this.props.withdrawals.loaded || !this.props.withdrawals.selectedItems.length}
												className={[this.props.styles["players-search-button"], this.props.styles["btn"], !this.props.withdrawals.loaded || !this.props.withdrawals.selectedItems.length ? "" : this.props.styles["red"]].join(" ")}
												onClick={() => {
													setTimeout(() => {
														this.toggleValueModal(5);
													}, 0);
												}}
												variant="primary"
												children="Reject"
											/>
											<Button
												iconName="status-negative"
												disabled={!this.props.withdrawals.loaded || !this.props.withdrawals.selectedItems.length}
												className={[this.props.styles["players-search-button"], this.props.styles["btn"], !this.props.withdrawals.loaded || !this.props.withdrawals.selectedItems.length ? "" : this.props.styles["gray"]].join(" ")}
												onClick={() => {
													setTimeout(() => {
														this.toggleValueModal(4);
													}, 0);
												}}
												variant="primary"
												children="Cancel"
											/>
											<Button
												iconName="status-positive"
												disabled={!this.props.withdrawals.loaded || !this.props.withdrawals.selectedItems.length}
												className={[this.props.styles["players-search-button"], this.props.styles["btn"], !this.props.withdrawals.loaded || !this.props.withdrawals.selectedItems.length ? "" : this.props.styles["yellow"]].join(" ")}
												onClick={() => {
													setTimeout(() => {
														this.managePayment("approve");
													}, 0);
												}}
												variant="primary"
												children="Approve"
											/>
											<Button
												iconName="refresh"
												disabled={!this.props.withdrawals.loaded}
												className={[this.props.styles["players-search-button"], this.props.styles["btn"]].join(" ")}
												onClick={() => {
													setTimeout(() => {
														this.loadWithdrawals();
													}, 0);
												}}
												variant="normal"
											/>
										</SpaceBetween>
									}
									description={
										<>
											<Box variant="span" fontWeight="normal">
												Selected withdrawals:
											</Box>{" "}
											<Box variant="span" fontWeight="bold">
												{this.props.withdrawals.selectedItems.length}
											</Box>
										</>
									}
								/>
							}
						/>
					}
					empty="No withdrawals found with requested filter"
				/>
			</>
		);
	}
}

function withRecoil(Component) {
	return function WrappedComponent(props) {
		const withdrawals = useRecoilValue(withdrawalStore);
		const setWithdrawals = useSetRecoilState(withdrawalStore);
		const config = useRecoilValue(configStore);
		const setConfig = useSetRecoilState(configStore);
		const playerModal = useRecoilValue(playerModalStore);
		const setPlayerModal = useSetRecoilState(playerModalStore);
		const user = useRecoilValue(userStore);

		return <Component {...props} withdrawals={withdrawals} setWithdrawals={setWithdrawals} config={config} setConfig={setConfig} playerModal={playerModal} setPlayerModal={setPlayerModal} user={user} />;
	};
}

export default withRecoil(Withdrawals);
