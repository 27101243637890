import React from "react";

import cloneDeep from "lodash/cloneDeep";

import Table from "@cloudscape-design/components/table";
import Pagination from "@cloudscape-design/components/pagination";
import Header from "@cloudscape-design/components/header";
import Input from "@cloudscape-design/components/input";
import Select from "@cloudscape-design/components/select";
import Button from "@cloudscape-design/components/button";
import Grid from "@cloudscape-design/components/grid";
import Box from "@cloudscape-design/components/box";
import Link from "@cloudscape-design/components/link";
import Badge from "@cloudscape-design/components/badge";
import Preferences from "@cloudscape-design/components/collection-preferences";
import Alert from "@cloudscape-design/components/alert";

import Avatar from "react-avatar";

/* Recoil */
import { useRecoilValue, useSetRecoilState } from "recoil";
import { configStore } from "../stores/config";
import { playerStore } from "../stores/players";
import { userStore } from "../stores/access";
import { SpaceBetween } from "@cloudscape-design/components";
/* ------- */

let isLoading = false;

class Players extends React.PureComponent {
	constructor(props) {
		super(props);
		this.currentSearch = "";
		this.query = {};
		window.location.search
			.substr(1)
			.split("&")
			.forEach((item) => {
				this.query[item.split("=")[0]] = item.split("=")[1];
			});
	}

	componentDidMount() {
		if (typeof this.query.ip !== "undefined") {
			this.currentSearch = this.query.ip;
			this.props.setPlayers({ ...this.props.players, data: [], loaded: false, currentSearch: this.query.ip, currentSearchType: this.props.config.players.options.type[2].type, currentOption: { ...this.props.players.currentOption, order: this.props.config.players.options.order[0], direction: this.props.config.players.options.direction[0], type: this.props.config.players.options.type[2] } });
			setTimeout(() => {
				if (this.props.players.currentSearch === this.query.ip) this.loadPlayers(true);
			}, 0);
		} else if (typeof this.query.refer !== "undefined") {
			this.currentSearch = this.query.refer;
			this.props.setPlayers({ ...this.props.players, currentSearch: this.query.refer, currentSearchType: this.props.config.players.options.type[3].type, currentOption: { ...this.props.players.currentOption, order: this.props.config.players.options.order[0], direction: this.props.config.players.options.direction[0], type: this.props.config.players.options.type[3] } });
			setTimeout(() => {
				if (this.props.players.currentSearch === this.query.refer) this.loadPlayers(true);
			}, 0);
		} else {
			this.props.setPlayers({ ...this.props.players, data: [], loaded: false, currentOption: { ...this.props.players.currentOption, order: this.props.config.players.options.order[0], direction: this.props.config.players.options.direction[0], type: this.props.config.players.options.type[0] } });
			setTimeout(() => {
				this.loadPlayers(true);
			}, 0);
		}
	}

	componentWillUnmount() {
		this.props.setPlayers({ ...this.props.players, currentSearch: "", currentSearchType: "uid", data: [], loaded: false });
	}

	loadPlayers(preLoad) {
		if (!isLoading) {
			isLoading = true;

			if (typeof preLoad === "undefined" || !preLoad) this.props.setPlayers({ ...this.props.players, data: [], loaded: false });

			const params = {
				order: this.props.players.currentOrder,
				searchType: this.props.players.currentSearchType,
				direction: this.props.players.currentDirection,
			};

			if (this.props.players.currentSearch.length) params.search = this.props.players.currentSearch;

			this.props.Interface.request("get", { class: "users", module: "data", method: "list" }, { ...params })
				.then((list) => {
					if (this.props.user.level >= 6)
						list.forEach((player) => {
							let score = 0;

							if (typeof player.withdrawals !== "undefined") {
								player.withdrawalsTotal = player.withdrawals.total;
								player.withdrawalsCount = player.withdrawals.count;
							} else {
								player.withdrawalsTotal = 0;
								player.withdrawalsCount = 0;
							}
							if (typeof player.withdrawals !== "undefined") {
								player.depositsTotal = player.deposits.total;
								player.depositsCount = player.deposits.count;
							} else {
								player.depositsTotal = 0;
								player.depositsCount = 0;
							}

							player.profit = player.depositsTotal - player.withdrawalsTotal;

							score = player.depositsTotal - player.withdrawalsTotal;

							if (player.depositsCount) {
								score *= player.depositsCount;
								score *= player.depositsTotal / player.depositsCount / 100;
							}

							if (player.withdrawalsCount) {
								score /= player.withdrawalsCount;
								score /= player.withdrawalsTotal / player.withdrawalsCount / 100;
							}

							score = score / 100;

							player.score = parseFloat(score.toFixed(4));

							delete player.deposits;
							delete player.withdrawals;
						});

					this.props.setPlayers({
						...this.props.players,
						loaded: true,
						error: {
							text: "",
							code: 0,
							timestamp: 0,
						},
						currentSearch: this.currentSearch,
						data: list,
						pagination: { current: 1, total: Math.ceil(list.length / this.props.config.players.perPage) },
					});
					isLoading = false;
				})
				.catch((e) => {
					if (typeof e.text === "undefined") e = { text: e.toString() };
					this.props.setPlayers({ ...this.props.players, loaded: true, error: e });
					isLoading = false;
				});
		}
	}

	compare(a, b) {
		if (a[this.props.players.sorting.sortingColumn.sortingField] < b[this.props.players.sorting.sortingColumn.sortingField]) {
			return this.props.players.sorting.isDescending ? 1 : -1;
		}

		if (a[this.props.players.sorting.sortingColumn.sortingField] > b[this.props.players.sorting.sortingColumn.sortingField]) {
			return this.props.players.sorting.isDescending ? -1 : 1;
		}

		return 0;
	}

	handleKeyPress = (event) => {
		if (event.detail.key === "Enter")
			setTimeout(() => {
				this.loadPlayers();
			}, 0);
	};

	getEconomicScoreColor(score) {
		return score < 0 ? "red" : score < 5 ? "orange" : score < 50 ? "purple" : "green";
	}

	getVisibleColumns() {
		let columns = [];
		columns.push("uid");
		columns.push("balance");
		columns.push("balance_c");
		columns.push("balance_r");
		columns.push("partner");
		columns.push("date");
		columns.push("casinoID");

		if (this.props.user.level >= 6 && this.props.players.currentOption.order.type !== "verify") {
			columns.push("deposits");
			columns.push("withdrawals");
			columns.push("profit");
			columns.push("escore");
		}

		if (this.props.players.currentOption.order.type === "verify") {
			columns.push("verifyPendingFrom");
		}

		return columns;
	}

	saveData(data) {
		const element = document.createElement("a");
		const file = new Blob([JSON.stringify(data)], {
			type: "text/plain",
		});
		element.href = URL.createObjectURL(file);
		element.download = this.props.config.app.name.toLowerCase() + "_players_" + +new Date() + ".json";
		document.body.appendChild(element);
		element.click();
	}

	checkItem(id) {
		this.props.setPlayers({ ...this.props.players, lastChecked: id });
	}

	render() {
		return (
			<>
				{this.props.players.loaded && this.props.players.error.text.length ? (
					<Box
						margin={{ bottom: "m" }}
						children={
							<Alert
								type="error"
								header="Error loading data:"
								children={
									<>
										<Box variant="p" children={this.props.players.error.text} />
										<hr />
										<Box
											variant="p"
											children={
												<>
													request error code: {this.props.players.error.code} | request timestamp: {this.props.players.error.timestamp}
												</>
											}
										/>
									</>
								}
							/>
						}
					/>
				) : (
					<></>
				)}

				<Table
					visibleColumns={this.getVisibleColumns()}
					loading={!this.props.players.loaded}
					sortingColumn={{ sortingField: this.props.players.sorting.sortingColumn.sortingField }}
					sortingDescending={this.props.players.sorting.isDescending}
					onSortingChange={(sorting) => {
						this.props.setPlayers({ ...this.props.players, sorting: sorting.detail });
						setTimeout(() => {
							this.props.setPlayers({
								...this.props.players,
								data: cloneDeep(this.props.players.data).sort((a, b) => {
									return this.compare(a, b);
								}),
							});
						}, 0);
					}}
					columnDefinitions={[
						{
							id: "uid",
							header: "UID",
							sortingField: "id",
							cell: (item) => (
								<Button
									iconName={typeof item.verify !== "undefined" && item.verify.status === 3 ? "status-stopped" : typeof item.verify !== "undefined" && item.verify.verified ? "status-positive" : "status-in-progress"}
									className={[this.props.styles["btnUID"], this.props.players.lastChecked === item.id ? this.props.styles["lastCheckedItem"] : "", typeof document.body.classList[0] !== "undefined" ? this.props.styles["dark"] : "", typeof item.verify !== "undefined" && item.verify.verified ? this.props.styles["green"] : typeof item.verify !== "undefined" && item.verify.need ? this.props.styles["red"] : this.props.styles["blue"]].join(" ")}
									variant="normal"
									onClick={() => {
										this.checkItem(item.id);
										this.props.Interface.loadInfo(item.id);
									}}
									children={item.id}
								/>
							),
						},
						{ id: "casinoID", header: "CasinoID", cell: (item) => item.casinoID || "none" },
						{
							id: "deposits",
							header: "Deposits",
							sortingField: "depositsTotal",
							cell: (item) => (
								<Link
									variant="normal"
									onFollow={() => {
										this.checkItem(item.id);
										this.props.Interface.loadPayments(item.id, 1);
									}}
									children={
										<>
											{new Intl.NumberFormat("ru-RU").format(item.depositsTotal)} RUB. ({item.depositsCount})
										</>
									}
								/>
							),
						},
						{
							id: "withdrawals",
							header: "Withdrawals",
							sortingField: "withdrawalsTotal",
							cell: (item) => (
								<Link
									variant="normal"
									onFollow={() => {
										this.checkItem(item.id);
										this.props.Interface.loadPayments(item.id, 2);
									}}
									children={
										<>
											{new Intl.NumberFormat("ru-RU").format(item.withdrawalsTotal)} RUB. ({item.withdrawalsCount})
										</>
									}
								/>
							),
						},
						{ id: "profit", header: "Profit", sortingField: "profit", cell: (item) => <>{new Intl.NumberFormat("ru-RU").format(item.profit)} RUB.</> },
						{
							id: "escore",
							header: "EScore",
							sortingField: "score",
							cell: (item) => <Badge className={[this.props.styles["badge"], this.props.styles[this.getEconomicScoreColor(item.score)]].join(" ")} children={item.score} />,
						},
						{
							id: "player",
							header: "Player",
							sortingField: "settings.name",
							cell: (item) => (
								<>
									{" "}
									<Avatar vkontakteId="1" size="150" />
									{item.settings.name}
								</>
							),
						},
						{ id: "balance", header: "Balance", sortingField: "balance", cell: (item) => <>{new Intl.NumberFormat("ru-RU").format(item.balance)} RUB.</> },
						{ id: "balance_c", header: "Loyalty balance", sortingField: "balance_c", cell: (item) => <>{new Intl.NumberFormat("ru-RU").format(item.balance_c)} RUB.</> },
						{ id: "balance_r", header: "Partner balance", sortingField: "balance_r", cell: (item) => <>{new Intl.NumberFormat("ru-RU").format(item.balance_r)} RUB.</> },
						{
							id: "partner",
							header: "Partner",
							cell: (item) =>
								item.referer ? (
									<Link
										variant="normal"
										onFollow={() => {
											this.checkItem(item.id);
											this.props.Interface.loadInfo(item.referer);
										}}
										children={item.referer}
									/>
								) : (
									"none"
								),
						},
						{ id: "verifyPendingFrom", header: "Pending from", sortingField: "verifyPendingFrom", cell: (item) => (typeof item.verifyPendingFrom !== "undefined" ? new Date(item.verifyPendingFrom).toLocaleDateString() : <></>) },
						{ id: "date", header: "Registration", sortingField: "date", cell: (item) => new Date(item.date).toLocaleDateString() },
					]}
					items={this.props.players.data.slice((this.props.players.pagination.current - 1) * this.props.config.players.perPage, this.props.players.pagination.current * this.props.config.players.perPage)}
					pagination={
						<Pagination
							disabled={!this.props.players.loaded}
							currentPageIndex={this.props.players.pagination.current}
							pagesCount={this.props.players.pagination.total}
							onChange={(page) => {
								this.props.setPlayers({ ...this.props.players, pagination: { ...this.props.players.pagination, current: page.detail.currentPageIndex } });
							}}
						/>
					}
					preferences={
						<Preferences
							cancelLabel="Cancel"
							confirmLabel="Confirm"
							pageSizePreference={{ title: "Logs per page", options: this.props.config.players.preferences.perPage }}
							preferences={{ pageSize: this.props.config.players.perPage }}
							title="Preferences"
							onConfirm={(preferences) => {
								localStorage.setItem("players.perPage", preferences.detail.pageSize);
								this.props.setConfig({ ...this.props.config, players: { ...this.props.config.players, perPage: preferences.detail.pageSize } });
								this.props.setPlayers({ ...this.props.players, pagination: { current: 1, total: Math.ceil(this.props.players.data.length / preferences.detail.pageSize) } });
							}}
						/>
					}
					filter={
						<Box
							margin={{ bottom: "m" }}
							children={
								<Grid
									gridDefinition={[{ colspan: 3 }, { colspan: 3 }, { colspan: 3 }, { colspan: 3 }]}
									children={
										<>
											<Select
												filteringType="auto"
												disabled={!this.props.players.loaded || this.props.players.currentOption.order.type === "deposits" || this.props.players.currentOption.order.type === "withdrawals" ? true : false}
												placeholder="Direction"
												selectedOption={this.props.players.currentOption.direction}
												onChange={(option) => {
													option.detail.selectedOption.type !== this.props.players.currentDirection && this.props.setPlayers({ ...this.props.players, currentDirection: option.detail.selectedOption.type, currentOption: { ...this.props.players.currentOption, direction: option.detail.selectedOption } });
												}}
												options={this.props.config.players.options.direction}
											/>
											<Select
												filteringType="auto"
												disabled={!this.props.players.loaded}
												placeholder="Order"
												selectedOption={this.props.players.currentOption.order}
												onChange={(option) => {
													option.detail.selectedOption.order !== this.props.players.currentOrder && this.props.setPlayers({ ...this.props.players, currentOrder: option.detail.selectedOption.type, currentOption: { ...this.props.players.currentOption, order: option.detail.selectedOption } });
													option.detail.selectedOption.order !== this.props.players.currentOrder &&
														setTimeout(() => {
															this.loadPlayers();
														}, 0);
												}}
												options={this.props.config.players.options.order}
											/>
											<Select
												filteringType="auto"
												disabled={!this.props.players.loaded}
												placeholder="Type"
												selectedOption={this.props.players.currentOption.type}
												onChange={(option) => {
													option.detail.selectedOption.type !== this.props.players.currentSearchType && this.props.setPlayers({ ...this.props.players, currentSearchType: option.detail.selectedOption.type, currentOption: { ...this.props.players.currentOption, type: option.detail.selectedOption } });
												}}
												options={this.props.config.players.options.type}
											/>
											<Input
												disabled={!this.props.players.loaded}
												onKeyDown={this.handleKeyPress}
												disableBrowserAutocorrect={true}
												placeholder="UID, name or something"
												inputMode="search"
												type="search"
												value={this.props.players.currentSearch}
												onChange={(input) => {
													this.props.setPlayers({ ...this.props.players, currentSearch: input.detail.value });
													this.currentSearch = input.detail.value;
												}}
											/>
										</>
									}
								/>
							}
						/>
					}
					header={
						<Box
							margin={{ bottom: "m" }}
							children={
								<Header
									children="Players"
									counter={<>({this.props.players.data.length})</>}
									actions={
										<SpaceBetween size="l" direction="horizontal">
											<Button
												disabled={!this.props.players.loaded || !this.props.players.data.length}
												className={this.props.styles["players-search-button"]}
												onClick={() => {
													this.saveData(this.props.players.data);
												}}
												variant="primary"
												children="Export"
											/>
											<Button
												disabled={!this.props.players.loaded}
												className={this.props.styles["players-search-button"]}
												onClick={() => {
													setTimeout(() => {
														this.loadPlayers();
													}, 0);
												}}
												variant="primary"
												children="Find"
											/>
										</SpaceBetween>
									}
									description="Below listed up to 500 matched players"
								/>
							}
						/>
					}
					empty="No players found with requested filter"
				/>
			</>
		);
	}
}

function withRecoil(Component) {
	return function WrappedComponent(props) {
		const players = useRecoilValue(playerStore);
		const setPlayers = useSetRecoilState(playerStore);
		const config = useRecoilValue(configStore);
		const setConfig = useSetRecoilState(configStore);
		const user = useRecoilValue(userStore);

		return <Component {...props} players={players} setPlayers={setPlayers} config={config} setConfig={setConfig} user={user} />;
	};
}

export default withRecoil(Players);
