import { atom } from "recoil";

export const documentStore = atom({
	key: "documentStore",
	default: {
		show: false,
		source: false,
		name: false,
		uid: 0,
	},
});
