import React from "react";

import cloneDeep from "lodash/cloneDeep";

import Table from "@cloudscape-design/components/table";
import Box from "@cloudscape-design/components/box";
import Header from "@cloudscape-design/components/header";
import Link from "@cloudscape-design/components/link";
import Alert from "@cloudscape-design/components/alert";
import Button from "@cloudscape-design/components/button";
import Pagination from "@cloudscape-design/components/pagination";
import StatusIndicator from "@cloudscape-design/components/status-indicator";
import Preferences from "@cloudscape-design/components/collection-preferences";
import Input from "@cloudscape-design/components/input";
import Badge from "@cloudscape-design/components/badge";

/* Recoil */
import { useRecoilValue, useSetRecoilState } from "recoil";
import { domainsStore } from "../stores/domains";
import { configStore } from "../stores/config";
import { SpaceBetween } from "@cloudscape-design/components";
import { userStore } from "../stores/access";
import { domainsAddModalStore } from "../stores/domainsAddModal";
import { domainsEditModalStore } from "../stores/domainsEditModal";
/* ------- */

class Domains extends React.Component {
	constructor(props) {
		super(props);
		this.loadDomains();
	}

	loadDomains() {
		this.props.setDomains({ ...this.props.domains, loaded: false });

		this.props.Interface.request("get", { class: "data", module: "info", method: "domains" })
			.then((data) => {
				data.forEach((item) => {
					item.filtered = true;
				});

				this.props.setDomains({ ...this.props.domains, data, loaded: true, pagination: { current: 1, total: Math.ceil(data.length / this.props.config.domains.perPage) } });
			})
			.catch((e) => {
				this.props.setDomains({ ...this.props.domains, loaded: true, error: e });
			});
	}

	compare(a, b) {
		if (a[this.props.domains.sorting.sortingColumn.sortingField] < b[this.props.domains.sorting.sortingColumn.sortingField]) {
			return this.props.domains.sorting.isDescending ? 1 : -1;
		}

		if (a[this.props.domains.sorting.sortingColumn.sortingField] > b[this.props.domains.sorting.sortingColumn.sortingField]) {
			return this.props.domains.sorting.isDescending ? -1 : 1;
		}

		return 0;
	}

	filter() {
		let data = cloneDeep(this.props.domains.data);

		if (!this.props.domains.currentSearch.length) {
			data.forEach((item) => {
				item.filtered = true;
			});
			this.props.setDomains({ ...this.props.domains, data });

			setTimeout(() => {
				let total = Math.ceil(
					this.props.domains.data.filter((item) => {
						return item.filtered;
					}).length / this.props.config.domains.perPage,
				);
				this.props.setDomains({ ...this.props.domains, pagination: { current: 1, total } });
			}, 0);
		} else {
			data.forEach((item) => {
				if (item.status.toString().indexOf(this.props.domains.currentSearch) !== -1 || item.domain.toUpperCase().indexOf(this.props.domains.currentSearch.toUpperCase()) !== -1) item.filtered = true;
				else item.filtered = false;
			});

			this.props.setDomains({ ...this.props.domains, data });

			setTimeout(() => {
				let total = Math.ceil(
					this.props.domains.data.filter((item) => {
						return item.filtered;
					}).length / this.props.config.domains.perPage,
				);
				this.props.setDomains({ ...this.props.domains, pagination: { current: 1, total } });
			}, 0);
		}
	}

	render() {
		return (
			<>
				{this.props.domains.loaded && this.props.domains.error.text.length ? (
					<Box
						margin={{ bottom: "m" }}
						children={
							<Alert
								type="error"
								header="Error loading data:"
								children={
									<>
										<Box variant="p" children={this.props.domains.error.text} />
										<hr />
										<Box
											variant="p"
											children={
												<>
													request error code: {this.props.domains.error.code} | request timestamp: {this.props.domains.error.timestamp}
												</>
											}
										/>
									</>
								}
							/>
						}
					/>
				) : (
					<></>
				)}

				<Table
					pagination={
						<Pagination
							disabled={!this.props.domains.loaded}
							currentPageIndex={this.props.domains.pagination.current}
							pagesCount={this.props.domains.pagination.total}
							onChange={(page) => {
								this.props.setDomains({ ...this.props.domains, pagination: { ...this.props.domains.pagination, current: page.detail.currentPageIndex } });
							}}
						/>
					}
					onSortingChange={(sorting) => {
						this.props.setDomains({ ...this.props.domains, sorting: sorting.detail });
						setTimeout(() => {
							this.props.setDomains({
								...this.props.domains,
								data: cloneDeep(this.props.domains.data).sort((a, b) => {
									return this.compare(a, b);
								}),
							});
						}, 0);
					}}
					columnDefinitions={[
						{ sortingField: "id", id: "id", header: "ID", cell: (item) => <Badge color="blue">{item.id}</Badge> },
						{ sortingField: "domain", id: "domain", header: "Domain", cell: (item) => <Link>{item.domain}</Link> },
						{ id: "status", header: "Status", sortingField: "status", cell: (item) => (item.status === "ban" ? <StatusIndicator type="error">{item.status}</StatusIndicator> : item.status === "free" || item.status === "mirror" ? <StatusIndicator type="info">{item.status}</StatusIndicator> : <StatusIndicator type="success">{item.status}</StatusIndicator>) },
						{ sortingField: "date", id: "date", header: "Date", cell: (item) => new Date(item.date).toLocaleTimeString() + " " + new Date(item.date).toLocaleDateString() },
					]}
					filter={
						<Input
							name="search"
							inputMode="search"
							type="search"
							autoFocus={true}
							disabled={!this.props.domains.loaded}
							disableBrowserAutocorrect={true}
							autoComplete="off"
							placeholder="Domain, status"
							value={this.props.domains.currentSearch}
							onChange={(input) => {
								this.props.setDomains({ ...this.props.domains, currentSearch: input.detail.value });
								setTimeout(() => {
									this.filter();
								}, 100);
							}}
						/>
					}
					preferences={
						<Preferences
							cancelLabel="Cancel"
							confirmLabel="Confirm"
							pageSizePreference={{ title: "Domains per page", options: this.props.config.domains.preferences.perPage }}
							preferences={{ pageSize: this.props.config.domains.perPage }}
							title="Preferences"
							onConfirm={(preferences) => {
								localStorage.setItem("domains.perPage", preferences.detail.pageSize);
								this.props.setConfig({ ...this.props.config, domains: { ...this.props.config.domains, perPage: preferences.detail.pageSize } });
								this.props.setDomains({
									...this.props.domains,
									pagination: {
										current: 1,
										total: Math.ceil(
											this.props.domains.data.filter((item) => {
												return item.filtered;
											}).length / preferences.detail.pageSize,
										),
									},
								});
							}}
						/>
					}
					items={this.props.domains.data
						.filter((item) => {
							return item.filtered;
						})
						.slice((this.props.domains.pagination.current - 1) * this.props.config.domains.perPage, this.props.domains.pagination.current * this.props.config.domains.perPage)}
					loading={!this.props.domains.data.length}
					sortingColumn={{ sortingField: this.props.domains.sorting.sortingColumn.sortingField }}
					sortingDescending={this.props.domains.sorting.isDescending}
					onSelectionChange={(selection) => {
						this.props.setDomains({ ...this.props.domains, selectedItems: selection.detail.selectedItems });
					}}
					selectedItems={this.props.domains.selectedItems}
					selectionType="single"
					header={
						<Box
							margin={{ bottom: "m" }}
							children={
								<Header
									children="Domains viewer"
									description="Below listed all project domains"
									actions={
										this.props.user.level >= 6 ? (
											<SpaceBetween direction="horizontal" size="l">
												<Button
													disabled={!this.props.domains.selectedItems.length || (this.props.domains.selectedItems.length && this.props.user.level <= this.props.domains.selectedItems[0].level)}
													onClick={() => {
														this.props.setDomainsEditModal({
															...this.props.domainsEditModal,
															id: this.props.domains.selectedItems[0].id,
															domain: this.props.domains.selectedItems[0].domain,
															currentOption: {
																...this.props.domainsEditModal.currentOption,
																type:
																	this.props.domains.selectedItems[0].status === "ban" ? this.props.config.domains.options.type[2] : this.props.domains.selectedItems[0].status === "free" ? this.props.config.domains.options.type[0] : this.props.domains.selectedItems[0].status === "mirror" ? this.props.config.domains.options.type[1] : this.props.domains.selectedItems[0].status === "current" ? this.props.config.domains.options.type[3] : this.props.config.domains.options.type[4],
															},
															show: true,
														});
													}}
													variant="normal">
													Edit domain
												</Button>
												<Button
													onClick={() => {
														this.props.setDomainsAddModal({ ...this.props.domainsAddModal, show: true });
													}}
													variant="primary">
													Add domain
												</Button>
											</SpaceBetween>
										) : (
											<></>
										)
									}
									counter={<>({this.props.domains.data.length})</>}
								/>
							}
						/>
					}
					empty="No domains were found"
				/>
			</>
		);
	}
}

function withRecoil(Component) {
	return function WrappedComponent(props) {
		const user = useRecoilValue(userStore);
		const domains = useRecoilValue(domainsStore);
		const setDomains = useSetRecoilState(domainsStore);
		const config = useRecoilValue(configStore);
		const setConfig = useSetRecoilState(configStore);
		const domainsAddModal = useRecoilValue(domainsAddModalStore);
		const setDomainsAddModal = useSetRecoilState(domainsAddModalStore);
		const domainsEditModal = useRecoilValue(domainsEditModalStore);
		const setDomainsEditModal = useSetRecoilState(domainsEditModalStore);

		return <Component {...props} setConfig={setConfig} user={user} domainsAddModal={domainsAddModal} setDomainsAddModal={setDomainsAddModal} domainsEditModal={domainsEditModal} setDomainsEditModal={setDomainsEditModal} config={config} domains={domains} setDomains={setDomains} />;
	};
}

export default withRecoil(Domains);
