import React from "react";

import Modal from "@cloudscape-design/components/modal";
import Input from "@cloudscape-design/components/input";
import Button from "@cloudscape-design/components/button";
import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Spinner from "@cloudscape-design/components/spinner";
import Toggle from "@cloudscape-design/components/toggle";
import Select from "@cloudscape-design/components/select";

/* Recoil */
import { useRecoilValue, useSetRecoilState } from "recoil";
import { configStore } from "../stores/config";
import { domainsEditModalStore } from "../stores/domainsEditModal";
import { userStore } from "../stores/access";
import { domainsStore } from "../stores/domains";
/* ------- */

class DomainsEditDomainModal extends React.Component {
	editDomain() {
		this.props.setDomainsEditModal({ ...this.props.domainsEditModal, loaded: false });

		this.props.Interface.request("set", { class: "settings", module: "domains", method: "edit" }, { id: this.props.domainsEditModal.id, domain: this.props.domainsEditModal.domain, type: this.props.domainsEditModal.currentOption.type.type })
			.then(() => {
				this.props.Interface.addNoty("Success", "Domain `" + this.props.domains.selectedItems[0].domain + "` was successfully edited");
				this.props.setDomainsEditModal({ ...this.props.domainsEditModal, checked: false, loaded: true, show: false, id: 0, domain: "", currentOption: { ...this.props.domainsEditModal.currentOption, type: "" } });

				this.props.setDomains({ ...this.props.domains, selectedItems: [], loaded: false });

				this.props.Interface.request("get", { class: "data", module: "info", method: "domains" })
					.then((data) => {
						data.forEach((item) => {
							item.filtered = true;
						});

						this.props.setDomains({
							...this.props.domains,
							data,
							loaded: true,
							pagination: { current: 1, total: Math.ceil(data.length / this.props.config.domains.perPage) },
							sorting: {
								sortingColumn: {
									sortingField: "",
								},
								sortingDescending: false,
							},
						});
					})
					.catch((e) => {
						this.props.setDomains({ ...this.props.domains, loaded: true, error: e });
					});
			})
			.catch((e) => {
				this.props.setDomainsEditModal({ ...this.props.domainsEditModal, checked: true, loaded: true });
				console.log(e);
			});
	}

	render() {
		return (
			<>
				<Modal
					visible={this.props.domainsEditModal.show}
					onDismiss={() => {
						this.props.setDomainsEditModal({ ...this.props.domainsEditModal, checked: false, loaded: true, show: false, id: 0, domain: "", currentOption: { ...this.props.domainsEditModal.currentOption, type: "" } });
					}}
					header="Edit domain"
					children={
						<>
							<SpaceBetween direction="vertical" size="m">
								{this.props.domainsEditModal.loaded ? (
									<>
										<Box variant="span">domain: </Box>
										<Input
											autoFocus="true"
											name="domain"
											autoComplete="off"
											invalid={(this.props.domainsEditModal.domain.length > 32 || this.props.domainsEditModal.domain.length < 4) && this.props.domainsEditModal.checked ? true : false}
											inputMode="url"
											type="text"
											disableBrowserAutocorrect={true}
											placeholder="domain with no protocol"
											value={this.props.domainsEditModal.domain}
											onChange={(input) => {
												this.props.setDomainsEditModal({ ...this.props.domainsEditModal, domain: input.detail.value });
											}}
										/>
										<Box variant="span">type: </Box>
										<Select
											filteringType="auto"
											placeholder="domain type"
											selectedOption={this.props.domainsEditModal.currentOption.type}
											onChange={(option) => {
												this.props.setDomainsEditModal({ ...this.props.domainsEditModal, currentOption: { ...this.props.domainsEditModal, type: option.detail.selectedOption } });
											}}
											options={this.props.config.domains.options.type}
										/>
									</>
								) : (
									<Box variant="div" textAlign="center">
										<Spinner size="large" />
									</Box>
								)}
							</SpaceBetween>
						</>
					}
					footer={
						<>
							<Box
								float="right"
								children={
									<>
										<Box
											variant="span"
											margin={{ right: "s" }}
											children={
												<Button
													variant="normal"
													onClick={() => {
														this.props.setDomainsEditModal({ ...this.props.domainsEditModal, show: false });
													}}
													children="Close"
												/>
											}
										/>
										<Box
											variant="span"
											children={
												<Button
													variant="primary"
													onClick={() => {
														this.editDomain();
													}}
													children="Ok"
												/>
											}
										/>
									</>
								}
							/>
						</>
					}
				/>
			</>
		);
	}
}

function withRecoil(Component) {
	return function WrappedComponent(props) {
		const domains = useRecoilValue(domainsStore);
		const setDomains = useSetRecoilState(domainsStore);
		const domainsEditModal = useRecoilValue(domainsEditModalStore);
		const setDomainsEditModal = useSetRecoilState(domainsEditModalStore);
		const config = useRecoilValue(configStore);
		const user = useRecoilValue(userStore);

		return <Component {...props} domains={domains} setDomains={setDomains} user={user} domainsEditModal={domainsEditModal} setDomainsEditModal={setDomainsEditModal} config={config} />;
	};
}

export default withRecoil(DomainsEditDomainModal);
