import React from "react";

import cloneDeep from "lodash/cloneDeep";

import Modal from "@cloudscape-design/components/modal";
import Input from "@cloudscape-design/components/input";
import Button from "@cloudscape-design/components/button";
import Box from "@cloudscape-design/components/box";

/* Recoil */
import { useRecoilValue, useSetRecoilState } from "recoil";
import { configStore } from "../stores/config";
import { gameStore } from "../stores/games";
import { playerModalStore } from "../stores/playerModal";
import { playerPageStore } from "../stores/playerPage";
import { playerStore } from "../stores/players";
import { withdrawalStore } from "../stores/withdrawals";
import { SpaceBetween } from "@cloudscape-design/components";
/* ------- */

class PlayerModal extends React.Component {
	toggleValueModal(type) {
		if (typeof type !== "undefined") this.props.setPlayerModal({ ...this.props.playerModal, show: 0, type, value: "", value2: "" });
		else this.props.setPlayerModal({ ...this.props.playerModal, item: false, show: 0, value: "", value2: "" });
	}

	managePayment(action, id, uid, note, project) {
		let withdrawals = cloneDeep(this.props.withdrawals.data);
		let withdrawalsToProcess = typeof id === "undefined" ? cloneDeep(this.props.withdrawals.selectedItems) : [{ id, uid, project }];

		withdrawalsToProcess.forEach((withdrawal, i) => {
			withdrawals.forEach((item, j) => {
				if (item.id === withdrawal.id) item.process = true;
			});
		});

		this.props.setWithdrawals({ ...this.props.withdrawals, selectedItems: [], data: withdrawals });

		let wirhdrawalsStatuses = {},
			checker = setInterval(() => {
				let withdrawals = cloneDeep(this.props.withdrawals.data);

				Object.keys(wirhdrawalsStatuses).forEach((processedWithdrawal) => {
					withdrawals.forEach((item) => {
						if (item.id === parseInt(processedWithdrawal)) {
							item.status = wirhdrawalsStatuses[processedWithdrawal].status;

							if (typeof wirhdrawalsStatuses[processedWithdrawal].balance !== "undefined") item.balance = wirhdrawalsStatuses[processedWithdrawal].balance;

							item.process = false;
						}
					});
				});

				this.props.setWithdrawals({ ...this.props.withdrawals, data: withdrawals });

				if (Object.keys(wirhdrawalsStatuses).length >= withdrawalsToProcess.length) clearInterval(checker);
			}, 500);

		withdrawalsToProcess.forEach((withdrawal, i) => {
			setTimeout(
				() => {
					const params = {
						id: withdrawal.id,
						uid: withdrawal.uid,
						project: withdrawal.project,
						note: typeof note !== "undefined" ? note : "default",
					};

					this.props.Interface.request("set", { class: "data", module: "withdrawals", method: action }, { ...params })
						.then((data) => {
							wirhdrawalsStatuses[withdrawal.id] = { status: data.status };

							if (typeof data.balance !== "undefined") wirhdrawalsStatuses[withdrawal.id].balance = data.balance;
						})
						.catch((e) => {
							wirhdrawalsStatuses[withdrawal.id] = { status: e.text };
							// this.props.setWithdrawals({ ...this.props.withdrawals, loaded: true, error: e });
						});
				},
				(i + 1) * 1000,
			);
		});
	}

	changeBalance() {
		this.props.setPlayerPage({ ...this.props.playerPage, loaded: false });

		this.props.Interface.request("set", { class: "users", module: "wallet", method: "balance" }, { uid: this.props.playerPage.uid, amount: this.props.playerModal.value })
			.then((balance) => {
				this.props.setPlayerPage({ ...this.props.playerPage, data: { ...this.props.playerPage.data, balance, balanceCasino: balance * 100 }, loaded: true });

				if (this.props.playerPage.log.type < 5)
					setTimeout(() => {
						this.props.Interface.loadLog(this.props.playerPage.uid);
					}, 250);
			})
			.catch(() => {
				this.props.setPlayerPage({ ...this.props.playerPage, loaded: true });
			});
	}

	changeLoyalty() {
		this.props.setPlayerPage({ ...this.props.playerPage, loaded: false });

		this.props.Interface.request("set", { class: "users", module: "wallet", method: "balanceLoyalty" }, { uid: this.props.playerPage.uid, amount: this.props.playerModal.value })
			.then((balance) => {
				this.props.setPlayerPage({ ...this.props.playerPage, data: { ...this.props.playerPage.data, balance_c: balance }, loaded: true });
			})
			.catch(() => {
				this.props.setPlayerPage({ ...this.props.playerPage, loaded: true });
			});
	}

	changePartner() {
		this.props.setPlayerPage({ ...this.props.playerPage, loaded: false });

		this.props.Interface.request("set", { class: "users", module: "wallet", method: "balancePartner" }, { uid: this.props.playerPage.uid, amount: this.props.playerModal.value })
			.then((balance) => {
				this.props.setPlayerPage({ ...this.props.playerPage, data: { ...this.props.playerPage.data, balance_r: balance }, loaded: true });
			})
			.catch(() => {
				this.props.setPlayerPage({ ...this.props.playerPage, loaded: true });
			});
	}

	givePromo() {
		this.props.setPlayerPage({ ...this.props.playerPage, loaded: false });

		this.props.Interface.request("set", { class: "users", module: "promo", method: "give" }, { uid: this.props.playerPage.uid, value: this.props.playerModal.value, note: this.props.playerModal.value2 })
			.then(() => {
				setTimeout(() => {
					this.props.Interface.loadInfo(this.props.playerPage.uid);
				}, 0);
			})
			.catch(() => {
				this.props.setPlayerPage({ ...this.props.playerPage, loaded: true });
			});
	}

	sellPromo() {
		this.props.setPlayerPage({ ...this.props.playerPage, loaded: false });

		this.props.Interface.request("set", { class: "users", module: "promo", method: "sell" }, { uid: this.props.playerPage.uid, value: this.props.playerModal.value, count: this.props.playerModal.value2 })
			.then(() => {
				this.props.setPlayerPage({ ...this.props.playerPage, log: { ...this.props.playerPage.log, min: 0, max: 0, limit: 0, type: 5 } });
				setTimeout(() => {
					this.props.Interface.loadInfo(this.props.playerPage.uid);
				}, 500);
			})
			.catch(() => {
				this.props.setPlayerPage({ ...this.props.playerPage, loaded: true });
			});
	}

	addButton() {
		let buttons = localStorage.getItem("withdrawals.buttons") ? JSON.parse(localStorage.getItem("withdrawals.buttons")) : [];
		buttons.push(this.props.playerModal.value);
		localStorage.setItem("withdrawals.buttons", JSON.stringify(buttons));

		this.props.setConfig({ ...this.props.config, withdrawals: { ...this.props.config.withdrawals, buttons } });
	}

	removeButton(button) {
		let buttons = localStorage.getItem("withdrawals.buttons") ? JSON.parse(localStorage.getItem("withdrawals.buttons")) : [];

		buttons.forEach((item, i) => {
			if (item === button) buttons.splice(i, 1);
		});

		localStorage.setItem("withdrawals.buttons", JSON.stringify(buttons));

		this.props.setConfig({ ...this.props.config, withdrawals: { ...this.props.config.withdrawals, buttons } });

		if (!buttons.length) this.toggleValueModal();
	}

	handleKeyPress = (event) => {
		if (event.detail.key === "Enter")
			setTimeout(() => {
				this.makeAction();
			}, 0);
	};

	makeAction(action, value) {
		switch (this.props.playerModal.type) {
			case 1:
				this.changeBalance();
				break;
			case 2:
				this.changePartner();
				break;
			case 3:
				this.changeLoyalty();
				break;
			case 4:
				switch (action) {
					case 1:
						this.managePayment("cancel", !this.props.playerModal.item ? undefined : this.props.playerModal.item.id, !this.props.playerModal.item ? undefined : this.props.playerModal.item.uid, "verify", !this.props.playerModal.item ? undefined : this.props.playerModal.item.project);
						break;
					case 2:
						this.managePayment("cancel", !this.props.playerModal.item ? undefined : this.props.playerModal.item.id, !this.props.playerModal.item ? undefined : this.props.playerModal.item.uid, "8.3", !this.props.playerModal.item ? undefined : this.props.playerModal.item.project);
						break;
					case 3:
						this.managePayment("cancel", !this.props.playerModal.item ? undefined : this.props.playerModal.item.id, !this.props.playerModal.item ? undefined : this.props.playerModal.item.uid, "8.4", !this.props.playerModal.item ? undefined : this.props.playerModal.item.project);
						break;
					case 4:
						this.managePayment("cancel", !this.props.playerModal.item ? undefined : this.props.playerModal.item.id, !this.props.playerModal.item ? undefined : this.props.playerModal.item.uid, "3.1.2.4", !this.props.playerModal.item ? undefined : this.props.playerModal.item.project);
						break;
					case 5:
						this.managePayment("cancel", !this.props.playerModal.item ? undefined : this.props.playerModal.item.id, !this.props.playerModal.item ? undefined : this.props.playerModal.item.uid, "3.1.2.22", !this.props.playerModal.item ? undefined : this.props.playerModal.item.project);
						break;
					case 6:
						this.managePayment("cancel", !this.props.playerModal.item ? undefined : this.props.playerModal.item.id, !this.props.playerModal.item ? undefined : this.props.playerModal.item.uid, this.props.playerModal.value, !this.props.playerModal.item ? undefined : this.props.playerModal.item.project);
						break;
					case 7:
						this.managePayment("cancel", !this.props.playerModal.item ? undefined : this.props.playerModal.item.id, !this.props.playerModal.item ? undefined : this.props.playerModal.item.uid, value, !this.props.playerModal.item ? undefined : this.props.playerModal.item.project);
						break;
				}
				break;
			case 5:
				switch (action) {
					case 1:
						this.managePayment("reject", !this.props.playerModal.item ? undefined : this.props.playerModal.item.id, !this.props.playerModal.item ? undefined : this.props.playerModal.item.uid, "8.3", !this.props.playerModal.item ? undefined : this.props.playerModal.item.project);
						break;
					case 2:
						this.managePayment("reject", !this.props.playerModal.item ? undefined : this.props.playerModal.item.id, !this.props.playerModal.item ? undefined : this.props.playerModal.item.uid, "8.4", !this.props.playerModal.item ? undefined : this.props.playerModal.item.project);
						break;
					case 3:
						this.managePayment("reject", !this.props.playerModal.item ? undefined : this.props.playerModal.item.id, !this.props.playerModal.item ? undefined : this.props.playerModal.item.uid, "3.1.2.4", !this.props.playerModal.item ? undefined : this.props.playerModal.item.project);
						break;
					case 4:
						this.managePayment("reject", !this.props.playerModal.item ? undefined : this.props.playerModal.item.id, !this.props.playerModal.item ? undefined : this.props.playerModal.item.uid, "3.1.2.22", !this.props.playerModal.item ? undefined : this.props.playerModal.item.project);
						break;
					case 5:
						this.managePayment("reject", !this.props.playerModal.item ? undefined : this.props.playerModal.item.id, !this.props.playerModal.item ? undefined : this.props.playerModal.item.uid, this.props.playerModal.value, !this.props.playerModal.item ? undefined : this.props.playerModal.item.project);
						break;
					case 6:
						this.managePayment("reject", !this.props.playerModal.item ? undefined : this.props.playerModal.item.id, !this.props.playerModal.item ? undefined : this.props.playerModal.item.uid, value, !this.props.playerModal.item ? undefined : this.props.playerModal.item.project);
						break;
				}
				break;
			case 6:
				this.givePromo();
				break;
			case 7:
				this.sellPromo();
				break;
			case 8:
				this.addButton();
				break;
			case 9:
				this.removeButton();
				break;
		}

		this.toggleValueModal();
	}

	render() {
		return (
			<>
				<Modal
					visible={this.props.playerModal.show}
					onDismiss={() => {
						this.toggleValueModal();
					}}
					header={this.props.playerModal.title}
					children={
						<>
							{this.props.playerModal.type !== 9 ? (
								<Input
									autoComplete={false}
									autoFocus={true}
									inputMode={this.props.playerModal.type < 4 || (this.props.playerModal.type >= 6 && this.props.playerModal.type !== 8 && this.props.playerModal.type !== 9) ? "numeric" : "text"}
									type={this.props.playerModal.type < 4 || (this.props.playerModal.type >= 6 && this.props.playerModal.type !== 8 && this.props.playerModal.type !== 9) ? "number" : "text"}
									onKeyDown={this.handleKeyPress}
									disableBrowserAutocorrect={true}
									placeholder={this.props.playerModal.description ? this.props.playerModal.description + " (" + this.props.playerModal.units + ")" : ""}
									value={this.props.playerModal.value}
									onChange={(input) => {
										this.props.setPlayerModal({ ...this.props.playerModal, value: input.detail.value });
									}}
								/>
							) : (
								<>
									{" "}
									{this.props.config.withdrawals.buttons.length ? (
										<SpaceBetween direction="horizontal" size="s">
											{this.props.config.withdrawals.buttons.map((button) => (
												<Box variant="span">
													<Button
														variant="normal"
														onClick={() => {
															this.removeButton(button);
														}}>
														{button}
													</Button>
												</Box>
											))}
										</SpaceBetween>
									) : (
										<></>
									)}
								</>
							)}

							{this.props.playerModal.type >= 6 && this.props.playerModal.type !== 8 && this.props.playerModal.type !== 9 ? (
								<Box
									margin={{ top: "m" }}
									children={
										<Input
											onKeyDown={this.handleKeyPress}
											disableBrowserAutocorrect={true}
											placeholder={this.props.playerModal.description2 ? this.props.playerModal.description2 + " (" + this.props.playerModal.units2 + ")" : ""}
											value={this.props.playerModal.value2}
											onChange={(input) => {
												this.props.setPlayerModal({ ...this.props.playerModal, value2: input.detail.value });
											}}
										/>
									}
								/>
							) : (
								<></>
							)}
						</>
					}
					footer={
						<>
							{this.props.playerModal.type < 4 || (this.props.playerModal.type >= 6 && this.props.playerModal.type !== 9) ? (
								<>
									<Box
										variant="span"
										margin={{ right: "s" }}
										children={
											<Button
												variant="normal"
												onClick={() => {
													this.toggleValueModal();
												}}
												children="Close"
											/>
										}
									/>
									<Box
										variant="span"
										children={
											<Button
												variant="primary"
												onClick={() => {
													this.makeAction();
												}}
												children="Ok"
											/>
										}
									/>
								</>
							) : this.props.playerModal.type === 4 ? (
								<SpaceBetween className={this.props.styles["withdrawalsButtons"]} direction="vertical" size="m">
									<SpaceBetween direction="horizontal" size="s">
										<Box variant="span">
											<Button
												variant="normal"
												onClick={() => {
													this.makeAction(1);
												}}>
												Verify
											</Button>
										</Box>
										<Box variant="span">
											<Button
												variant="normal"
												onClick={() => {
													this.makeAction(2);
												}}>
												8.3
											</Button>
										</Box>
										<Box variant="span">
											<Button
												variant="normal"
												onClick={() => {
													this.makeAction(3);
												}}>
												8.4
											</Button>
										</Box>
										<Box variant="span">
											<Button
												variant="normal"
												onClick={() => {
													this.makeAction(4);
												}}>
												3.1.2.4
											</Button>
										</Box>
										<Box variant="span">
											<Button
												variant="normal"
												onClick={() => {
													this.makeAction(5);
												}}>
												3.1.2.22
											</Button>
										</Box>
									</SpaceBetween>
									{this.props.config.withdrawals.buttons.length ? (
										<SpaceBetween direction="horizontal" size="s">
											{this.props.config.withdrawals.buttons.map((button) => (
												<Box variant="span">
													<Button
														variant="normal"
														onClick={() => {
															this.makeAction(7, button);
														}}>
														{button}
													</Button>
												</Box>
											))}
										</SpaceBetween>
									) : (
										<></>
									)}
									<SpaceBetween direction="horizontal" size="s">
										<Box
											variant="span"
											children={
												<Button
													variant="normal"
													onClick={() => {
														this.toggleValueModal();
													}}>
													Close
												</Button>
											}
										/>
										<Box
											variant="span"
											children={
												<Button
													variant="primary"
													onClick={() => {
														this.makeAction(6);
													}}>
													Ok
												</Button>
											}
										/>
									</SpaceBetween>
								</SpaceBetween>
							) : this.props.playerModal.type === 9 ? (
								<Box
									variant="span"
									children={
										<Button
											variant="primary"
											onClick={() => {
												this.removeButton(5);
											}}>
											Ok
										</Button>
									}
								/>
							) : (
								<SpaceBetween className={this.props.styles["withdrawalsButtons"]} direction="vertical" size="m">
									<SpaceBetween direction="horizontal" size="s">
										<Box variant="span">
											<Button
												variant="normal"
												onClick={() => {
													this.makeAction(1);
												}}>
												8.3
											</Button>
										</Box>
										<Box variant="span">
											<Button
												variant="normal"
												onClick={() => {
													this.makeAction(2);
												}}>
												8.4
											</Button>
										</Box>
										<Box variant="span">
											<Button
												variant="normal"
												onClick={() => {
													this.makeAction(3);
												}}>
												3.1.2.4
											</Button>
										</Box>
										<Box variant="span">
											<Button
												variant="normal"
												onClick={() => {
													this.makeAction(4);
												}}>
												3.1.2.22
											</Button>
										</Box>
									</SpaceBetween>
									{this.props.config.withdrawals.buttons.length ? (
										<SpaceBetween direction="horizontal" size="s">
											{this.props.config.withdrawals.buttons.map((button) => (
												<Box variant="span">
													<Button
														variant="normal"
														onClick={() => {
															this.makeAction(6, button);
														}}>
														{button}
													</Button>
												</Box>
											))}
										</SpaceBetween>
									) : (
										<></>
									)}
									<SpaceBetween direction="horizontal" size="s">
										<Box
											variant="span"
											children={
												<Button
													variant="normal"
													onClick={() => {
														this.toggleValueModal();
													}}>
													Close
												</Button>
											}
										/>
										<Box
											variant="span"
											children={
												<Button
													variant="primary"
													onClick={() => {
														this.makeAction(5);
													}}>
													Ok
												</Button>
											}
										/>
									</SpaceBetween>
								</SpaceBetween>
							)}
						</>
					}
				/>
			</>
		);
	}
}

function withRecoil(Component) {
	return function WrappedComponent(props) {
		const players = useRecoilValue(playerStore);
		const setPlayers = useSetRecoilState(playerStore);
		const config = useRecoilValue(configStore);
		const setConfig = useSetRecoilState(configStore);
		const playerPage = useRecoilValue(playerPageStore);
		const setPlayerPage = useSetRecoilState(playerPageStore);
		const game = useRecoilValue(gameStore);
		const setGame = useSetRecoilState(gameStore);
		const playerModal = useRecoilValue(playerModalStore);
		const setPlayerModal = useSetRecoilState(playerModalStore);
		const withdrawals = useRecoilValue(withdrawalStore);
		const setWithdrawals = useSetRecoilState(withdrawalStore);

		return <Component {...props} players={players} setPlayers={setPlayers} config={config} setConfig={setConfig} playerModal={playerModal} setPlayerModal={setPlayerModal} playerPage={playerPage} setPlayerPage={setPlayerPage} game={game} setGame={setGame} withdrawals={withdrawals} setWithdrawals={setWithdrawals} />;
	};
}

export default withRecoil(PlayerModal);
