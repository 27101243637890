import { atom } from "recoil";

export const topMenuStore = atom({
	key: "topMenuStore",
	default: [],
});

export const loaderStore = atom({
	key: "loaderStore",
	default: {
		paymentData: false,
	},
});
