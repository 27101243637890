import { atom } from "recoil";

export const playerPageStore = atom({
	key: "playerPageStore",
	default: {
		loaded: false,
		error: {
			text: "",
			code: 0,
			timestamp: 0,
		},
		uid: 0,
		data: {},
		log: {
			loaded: false,
			error: {
				text: "",
				code: 0,
				timestamp: 0,
			},
			data: [],
			type: 1,
			min: 0,
			max: 999999999,
			limit: 20000,
			currentSearch: "",
		},
		gameLog: {
			pagination: {
				current: 1,
				total: 0,
			},
			currentSearch: "",
		},
		sorting: {
			sortingColumn: {
				sortingField: "",
			},
			isDescending: false,
		},
		pagination: {
			current: 1,
			total: 0,
		},
	},
});
