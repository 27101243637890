import { atom } from "recoil";

export const exportsStore = atom({
	key: "exportsStore",
	default: {
		loaded: false,
		error: {
			text: "",
			code: 0,
			timestamp: 0,
		},
		sorting: {
			sortingColumn: {
				sortingField: "",
			},
			sortingDescending: false,
		},
	},
});
