import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

import styles from "../styles/main.module.scss";

import Logo from "../assets/logoDark.svg";

import cloneDeep from "lodash/cloneDeep";

import "@cloudscape-design/global-styles/index.css";
import "@cloudscape-design/global-styles/dark-mode-utils.css";

import { applyMode, applyDensity, Density, Mode } from "@cloudscape-design/global-styles";

import TopNavigation from "@cloudscape-design/components/top-navigation";
import AppLayout from "@cloudscape-design/components/app-layout";
import Form from "@cloudscape-design/components/form";
import FormField from "@cloudscape-design/components/form-field";
import Input from "@cloudscape-design/components/input";
import Container from "@cloudscape-design/components/container";
import Header from "@cloudscape-design/components/header";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Spinner from "@cloudscape-design/components/spinner";
import Box from "@cloudscape-design/components/box";
import Icon from "@cloudscape-design/components/icon";
import Cards from "@cloudscape-design/components/cards";
import ContentLayout from "@cloudscape-design/components/content-layout";

import Notys from "./Notys";
import PlayerPaymentsModal from "./PlayerPaymentsModal";
import PlayerProfileModal from "./PlayerProfileModal";
import PlayerGameLogModal from "./PlayerGameLogModal";
import PlayerModal from "./PlayerModal";
import GamesAddModal from "./GamesAddModal";
import GamesStatsModal from "./GamesStatsModal";
import GamesEditModal from "./GamesEditModal";
import StaffSessionsModal from "./StaffSessionsModal";
import StaffLogModal from "./StaffLogModal";
import StaffAddUserModal from "./StaffAddUserModal";
import StaffEditUserModal from "./StaffEditUserModal";
import PlayerDocumentModal from "./PlayerDocumentModal";
import PlayerSessionsModal from "./PlayerSessionsModal";
import ReportsAddCostsModal from "./ReportsAddCostsModal";
import BonusesAddModal from "./BonusesAddModal";
import GamesAutomanageModal from "./GamesAutomanageModal";
import ReportsAddSettlementsModal from "./ReportsAddSettlementsModal";
import ChatBanUserModal from "./ChatBanUserModal";
import DomainsAddDomainModal from "./DomainsAddDomainModal";
import DomainsEditDomainModal from "./DomainsEditDomainModal";

import Interface from "../lib/Interface";
/* ------- */

/* Recoil */
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userStore } from "../stores/access";
import { routeStore } from "../stores/route";
import { topMenuStore } from "../stores/interface";
import { configStore } from "../stores/config";
/* ------- */

if (localStorage.getItem("mode") === "dark") applyMode(Mode.Dark);

class Index extends React.PureComponent {
	constructor(props) {
		super(props);

		Interface.isTopMenuLoaded().then(() => {
			let menu = cloneDeep(this.props.topMenu);

			menu.forEach((item) => {
				if (typeof item.items !== "undefined" && typeof item.routing !== "undefined" && item.routing)
					item.onItemClick = (innerItem) => {
						this.doRoute("/" + innerItem.detail.id);
					};
				else
					item.onClick = () => {
						this.doRoute(item.path);
					};
			});

			this.props.setTopMenu([...menu]);
		});
	}

	componentDidMount() {
		Interface.loadBrands();

		if (localStorage.getItem("access.token")) {
			this.props.setUser({ ...this.props.user, process: true });
			Interface.request("get", { class: "user", module: "auth", method: "check" })
				.then((level) => {
					this.props.setUser({ ...this.props.user, level, process: false });
					localStorage.setItem("access.level", level);

					Interface.isUserLoaded().then(() => {
						Interface.initData();
						Interface.loadCasinoData();

						if (this.props.user.mode === "brand" && this.props.user.level >= 5) Interface.loadPaymentData();
					});
				})
				.catch(() => {
					localStorage.removeItem("access.token");
					localStorage.removeItem("access.level");
					localStorage.removeItem("access.login");
					this.props.setUser({ ...this.props.user, token: false, level: 0, login: "", process: false });
				});
		}
	}

	doRoute = (href) => {
		// eslint-disable-next-line default-case
		switch (href) {
			case "/mode-dark":
				localStorage.setItem("mode", "dark");
				applyMode(Mode.Dark);
				window.location.reload();
				break;
			case "/mode-light":
				localStorage.setItem("mode", "light");
				applyMode(Mode.Light);
				window.location.reload();
				break;
		}
		this.props.setRoute({ currentPath: href });
		this.props.history(href);

		return true;
	};

	handleKeyPress = (event) => {
		if (event.detail.key === "Enter" && this.props.user.login.length && this.props.user.password.length) this.doLogin();
	};

	doLogin() {
		this.props.setUser({ ...this.props.user, process: true });

		Interface.request("get", { class: "user", module: "auth", method: "login" }, { login: this.props.user.login, password: this.props.user.password })
			.then((token) => {
				this.props.setUser({ ...this.props.user, token });
				localStorage.setItem("access.token", token);
				localStorage.setItem("access.login", this.props.user.login);

				setTimeout(() => {
					Interface.request("get", { class: "user", module: "auth", method: "check" })
						.then((level) => {
							localStorage.setItem("access.level", level);
							this.props.setUser({ ...this.props.user, level, process: false });

							Interface.isTopMenuLoaded().then(() => {
								let menu = cloneDeep(this.props.topMenu);

								menu.forEach((item) => {
									item.onClick = () => {
										this.doRoute(item.path);
									};
								});

								this.props.setTopMenu([...menu]);
							});
						})
						.catch(() => {
							this.props.setUser({ ...this.props.user, process: false });
						});
				}, 50);

				Interface.isTopMenuLoaded().then(() => {
					let menu = cloneDeep(this.props.topMenu);

					menu.forEach((item) => {
						if (typeof item.items !== "undefined" && typeof item.routing !== "undefined" && item.routing)
							item.onItemClick = (innerItem) => {
								this.doRoute("/" + innerItem.detail.id);
							};
						else
							item.onClick = () => {
								this.doRoute(item.path);
							};
					});

					this.props.setTopMenu([...menu]);
				});

				Interface.isUserLoaded().then(() => {
					Interface.initData();
					Interface.loadCasinoData();

					if (this.props.user.mode === "brand" && this.props.user.level >= 5) Interface.loadPaymentData();
				});
			})
			.catch(() => {
				this.props.setUser({ ...this.props.user, process: false });
			});
	}

	render() {
		return (
			<>
				<div id="h" style={{ position: "sticky", top: 0, zIndex: 1002 }}>
					<TopNavigation
						identity={{
							logo: { src: this.props.user.brand && this.props.user.level ? this.props.user.brand.logoDark : Logo },
							href: "#",
							onFollow: () => {
								this.doRoute("/");
							},
						}}
						utilities={this.props.topMenu}
						i18nStrings={{
							searchIconAriaLabel: "Search",
							searchDismissIconAriaLabel: "Close search",
							overflowMenuTriggerText: "More",
							overflowMenuBackIconAriaLabel: "Back",
							overflowMenuDismissIconAriaLabel: "Close menu",
							overflowMenuTriggerText: <Icon name="menu" />,
						}}
						className={this.props.user.brand && this.props.user.level ? styles["topNavigation"] : ""}
					/>
				</div>
				<AppLayout
					className={styles["contentLayout"]}
					maxContentWidth={Number.MAX_VALUE}
					toolsHide={true}
					navigationHide={true}
					stickyNotifications={true}
					headerSelector="#h"
					content={
						<ContentLayout>
							{this.props.user.token && !this.props.user.process ? (
								<Routes>{Interface.getRoutes().map((route) => this.props.user.level >= route.level && <Route key={route.path} exact path={route.path} element={<route.component styles={styles} doRoute={this.doRoute} Interface={Interface} />} />)}</Routes>
							) : this.props.user.mode === "brand" && this.props.user.brand ? (
								<Container className={styles["authForm"]}>
									<Form
										actions={
											<SpaceBetween direction="horizontal" size="xs">
												<Button
													onClick={() => {
														localStorage.removeItem("access.brand");
														this.props.setUser({ ...this.props.user, brand: false });
													}}
													variant="link">
													Switch brand
												</Button>
												<Button
													disabled={!this.props.user.login.length || !this.props.user.password.length}
													onClick={() => {
														this.doLogin();
													}}
													variant="primary">
													Sign in
												</Button>
											</SpaceBetween>
										}
										header={
											<Header variant="h1" description="Enter your login and password">
												Sign in
											</Header>
										}>
										<Container>
											{this.props.user.process ? (
												<Box className={styles["authSpinner"]}>
													<Spinner size="large" />
												</Box>
											) : (
												<SpaceBetween direction="vertical" size="l">
													<div className={[styles["brandCard"], styles["noHover"]].join(" ")}>
														<img src={document.body.classList[0] === "awsui-dark-mode" ? this.props.user.brand.logoDark : this.props.user.brand.logo} />
													</div>
													<FormField label="Login">
														<Input
															onKeyDown={(ev) => {
																this.handleKeyPress(ev);
															}}
															name="login"
															value={this.props.user.login}
															onChange={(login) => this.props.setUser({ ...this.props.user, login: login.detail.value })}
														/>
													</FormField>
													<FormField label="Password">
														<Input
															onKeyDown={(ev) => {
																this.handleKeyPress(ev);
															}}
															name="password"
															type="password"
															value={this.props.user.password}
															onChange={(password) => this.props.setUser({ ...this.props.user, password: password.detail.value })}
														/>
													</FormField>
												</SpaceBetween>
											)}
										</Container>
									</Form>
								</Container>
							) : (
								<Container className={styles["authForm"]}>
									<Form
										header={
											<Header
												variant="h1"
												description={this.props.user.mode === "brand" ? "Select brand to log in CRM" : "Enter your login and password"}
												actions={
													<SpaceBetween direction="horizontal" size="xs">
														<Button
															onClick={() => {
																this.props.user !== "brand" && !this.props.user.process && localStorage.setItem("access.mode", "brand");
																this.props.user !== "brand" && !this.props.user.process && this.props.setUser({ ...this.props.user, mode: "brand" });
															}}
															variant={this.props.user.mode === "brand" ? "secondary" : "primary"}>
															Brand mode
														</Button>
														<Button
															onClick={() => {
																this.props.user !== "brand" && !this.props.user.process && localStorage.setItem("access.mode", "global");
																this.props.user !== "brand" && !this.props.user.process && this.props.setUser({ ...this.props.user, mode: "global" });
															}}
															variant={this.props.user.mode === "global" ? "secondary" : "primary"}>
															Global mode
														</Button>
													</SpaceBetween>
												}>
												{this.props.user.mode === "brand" ? "Select brand" : "Sign in"}
											</Header>
										}>
										{this.props.user.mode === "brand" ? (
											<Container>
												{this.props.user.process ? (
													<Box className={styles["authSpinner"]}>
														<Spinner size="large" />
													</Box>
												) : (
													<Cards
														className={styles["brandsList"]}
														cardDefinition={{
															header: (item) => (
																<div
																	onClick={() => {
																		localStorage.setItem("access.brand", JSON.stringify(item));
																		this.props.setConfig({ ...this.props.config, app: { ...this.props.config.app, name: item.name }, paymentApi: { ...this.props.config.paymentApi, id: item.ecorpayID }, routerApi: { ...this.props.config.routerApi, id: item.elsikoraID } });
																		this.props.setUser({ ...this.props.user, brand: item });
																	}}
																	className={[styles["brandCard"], document.body.classList[0] === "awsui-dark-mode" ? styles["dark"] : ""].join(" ")}>
																	<img src={document.body.classList[0] === "awsui-dark-mode" ? item.logoDark : item.logo} />
																</div>
															),
														}}
														items={this.props.user.brandsList}
													/>
												)}
											</Container>
										) : (
											<Container>
												<Form
													actions={
														<SpaceBetween direction="horizontal" size="xs">
															<Button
																disabled={!this.props.user.login.length || !this.props.user.password.length}
																onClick={() => {
																	this.doLogin();
																}}
																variant="primary">
																Sign in
															</Button>
														</SpaceBetween>
													}>
													<Container>
														{this.props.user.process ? (
															<Box className={styles["authSpinner"]}>
																<Spinner size="large" />
															</Box>
														) : (
															<SpaceBetween direction="vertical" size="l">
																<div className={[styles["brandCard"], styles["noHover"]].join(" ")}>
																	<img src={document.body.classList[0] === "awsui-dark-mode" ? this.props.user.brand.logoDark : this.props.user.brand.logo} />
																</div>
																<FormField label="Login">
																	<Input
																		onKeyDown={(ev) => {
																			this.handleKeyPress(ev);
																		}}
																		name="login"
																		value={this.props.user.login}
																		onChange={(login) => this.props.setUser({ ...this.props.user, login: login.detail.value })}
																	/>
																</FormField>
																<FormField label="Password">
																	<Input
																		onKeyDown={(ev) => {
																			this.handleKeyPress(ev);
																		}}
																		name="password"
																		type="password"
																		value={this.props.user.password}
																		onChange={(password) => this.props.setUser({ ...this.props.user, password: password.detail.value })}
																	/>
																</FormField>
															</SpaceBetween>
														)}
													</Container>
												</Form>
											</Container>
										)}
									</Form>
								</Container>
							)}
							<PlayerProfileModal styles={styles} doRoute={this.doRoute} Interface={Interface} />
							<PlayerPaymentsModal styles={styles} doRoute={this.doRoute} Interface={Interface} />
							<PlayerDocumentModal styles={styles} doRoute={this.doRoute} Interface={Interface} />
							<PlayerSessionsModal styles={styles} doRoute={this.doRoute} Interface={Interface} />
							<PlayerGameLogModal styles={styles} doRoute={this.doRoute} Interface={Interface} />
							<PlayerModal styles={styles} doRoute={this.doRoute} Interface={Interface} />
							<GamesAddModal styles={styles} doRoute={this.doRoute} Interface={Interface} />
							<GamesStatsModal styles={styles} doRoute={this.doRoute} Interface={Interface} />
							<GamesEditModal styles={styles} doRoute={this.doRoute} Interface={Interface} />
							<GamesAutomanageModal styles={styles} doRoute={this.doRoute} Interface={Interface} />
							<StaffSessionsModal styles={styles} doRoute={this.doRoute} Interface={Interface} />
							<StaffLogModal styles={styles} doRoute={this.doRoute} Interface={Interface} />
							<StaffEditUserModal styles={styles} doRoute={this.doRoute} Interface={Interface} />
							<StaffAddUserModal styles={styles} doRoute={this.doRoute} Interface={Interface} />
							<DomainsEditDomainModal styles={styles} doRoute={this.doRoute} Interface={Interface} />
							<DomainsAddDomainModal styles={styles} doRoute={this.doRoute} Interface={Interface} />
							<ReportsAddCostsModal styles={styles} doRoute={this.doRoute} Interface={Interface} />
							<ReportsAddSettlementsModal styles={styles} doRoute={this.doRoute} Interface={Interface} />
							<BonusesAddModal styles={styles} doRoute={this.doRoute} Interface={Interface} />
							<ChatBanUserModal styles={styles} doRoute={this.doRoute} Interface={Interface} />
						</ContentLayout>
					}
				/>
				<Notys />
			</>
		);
	}
}

function withRecoil(Component) {
	return function WrappedComponent(props) {
		const user = useRecoilValue(userStore);
		const setUser = useSetRecoilState(userStore);
		const route = useRecoilValue(routeStore);
		const setRoute = useSetRecoilState(routeStore);
		const topMenu = useRecoilValue(topMenuStore);
		const setTopMenu = useSetRecoilState(topMenuStore);
		const config = useRecoilValue(configStore);
		const setConfig = useSetRecoilState(configStore);
		const history = useNavigate();

		return <Component {...props} topMenu={topMenu} setTopMenu={setTopMenu} user={user} setUser={setUser} route={route} setRoute={setRoute} history={history} config={config} setConfig={setConfig} />;
	};
}

export default withRecoil(Index);
