import React from "react";

import Modal from "@cloudscape-design/components/modal";
import Input from "@cloudscape-design/components/input";
import Button from "@cloudscape-design/components/button";
import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Spinner from "@cloudscape-design/components/spinner";
import Toggle from "@cloudscape-design/components/toggle";
import Select from "@cloudscape-design/components/select";

/* Recoil */
import { useRecoilValue, useSetRecoilState } from "recoil";
import { configStore } from "../stores/config";
import { reportsAddCostsModalStore } from "../stores/reports";
import { userStore } from "../stores/access";
import { staffStore } from "../stores/staff";
/* ------- */

class ReportsAddCostsModal extends React.Component {
	addItem() {
		this.props.setReportsAddCostsModal({ ...this.props.reportsAddCostsModal, loaded: false });

		this.props.Interface.request(
			"set",
			{ class: "settings", module: "costs", method: "add" },
			{ name: this.props.reportsAddCostsModal.name, amount: this.props.reportsAddCostsModal.amount, data: this.props.reportsAddCostsModal.data, date: this.props.reportsAddCostsModal.date, brand: this.props.user.mode === "global" ? this.props.reportsAddCostsModal.brand.value : this.props.user.brand.id, group: this.props.reportsAddCostsModal.group.type, currency: this.props.reportsAddCostsModal.currency.type },
		)
			.then(() => {
				this.props.Interface.addNoty("Success", "Cost item was added");
				this.props.setReportsAddCostsModal({ ...this.props.reportsAddCostsModal, checked: false, loaded: true, show: false, name: "", data: "", date: "", amount: "", brand: this.getBrandsAsOptions()[0], group: this.props.config.reports.options.group[1], currency: this.props.config.reports.options.currency[0] });
			})
			.catch((e) => {
				this.props.setReportsAddCostsModal({ ...this.props.reportsAddCostsModal, checked: true, loaded: true });
				console.log(e);
			});
	}

	getBrandsAsOptions() {
		const options = [];
		this.props.user.brandsList.forEach((item) => {
			options.push({ label: item.name, value: item.id });
		});

		return options;
	}

	render() {
		return (
			<>
				<Modal
					visible={this.props.reportsAddCostsModal.show}
					onDismiss={() => {
						this.props.setReportsAddCostsModal({ ...this.props.reportsAddCostsModal, checked: false, name: "", data: "", date: "", amount: "", brand: false, show: false });
					}}
					header="Add item"
					children={
						<>
							<SpaceBetween direction="vertical" size="m">
								{this.props.reportsAddCostsModal.loaded ? (
									<>
										<Box variant="span">name: </Box>
										<Input
											invalid={(this.props.reportsAddCostsModal.name.length > 16 || this.props.reportsAddCostsModal.name.length < 3) && this.props.reportsAddCostsModal.checked ? true : false}
											inputMode="text"
											type="text"
											disableBrowserAutocorrect={true}
											placeholder="Item name"
											value={this.props.reportsAddCostsModal.name}
											onChange={(input) => {
												this.props.setReportsAddCostsModal({ ...this.props.reportsAddCostsModal, name: input.detail.value });
											}}
										/>
										<Box variant="span">data link: </Box>
										<Input
											inputMode="text"
											type="text"
											disableBrowserAutocorrect={true}
											placeholder="Data link"
											value={this.props.reportsAddCostsModal.data}
											onChange={(input) => {
												this.props.setReportsAddCostsModal({ ...this.props.reportsAddCostsModal, data: input.detail.value });
											}}
										/>
										<Box variant="span">group: </Box>
										<Select
											filteringType="auto"
											placeholder="Select group"
											selectedOption={this.props.reportsAddCostsModal.group}
											onChange={({ detail }) => {
												this.props.setReportsAddCostsModal({ ...this.props.reportsAddCostsModal, group: detail.selectedOption });
											}}
											options={this.props.config.reports.options.group.slice(1)}
											selectedAriaLabel="Selected"
										/>
										<Box variant="span">amount: </Box>
										<Input
											invalid={isNaN(parseInt(this.props.reportsAddCostsModal.amount)) && this.props.reportsAddCostsModal.checked ? true : false}
											inputMode="numeric"
											type="number"
											disableBrowserAutocorrect={true}
											placeholder="Amount"
											value={this.props.reportsAddCostsModal.amount}
											onChange={(input) => {
												this.props.setReportsAddCostsModal({ ...this.props.reportsAddCostsModal, amount: input.detail.value });
											}}
										/>
										<Box variant="span">currency: </Box>
										<Select
											filteringType="auto"
											placeholder="Select currency"
											selectedOption={this.props.reportsAddCostsModal.currency}
											onChange={({ detail }) => {
												this.props.setReportsAddCostsModal({ ...this.props.reportsAddCostsModal, currency: detail.selectedOption });
											}}
											options={this.props.config.reports.options.currency}
											selectedAriaLabel="Selected"
										/>
										{this.props.user.mode === "global" ? (
											<>
												<Box variant="span">brand: </Box>
												<Select
													filteringType="auto"
													placeholder="Select brand"
													selectedOption={this.props.reportsAddCostsModal.brand}
													onChange={({ detail }) => {
														this.props.setReportsAddCostsModal({ ...this.props.reportsAddCostsModal, brand: detail.selectedOption });
													}}
													options={this.getBrandsAsOptions()}
													selectedAriaLabel="Selected"
												/>
											</>
										) : (
											<> </>
										)}
										<Box variant="span">date: </Box>
										<Input
											invalid={isNaN(parseInt(this.props.reportsAddCostsModal.date)) && this.props.reportsAddCostsModal.checked ? true : false}
											inputMode="date"
											type="date"
											disableBrowserAutocorrect={true}
											placeholder="Date"
											value={this.props.reportsAddCostsModal.date}
											onChange={(input) => {
												this.props.setReportsAddCostsModal({ ...this.props.reportsAddCostsModal, date: input.detail.value });
											}}
										/>
									</>
								) : (
									<Box variant="div" textAlign="center">
										<Spinner size="large" />
									</Box>
								)}
							</SpaceBetween>
						</>
					}
					footer={
						<>
							<Box
								float="right"
								children={
									<>
										<Box
											variant="span"
											margin={{ right: "s" }}
											children={
												<Button
													variant="normal"
													onClick={() => {
														this.props.setReportsAddCostsModal({ ...this.props.reportsAddCostsModal, show: false });
													}}
													children="Close"
												/>
											}
										/>
										<Box
											variant="span"
											children={
												<Button
													variant="primary"
													onClick={() => {
														this.addItem();
													}}
													children="Ok"
												/>
											}
										/>
									</>
								}
							/>
						</>
					}
				/>
			</>
		);
	}
}

function withRecoil(Component) {
	return function WrappedComponent(props) {
		const staff = useRecoilValue(staffStore);
		const setStaff = useSetRecoilState(staffStore);
		const reportsAddCostsModal = useRecoilValue(reportsAddCostsModalStore);
		const setReportsAddCostsModal = useSetRecoilState(reportsAddCostsModalStore);
		const config = useRecoilValue(configStore);
		const user = useRecoilValue(userStore);

		return <Component {...props} staff={staff} setStaff={setStaff} user={user} reportsAddCostsModal={reportsAddCostsModal} setReportsAddCostsModal={setReportsAddCostsModal} config={config} />;
	};
}

export default withRecoil(ReportsAddCostsModal);
