import { atom } from "recoil";

export const gamesStatsModalStore = atom({
	key: "gamesStatsModalStore",
	default: {
		show: false,
		loaded: false,
		needLoad: false,
		error: {
			text: "",
			code: 0,
			timestamp: 0,
		},
		gameID: false,
		data: [],
		chart: { bets: { total: [], count: [] }, wins: { total: [], count: [] }, rtp: [] },
		period: 0,
		range: false,
		offset: 1,
		limit: 1,
		sorting: {
			sortingColumn: {
				sortingField: "rtp",
			},
			sortingDescending: false,
		},
		currentOption: {
			group: false,
			period: false,
		},
		currentGroup: false,
		currentPeriod: false,
	},
});
