import React from "react";

import cloneDeep from "lodash/cloneDeep";

import Modal from "@cloudscape-design/components/modal";
import Alert from "@cloudscape-design/components/alert";
import Button from "@cloudscape-design/components/button";
import Box from "@cloudscape-design/components/box";
import Select from "@cloudscape-design/components/select";
import Multiselect from "@cloudscape-design/components/multiselect";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Toggle from "@cloudscape-design/components/toggle";
import Spinner from "@cloudscape-design/components/spinner";

/* Recoil */
import { useRecoilValue, useSetRecoilState } from "recoil";
import { configStore } from "../stores/config";
import { gameStore } from "../stores/games";
import { gamesAutoManageModalStore } from "../stores/gamesAutomanageModal";
/* ------- */

class GamesAutomanageModal extends React.Component {
	componentDidMount() {
		this.props.setGamesAutoManageModal({ ...this.props.gamesAutoManageModal, currentOption: { ...this.props.gamesAutoManageModal.currentOption, type: this.props.config.game.options.automanageTypes[0] } });
	}

	manage() {
		this.props.setGamesAutoManageModal({ ...this.props.gamesAutoManageModal, managedInfo: false, loaded: false });
		this.props.Interface.addNoty("Info", "Wait for managed will be complete");

		this.props.Interface.request("set", { class: "data", module: "games", method: "autoManage" }, { type: this.props.gamesAutoManageModal.currentType })
			.then((data) => {
				this.props.Interface.addNoty("Success", "Manager was successfully processed");
				let managed = data,
					games = [];

				managed.forEach((item) => {
					games.push({ name: item.params.name, providerID: item.providerID, categoryID: item.categoryID });
				});

				this.props.setGamesAutoManageModal({ ...this.props.gamesAutoManageModal, loaded: true, managedInfo: games });
			})
			.catch((e) => {
				this.props.setGamesAutoManageModal({ ...this.props.gamesAutoManageModal, loaded: true });
				this.props.Interface.addNoty("Error", e.text);
				console.log(e);
			});
	}

	render() {
		return (
			<>
				<Modal
					visible={this.props.gamesAutoManageModal.show}
					onDismiss={() => {
						this.props.setGamesAutoManageModal({ ...this.props.gamesAutoManageModal, show: false });
					}}
					header="Lobby automanager"
					children={
						<>
							{this.props.gamesAutoManageModal.loaded ? (
								<SpaceBetween direction="vertical" size="m">
									<Select
										filteringType="auto"
										disabled={!this.props.game.loaded}
										placeholder="Manage type"
										selectedOption={this.props.gamesAutoManageModal.currentOption.type}
										onChange={(option) => {
											option.detail.selectedOption.type !== this.props.gamesAutoManageModal.currentType && this.props.setGamesAutoManageModal({ ...this.props.gamesAutoManageModal, currentType: option.detail.selectedOption.type, currentOption: { ...this.props.gamesAutoManageModal.currentOption, type: option.detail.selectedOption } });
										}}
										options={this.props.config.game.options.automanageTypes}
									/>
									{this.props.gamesAutoManageModal.managedInfo && (
										<Box
											margin={{ bottom: "m" }}
											children={
												<Alert
													type="info"
													header="Added games:"
													children={
														<>
															<Box variant="p">
																<SpaceBetween direction="vertical" size="s">
																	{this.props.gamesAutoManageModal.managedInfo.length
																		? this.props.gamesAutoManageModal.managedInfo.map((item) => (
																				<Box>
																					{item.name} ({item.providerID})
																				</Box>
																			))
																		: "No games was added"}
																</SpaceBetween>
															</Box>
															<hr />
															<Box variant="p" children={<>managed type: {this.props.gamesAutoManageModal.currentType}</>} />
														</>
													}
												/>
											}
										/>
									)}
								</SpaceBetween>
							) : (
								<Box variant="div" textAlign="center">
									<Spinner size="large" />
								</Box>
							)}
						</>
					}
					footer={
						<>
							<Box
								float="right"
								children={
									<>
										<Box
											variant="span"
											margin={{ right: "s" }}
											children={
												<Button
													variant="normal"
													onClick={() => {
														this.props.setGamesAutoManageModal({ ...this.props.gamesAutoManageModal, show: false });
													}}
													children="Close"
												/>
											}
										/>
										<Box
											variant="span"
											children={
												<Button
													variant="primary"
													onClick={() => {
														this.manage();
													}}
													children="Manage"
												/>
											}
										/>
									</>
								}
							/>
						</>
					}
				/>
			</>
		);
	}
}

function withRecoil(Component) {
	return function WrappedComponent(props) {
		const gamesAutoManageModal = useRecoilValue(gamesAutoManageModalStore);
		const setGamesAutoManageModal = useSetRecoilState(gamesAutoManageModalStore);
		const game = useRecoilValue(gameStore);
		const setGame = useSetRecoilState(gameStore);
		const config = useRecoilValue(configStore);

		return <Component {...props} gamesAutoManageModal={gamesAutoManageModal} setGamesAutoManageModal={setGamesAutoManageModal} game={game} setGame={setGame} config={config} />;
	};
}

export default withRecoil(GamesAutomanageModal);
