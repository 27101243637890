import React from "react";

import Flashbar from "@cloudscape-design/components/flashbar";
import Box from "@cloudscape-design/components/box";

/* Recoil */
import { useRecoilValue, useSetRecoilState } from "recoil";
import { notyStore } from "../stores/notys";
/* ------- */

class Notys extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div
				aria-live="polite"
				aria-atomic="true"
				style={{
					position: "fixed",
					top: "20px",
					right: "calc(50% - 160px)",
					zIndex: 2000000,
					width: "100%",
				}}>
				<div
					style={{
						position: "absolute",
						top: 0,
						right: 0,
						width: "320px",
						maxWidth: "320px",
					}}>
					{this.props.notys.data.map((item, id) => (
						<Box margin={{ bottom: "m" }} children={<Flashbar id={id} items={[{ header: item.name, content: item.text, type: item.name === "Error" ? "error" : item.name === "Success" ? "success" : "info" }]} />} />
					))}
				</div>
			</div>
		);
	}
}

function withRecoil(Component) {
	return function WrappedComponent(props) {
		const notys = useRecoilValue(notyStore);
		const setNotys = useSetRecoilState(notyStore);

		return <Component {...props} notys={notys} setNotys={setNotys} />;
	};
}

export default withRecoil(Notys);
