import { atom } from "recoil";

export const staffAddModalStore = atom({
	key: "staffAddModalStore",
	default: {
		show: false,
		loaded: true,
		name: "",
		password: "",
		level: 1,
	},
});
