import { atom } from "recoil";

export const bonusesAddModalStore = atom({
	key: "bonusesAddModalStore",
	default: {
		show: false,
		loaded: true,
		type: false,
		count: "",
		uid: "",
		value: "",
		note: "",
		checked: false,
	},
});
