import { atom } from "recoil";

export const bonusesStore = atom({
	key: "bonusesStore",
	default: {
		loaded: false,
		data: [],
		error: {
			text: "",
			code: 0,
			timestamp: 0,
		},
		currentOrder: "id",
		currentSearchType: "uid",
		currentDirection: "desc",
		currentSearch: "",
		selectedItems: [],
		sorting: {
			sortingColumn: {
				sortingField: "",
			},
			sortingDescending: false,
		},
		pagination: {
			current: 1,
			total: 0,
		},
		currentType: "promocodes",
		currentOption: {
			type: false,
			order: false,
			direction: false,
		},
	},
});
