import { atom } from "recoil";

export const staffEditModalStore = atom({
	key: "staffEditModalStore",
	default: {
		show: false,
		loaded: true,
		id: 0,
		level: 1,
	},
});
