import { atom } from "recoil";

function getPeriodConfig(period) {
	const now = new Date();
	const currentDay = now.getDay();
	const currentDate = now.getDate();
	const currentMonth = now.getMonth();
	const currentYear = now.getFullYear();

	const daysInPrevMonth = new Date(currentYear, currentMonth, 0).getDate();

	const daysSinceWeekStart = (currentDay + 6) % 7;

	switch (period) {
		case "This week":
			return { offset: 0, limit: daysSinceWeekStart + 1 };
		case "Prev week":
			return { offset: daysSinceWeekStart + 1, limit: 7 };
		case "This month":
			return { offset: 0, limit: currentDate };
		case "Prev month":
			return { offset: currentDate, limit: daysInPrevMonth };
		case "Half of year":
			return { offset: 0, limit: Math.floor((now - new Date(currentYear, 0, 1)) / (1000 * 60 * 60 * 24)) + 1 };
		case "This year":
			return { offset: 0, limit: Math.floor((now - new Date(currentYear, 0, 1)) / (1000 * 60 * 60 * 24)) + 1 };
		case "Prev year":
			return { offset: Math.floor((now - new Date(currentYear, 0, 1)) / (1000 * 60 * 60 * 24)) + 1, limit: 365 };
	}
}

export const configStore = atom({
	key: "configStore",
	default: {
		app: {
			name: JSON.parse(localStorage.getItem("access.brand")) ? JSON.parse(localStorage.getItem("access.brand")).name : "XPanel",
		},
		apiServlet: window.location.href.indexOf("127.0.0.1") !== -1 || window.location.href.indexOf("localhost") !== -1 ? "http://127.0.0.1:4444/v1/" : process.env.REACT_APP_API_SERVLET,
		paymentApi: {
			servlet: "https://private.ecorpay.net",
			id: JSON.parse(localStorage.getItem("access.brand")) ? JSON.parse(localStorage.getItem("access.brand")).ecorpayID : 0,
		},
		routerApi: {
			servlet: "https://elsikora-api.com",
			id: JSON.parse(localStorage.getItem("access.brand")) ? JSON.parse(localStorage.getItem("access.brand")).elsikoraID : 0,
		},
		updated: 1712350155000,
		version: "7.12",
		domain: "",
		game: {
			perPage: localStorage.getItem("games.perPage") || 15,
			preferences: {
				perPage: [
					{ value: 15, label: "15" },
					{ value: 30, label: "30" },
					{ value: 50, label: "50" },
					{ value: 100, label: "100" },
					{ value: 200, label: "200" },
				],
			},
			options: {
				integrator: [
					{ value: "All integrators", type: "all" },
					{ value: "SoftSwiss", type: "softswiss" },
					{ value: "QTech", type: "qtech" },
					{ value: "Slotegrator", type: "slotegrator" },
					{ value: "Endorphina", type: "endorphina" },
					{ value: "Infin", type: "infin" },
					{ value: "Softgamings", type: "softgamings" },
					{ value: "Internal", type: "internal" },
				],
				provider: [],
				category: [],
				status: [
					{ value: "All games", type: false },
					{ value: "Enabled", type: "enabled" },
					{ value: "Disabled", type: "disabled" },
				],
				automanageTypes: [
					{ value: "QTech", type: "qtech" },
					{ value: "Slotegrator", type: "slotegrator" },
					{ value: "Softswiss", type: "softswiss" },
					{ value: "Infin", type: "infin" },
					{ value: "Softgamings", type: "softgamings" },
				],
			},
		},
		staff: {
			perPage: localStorage.getItem("staff.perPage") || 10,
			preferences: {
				perPage: [
					{ value: 10, label: "10" },
					{ value: 30, label: "30" },
					{ value: 50, label: "50" },
					{ value: 100, label: "100" },
					{ value: 200, label: "200" },
				],
			},
			sessions: {
				perPage: localStorage.getItem("staff.sessions.perPage") || 15,
				preferences: {
					perPage: [
						{ value: 15, label: "15" },
						{ value: 30, label: "30" },
						{ value: 50, label: "50" },
						{ value: 100, label: "100" },
						{ value: 200, label: "200" },
					],
				},
			},
			log: {
				perPage: localStorage.getItem("staff.log.perPage") || 15,
				preferences: {
					perPage: [
						{ value: 15, label: "15" },
						{ value: 30, label: "30" },
						{ value: 50, label: "50" },
						{ value: 100, label: "100" },
						{ value: 200, label: "200" },
					],
				},
			},
		},
		domains: {
			perPage: localStorage.getItem("domains.perPage") || 10,
			preferences: {
				perPage: [
					{ value: 10, label: "10" },
					{ value: 30, label: "30" },
					{ value: 50, label: "50" },
					{ value: 100, label: "100" },
					{ value: 200, label: "200" },
				],
			},
			options: {
				type: [
					{ value: "Project domain", type: "domain" },
					{ value: "Mirror domain", type: "mirror" },
					{ value: "Banned domain", type: "ban" },
					{ value: "Current project domain", type: "current" },
					{ value: "Current mirror domain", type: "mirrorCurrent" },
					{ value: "PWA domain", type: "pwa" },
					{ value: "Current PWA domain", type: "pwaCurrent" },
				],
			},
		},
		players: {
			perPage: localStorage.getItem("players.perPage") || 15,
			preferences: {
				perPage: [
					{ value: 15, label: "15" },
					{ value: 30, label: "30" },
					{ value: 50, label: "50" },
					{ value: 100, label: "100" },
					{ value: 200, label: "200" },
				],
			},
			options: {
				type: [
					{ value: "By UID", type: "uid" },
					{ value: "By casinoID", type: "casinoid" },
					{ value: "By IP", type: "ip" },
					{ value: "By refer", type: "refer" },
					{ value: "By misc", type: "misc" },
					{ value: "By social account", type: "social" },
				],
				order: [
					{ value: "By balance", type: "balance" },
					{ value: "By loyalty balance", type: "balance_c" },
					{ value: "By partner balance", type: "balance_r" },
					{ value: "TOP 500 depositors", type: "deposits" },
					{ value: "TOP 500 withdrawals", type: "withdrawals" },
				],
				direction: [
					{ value: "Descending", type: "desc" },
					{ value: "Ascending", type: "asc" },
				],
			},
			log: {
				perPage: localStorage.getItem("players.log.perPage") || 50,
				preferences: {
					perPage: [
						{ value: 15, label: "15" },
						{ value: 30, label: "30" },
						{ value: 50, label: "50" },
						{ value: 100, label: "100" },
						{ value: 200, label: "200" },
					],
				},
			},
			gameLog: {
				perPage: localStorage.getItem("players.gameLog.perPage") || 15,
				preferences: {
					perPage: [
						{ value: 15, label: "15" },
						{ value: 30, label: "30" },
						{ value: 50, label: "50" },
						{ value: 100, label: "100" },
						{ value: 200, label: "200" },
					],
				},
			},
			gameLogShort: {
				perPage: localStorage.getItem("players.gameLog.short.perPage") || 5,
				preferences: {
					perPage: [
						{ value: 5, label: "5" },
						{ value: 15, label: "15" },
						{ value: 30, label: "30" },
					],
				},
			},
			payments: {
				perPage: localStorage.getItem("players.payments.perPage") || 15,
				preferences: {
					perPage: [
						{ value: 15, label: "15" },
						{ value: 30, label: "30" },
						{ value: 50, label: "50" },
						{ value: 100, label: "100" },
						{ value: 200, label: "200" },
					],
				},
			},
		},
		withdrawals: {
			perPage: localStorage.getItem("withdrawals.perPage") || 15,
			preferences: {
				perPage: [
					{ value: 15, label: "15" },
					{ value: 30, label: "30" },
					{ value: 50, label: "50" },
					{ value: 100, label: "100" },
					{ value: 200, label: "200" },
				],
			},
			buttons: localStorage.getItem("withdrawals.buttons") ? JSON.parse(localStorage.getItem("withdrawals.buttons")) : [],
		},
		bonuses: {
			perPage: localStorage.getItem("bonuses.perPage") || 15,
			preferences: {
				perPage: [
					{ value: 15, label: "15" },
					{ value: 30, label: "30" },
					{ value: 50, label: "50" },
					{ value: 100, label: "100" },
					{ value: 200, label: "200" },
				],
			},
			options: {
				type: [
					{ value: "Public", type: 0 },
					{ value: "Affiliative", type: 1 },
					{ value: "Dedicated", type: 2 },
				],
			},
		},
		stats: {
			options: {
				period: [
					{ value: "Today", type: { offset: 0, limit: 1 } },
					{ value: "Yesterday", type: { offset: 1, limit: 1 } },
					{ value: "This week", type: getPeriodConfig("This week") },
					{ value: "Prev week", type: getPeriodConfig("Prev week") },
					{ value: "This month", type: getPeriodConfig("This month") },
					{ value: "Prev month", type: getPeriodConfig("Prev month") },
					{ value: "Half of year", type: getPeriodConfig("Half of year") },
					{ value: "This year", type: getPeriodConfig("This year") },
					{ value: "Prev year", type: getPeriodConfig("Prev year") },
				],
				delimiter: [
					{ value: "Per day", type: 1 },
					{ value: "Per week", type: 7 },
					{ value: "Per month", type: 30 },
					{ value: "Per year", type: 365 },
				],
				delimiterFull: [
					{ value: "Per 5 minutes", type: 300 },
					{ value: "Per 15 minutes", type: 900 },
					{ value: "Per 1 hour", type: 3600 },
					{ value: "Per 1 day", type: 86400 },
				],
				delimiterProvider: [
					{ value: "Per 1 hour", type: "hour" },
					{ value: "Per day", type: 1 },
					{ value: "Per week", type: 7 },
					{ value: "Per month", type: 30 },
					{ value: "Per year", type: 365 },
				],
				payway: [],
			},
		},
		pstats: {
			options: {
				period: [
					{ value: "Today", type: { offset: 0, limit: 1 } },
					{ value: "Yesterday", type: { offset: 1, limit: 1 } },
					{ value: "This week", type: getPeriodConfig("This week") },
					{ value: "Prev week", type: getPeriodConfig("Prev week") },
					{ value: "This month", type: getPeriodConfig("This month") },
					{ value: "Prev month", type: getPeriodConfig("Prev month") },
					{ value: "Half of year", type: getPeriodConfig("Half of year") },
					{ value: "This year", type: getPeriodConfig("This year") },
					{ value: "Prev year", type: getPeriodConfig("Prev year") },
				],
				delimiter: [
					{ value: "Per day", type: 1 },
					{ value: "Per week", type: 7 },
					{ value: "Per month", type: 30 },
					{ value: "Per year", type: 365 },
				],
				payway: [],
			},
		},
		reports: {
			options: {
				currency: [
					{ value: "RUB", type: "RUB" },
					{ value: "USD", type: "USD" },
					{ value: "EUR", type: "EUR" },
				],
				group: [
					{ type: "all", value: "All groups" },
					{ type: "providers", value: "Providers" },
					{ type: "infrastructure", value: "Infrastructure" },
					{ type: "marketing", value: "Marketing" },
					{ type: "laws", value: "Laws" },
					{ type: "service", value: "Service" },
					{ type: "outsource", value: "Outsource" },
					{ type: "domains", value: "Domains" },
					{ type: "fee", value: "Fee" },
					{ type: "vacancy", value: "Vacancy" },
					{ type: "resume", value: "Resume" },
					{ type: "meetings", value: "Meetings" },
					{ type: "invests", value: "Invests" },
					{ type: "businessTrips", value: "Business trips" },
					{ type: "equipment", value: "Equipment" },
					{ type: "training", value: "Training" },
					{ type: "links", value: "Links" },
					{ type: "salaries", value: "Salaries" },
					{ type: "streamers", value: "Streamers" },
					{ type: "testDeposits", value: "Test deposits" },
					{ type: "testEmployees", value: "Test Employees" },
					{ type: "advertisment", value: "Advertisment" },
					{ type: "commission", value: "Commission" },
					{ type: "license", value: "License" },
					{ type: "drops", value: "Drops" },
					{ type: "other", value: "Other" },
				],
			},
		},
	},
});
