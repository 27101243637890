import React from "react";

import Modal from "@cloudscape-design/components/modal";
import Input from "@cloudscape-design/components/input";
import Button from "@cloudscape-design/components/button";
import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Spinner from "@cloudscape-design/components/spinner";
import Toggle from "@cloudscape-design/components/toggle";

/* Recoil */
import { useRecoilValue, useSetRecoilState } from "recoil";
import { configStore } from "../stores/config";
import { staffEditModalStore } from "../stores/staffEditModal";
import { userStore } from "../stores/access";
import { staffStore } from "../stores/staff";
/* ------- */

class StaffEditUserModal extends React.Component {
	editUser() {
		this.props.setStaffEditModal({ ...this.props.staffEditModal, loaded: false });

		this.props.Interface.request("set", { class: "settings", module: "staff", method: "access" }, { uid: this.props.staffEditModal.id, level: parseInt(this.props.staffEditModal.level) })
			.then(() => {
				this.props.Interface.addNoty("Success", "User `" + this.props.staff.selectedItems[0].user + "` was successfully granted with level " + this.props.staffEditModal.level);
				this.props.setStaffEditModal({ ...this.props.staffEditModal, checked: false, loaded: true, show: false, id: 0, level: 1 });

				this.props.setStaff({ ...this.props.staff, selectedItems: [], loaded: false });

				this.props.Interface.request("get", { class: "data", module: "info", method: "staff" })
					.then((data) => {
						this.props.setStaff({
							...this.props.staff,
							data,
							loaded: true,
							pagination: { current: 1, total: Math.ceil(data.length / this.props.config.staff.perPage) },
							sorting: {
								sortingColumn: {
									sortingField: "",
								},
								sortingDescending: false,
							},
						});
					})
					.catch((e) => {
						this.props.setStaff({ ...this.props.staff, loaded: true, error: e });
					});
			})
			.catch((e) => {
				this.props.setStaffEditModal({ ...this.props.staffEditModal, checked: true, loaded: true });
				console.log(e);
			});
	}

	render() {
		return (
			<>
				<Modal
					visible={this.props.staffEditModal.show}
					onDismiss={() => {
						this.props.setStaffEditModal({ ...this.props.staffEditModal, checked: false, name: "", password: "", level: 1, show: false });
					}}
					header="Edit user"
					children={
						<>
							<SpaceBetween direction="vertical" size="m">
								{this.props.staffEditModal.loaded ? (
									<>
										<Box variant="span">uid: </Box>
										<Input disabled={true} value={this.props.staffEditModal.id} />
										<Box variant="span">level: </Box>
										<Input
											disabled={!this.props.staffEditModal.level}
											inputMode="numeric"
											type="number"
											disableBrowserAutocorrect={true}
											placeholder="access level"
											value={this.props.staffEditModal.level}
											onChange={(input) => {
												this.props.setStaffEditModal({ ...this.props.staffEditModal, level: input.detail.value > this.props.user.level - 1 ? this.props.user.level - 1 : input.detail.value < 1 ? 1 : input.detail.value });
											}}
										/>
										<Toggle
											checked={!this.props.staffEditModal.level}
											onChange={(checkbox) => {
												this.props.setStaffEditModal({ ...this.props.staffEditModal, level: checkbox.detail.checked ? 0 : 1 });
											}}>
											disable access
										</Toggle>
									</>
								) : (
									<Box variant="div" textAlign="center">
										<Spinner size="large" />
									</Box>
								)}
							</SpaceBetween>
						</>
					}
					footer={
						<>
							<Box
								float="right"
								children={
									<>
										<Box
											variant="span"
											margin={{ right: "s" }}
											children={
												<Button
													variant="normal"
													onClick={() => {
														this.props.setStaffEditModal({ ...this.props.staffEditModal, show: false });
													}}
													children="Close"
												/>
											}
										/>
										<Box
											variant="span"
											children={
												<Button
													variant="primary"
													onClick={() => {
														this.editUser();
													}}
													children="Ok"
												/>
											}
										/>
									</>
								}
							/>
						</>
					}
				/>
			</>
		);
	}
}

function withRecoil(Component) {
	return function WrappedComponent(props) {
		const staff = useRecoilValue(staffStore);
		const setStaff = useSetRecoilState(staffStore);
		const staffEditModal = useRecoilValue(staffEditModalStore);
		const setStaffEditModal = useSetRecoilState(staffEditModalStore);
		const config = useRecoilValue(configStore);
		const user = useRecoilValue(userStore);

		return <Component {...props} staff={staff} setStaff={setStaff} user={user} staffEditModal={staffEditModal} setStaffEditModal={setStaffEditModal} config={config} />;
	};
}

export default withRecoil(StaffEditUserModal);
