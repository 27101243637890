import React from "react";

import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

import Modal from "@cloudscape-design/components/modal";
import Container from "@cloudscape-design/components/container";
import Button from "@cloudscape-design/components/button";
import Box from "@cloudscape-design/components/box";
import Select from "@cloudscape-design/components/select";
import Grid from "@cloudscape-design/components/grid";
import Header from "@cloudscape-design/components/header";
import LineChart from "@cloudscape-design/components/line-chart";
import Tabs from "@cloudscape-design/components/tabs";
import Alert from "@cloudscape-design/components/alert";
import RangePicker from "@cloudscape-design/components/date-range-picker";
import Table from "@cloudscape-design/components/table";

/* Recoil */
import { useRecoilValue, useSetRecoilState } from "recoil";
import { configStore } from "../stores/config";
import { gameStore } from "../stores/games";
import { gamesStatsModalStore } from "../stores/gamesStatsModal";
/* ------- */

class GamesStatsModal extends React.Component {
	componentDidUpdate(prevProps) {
		if (prevProps.gamesStatsModal.gameID !== this.props.gamesStatsModal.gameID) {
			let currentPeriod = this.props.config.stats.options.period[4].type;

			setTimeout(() => {
				this.props.setGamesStatsModal({
					...this.props.gamesStatsModal,
					loaded: false,
					data: [],
					limit: currentPeriod.limit,
					offset: currentPeriod.offset,
					range: { type: "absolute", startDate: this.formatLimitOffset(currentPeriod.limit, currentPeriod.offset).from, endDate: this.formatLimitOffset(this.props.gamesStatsModal.limit, this.props.gamesStatsModal.offset).to },
					currentGroup: this.props.config.stats.options.delimiter[0].type,
					currentOption: { ...this.props.gamesStatsModal.currentOption, group: this.props.config.stats.options.delimiter[0], period: this.props.config.stats.options.period[4] },
				});
			}, 0);

			setTimeout(() => {
				this.loadStats();
			}, 100);
		}
	}

	loadStats() {
		if (this.props.gamesStatsModal.limit) {
			this.props.setGamesStatsModal({ ...this.props.gamesStatsModal, loaded: false, data: [] });

			this.props.Interface.request("get", { class: "data", module: "games", method: "stats" }, { offset: this.props.gamesStatsModal.offset, limit: this.props.gamesStatsModal.limit, gameID: this.props.gamesStatsModal.gameID || "all" })
				.then((rawData) => {
					this.rawData = rawData;
					this.rebuildStats();
				})
				.catch((e) => {
					this.props.setGamesStatsModal({ ...this.props.gamesStatsModal, data: [], chart: { bets: { total: [], count: [] }, wins: { total: [], count: [] }, rtp: [] }, loaded: true, error: e });
				});
		}
	}

	rebuildStats() {
		let chart = { bets: { total: [], count: [] }, wins: { total: [], count: [] }, rtp: [] },
			totalColumn = { days: 0, bets: { total: 0, count: 0 }, wins: { total: 0, count: 0 }, rtp: 0 };
		let data = [],
			j = 0;

		let rawData = this.rawData;

		rawData.reverse().forEach((stat, i) => {
			stat.rtp = parseFloat(((stat.wins.total / stat.bets.total) * 100).toFixed(2));
			if (isNaN(stat.rtp)) stat.rtp = 0;

			if (i && i % this.props.gamesStatsModal.currentGroup === 0) j++;

			if (typeof data[j] === "undefined") data[j] = { bets: { total: 0, count: 0 }, wins: { total: 0, count: 0 }, rtp: 0, days: 0, date: stat.date };

			if (stat.rtp) data[j].days++;

			data[j].bets.total += parseFloat(parseFloat(stat.bets.total / 100).toFixed(2));
			data[j].bets.count += stat.bets.count;
			data[j].wins.total += parseFloat(parseFloat(stat.wins.total / 100).toFixed(2));
			data[j].wins.count += stat.wins.count;

			data[j].rtp += stat.rtp;

			data[j].dateEnd = stat.date;
		});

		data.forEach((stat) => {
			let formattedRangeDate = new Date(stat.date).toLocaleDateString().indexOf("/") !== -1 ? new Date(stat.date).toLocaleDateString().split("/").slice(0, -1).join("/") + " - " + new Date(stat.dateEnd).toLocaleDateString().split("/").slice(0, -1).join("/") : new Date(stat.date).toLocaleDateString().split(".").slice(0, -1).join(".") + " - " + new Date(stat.dateEnd).toLocaleDateString().split(".").slice(0, -1).join(".");

			totalColumn.bets.total += stat.bets.total;
			totalColumn.bets.count += stat.bets.count;
			totalColumn.wins.total += stat.wins.total;
			totalColumn.wins.count += stat.wins.count;
			totalColumn.rtp += stat.rtp;
			totalColumn.days += stat.days;

			if (typeof stat.days !== "undefined" && stat.days) stat.rtp = parseFloat((stat.rtp / stat.days).toFixed(2));

			chart.bets.total.push({ x: typeof stat.dateEnd === "undefined" || stat.date === stat.dateEnd ? new Date(stat.date).toLocaleDateString() : formattedRangeDate, y: stat.bets.total });
			chart.bets.count.push({ x: typeof stat.dateEnd === "undefined" || stat.date === stat.dateEnd ? new Date(stat.date).toLocaleDateString() : formattedRangeDate, y: stat.bets.count });

			chart.wins.total.push({ x: typeof stat.dateEnd === "undefined" || stat.date === stat.dateEnd ? new Date(stat.date).toLocaleDateString() : formattedRangeDate, y: stat.wins.total });
			chart.wins.count.push({ x: typeof stat.dateEnd === "undefined" || stat.date === stat.dateEnd ? new Date(stat.date).toLocaleDateString() : formattedRangeDate, y: stat.wins.count });

			chart.rtp.push({ x: typeof stat.dateEnd === "undefined" || stat.date === stat.dateEnd ? new Date(stat.date).toLocaleDateString() : formattedRangeDate, y: stat.rtp });
		});

		totalColumn.rtp = parseFloat(totalColumn.rtp / totalColumn.days).toFixed(2);

		totalColumn.date = "Total";

		data.reverse().push(totalColumn);
		this.rawData.reverse();

		this.props.setGamesStatsModal({ ...this.props.gamesStatsModal, data, chart, loaded: true });
	}

	compare(a, b) {
		if (this.props.gamesStatsModal.sorting.sortingColumn.sortingField.indexOf(".") === -1) {
			if (a[this.props.gamesStatsModal.sorting.sortingColumn.sortingField] < b[this.props.gamesStatsModal.sorting.sortingColumn.sortingField]) {
				return this.props.gamesStatsModal.sorting.isDescending ? 1 : -1;
			}

			if (a[this.props.gamesStatsModal.sorting.sortingColumn.sortingField] > b[this.props.gamesStatsModal.sorting.sortingColumn.sortingField]) {
				return this.props.gamesStatsModal.sorting.isDescending ? -1 : 1;
			}
		} else {
			if (a[this.props.gamesStatsModal.sorting.sortingColumn.sortingField.split(".")[0]][this.props.gamesStatsModal.sorting.sortingColumn.sortingField.split(".")[1]] < b[this.props.gamesStatsModal.sorting.sortingColumn.sortingField.split(".")[0]][this.props.gamesStatsModal.sorting.sortingColumn.sortingField.split(".")[1]]) {
				return this.props.gamesStatsModal.sorting.isDescending ? 1 : -1;
			}

			if (a[this.props.gamesStatsModal.sorting.sortingColumn.sortingField.split(".")[0]][this.props.gamesStatsModal.sorting.sortingColumn.sortingField.split(".")[1]] > b[this.props.gamesStatsModal.sorting.sortingColumn.sortingField.split(".")[0]][this.props.gamesStatsModal.sorting.sortingColumn.sortingField.split(".")[1]]) {
				return this.props.gamesStatsModal.sorting.isDescending ? -1 : 1;
			}
		}

		return 0;
	}

	formatRange(startDate, stopDate) {
		startDate = startDate.split("-").join("/");
		stopDate = stopDate.split("-").join("/");

		let limit = 0,
			offset = [],
			currentDate = +new Date(startDate);

		while (currentDate <= +new Date(stopDate)) {
			currentDate = +new Date(currentDate).setDate(new Date(currentDate).getDate() + 1);
			limit++;
		}

		offset = (+new Date(new Date().getFullYear() + "/" + (new Date().getMonth() + 1) + "/" + new Date().getDate()) - +new Date(stopDate)) / 1000 / 60 / 60 / 24;
		return { limit, offset };
	}

	formatLimitOffset(limit, offset) {
		let startDate = +new Date().setDate(new Date().getDate() - offset),
			endDate = +new Date().setDate(new Date().getDate() - offset - limit + 1);
		return { from: new Date(endDate).toLocaleDateString().split("/").reverse().join("-"), to: new Date(startDate).toLocaleDateString().split("/").reverse().join("-") };
	}

	render() {
		return (
			<>
				<Modal
					size="max"
					visible={this.props.gamesStatsModal.show}
					onDismiss={() => {
						this.props.setGamesStatsModal({ ...this.props.gamesStatsModal, show: false });
					}}
					header="Game stats"
					children={
						<>
							{this.props.gamesStatsModal.loaded && !this.props.gamesStatsModal.data.length && this.props.gamesStatsModal.error.text.length ? (
								<Box
									margin={{ bottom: "m" }}
									children={
										<Alert
											type="error"
											header="Error loading data:"
											children={
												<>
													<Box variant="p" children={this.props.gamesStatsModal.error.text} />
													<hr />
													<Box
														variant="p"
														children={
															<>
																request error code: {this.props.gamesStatsModal.error.code} | request timestamp: {this.props.gamesStatsModal.error.timestamp}
															</>
														}
													/>
												</>
											}
										/>
									}
								/>
							) : (
								<></>
							)}

							<Container
								header={
									<Box
										margin={{ bottom: "m" }}
										children={
											<Header
												children="Game stats"
												counter={<>({this.props.gamesStatsModal.data.length ? this.props.gamesStatsModal.data.length - 1 : 0})</>}
												actions={
													<Button
														disabled={!this.props.gamesStatsModal.loaded}
														className={this.props.styles["players-search-button"]}
														onClick={() => {
															setTimeout(() => {
																this.rebuildStats();
															}, 0);
														}}
														variant="primary"
														children="Load stats"
													/>
												}
												description="Below listed stats for selected date"
											/>
										}
									/>
								}
								children={
									<>
										<Grid
											gridDefinition={[{ colspan: 12 }, { colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}
											children={
												<>
													<RangePicker
														disabled={!this.props.gamesStatsModal.loaded}
														relativeOptions={[]}
														clearButtonLabel="Clear"
														dateOnly={true}
														placeholder="Select range for show stats"
														onChange={(range) => {
															this.props.setGamesStatsModal({ ...this.props.gamesStatsModal, ...this.formatRange(range.detail.value.startDate, range.detail.value.endDate), range: range.detail.value });
															setTimeout(() => {
																this.loadStats();
															}, 250);
														}}
														value={this.props.gamesStatsModal.range}
														i18nStrings={{
															customRelativeRangeUnitLabel: "",
															formatRelativeRange: (value) => {
																return value.amount;
															},
															formatUnit: () => {
																return "";
															},
															nextMonthAriaLabel: "Next",
															applyButtonLabel: "Ok",
														}}
													/>
													<Select
														filteringType="auto"
														disabled={!this.props.gamesStatsModal.loaded}
														placeholder="Group by"
														selectedOption={this.props.gamesStatsModal.currentOption.group}
														onChange={(option) => {
															option.detail.selectedOption.type !== this.props.gamesStatsModal.currentGroup && this.props.setGamesStatsModal({ ...this.props.gamesStatsModal, currentGroup: option.detail.selectedOption.type, currentOption: { ...this.props.gamesStatsModal.currentOption, group: option.detail.selectedOption } });
															setTimeout(() => {
																this.rebuildStats();
															}, 0);
														}}
														options={this.props.config.stats.options.delimiter}
													/>

													<Select
														filteringType="auto"
														disabled={!this.props.gamesStatsModal.loaded}
														placeholder="Period"
														selectedOption={this.props.gamesStatsModal.currentOption.period}
														onChange={(option) => {
															!isEqual(option.detail.selectedOption.type, this.props.gamesStatsModal.currentPeriod) &&
																this.props.setGamesStatsModal({
																	...this.props.gamesStatsModal,
																	currentPeriod: option.detail.selectedOption.type,
																	currentOption: { ...this.props.gamesStatsModal.currentOption, period: option.detail.selectedOption },
																	limit: option.detail.selectedOption.type.limit,
																	offset: option.detail.selectedOption.type.offset,
																	range: { type: "absolute", startDate: this.formatLimitOffset(option.detail.selectedOption.type.limit, option.detail.selectedOption.type.offset).from, endDate: this.formatLimitOffset(option.detail.selectedOption.type.limit, option.detail.selectedOption.type.offset).to },
																});
															setTimeout(() => {
																this.loadStats();
															}, 0);
														}}
														options={this.props.config.stats.options.period}
													/>
												</>
											}
										/>
										<Tabs
											tabs={[
												{
													id: "table",
													label: "Table view",
													content: (
														<Table
															stickyHeader={true}
															loading={!this.props.gamesStatsModal.loaded}
															sortingColumn={{ sortingField: this.props.gamesStatsModal.sorting.sortingColumn.sortingField }}
															sortingDescending={this.props.gamesStatsModal.sorting.isDescending}
															onSortingChange={(sorting) => {
																this.props.setGamesStatsModal({ ...this.props.gamesStatsModal, sorting: sorting.detail });
																setTimeout(() => {
																	this.props.setGamesStatsModal({
																		...this.props.gamesStatsModal,
																		data: cloneDeep(this.props.gamesStatsModal.data).sort((a, b) => {
																			return this.compare(a, b);
																		}),
																	});
																}, 0);
															}}
															columnDefinitions={[
																{ id: "date", header: "Date", sortingField: "date", cell: (item) => (typeof item.date === "string" ? <b>{item.date}</b> : typeof item.dateEnd !== "undefined" && item.date !== item.dateEnd ? new Date(item.date).toLocaleDateString() + " - " + new Date(item.dateEnd).toLocaleDateString() : new Date(item.date).toLocaleDateString()) },
																{ id: "betsTotal", header: "Bets total", sortingField: "bets.total", cell: (item) => new Intl.NumberFormat("ru-RU").format(item.bets.total) + " RUB." },
																{ id: "winsTotal", header: "Wins total", sortingField: "wins.total", cell: (item) => new Intl.NumberFormat("ru-RU").format(item.wins.total) + " RUB." },
																{ id: "Bets count", header: "Bets count", sortingField: "bets.count", cell: (item) => item.bets.count },
																{ id: "winsCount", header: "Wins count", sortingField: "wins.count", cell: (item) => item.wins.count },
																{ id: "rtp", header: "RTP", sortingField: "rtp", cell: (item) => <Box variant="span" color={item.rtp < 98 ? (item.rtp < 85 ? "text-status-success" : "text-body-secondary") : "text-status-error"} children={item.rtp + "%"} /> },
															]}
															empty="No stats was loaded"
															items={this.props.gamesStatsModal.data}
														/>
													),
												},
												{
													id: "chart",
													label: "Chart view",
													content: (
														<Grid
															gridDefinition={[{ colspan: 12 }, { colspan: 12 }, { colspan: 12 }, { colspan: 12 }, { colspan: 12 }]}
															children={
																<>
																	<Grid
																		gridDefinition={[{ colspan: { default: 12, m: 6 } }, { colspan: { default: 12, m: 6 } }]}
																		children={
																			<>
																				<Container
																					header={<Header children={"Bets total chart (" + (this.props.gamesStatsModal.data.length ? new Intl.NumberFormat("ru-RU").format(this.props.gamesStatsModal.data[this.props.gamesStatsModal.data.length - 1].bets.total) : 0) + " RUB.)"} />}
																					children={<LineChart hideLegend={true} hideFilter={true} statusType={this.props.gamesStatsModal.loaded && typeof this.props.gamesStatsModal.chart.bets !== "undefined" ? "finished" : "loading"} yScaleType="linear" xScaleType="categorical" series={[{ title: "Bets total", type: "bar", data: this.props.gamesStatsModal.chart.bets.total }]} errorText="Error building chart" />}
																				/>
																				<Container
																					header={<Header children={"Bets count chart (" + (this.props.gamesStatsModal.data.length ? this.props.gamesStatsModal.data[this.props.gamesStatsModal.data.length - 1].bets.count : 0) + ")"} />}
																					children={<LineChart hideLegend={true} hideFilter={true} statusType={this.props.gamesStatsModal.loaded && typeof this.props.gamesStatsModal.chart.bets !== "undefined" ? "finished" : "loading"} yScaleType="linear" xScaleType="categorical" series={[{ title: "Bets count", color: "#1f8205", type: "line", data: this.props.gamesStatsModal.chart.bets.count }]} errorText="Error building chart" />}
																				/>
																			</>
																		}
																	/>

																	<Grid
																		gridDefinition={[{ colspan: { default: 12, m: 6 } }, { colspan: { default: 12, m: 6 } }]}
																		children={
																			<>
																				<Container
																					header={<Header children={"Wins total chart (" + (this.props.gamesStatsModal.data.length ? new Intl.NumberFormat("ru-RU").format(this.props.gamesStatsModal.data[this.props.gamesStatsModal.data.length - 1].wins.total) : 0) + " RUB.)"} />}
																					children={<LineChart hideLegend={true} hideFilter={true} statusType={this.props.gamesStatsModal.loaded && typeof this.props.gamesStatsModal.chart.wins !== "undefined" ? "finished" : "loading"} yScaleType="linear" xScaleType="categorical" series={[{ title: "Wins total", type: "bar", data: this.props.gamesStatsModal.chart.wins.total }]} errorText="Error building chart" />}
																				/>
																				<Container
																					header={<Header children={"Wins count chart (" + (this.props.gamesStatsModal.data.length ? this.props.gamesStatsModal.data[this.props.gamesStatsModal.data.length - 1].wins.count : 0) + ")"} />}
																					children={<LineChart hideLegend={true} hideFilter={true} statusType={this.props.gamesStatsModal.loaded && typeof this.props.gamesStatsModal.chart.wins !== "undefined" ? "finished" : "loading"} yScaleType="linear" xScaleType="categorical" series={[{ title: "Wins count", color: "#1f8205", type: "line", data: this.props.gamesStatsModal.chart.wins.count }]} errorText="Error building chart" />}
																				/>
																			</>
																		}
																	/>

																	<Grid
																		gridDefinition={[{ colspan: 12 }]}
																		children={
																			<>
																				<Container
																					header={<Header children={"RTP chart (" + (this.props.gamesStatsModal.data.length ? this.props.gamesStatsModal.data[this.props.gamesStatsModal.data.length - 1].rtp : 0) + ")"} />}
																					children={<LineChart hideLegend={true} hideFilter={true} statusType={this.props.gamesStatsModal.loaded && typeof this.props.gamesStatsModal.chart.rtp !== "undefined" ? "finished" : "loading"} yScaleType="linear" xScaleType="categorical" series={[{ title: "RTP", color: "#1f8205", type: "line", data: this.props.gamesStatsModal.chart.rtp }]} errorText="Error building chart" />}
																				/>
																			</>
																		}
																	/>
																</>
															}
														/>
													),
												},
											]}
										/>
									</>
								}
							/>
						</>
					}
					footer={
						<>
							<Box
								float="right"
								children={
									<>
										<Box
											variant="span"
											margin={{ right: "s" }}
											children={
												<Button
													variant="normal"
													onClick={() => {
														this.props.setGamesStatsModal({ ...this.props.gamesStatsModal, show: false });
													}}
													children="Close"
												/>
											}
										/>
										<Box
											variant="span"
											children={
												<Button
													variant="primary"
													onClick={() => {
														this.addGame();
													}}
													children="Ok"
												/>
											}
										/>
									</>
								}
							/>
						</>
					}
				/>
			</>
		);
	}
}

function withRecoil(Component) {
	return function WrappedComponent(props) {
		const gamesStatsModal = useRecoilValue(gamesStatsModalStore);
		const setGamesStatsModal = useSetRecoilState(gamesStatsModalStore);
		const game = useRecoilValue(gameStore);
		const setGame = useSetRecoilState(gameStore);
		const config = useRecoilValue(configStore);

		return <Component {...props} gamesStatsModal={gamesStatsModal} setGamesStatsModal={setGamesStatsModal} game={game} setGame={setGame} config={config} />;
	};
}

export default withRecoil(GamesStatsModal);
