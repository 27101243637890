import React from "react";

import cloneDeep from "lodash/cloneDeep";

import Table from "@cloudscape-design/components/table";
import Box from "@cloudscape-design/components/box";
import Header from "@cloudscape-design/components/header";
import Link from "@cloudscape-design/components/link";
import Alert from "@cloudscape-design/components/alert";
import Button from "@cloudscape-design/components/button";
import Pagination from "@cloudscape-design/components/pagination";

/* Recoil */
import { useRecoilValue, useSetRecoilState } from "recoil";
import { staffStore } from "../stores/staff";
import { staffSessionsModalStore } from "../stores/staffSessionsModal";
import { configStore } from "../stores/config";
import { staffAddModalStore } from "../stores/staffAddModal";
import { SpaceBetween } from "@cloudscape-design/components";
import { staffEditModalStore } from "../stores/staffEditModal";
import { userStore } from "../stores/access";
/* ------- */

class Staff extends React.Component {
	constructor(props) {
		super(props);
		this.loadStaff();
	}

	loadSessions(uid) {
		this.props.setStaffSessions({ ...this.props.staffSessions, uid, show: true });
		this.props.Interface.request("get", { class: "data", module: "staff", method: "sessions" }, { uid })
			.then((data) => {
				this.props.setStaffSessions({ ...this.props.staffSessions, data: data.reverse(), pagination: { current: 1, total: Math.ceil(data.length / this.props.config.staff.sessions.perPage) }, loaded: true });
			})
			.catch((e) => {
				this.props.setStaffSessions({ ...this.props.staffSessions, loaded: true, error: e });
			});
	}

	loadStaff() {
		this.props.setStaff({ ...this.props.staff, loaded: false });

		this.props.Interface.request("get", { class: "data", module: "info", method: "staff" })
			.then((data) => {
				this.props.setStaff({ ...this.props.staff, data, loaded: true, pagination: { current: 1, total: Math.ceil(data.length / this.props.config.staff.perPage) } });
			})
			.catch((e) => {
				this.props.setStaff({ ...this.props.staff, loaded: true, error: e });
			});
	}

	compare(a, b) {
		if (a[this.props.staff.sorting.sortingColumn.sortingField] < b[this.props.staff.sorting.sortingColumn.sortingField]) {
			return this.props.staff.sorting.isDescending ? 1 : -1;
		}

		if (a[this.props.staff.sorting.sortingColumn.sortingField] > b[this.props.staff.sorting.sortingColumn.sortingField]) {
			return this.props.staff.sorting.isDescending ? -1 : 1;
		}

		return 0;
	}

	render() {
		return (
			<>
				{this.props.staff.loaded && this.props.staff.error.text.length ? (
					<Box
						margin={{ bottom: "m" }}
						children={
							<Alert
								type="error"
								header="Error loading data:"
								children={
									<>
										<Box variant="p" children={this.props.staff.error.text} />
										<hr />
										<Box
											variant="p"
											children={
												<>
													request error code: {this.props.staff.error.code} | request timestamp: {this.props.staff.error.timestamp}
												</>
											}
										/>
									</>
								}
							/>
						}
					/>
				) : (
					<></>
				)}

				<Table
					isItemDisabled={(item) => {
						return item.level >= this.props.user.level;
					}}
					pagination={
						<Pagination
							disabled={!this.props.staff.loaded}
							currentPageIndex={this.props.staff.pagination.current}
							pagesCount={this.props.staff.pagination.total}
							onChange={(page) => {
								this.props.setStaff({ ...this.props.staff, pagination: { ...this.props.staff.pagination, current: page.detail.currentPageIndex } });
							}}
						/>
					}
					onSortingChange={(sorting) => {
						this.props.setStaff({ ...this.props.staff, sorting: sorting.detail });
						setTimeout(() => {
							this.props.setStaff({
								...this.props.staff,
								data: cloneDeep(this.props.staff.data).sort((a, b) => {
									return this.compare(a, b);
								}),
							});
						}, 0);
					}}
					columnDefinitions={[
						{
							id: "user",
							header: "User",
							cell: (item) => (
								<Link
									onFollow={() => {
										this.loadSessions(item.id);
									}}>
									{item.user}
								</Link>
							),
						},
						{ id: "level", sortingField: "level", header: "Level", cell: (item) => item.level },
						{
							id: "status",
							header: "Last online",
							sortingField: "last",
							cell: (item) =>
								!item.last ? (
									<Box variant="span" color="text-status-error">
										Not logged yet
									</Box>
								) : Date.now() - item.last < 300000 ? (
									<Box variant="span" color="text-status-success">
										Online
									</Box>
								) : (
									new Date(item.last).toLocaleTimeString() + " " + new Date(item.last).toLocaleDateString()
								),
						},
						{ sortingField: "created", id: "created", header: "Created", cell: (item) => new Date(item.created).toLocaleTimeString() + " " + new Date(item.created).toLocaleDateString() },
					]}
					items={this.props.staff.data.slice((this.props.staff.pagination.current - 1) * this.props.config.staff.perPage, this.props.staff.pagination.current * this.props.config.staff.perPage)}
					loading={!this.props.staff.data.length}
					sortingColumn={{ sortingField: this.props.staff.sorting.sortingColumn.sortingField }}
					sortingDescending={this.props.staff.sorting.isDescending}
					onSelectionChange={(selection) => {
						this.props.setStaff({ ...this.props.staff, selectedItems: selection.detail.selectedItems });
					}}
					selectedItems={this.props.staff.selectedItems}
					selectionType="single"
					header={
						<Box
							margin={{ bottom: "m" }}
							children={
								<Header
									children="Staff viewer"
									description="Below listed all users has granted access to this panel"
									actions={
										this.props.user.level >= 6 ? (
											<SpaceBetween direction="horizontal" size="l">
												<Button
													disabled={!this.props.staff.selectedItems.length || (this.props.staff.selectedItems.length && this.props.user.level <= this.props.staff.selectedItems[0].level)}
													onClick={() => {
														this.props.setStaffEditModal({ ...this.props.staffEditModal, id: this.props.staff.selectedItems[0].id, level: this.props.staff.selectedItems[0].level, show: true });
													}}
													variant="normal">
													Edit access
												</Button>
												<Button
													onClick={() => {
														this.props.setStaffAddModal({ ...this.props.staffAddModal, show: true });
													}}
													variant="primary">
													Add user
												</Button>
											</SpaceBetween>
										) : (
											<></>
										)
									}
									counter={<>({this.props.staff.data.length})</>}
								/>
							}
						/>
					}
					empty="No users were found"
				/>
			</>
		);
	}
}

function withRecoil(Component) {
	return function WrappedComponent(props) {
		const user = useRecoilValue(userStore);
		const staff = useRecoilValue(staffStore);
		const setStaff = useSetRecoilState(staffStore);
		const config = useRecoilValue(configStore);
		const staffSessions = useRecoilValue(staffSessionsModalStore);
		const setStaffSessions = useSetRecoilState(staffSessionsModalStore);
		const staffAddModal = useRecoilValue(staffAddModalStore);
		const setStaffAddModal = useSetRecoilState(staffAddModalStore);
		const staffEditModal = useRecoilValue(staffEditModalStore);
		const setStaffEditModal = useSetRecoilState(staffEditModalStore);

		return <Component {...props} user={user} staffEditModal={staffEditModal} setStaffEditModal={setStaffEditModal} staffAddModal={staffAddModal} setStaffAddModal={setStaffAddModal} config={config} staff={staff} setStaff={setStaff} staffSessions={staffSessions} setStaffSessions={setStaffSessions} />;
	};
}

export default withRecoil(Staff);
