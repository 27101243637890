import React from "react";

import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

import RangePicker from "@cloudscape-design/components/date-range-picker";
import Alert from "@cloudscape-design/components/alert";
import Table from "@cloudscape-design/components/table";
import Box from "@cloudscape-design/components/box";
import Header from "@cloudscape-design/components/header";
import LineChart from "@cloudscape-design/components/line-chart";
import Container from "@cloudscape-design/components/container";
import Grid from "@cloudscape-design/components/grid";
import Tabs from "@cloudscape-design/components/tabs";
import Select from "@cloudscape-design/components/select";
import Button from "@cloudscape-design/components/button";

/* Recoil */
import { useRecoilValue, useSetRecoilState } from "recoil";
import { configStore } from "../stores/config";
import { statStore } from "../stores/stats";
/* ------- */

class Stats extends React.PureComponent {
	constructor(props) {
		super(props);
		this.rawData = [];
	}

	componentDidMount() {
		this.props.Interface.isPaymentDataLoaded().then(() => {
			let currentPeriod = this.props.config.stats.options.period[4].type;

			if (this.props.config.stats.options.payway.length)
				setTimeout(() => {
					this.props.setStats({
						...this.props.stats,
						loaded: false,
						data: [],
						limit: currentPeriod.limit,
						offset: currentPeriod.offset,
						range: { type: "absolute", startDate: this.formatLimitOffset(currentPeriod.limit, currentPeriod.offset).from, endDate: this.formatLimitOffset(this.props.stats.limit, this.props.stats.offset).to },
						currentPayWay: this.props.config.stats.options.payway[0].type,
						currentGroup: this.props.config.stats.options.delimiter[0].type,
						currentOption: { ...this.props.stats.currentOption, group: this.props.config.stats.options.delimiter[0], period: this.props.config.stats.options.period[4], payWay: this.props.config.stats.options.payway[0] },
					});
				}, 0);
			else
				setTimeout(() => {
					this.props.setStats({
						...this.props.stats,
						loaded: false,
						data: [],
						limit: currentPeriod.limit,
						offset: currentPeriod.offset,
						range: { type: "absolute", startDate: this.formatLimitOffset(currentPeriod.limit, currentPeriod.offset).from, endDate: this.formatLimitOffset(this.props.stats.limit, this.props.stats.offset).to },
						currentGroup: this.props.config.stats.options.delimiter[0].type,
						currentOption: { ...this.props.stats.currentOption, group: this.props.config.stats.options.delimiter[0], period: this.props.config.stats.options.period[4] },
					});
				}, 0);

			setTimeout(() => {
				this.loadStats();
			}, 100);
		});
	}

	componentWillUnmount() {
		this.props.setStats({ ...this.props.stats, loaded: false, data: [] });
	}

	loadStats() {
		if (this.props.stats.limit) {
			this.props.setStats({ ...this.props.stats, loaded: false, data: [] });

			this.props.Interface.request("get", { class: "data", module: "info", method: "stats" }, { offset: this.props.stats.offset, limit: this.props.stats.limit, payWay: this.props.stats.currentPayWay || "all" })
				.then((rawData) => {
					this.rawData = rawData;
					this.rebuildStats();
				})
				.catch((e) => {
					this.props.setStats({ ...this.props.stats, data: [], chart: { exchanged: { total: [], count: [], correlation: [] }, deposits: { total: [], count: [], conversion: [] }, withdrawals: { total: [], count: [] }, profit: [] }, loaded: true, error: e });
				});
		}
	}

	saveData(data) {
		const element = document.createElement("a");
		const file = new Blob([JSON.stringify(data)], {
			type: "text/plain",
		});
		element.href = URL.createObjectURL(file);
		element.download = this.props.config.app.name.toLowerCase() + "_stats_" + +new Date() + ".json";
		document.body.appendChild(element);
		element.click();
	}

	getWeekStart(date) {
		const weekStart = new Date(date);
		weekStart.setDate(weekStart.getDate() - ((weekStart.getDay() + 6) % 7));
		weekStart.setHours(0, 0, 0, 0);
		return weekStart;
	}

	getMonthStart(date) {
		const monthStart = new Date(date);
		monthStart.setDate(1);
		monthStart.setHours(0, 0, 0, 0);
		return monthStart;
	}

	getYearStart(date) {
		const yearStart = new Date(date);
		yearStart.setMonth(0, 1);
		yearStart.setHours(0, 0, 0, 0);
		return yearStart;
	}

	rebuildStats() {
		let total = 0,
			average = 0,
			chart = { exchanged: { total: [], count: [], correlation: [] }, deposits: { total: [], count: [], conversion: [] }, withdrawals: { total: [], count: [] }, profit: [] },
			totalColumn = { profit: 0, average_percent: 0, exchanged: { total: 0, count: 0, correlation: 0 }, deposits: { total: 0, count: 0, conversion: 0 }, withdrawals: { total: 0, count: 0 } };
		let data = [],
			j = 0;

		let rawData = cloneDeep(this.rawData);

		let lastPeriod = false;

		rawData.reverse().forEach((stat, i) => {
			if (!i) {
				if (this.props.stats.currentGroup === 365) {
					lastPeriod = this.getYearStart(stat.date).getTime();
				} else if (this.props.stats.currentGroup === 30) {
					lastPeriod = this.getMonthStart(stat.date).getTime();
				} else if (this.props.stats.currentGroup === 7) {
					lastPeriod = this.getWeekStart(stat.date).getTime();
				}
			}

			if (i) {
				if (this.props.stats.currentGroup === 365) {
					if (!lastPeriod || lastPeriod !== this.getYearStart(stat.date).getTime()) {
						lastPeriod = this.getYearStart(stat.date).getTime();
						j++;
					}
				} else if (this.props.stats.currentGroup === 30) {
					if (!lastPeriod || lastPeriod !== this.getMonthStart(stat.date).getTime()) {
						lastPeriod = this.getMonthStart(stat.date).getTime();
						j++;
					}
				} else if (this.props.stats.currentGroup === 7) {
					if (!lastPeriod || lastPeriod !== this.getWeekStart(stat.date).getTime()) {
						lastPeriod = this.getWeekStart(stat.date).getTime();
						j++;
					}
				} else j++;
			}

			if (typeof data[j] === "undefined") data[j] = { deposits: { total: 0, count: 0, conversion: 0 }, withdrawals: { total: 0, count: 0 }, exchanged: { total: 0, count: 0, correlation: 0 }, days: 0, profit: 0, date: stat.date };

			data[j].days++;
			data[j].deposits.total += stat.deposits.total;
			data[j].deposits.count += stat.deposits.count;
			data[j].deposits.conversion += stat.deposits.conversion;
			data[j].withdrawals.total += stat.withdrawals.total;
			data[j].withdrawals.count += stat.withdrawals.count;
			data[j].profit += stat.deposits.total - stat.withdrawals.total;

			if (typeof stat.exchanged !== "undefined") {
				data[j].exchanged.total = stat.exchanged.total;
				data[j].exchanged.count = stat.exchanged.count;
				data[j].exchanged.correlation = parseFloat(((stat.exchanged.total / data[j].profit) * 100).toFixed(2));
			}

			data[j].dateEnd = stat.date;
		});

		data.forEach((stat) => {
			let formattedRangeDate = new Date(stat.date).toLocaleDateString().indexOf("/") !== -1 ? new Date(stat.date).toLocaleDateString().split("/").slice(0, -1).join("/") + " - " + new Date(stat.dateEnd).toLocaleDateString().split("/").slice(0, -1).join("/") : new Date(stat.date).toLocaleDateString().split(".").slice(0, -1).join(".") + " - " + new Date(stat.dateEnd).toLocaleDateString().split(".").slice(0, -1).join(".");

			total += stat.deposits.total;
			total -= stat.withdrawals.total;
			stat.profit = stat.deposits.total - stat.withdrawals.total;

			totalColumn.deposits.total += stat.deposits.total;
			totalColumn.deposits.count += stat.deposits.count;
			totalColumn.withdrawals.total += stat.withdrawals.total;
			totalColumn.withdrawals.count += stat.withdrawals.count;

			if (typeof stat.days !== "undefined") stat.deposits.conversion = parseFloat((stat.deposits.conversion / stat.days).toFixed(2));

			totalColumn.profit += stat.profit;
			totalColumn.deposits.conversion += stat.deposits.conversion;
			chart.deposits.total.push({ x: typeof stat.dateEnd === "undefined" || stat.date === stat.dateEnd ? new Date(stat.date).toLocaleDateString() : formattedRangeDate, y: stat.deposits.total });
			chart.deposits.count.push({ x: typeof stat.dateEnd === "undefined" || stat.date === stat.dateEnd ? new Date(stat.date).toLocaleDateString() : formattedRangeDate, y: stat.deposits.count });

			chart.withdrawals.total.push({ x: typeof stat.dateEnd === "undefined" || stat.date === stat.dateEnd ? new Date(stat.date).toLocaleDateString() : formattedRangeDate, y: stat.withdrawals.total });
			chart.withdrawals.count.push({ x: typeof stat.dateEnd === "undefined" || stat.date === stat.dateEnd ? new Date(stat.date).toLocaleDateString() : formattedRangeDate, y: stat.withdrawals.count });

			chart.deposits.conversion.push({ x: typeof stat.dateEnd === "undefined" || stat.date === stat.dateEnd ? new Date(stat.date).toLocaleDateString() : formattedRangeDate, y: stat.deposits.conversion });

			chart.profit.push({ x: typeof stat.dateEnd === "undefined" || stat.date === stat.dateEnd ? new Date(stat.date).toLocaleDateString() : formattedRangeDate, y: stat.profit });

			if (typeof stat.exchanged !== "undefined") {
				totalColumn.exchanged.total += stat.exchanged.total;
				totalColumn.exchanged.count += stat.exchanged.count;
				totalColumn.exchanged.correlation += stat.exchanged.correlation;

				chart.exchanged.total.push({ x: typeof stat.dateEnd === "undefined" || stat.date === stat.dateEnd ? new Date(stat.date).toLocaleDateString() : formattedRangeDate, y: stat.exchanged.total });
				chart.exchanged.count.push({ x: typeof stat.dateEnd === "undefined" || stat.date === stat.dateEnd ? new Date(stat.date).toLocaleDateString() : formattedRangeDate, y: stat.exchanged.count });
				chart.exchanged.correlation.push({ x: typeof stat.dateEnd === "undefined" || stat.date === stat.dateEnd ? new Date(stat.date).toLocaleDateString() : formattedRangeDate, y: stat.exchanged.correlation });
			}
		});

		average = parseFloat(total / data.length);

		data.forEach((stat) => {
			stat.average_percent = parseFloat((-100 + (stat.profit / average) * 100).toFixed(2));
			if (isNaN(stat.average_percent)) stat.average_percent = 0;
		});

		totalColumn.deposits.conversion = parseFloat(totalColumn.deposits.conversion / data.length).toFixed(2);

		if (typeof totalColumn.exchanged !== "undefined") totalColumn.exchanged.correlation = parseFloat(totalColumn.exchanged.correlation / data.length).toFixed(2);

		totalColumn.date = "Total";

		data.reverse().push(totalColumn);

		this.props.setStats({ ...this.props.stats, data, chart, average, total, loaded: true });
	}

	compare(a, b) {
		if (this.props.stats.sorting.sortingColumn.sortingField.indexOf(".") === -1) {
			if (a[this.props.stats.sorting.sortingColumn.sortingField] < b[this.props.stats.sorting.sortingColumn.sortingField]) {
				return this.props.stats.sorting.isDescending ? 1 : -1;
			}

			if (a[this.props.stats.sorting.sortingColumn.sortingField] > b[this.props.stats.sorting.sortingColumn.sortingField]) {
				return this.props.stats.sorting.isDescending ? -1 : 1;
			}
		} else {
			if (a[this.props.stats.sorting.sortingColumn.sortingField.split(".")[0]][this.props.stats.sorting.sortingColumn.sortingField.split(".")[1]] < b[this.props.stats.sorting.sortingColumn.sortingField.split(".")[0]][this.props.stats.sorting.sortingColumn.sortingField.split(".")[1]]) {
				return this.props.stats.sorting.isDescending ? 1 : -1;
			}

			if (a[this.props.stats.sorting.sortingColumn.sortingField.split(".")[0]][this.props.stats.sorting.sortingColumn.sortingField.split(".")[1]] > b[this.props.stats.sorting.sortingColumn.sortingField.split(".")[0]][this.props.stats.sorting.sortingColumn.sortingField.split(".")[1]]) {
				return this.props.stats.sorting.isDescending ? -1 : 1;
			}
		}

		return 0;
	}

	formatRange(startDate, stopDate) {
		startDate = startDate.split("-").join("/");
		stopDate = stopDate.split("-").join("/");

		let limit = 0,
			offset = [],
			currentDate = +new Date(startDate);

		while (currentDate <= +new Date(stopDate)) {
			currentDate = +new Date(currentDate).setDate(new Date(currentDate).getDate() + 1);
			limit++;
		}

		offset = (+new Date(new Date().getFullYear() + "/" + (new Date().getMonth() + 1) + "/" + new Date().getDate()) - +new Date(stopDate)) / 1000 / 60 / 60 / 24;
		return { limit, offset };
	}

	formatLimitOffset(limit, offset) {
		let startDate = +new Date().setDate(new Date().getDate() - offset),
			endDate = +new Date().setDate(new Date().getDate() - offset - limit + 1);
		return { from: new Date(endDate).toLocaleDateString().split("/").reverse().join("-"), to: new Date(startDate).toLocaleDateString().split("/").reverse().join("-") };
	}

	render() {
		return (
			<>
				{this.props.stats.loaded && !this.props.stats.data.length && this.props.stats.error.text.length ? (
					<Box
						margin={{ bottom: "m" }}
						children={
							<Alert
								type="error"
								header="Error loading data:"
								children={
									<>
										<Box variant="p" children={this.props.stats.error.text} />
										<hr />
										<Box
											variant="p"
											children={
												<>
													request error code: {this.props.stats.error.code} | request timestamp: {this.props.stats.error.timestamp}
												</>
											}
										/>
									</>
								}
							/>
						}
					/>
				) : (
					<></>
				)}

				<Container
					header={
						<Box
							margin={{ bottom: "m" }}
							children={
								<Header
									children="Financial stats"
									counter={<>({this.props.stats.data.length ? this.props.stats.data.length - 1 : 0})</>}
									actions={
										<Button
											disabled={!this.props.stats.loaded || !this.props.stats.data.length}
											className={this.props.styles["players-search-button"]}
											onClick={() => {
												this.saveData(this.props.stats.data);
											}}
											variant="primary"
											children="Export"
										/>
									}
									description="Below listed stats for selected date"
								/>
							}
						/>
					}
					children={
						<>
							<Grid
								gridDefinition={[{ colspan: 12 }, { colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}
								children={
									<>
										<RangePicker
											disabled={!this.props.stats.loaded}
											relativeOptions={[]}
											clearButtonLabel="Clear"
											dateOnly={true}
											placeholder="Select range for show stats"
											onChange={(range) => {
												this.props.setStats({ ...this.props.stats, ...this.formatRange(range.detail.value.startDate, range.detail.value.endDate), range: range.detail.value });
												setTimeout(() => {
													this.loadStats();
												}, 250);
											}}
											value={this.props.stats.range}
											i18nStrings={{
												customRelativeRangeUnitLabel: "",
												formatRelativeRange: (value) => {
													return value.amount;
												},
												formatUnit: () => {
													return "";
												},
												nextMonthAriaLabel: "Next",
												applyButtonLabel: "Ok",
											}}
										/>
										<Select
											filteringType="auto"
											disabled={!this.props.stats.loaded}
											placeholder="Group by"
											selectedOption={this.props.stats.currentOption.group}
											onChange={(option) => {
												option.detail.selectedOption.type !== this.props.stats.currentGroup && this.props.setStats({ ...this.props.stats, currentGroup: option.detail.selectedOption.type, currentOption: { ...this.props.stats.currentOption, group: option.detail.selectedOption } });
												setTimeout(() => {
													this.rebuildStats();
												}, 0);
											}}
											options={this.props.config.stats.options.delimiter}
										/>

										<Select
											filteringType="auto"
											disabled={!this.props.stats.loaded}
											placeholder="Payway"
											selectedOption={this.props.stats.currentOption.payWay}
											onChange={(option) => {
												option.detail.selectedOption.type !== this.props.stats.currentPayWay && this.props.setStats({ ...this.props.stats, currentPayWay: option.detail.selectedOption.type, currentOption: { ...this.props.stats.currentOption, payWay: option.detail.selectedOption } });
												setTimeout(() => {
													this.loadStats();
												}, 0);
											}}
											options={this.props.config.stats.options.payway}
										/>

										<Select
											filteringType="auto"
											disabled={!this.props.stats.loaded}
											placeholder="Period"
											selectedOption={this.props.stats.currentOption.period}
											onChange={(option) => {
												!isEqual(option.detail.selectedOption.type, this.props.stats.currentPeriod) &&
													this.props.setStats({
														...this.props.stats,
														currentPeriod: option.detail.selectedOption.type,
														currentOption: { ...this.props.stats.currentOption, period: option.detail.selectedOption },
														limit: option.detail.selectedOption.type.limit,
														offset: option.detail.selectedOption.type.offset,
														range: { type: "absolute", startDate: this.formatLimitOffset(option.detail.selectedOption.type.limit, option.detail.selectedOption.type.offset).from, endDate: this.formatLimitOffset(option.detail.selectedOption.type.limit, option.detail.selectedOption.type.offset).to },
													});
												setTimeout(() => {
													this.loadStats();
												}, 0);
											}}
											options={this.props.config.stats.options.period}
										/>
									</>
								}
							/>
							<Tabs
								tabs={[
									{
										id: "table",
										label: "Table view",
										content: (
											<Table
												visibleColumns={["date", "profit", "depositsTotal", "withdrawalsTotal", "depositsCount", "withdrawalsCount", "averageProfit", "depositsConversion", this.props.stats.currentPayWay === "all" ? "exchangedCount" : "", this.props.stats.currentPayWay === "all" ? "exchangedTotal" : "", this.props.stats.currentPayWay === "all" ? "exchangedCorrelation" : ""]}
												stickyHeader={true}
												loading={!this.props.stats.loaded}
												sortingColumn={{ sortingField: this.props.stats.sorting.sortingColumn.sortingField }}
												sortingDescending={this.props.stats.sorting.isDescending}
												onSortingChange={(sorting) => {
													this.props.setStats({ ...this.props.stats, sorting: sorting.detail });
													setTimeout(() => {
														this.props.setStats({
															...this.props.stats,
															data: cloneDeep(this.props.stats.data).sort((a, b) => {
																return this.compare(a, b);
															}),
														});
													}, 0);
												}}
												columnDefinitions={[
													{ id: "date", header: "Date", sortingField: "date", cell: (item) => (typeof item.date === "string" ? <b>{item.date}</b> : typeof item.dateEnd !== "undefined" && item.date !== item.dateEnd ? new Date(item.date).toLocaleDateString() + " - " + new Date(item.dateEnd).toLocaleDateString() : new Date(item.date).toLocaleDateString()) },
													{ id: "profit", header: "Profit", sortingField: "profit", cell: (item) => <Box variant="span" color={item.profit >= 0 ? (item.profit > 0 ? "text-status-success" : "text-body-secondary") : "text-status-error"} children={<>{new Intl.NumberFormat("ru-RU").format(item.profit)} RUB.</>} /> },
													{ id: "depositsTotal", header: "Deposits amount", sortingField: "deposits.total", cell: (item) => new Intl.NumberFormat("ru-RU").format(item.deposits.total) + " RUB." },
													{ id: "withdrawalsTotal", header: "Withdrawals amount", sortingField: "withdrawals.total", cell: (item) => new Intl.NumberFormat("ru-RU").format(item.withdrawals.total) + " RUB." },
													{ id: "depositsCount", header: "Deposits count", sortingField: "deposits.count", cell: (item) => item.deposits.count },
													{ id: "withdrawalsCount", header: "Withdrawals count", sortingField: "withdrawals.count", cell: (item) => item.withdrawals.count },
													{ id: "averageProfit", header: "Average profit", sortingField: "average_percent", cell: (item) => <Box variant="span" color={item.average_percent > -20 ? (item.average_percent > 0 ? "text-status-success" : "text-body-secondary") : "text-status-error"} children={item.average_percent + "%"} /> },
													{ id: "depositsConversion", header: "Deposits conversion", sortingField: "conversion", cell: (item) => <Box variant="span" color={item.conversion > 60 ? (item.conversion > 80 ? "text-status-success" : "text-body-secondary") : "text-status-error"} children={item.deposits.conversion + "%"} /> },
												]}
												empty="No stats was loaded"
												items={this.props.stats.data}
											/>
										),
									},
									{
										id: "chart",
										label: "Chart view",
										content: (
											<Grid
												gridDefinition={[{ colspan: 12 }, { colspan: 12 }, { colspan: 12 }, { colspan: 12 }, { colspan: 12 }]}
												children={
													<>
														<Grid
															gridDefinition={[{ colspan: { default: 12, m: 6 } }, { colspan: { default: 12, m: 6 } }]}
															children={
																<>
																	<Container
																		header={<Header children={"Deposits total chart (" + (this.props.stats.data.length ? new Intl.NumberFormat("ru-RU").format(this.props.stats.data[this.props.stats.data.length - 1].deposits.total) : 0) + " RUB.)"} />}
																		children={<LineChart hideLegend={true} hideFilter={true} statusType={this.props.stats.loaded && typeof this.props.stats.chart.deposits !== "undefined" ? "finished" : "loading"} yScaleType="linear" xScaleType="categorical" series={[{ title: "Deposits total", type: "bar", data: this.props.stats.chart.deposits.total }]} errorText="Error building chart" />}
																	/>
																	<Container
																		header={<Header children={"Deposits count chart (" + (this.props.stats.data.length ? this.props.stats.data[this.props.stats.data.length - 1].deposits.count : 0) + ")"} />}
																		children={<LineChart hideLegend={true} hideFilter={true} statusType={this.props.stats.loaded && typeof this.props.stats.chart.deposits !== "undefined" ? "finished" : "loading"} yScaleType="linear" xScaleType="categorical" series={[{ title: "Deposits count", color: "#1f8205", type: "line", data: this.props.stats.chart.deposits.count }]} errorText="Error building chart" />}
																	/>
																</>
															}
														/>

														<Grid
															gridDefinition={[{ colspan: { default: 12, m: 6 } }, { colspan: { default: 12, m: 6 } }]}
															children={
																<>
																	<Container
																		header={<Header children={"Profit chart (" + (this.props.stats.data.length ? new Intl.NumberFormat("ru-RU").format(this.props.stats.data[this.props.stats.data.length - 1].profit) : 0) + " RUB.)"} />}
																		children={<LineChart hideLegend={true} hideFilter={true} statusType={this.props.stats.loaded && typeof this.props.stats.chart.profit !== "undefined" ? "finished" : "loading"} yScaleType="linear" xScaleType="categorical" series={[{ title: "Profit chart", color: "#f59300", type: "bar", data: this.props.stats.chart.profit }]} errorText="Error building chart" />}
																	/>
																	<Container
																		header={<Header children={"Deposits conversion chart (" + (this.props.stats.data.length ? this.props.stats.data[this.props.stats.data.length - 1].deposits.conversion : 0) + "%)"} />}
																		children={<LineChart hideLegend={true} hideFilter={true} statusType={this.props.stats.loaded && typeof this.props.stats.chart.deposits !== "undefined" ? "finished" : "loading"} yScaleType="linear" xScaleType="categorical" series={[{ title: "Deposits conversion", color: "#1f8205", type: "line", data: this.props.stats.chart.deposits.conversion }]} errorText="Error building chart" />}
																	/>
																</>
															}
														/>

														<Grid
															gridDefinition={[{ colspan: { default: 12, m: 6 } }, { colspan: { default: 12, m: 6 } }]}
															children={
																<>
																	<Container
																		header={<Header children={"Withdrawals total chart (" + (this.props.stats.data.length ? new Intl.NumberFormat("ru-RU").format(this.props.stats.data[this.props.stats.data.length - 1].withdrawals.total) : 0) + " RUB.)"} />}
																		children={<LineChart hideLegend={true} hideFilter={true} statusType={this.props.stats.loaded && typeof this.props.stats.chart.withdrawals !== "undefined" ? "finished" : "loading"} yScaleType="linear" xScaleType="categorical" series={[{ title: "Withdrawals total", type: "bar", data: this.props.stats.chart.withdrawals.total }]} errorText="Error building chart" />}
																	/>
																	<Container
																		header={<Header children={"Withdrawals count chart (" + (this.props.stats.data.length ? this.props.stats.data[this.props.stats.data.length - 1].withdrawals.count : 0) + ")"} />}
																		children={<LineChart hideLegend={true} hideFilter={true} statusType={this.props.stats.loaded && typeof this.props.stats.chart.withdrawals !== "undefined" ? "finished" : "loading"} yScaleType="linear" xScaleType="categorical" series={[{ title: "Withdrawals count", color: "#1f8205", type: "line", data: this.props.stats.chart.withdrawals.count }]} errorText="Error building chart" />}
																	/>
																</>
															}
														/>
													</>
												}
											/>
										),
									},
								]}
							/>
						</>
					}
				/>
			</>
		);
	}
}

function withRecoil(Component) {
	return function WrappedComponent(props) {
		const stats = useRecoilValue(statStore);
		const setStats = useSetRecoilState(statStore);
		const config = useRecoilValue(configStore);

		return <Component {...props} stats={stats} setStats={setStats} config={config} />;
	};
}

export default withRecoil(Stats);
