import React from "react";

import Modal from "@cloudscape-design/components/modal";
import Input from "@cloudscape-design/components/input";
import Button from "@cloudscape-design/components/button";
import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Spinner from "@cloudscape-design/components/spinner";
import Toggle from "@cloudscape-design/components/toggle";

/* Recoil */
import { useRecoilValue, useSetRecoilState } from "recoil";
import { configStore } from "../stores/config";
import { reportsAddSettlementsModalStore } from "../stores/reports";
import { userStore } from "../stores/access";
import { staffStore } from "../stores/staff";
/* ------- */

class reportsAddSettlementsModal extends React.Component {
	addUser() {
		this.props.setReportsAddSettlementsModal({ ...this.props.reportsAddSettlementsModal, loaded: false });

		this.props.Interface.request(
			"set",
			{ class: "settings", module: "settlements", method: "add" },
			{ name: this.props.reportsAddSettlementsModal.name, amount: this.props.reportsAddSettlementsModal.amount, gateAmount: this.props.reportsAddSettlementsModal.gateAmount, gateCurrency: this.props.reportsAddSettlementsModal.gateCurrency, data: this.props.reportsAddSettlementsModal.data, date: this.props.reportsAddSettlementsModal.date, brand: this.props.reportsAddSettlementsModal.brand },
		)
			.then(() => {
				this.props.Interface.addNoty("Success", "Settlement item was added");
				this.props.setReportsAddSettlementsModal({ ...this.props.reportsAddSettlementsModal, checked: false, loaded: true, show: false, name: "", data: "", date: "", amount: "", brand: false });
			})
			.catch((e) => {
				this.props.setReportsAddSettlementsModal({ ...this.props.reportsAddSettlementsModal, checked: true, loaded: true });
				console.log(e);
			});
	}

	render() {
		return (
			<>
				<Modal
					visible={this.props.reportsAddSettlementsModal.show}
					onDismiss={() => {
						this.props.setReportsAddSettlementsModal({ ...this.props.reportsAddSettlementsModal, checked: false, name: "", data: "", date: "", amount: "", brand: false, show: false });
					}}
					header="Add item"
					children={
						<>
							<SpaceBetween direction="vertical" size="m">
								{this.props.reportsAddSettlementsModal.loaded ? (
									<>
										<Box variant="span">name: </Box>
										<Input
											invalid={(this.props.reportsAddSettlementsModal.name.length > 16 || this.props.reportsAddSettlementsModal.name.length < 3) && this.props.reportsAddSettlementsModal.checked ? true : false}
											inputMode="text"
											type="text"
											disableBrowserAutocorrect={true}
											placeholder="Item name"
											value={this.props.reportsAddSettlementsModal.name}
											onChange={(input) => {
												this.props.setReportsAddSettlementsModal({ ...this.props.reportsAddSettlementsModal, name: input.detail.value });
											}}
										/>
										<Box variant="span">amount: </Box>
										<Input
											invalid={isNaN(parseInt(this.props.reportsAddSettlementsModal.amount)) && this.props.reportsAddSettlementsModal.checked ? true : false}
											inputMode="numeric"
											type="number"
											disableBrowserAutocorrect={true}
											placeholder="Amount (USD.)"
											value={this.props.reportsAddSettlementsModal.amount}
											onChange={(input) => {
												this.props.setReportsAddSettlementsModal({ ...this.props.reportsAddSettlementsModal, amount: input.detail.value });
											}}
										/>
										<Box variant="span">gate amount: </Box>
										<Input
											invalid={isNaN(parseInt(this.props.reportsAddSettlementsModal.gateAmount)) && this.props.reportsAddSettlementsModal.checked ? true : false}
											inputMode="numeric"
											type="number"
											disableBrowserAutocorrect={true}
											placeholder="Amount (gate currency)"
											value={this.props.reportsAddSettlementsModal.gateAmount}
											onChange={(input) => {
												this.props.setReportsAddSettlementsModal({ ...this.props.reportsAddSettlementsModal, gateAmount: input.detail.value });
											}}
										/>
										<Box variant="span">gate currency: </Box>
										<Input
											invalid={(this.props.reportsAddSettlementsModal.gateCurrency.length > 3 || this.props.reportsAddSettlementsModal.gateCurrency.length < 3) && this.props.reportsAddSettlementsModal.checked ? true : false}
											inputMode="text"
											type="text"
											disableBrowserAutocorrect={true}
											placeholder="Gate currency"
											value={this.props.reportsAddSettlementsModal.gateCurrency}
											onChange={(input) => {
												this.props.setReportsAddSettlementsModal({ ...this.props.reportsAddSettlementsModal, gateCurrency: input.detail.value });
											}}
										/>
										<Box variant="span">data link: </Box>
										<Input
											inputMode="text"
											type="text"
											disableBrowserAutocorrect={true}
											placeholder="Data link"
											value={this.props.reportsAddSettlementsModal.data}
											onChange={(input) => {
												this.props.setReportsAddSettlementsModal({ ...this.props.reportsAddSettlementsModal, data: input.detail.value });
											}}
										/>

										<Toggle
											checked={this.props.reportsAddSettlementsModal.brand}
											onChange={(checkbox) => {
												this.props.setReportsAddSettlementsModal({ ...this.props.reportsAddSettlementsModal, brand: checkbox.detail.checked });
											}}>
											branded
										</Toggle>
										<Box variant="span">date: </Box>
										<Input
											invalid={isNaN(parseInt(this.props.reportsAddSettlementsModal.date)) && this.props.reportsAddSettlementsModal.checked ? true : false}
											inputMode="date"
											type="date"
											disableBrowserAutocorrect={true}
											placeholder="Date"
											value={this.props.reportsAddSettlementsModal.date}
											onChange={(input) => {
												this.props.setReportsAddSettlementsModal({ ...this.props.reportsAddSettlementsModal, date: input.detail.value });
											}}
										/>
									</>
								) : (
									<Box variant="div" textAlign="center">
										<Spinner size="large" />
									</Box>
								)}
							</SpaceBetween>
						</>
					}
					footer={
						<>
							<Box
								float="right"
								children={
									<>
										<Box
											variant="span"
											margin={{ right: "s" }}
											children={
												<Button
													variant="normal"
													onClick={() => {
														this.props.setReportsAddSettlementsModal({ ...this.props.reportsAddSettlementsModal, show: false });
													}}
													children="Close"
												/>
											}
										/>
										<Box
											variant="span"
											children={
												<Button
													variant="primary"
													onClick={() => {
														this.addUser();
													}}
													children="Ok"
												/>
											}
										/>
									</>
								}
							/>
						</>
					}
				/>
			</>
		);
	}
}

function withRecoil(Component) {
	return function WrappedComponent(props) {
		const staff = useRecoilValue(staffStore);
		const setStaff = useSetRecoilState(staffStore);
		const reportsAddSettlementsModal = useRecoilValue(reportsAddSettlementsModalStore);
		const setReportsAddSettlementsModal = useSetRecoilState(reportsAddSettlementsModalStore);
		const config = useRecoilValue(configStore);
		const user = useRecoilValue(userStore);

		return <Component {...props} staff={staff} setStaff={setStaff} user={user} reportsAddSettlementsModal={reportsAddSettlementsModal} setReportsAddSettlementsModal={setReportsAddSettlementsModal} config={config} />;
	};
}

export default withRecoil(reportsAddSettlementsModal);
