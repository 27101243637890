import { atom } from "recoil";

export const domainsEditModalStore = atom({
	key: "domainsEditModalStore",
	default: {
		show: false,
		loaded: true,
		id: 0,
		domain: "",
		currentOption: {
			type: false,
		},
	},
});
