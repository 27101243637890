import React from "react";

import cloneDeep from "lodash/cloneDeep";

import Table from "@cloudscape-design/components/table";
import Pagination from "@cloudscape-design/components/pagination";
import Header from "@cloudscape-design/components/header";
import Input from "@cloudscape-design/components/input";
import Button from "@cloudscape-design/components/button";
import Box from "@cloudscape-design/components/box";
import Grid from "@cloudscape-design/components/grid";
import Badge from "@cloudscape-design/components/badge";
import Preferences from "@cloudscape-design/components/collection-preferences";
import Alert from "@cloudscape-design/components/alert";
import Spinner from "@cloudscape-design/components/spinner";
import Select from "@cloudscape-design/components/select";

/* Recoil */
import { useRecoilValue, useSetRecoilState } from "recoil";
import { configStore } from "../stores/config";
import SpaceBetween from "@cloudscape-design/components/space-between";
import { exportsStore } from "../stores/exports";
import { userStore } from "../stores/access";
/* ------- */

class Exports extends React.Component {
	constructor(props) {
		super(props);

		this.props.setExports({ ...this.props.exports, loaded: true });
		this.exports = [
			{ type: "email", name: "E-Mails" },
			{ type: "phone", name: "Phones" },
		];
	}

	saveData(name, data) {
		const element = document.createElement("a");
		const file = new Blob([data], {
			type: "text/plain",
		});
		element.href = URL.createObjectURL(file);
		element.download = this.props.user.brand.id + "_" + name + "_" + +new Date() + ".json";
		document.body.appendChild(element);
		element.click();
	}

	exportData(method) {
		this.props.setExports({ ...this.props.exports, loaded: false });
		this.props.Interface.request("get", { class: "data", module: "exports", method })
			.then((list) => {
				this.saveData(method, JSON.stringify(list));
				this.props.setExports({ ...this.props.exports, loaded: true });
			})
			.catch((e) => {
				this.props.setExports({ ...this.props.exports, loaded: true, error: e });
				this.props.setExports({ ...this.props.exports, loaded: true });
			});
	}

	render() {
		return (
			<>
				{this.props.exports.loaded && this.props.exports.error.text.length ? (
					<Box
						margin={{ bottom: "m" }}
						children={
							<Alert
								type="error"
								header="Error loading data:"
								children={
									<>
										<Box variant="p" children={this.props.exports.error.text} />
										<hr />
										<Box
											variant="p"
											children={
												<>
													request error code: {this.props.exports.error.code} | request timestamp: {this.props.exports.error.timestamp}
												</>
											}
										/>
									</>
								}
							/>
						}
					/>
				) : (
					<></>
				)}

				<Table
					stickyHeader="true"
					trackBy="id"
					loading={!this.props.exports.loaded}
					sortingColumn={{ sortingField: this.props.exports.sorting.sortingColumn.sortingField }}
					sortingDescending={this.props.exports.sorting.isDescending}
					onSortingChange={(sorting) => {
						this.props.setExports({ ...this.props.exports, sorting: sorting.detail });
						setTimeout(() => {
							this.props.setExports({
								...this.props.exports,
								data: cloneDeep(this.props.exports.data).sort((a, b) => {
									return this.compare(a, b);
								}),
							});
						}, 0);
					}}
					columnDefinitions={[
						{ id: "name", header: "Name", sortingField: "name", cell: (item) => <Button iconName={"status-info"} className={this.props.styles["btnUID"]} variant="link" children={item.name} /> },
						{
							id: "actions",
							header: "Actions",
							width: 355,
							minWidth: 355,
							cell: (item) => (
								<SpaceBetween direction="horizontal" size="l">
									<Button
										className={[this.props.styles["players-search-button"], this.props.styles["btn"]].join(" ")}
										onClick={() => {
											this.exportData(item.type);
										}}
										variant="primary"
										children="Export"
									/>
								</SpaceBetween>
							),
						},
					]}
					items={this.exports}
					header={<Box margin={{ bottom: "m" }} children={<Header children="Exports" counter={<>({this.exports.length})</>} />} />}
					empty="No exports found with requested filter"
				/>
			</>
		);
	}
}

function withRecoil(Component) {
	return function WrappedComponent(props) {
		const exports = useRecoilValue(exportsStore);
		const setExports = useSetRecoilState(exportsStore);
		const config = useRecoilValue(configStore);
		const setConfig = useSetRecoilState(configStore);
		const user = useRecoilValue(userStore);

		return <Component {...props} exports={exports} setExports={setExports} config={config} setConfig={setConfig} user={user} />;
	};
}

export default withRecoil(Exports);
