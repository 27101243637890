import { atom } from "recoil";

export const paymentStore = atom({
	key: "paymentStore",
	default: {
		show: false,
		loaded: false,
		statusLoaded: true,
		error: {
			text: "",
			code: 0,
			timestamp: 0,
		},
		data: [],
		deposits: [],
		withdrawals: [],
		selectedItems: [],
		sorting: {
			sortingColumn: {
				sortingField: "",
			},
			sortingDescending: false,
		},
		pagination: {
			current: 1,
			total: 0,
		},
	},
});
