import { atom } from "recoil";

export const statStore = atom({
	key: "statStore",
	default: {
		loaded: false,
		error: {
			text: "",
			code: 0,
			timestamp: 0,
		},
		data: [],
		chart: { exchanged: { total: [], count: [], correlation: [] }, deposits: { total: [], count: [], conversion: [] }, withdrawals: { total: [], count: [] }, profit: [] },
		average: 0,
		total: 0,
		period: 0,
		range: false,
		offset: 0,
		limit: 0,
		sorting: {
			sortingColumn: {
				sortingField: "deposits",
			},
			sortingDescending: false,
		},
		currentOption: {
			group: false,
			payWay: false,
			period: false,
		},
		currentGroup: false,
		currentPayWay: false,
		currentPeriod: false,
	},
});
