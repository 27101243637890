import { atom } from "recoil";

export const playerModalStore = atom({
	key: "playerModalStore",
	default: {
		show: false,
		type: false,
		title: false,
		description: false,
		units: false,
		description2: false,
		units2: false,
		item: false,
		value: "",
		value2: "",
	},
});
