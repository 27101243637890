import { atom } from "recoil";

export const playerSessionsModalStore = atom({
	key: "playerSessionsModalStore",
	default: {
		show: false,
		loaded: false,
		error: {
			text: "",
			code: 0,
			timestamp: 0,
		},
		count: 15,
		uid: 0,
		data: [],
		from: 0,
		to: 0,
		pagination: {
			current: 1,
			total: 0,
		},
		currentSearch: "",
	},
});
