import React from "react";

import cloneDeep from "lodash/cloneDeep";

import Modal from "@cloudscape-design/components/modal";
import Table from "@cloudscape-design/components/table";
import Pagination from "@cloudscape-design/components/pagination";
import Preferences from "@cloudscape-design/components/collection-preferences";
import Input from "@cloudscape-design/components/input";
import Header from "@cloudscape-design/components/header";

/* Recoil */
import { useRecoilValue, useSetRecoilState } from "recoil";
import { configStore } from "../stores/config";
import { gameStore } from "../stores/games";
import { playerGameLogStore } from "../stores/playerGameLog";
import { playerPageStore } from "../stores/playerPage";
import { playerStore } from "../stores/players";
/* ------- */

class PlayerGameLogModal extends React.Component {
	filter() {
		if (!this.props.playerGameLog.currentSearch.length) this.props.setPlayerGameLog({ ...this.props.playerGameLog, pagination: { current: 1, total: Math.ceil(this.props.playerGameLog.initialData.length / this.props.config.players.gameLog.perPage) }, data: cloneDeep(this.props.playerGameLog.initialData) });
		else {
			let data = cloneDeep(this.props.playerGameLog.initialData);

			data = data.filter((item) => {
				if (item.query.toString().indexOf(this.props.playerGameLog.currentSearch) !== -1 || item.response.toString().indexOf(this.props.playerGameLog.currentSearch) !== -1) return true;
			});

			this.props.setPlayerGameLog({ ...this.props.playerGameLog, pagination: { current: 1, total: Math.ceil(data.length / this.props.config.players.gameLog.perPage) }, data });
		}
	}

	render() {
		return (
			<>
				<Modal
					size="max"
					visible={this.props.playerGameLog.show}
					onDismiss={() => {
						this.props.setPlayerGameLog({ ...this.props.playerGameLog, show: false, loaded: false });
					}}
					header={<Header description={this.props.Interface.getGameByID(this.props.playerGameLog.gameID)} counter={"(" + this.props.playerGameLog.data.length + ")"} children="Session log" />}
					children={
						<Table
							header={
								<>
									<b>
										Start balance: {new Intl.NumberFormat("ru-RU").format((this.props.playerGameLog.from / 100).toFixed(2))} RUB. / End balance: {new Intl.NumberFormat("ru-RU").format((this.props.playerGameLog.to / 100).toFixed(2))} RUB. / Profit: {new Intl.NumberFormat("ru-RU").format(((this.props.playerGameLog.to - this.props.playerGameLog.from) / 100).toFixed(2))} RUB.
									</b>
								</>
							}
							stickyHeader={true}
							wrapLines={true}
							loading={!this.props.playerGameLog.loaded}
							resizableColumns={true}
							columnDefinitions={[
								{ id: "id", header: "ID", cell: (item) => item.id },
								{ id: "date", header: "Date", cell: (item) => new Date(item.date * 1000).toLocaleDateString() + " " + new Date(item.date * 1000).toLocaleTimeString() },
								{
									id: "request",
									header: "Request",
									minWidth: 900,
									cell: (item) => item.query,
								},
								{
									id: "response",
									header: "Response",
									cell: (item) => (
										<span
											style={{
												"max-height": "350px",
												"overflow-y": "scroll",
												"word-wrap": "break-word",
											}}>
											{item.response}
										</span>
									),
								},
							]}
							items={this.props.playerGameLog.data.slice((this.props.playerGameLog.pagination.current - 1) * this.props.config.players.log.perPage, this.props.playerGameLog.pagination.current * this.props.config.players.log.perPage)}
							pagination={
								<Pagination
									currentPageIndex={this.props.playerGameLog.pagination.current}
									pagesCount={this.props.playerGameLog.pagination.total}
									onChange={(page) => {
										this.props.setPlayerGameLog({ ...this.props.playerGameLog, pagination: { ...this.props.playerGameLog.pagination, current: page.detail.currentPageIndex } });
									}}
								/>
							}
							preferences={
								<Preferences
									cancelLabel="Cancel"
									confirmLabel="Confirm"
									pageSizePreference={{ title: "Logs per page", options: this.props.config.players.log.preferences.perPage }}
									preferences={{ pageSize: this.props.config.players.log.perPage }}
									title="Preferences"
									onConfirm={(preferences) => {
										localStorage.setItem("players.gameLog.perPage", preferences.detail.pageSize);
										this.props.setConfig({ ...this.props.config, players: { ...this.props.config.players, log: { ...this.props.config.players.log, perPage: preferences.detail.pageSize } } });
										this.props.setPlayerGameLog({ ...this.props.playerGameLog, pagination: { current: 1, total: Math.ceil(this.props.playerGameLog.data.length / preferences.detail.pageSize) } });
									}}
								/>
							}
							filter={
								<Input
									onKeyDown={this.handleKeyPress}
									disableBrowserAutocorrect={true}
									placeholder="Operation type, amount or something"
									inputMode="search"
									type="search"
									value={this.props.playerGameLog.currentSearch}
									onChange={(input) => {
										this.props.setPlayerGameLog({ ...this.props.playerGameLog, currentSearch: input.detail.value });
										setTimeout(() => {
											this.filter();
										}, 100);
									}}
								/>
							}
							empty="No players found with requested filter"
						/>
					}
				/>
			</>
		);
	}
}

function withRecoil(Component) {
	return function WrappedComponent(props) {
		const players = useRecoilValue(playerStore);
		const setPlayers = useSetRecoilState(playerStore);
		const config = useRecoilValue(configStore);
		const setConfig = useSetRecoilState(configStore);
		const playerPage = useRecoilValue(playerPageStore);
		const setPlayerPage = useSetRecoilState(playerPageStore);
		const game = useRecoilValue(gameStore);
		const setGame = useSetRecoilState(gameStore);
		const playerGameLog = useRecoilValue(playerGameLogStore);
		const setPlayerGameLog = useSetRecoilState(playerGameLogStore);

		return <Component {...props} players={players} setPlayers={setPlayers} config={config} setConfig={setConfig} playerGameLog={playerGameLog} setPlayerPage={setPlayerPage} playerPage={playerPage} setPlayerGameLog={setPlayerGameLog} game={game} setGame={setGame} />;
	};
}

export default withRecoil(PlayerGameLogModal);
