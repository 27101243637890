import React from "react";

import cloneDeep from "lodash/cloneDeep";

import Modal from "@cloudscape-design/components/modal";
import Input from "@cloudscape-design/components/input";
import Button from "@cloudscape-design/components/button";
import Box from "@cloudscape-design/components/box";
import Multiselect from "@cloudscape-design/components/multiselect";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Toggle from "@cloudscape-design/components/toggle";
import Spinner from "@cloudscape-design/components/spinner";
import RangePicker from "@cloudscape-design/components/date-range-picker";

/* Recoil */
import { useRecoilValue, useSetRecoilState } from "recoil";
import { configStore } from "../stores/config";
import { gameStore } from "../stores/games";
import { gamesEditModalStore } from "../stores/gamesEditModal";
/* ------- */

class GamesEditModal extends React.Component {
	componentDidUpdate(prevProps) {
		if (prevProps.gamesEditModal.id !== this.props.gamesEditModal.id && this.props.gamesEditModal.id) {
			setTimeout(() => {
				this.loadGameParams();
			}, 100);
		}
	}

	loadGameParams() {
		this.props.setGamesEditModal({ ...this.props.gamesEditModal, biases: { bias: 0, providerID: 0, from: null, from: null, auto: false }, id: false, loaded: false, rtpUp: "", select: false, new: false, newYear: false, minbet: false, tournament: false, top: false, crash: false, live: false, jackpot: false, halloween: false });

		this.props.Interface.request("get", { class: "data", module: "games", method: "params" }, { gameID: this.props.gamesEditModal.id, edit: true })
			.then((params) => {
				let gameParams = cloneDeep(this.props.gamesEditModal),
					keyParams = [],
					categoryID = [];

				Object.keys(params).forEach((key) => {
					if (key !== "categoryID") {
						if (key === "biases") {
							gameParams[key] = { ...params[key], from: params[key].from ? params[key].from.split("T")[0] : "", to: params[key].to ? params[key].to.split("T")[0] : "" };
						} else {
							gameParams[key] = params[key];
						}
						keyParams.push(key);
					} else
						this.props.config.game.options.category.slice(1).forEach((category) => {
							params[key].forEach((paramCategory) => {
								if (category.type === parseInt(paramCategory)) categoryID.push(category);
							});
						});
				});

				this.props.setGamesEditModal({ ...this.props.gamesEditModal, ...gameParams, loaded: true, params: keyParams, categoryID, newPoster: false, newBigPoster: false });
			})
			.catch((e) => {
				this.props.setGamesEditModal({ ...this.props.gamesEditModal, loaded: true });
				console.log(e);
			});
	}

	editGame() {
		this.props.setGamesEditModal({ ...this.props.gamesEditModal, loaded: false });

		let params = {};

		Object.keys(this.props.gamesEditModal).forEach((key) => {
			if (key === "categoryID") {
				params[key] = [];
				this.props.gamesEditModal[key].forEach((category) => {
					params[key].push(category.type);
				});
			} else if (key !== "show" && key !== "loaded" && key !== "biases") params[key] = this.props.gamesEditModal[key];
			else if (key === "biases") {
				params.bias = this.props.gamesEditModal.biases.bias;

				if (this.props.gamesEditModal.biases.from) params.biasDateFrom = this.props.gamesEditModal.biases.from + "T00:00:00";
				if (this.props.gamesEditModal.biases.to) params.biasDateTo = this.props.gamesEditModal.biases.to + "T00:00:00";
			}
		});

		params.id = this.props.gamesEditModal.editID;

		this.props.Interface.request("get", { class: "data", module: "games", method: "edit" }, { ...params })
			.then(() => {
				this.props.setGamesEditModal({ ...this.props.gamesEditModal, loaded: true });
				setTimeout(() => {
					this.props.setGamesEditModal({ ...this.props.gamesEditModal, show: false });
				}, 0);

				//setTimeout(() => { this.props.Interface.loadCasinoData() }, 500);
				this.props.Interface.updateGameData(params.id, params);
			})
			.catch((e) => {
				this.props.setGamesEditModal({ ...this.props.gamesEditModal, loaded: true });
				console.log(e);
			});
	}

	getBase64(file, key) {
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			this.updateParam(key, reader.result);
		};
	}

	updateParam(key, value) {
		let params = cloneDeep(this.props.gamesEditModal);
		params[key] = value;
		this.props.setGamesEditModal({ ...params });
	}

	render() {
		return (
			<>
				<Modal
					visible={this.props.gamesEditModal.show}
					onDismiss={() => {
						this.props.setGamesEditModal({ ...this.props.gamesEditModal, show: false });
					}}
					header="Edit game"
					children={
						<>
							<SpaceBetween direction="vertical" size="m">
								{this.props.gamesEditModal.loaded ? (
									<>
										<Multiselect
											filteringType="auto"
											selectedOptions={this.props.gamesEditModal.categoryID}
											onChange={(select) => {
												this.props.setGamesEditModal({ ...this.props.gamesEditModal, categoryID: select.detail.selectedOptions });
											}}
											disabled={!this.props.game.loaded}
											placeholder="Category"
											options={this.props.config.game.options.category.slice(1)}
										/>
										{this.props.gamesEditModal.params
											.filter((item) => {
												return item !== "select" && item !== "rtpUp" && item !== "bigPoster" && item !== "poster" && item !== "new" && item !== "newYear" && item !== "top" && item !== "active" && item !== "minbet" && item !== "halloween" && item !== "tournament" && item !== "biases" && item !== "live" && item !== "crash" && item !== "jackpot";
											})
											.map((item) => (
												<>
													<Box variant="span">{item}: </Box>
													<Input
														disableBrowserAutocorrect={true}
														placeholder={item}
														value={this.props.gamesEditModal[item]}
														onChange={(input) => {
															this.updateParam(item, input.detail.value);
														}}
													/>
												</>
											))}

										<Box variant="span">current poster:</Box>
										<Input disableBrowserAutocorrect={true} placeholder="poster" value={this.props.gamesEditModal.poster} disabled="true" />
										<Box variant="span">current big poster:</Box>
										<Input disableBrowserAutocorrect={true} placeholder="poster" value={this.props.gamesEditModal.bigPoster} disabled="true" />
										<Box variant="span">poster:</Box>
										<input
											type="file"
											onChange={(ev) => {
												this.getBase64(ev.target.files[0], "newPoster");
											}}></input>
										<Box variant="span">big poster:</Box>
										<input
											type="file"
											onChange={(ev) => {
												this.getBase64(ev.target.files[0], "newBigPoster");
											}}></input>
										<SpaceBetween direction="horizontal" size="m">
											{this.props.gamesEditModal.params
												.filter((item) => {
													return item === "new" || item === "top" || item === "active";
												})
												.map((item) => (
													<>
														<Toggle
															checked={this.props.gamesEditModal[item]}
															onChange={(checkbox) => {
																this.updateParam(item, checkbox.detail.checked);
															}}>
															{item}
														</Toggle>
													</>
												))}
											<Toggle
												checked={this.props.gamesEditModal["select"]}
												onChange={(checkbox) => {
													this.updateParam("select", checkbox.detail.checked);
												}}>
												select
											</Toggle>
											<Toggle
												checked={this.props.gamesEditModal["minbet"]}
												onChange={(checkbox) => {
													this.updateParam("minbet", checkbox.detail.checked);
												}}>
												minbet
											</Toggle>
											<Toggle
												checked={this.props.gamesEditModal["tournament"]}
												onChange={(checkbox) => {
													this.updateParam("tournament", checkbox.detail.checked);
												}}>
												tournament
											</Toggle>
											<Toggle
												checked={this.props.gamesEditModal["live"]}
												onChange={(checkbox) => {
													this.updateParam("live", checkbox.detail.checked);
												}}>
												live
											</Toggle>
											<Toggle
												checked={this.props.gamesEditModal["crash"]}
												onChange={(checkbox) => {
													this.updateParam("crash", checkbox.detail.checked);
												}}>
												crash
											</Toggle>
											<Toggle
												checked={this.props.gamesEditModal["jackpot"]}
												onChange={(checkbox) => {
													this.updateParam("jackpot", checkbox.detail.checked);
												}}>
												jackpot
											</Toggle>
											<Toggle
												checked={this.props.gamesEditModal["halloween"]}
												onChange={(checkbox) => {
													this.updateParam("halloween", checkbox.detail.checked);
												}}>
												halloween
											</Toggle>
										</SpaceBetween>
										<Input
											inputMode={"number"}
											type="number"
											disableBrowserAutocorrect={true}
											placeholder="rtpUp"
											value={this.props.gamesEditModal["rtpUp"]}
											onChange={(input) => {
												this.updateParam("rtpUp", input.detail.value);
											}}
										/>
										<SpaceBetween direction="vertical" size="m">
											<Box variant="span">bias:</Box>
											<Input
												inputMode={"number"}
												type="number"
												disableBrowserAutocorrect={true}
												placeholder="bias"
												value={this.props.gamesEditModal.biases.bias}
												onChange={(input) => {
													this.updateParam("biases", { ...this.props.gamesEditModal.biases, bias: input.detail.value });
												}}
											/>
											<Toggle
												checked={this.props.gamesEditModal["biases"]["auto"]}
												onChange={(checkbox) => {
													if (checkbox.detail.checked) {
														this.updateParam("biases", { ...this.props.gamesEditModal.biases,
															auto: checkbox.detail.checked,
															from: "1970-01-01",
															to: "9999-12-31"});
													} else {
														this.updateParam("biases", {
															...this.props.gamesEditModal.biases,
															auto: checkbox.detail.checked,
															from: null,
															to: null
														});
													}
												}}>
												indefinitely
											</Toggle>


												<Box variant="span">date from: </Box>
												<RangePicker
												relativeOptions={[]}
												clearButtonLabel="Clear"
												dateOnly={true}
												placeholder="Select range for show stats"
												onChange={(range) => {
													this.updateParam("biases", { ...this.props.gamesEditModal.biases, from: range.detail.value.startDate.length ? range.detail.value.startDate : null, to: range.detail.value.endDate.length ? range.detail.value.endDate : null });
												}}
												value={{ type: "absolute", startDate: this.props.gamesEditModal.biases.from, endDate: this.props.gamesEditModal.biases.to }}
												i18nStrings={{
													customRelativeRangeUnitLabel: "",
													formatRelativeRange: (value) => {
														return value.amount;
													},
													formatUnit: () => {
														return "";
													},
													nextMonthAriaLabel: "Next",
													applyButtonLabel: "Ok",
												}}
											/>
										</SpaceBetween>
									</>
								) : (
									<Box variant="div" textAlign="center">
										<Spinner size="large" />
									</Box>
								)}
							</SpaceBetween>
						</>
					}
					footer={
						<>
							<Box
								float="right"
								children={
									<>
										<Box
											variant="span"
											margin={{ right: "s" }}
											children={
												<Button
													variant="normal"
													onClick={() => {
														this.props.setGamesEditModal({ ...this.props.gamesEditModal, show: false });
													}}
													children="Close"
												/>
											}
										/>
										<Box
											variant="span"
											children={
												<Button
													variant="primary"
													onClick={() => {
														this.editGame();
													}}
													children="Ok"
												/>
											}
										/>
									</>
								}
							/>
						</>
					}
				/>
			</>
		);
	}
}

function withRecoil(Component) {
	return function WrappedComponent(props) {
		const gamesEditModal = useRecoilValue(gamesEditModalStore);
		const setGamesEditModal = useSetRecoilState(gamesEditModalStore);
		const game = useRecoilValue(gameStore);
		const setGame = useSetRecoilState(gameStore);
		const config = useRecoilValue(configStore);

		return <Component {...props} gamesEditModal={gamesEditModal} setGamesEditModal={setGamesEditModal} game={game} setGame={setGame} config={config} />;
	};
}

export default withRecoil(GamesEditModal);
