import React from "react";

import cloneDeep from "lodash/cloneDeep";

import Modal from "@cloudscape-design/components/modal";
import Table from "@cloudscape-design/components/table";
import Pagination from "@cloudscape-design/components/pagination";
import Preferences from "@cloudscape-design/components/collection-preferences";
import Alert from "@cloudscape-design/components/alert";
import Box from "@cloudscape-design/components/box";
import Badge from "@cloudscape-design/components/badge";
import Tabs from "@cloudscape-design/components/tabs";
import Input from "@cloudscape-design/components/input";
import Button from "@cloudscape-design/components/button";
import Header from "@cloudscape-design/components/header";

/* Recoil */
import { useRecoilValue, useSetRecoilState } from "recoil";
import { configStore } from "../stores/config";
import { paymentStore } from "../stores/payments";
import { playerPageStore } from "../stores/playerPage";
import { userStore } from "../stores/access";
/* ------- */

class PlayerPaymentsModal extends React.Component {
	getColorByState(state) {
		switch (state) {
			case "waiting":
				return "blue";
			case "canceled":
				return "dark";
			case "error":
				return "red";
			case "success":
				return "green";
			default:
				return "cyan";
		}
	}

	compare(a, b) {
		if (a[this.props.payments.sorting.sortingColumn.sortingField] < b[this.props.payments.sorting.sortingColumn.sortingField]) {
			return this.props.payments.sorting.isDescending ? 1 : -1;
		}

		if (a[this.props.payments.sorting.sortingColumn.sortingField] > b[this.props.payments.sorting.sortingColumn.sortingField]) {
			return this.props.payments.sorting.isDescending ? -1 : 1;
		}

		return 0;
	}

	switchTab(tab) {
		switch (tab) {
			case "deposits":
				this.props.setPayments({ ...this.props.payments, type: 1, currentSearch: "", pagination: { current: 1, total: Math.ceil(this.props.payments.deposits.length / this.props.config.players.payments.perPage) }, data: cloneDeep(this.props.payments.deposits) });
				break;
			case "withdrawals":
				this.props.setPayments({ ...this.props.payments, type: 2, currentSearch: "", pagination: { current: 1, total: Math.ceil(this.props.payments.withdrawals.length / this.props.config.players.payments.perPage) }, data: cloneDeep(this.props.payments.withdrawals) });
				break;
		}
	}

	filter() {
		let data = cloneDeep(this.props.payments.data);
		if (!this.props.payments.currentSearch.length) {
			data.forEach((item) => {
				item.filtered = true;
			});
			this.props.setPayments({ ...this.props.payments, data });

			setTimeout(() => {
				let total = Math.ceil(
					this.props.payments.data.filter((item) => {
						return item.filtered;
					}).length / this.props.config.players.payments.perPage,
				);
				this.props.setPayments({ ...this.props.payments, pagination: { current: 1, total } });
			}, 0);
		} else {
			data.forEach((item) => {
				if (item.amount.toString().indexOf(this.props.payments.currentSearch) !== -1 || item.type.toUpperCase().indexOf(this.props.payments.currentSearch.toUpperCase()) !== -1 || item.id.toString().indexOf(this.props.payments.currentSearch) !== -1 || (this.props.payments.type === 2 && item.wallet.toString().indexOf(this.props.payments.currentSearch) !== -1) || (this.props.payments.type === 2 && item.note.toString().indexOf(this.props.payments.currentSearch) !== -1))
					item.filtered = true;
				else item.filtered = false;
			});

			this.props.setPayments({ ...this.props.payments, data });

			setTimeout(() => {
				let total = Math.ceil(
					this.props.payments.data.filter((item) => {
						return item.filtered;
					}).length / this.props.config.players.payments.perPage,
				);
				this.props.setPayments({ ...this.props.payments, pagination: { current: 1, total } });
			}, 0);
		}
	}

	checkPayment(item) {
		let payWay = item.type;
		item = cloneDeep(item);

		this.props.setPayments({ ...this.props.payments, statusLoaded: false });

		fetch(this.props.config.paymentApi.servlet + "/?method=check&type=" + payWay + "&paymentID=" + item.id + "&module=" + this.props.config.paymentApi.id, {
			method: "GET",
			headers: { "Content-Type": "application/json" },
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (typeof data.data !== "undefined")
					switch (data.data) {
						case 1:
							item.status = "success";
							this.props.Interface.addNoty("Success", "This payment already has been processed at: " + new Date(data.date * 1000).toLocaleDateString() + " " + new Date(data.date * 1000).toLocaleTimeString());
							break;
						case 2:
							item.status = "waiting";
							this.props.Interface.addNoty("Info", "This payment was not paid yet");
							break;
						case 3:
							item.status = "error";
							this.props.Interface.addNoty("Error", "This payment already has an error status in a payment system");
							break;
						case 4:
							this.props.Interface.addNoty("Error", "Undefined status was returned from a payment system");
							break;
						case 5:
							item.status = "success";
							this.props.Interface.addNoty("Success", "Payment was successfully rechecked and credited to user's account");
							break;
						case 6:
							this.props.Interface.addNoty("Info", "Payment was expired");
							break;
						case 11:
							this.props.Interface.addNoty("Info", "Undefined error was returned from QIWI");
							break;
					}
				else this.props.Interface.addNoty("Error", data.error);

				let payments = cloneDeep(this.props.payments.data);

				payments.forEach((payment) => {
					if (payment.id === item.id) payment.status = item.status;
				});

				this.props.setPayments({ ...this.props.payments, data: payments, statusLoaded: true, selectedItems: [] });
			});
	}

	render() {
		return (
			<>
				<Modal
					size="max"
					visible={this.props.payments.show}
					onDismiss={() => {
						this.props.setPayments({ ...this.props.payments, show: false });
					}}
					header={<Header counter={"(" + this.props.playerPage.uid + ")"} children="Player payments" />}
					children={
						<>
							{this.props.payments.loaded && this.props.payments.error.text.length ? (
								<Box
									margin={{ bottom: "m" }}
									children={
										<Alert
											type="error"
											header="Error loading data:"
											children={
												<>
													<Box variant="p" children={this.props.payments.error.text} />
													<hr />
													<Box
														variant="p"
														children={
															<>
																request error code: {this.props.payments.error.code} | request timestamp: {this.props.payments.error.timestamp}
															</>
														}
													/>
												</>
											}
										/>
									}
								/>
							) : (
								<></>
							)}

							<Tabs
								activeTabId={this.props.payments.type === 1 ? "deposits" : "withdrawals"}
								onChange={(tab) => {
									this.switchTab(tab.detail.activeTabId);
								}}
								tabs={[
									{
										id: "deposits",
										label: "Deposits",
										content: (
											<Table
												stickyHeader={true}
												loading={!this.props.payments.loaded}
												sortingColumn={{ sortingField: this.props.payments.sorting.sortingColumn.sortingField }}
												sortingDescending={this.props.payments.sorting.isDescending}
												onSortingChange={(sorting) => {
													this.props.setPayments({ ...this.props.payments, sorting: sorting.detail });
													setTimeout(() => {
														this.props.setPayments({
															...this.props.payments,
															data: cloneDeep(this.props.payments.data).sort((a, b) => {
																return this.compare(a, b);
															}),
														});
													}, 0);
												}}
												columnDefinitions={[
													{ id: "id", header: "ID", sortingField: "id", cell: (item) => "#" + item.id },
													{ id: "amount", header: "Amount", sortingField: "amount", cell: (item) => <>{new Intl.NumberFormat("ru-RU").format(item.amount)} RUB.</> },
													{ id: "type", header: "Provider", sortingField: "type", cell: (item) => item.type },
													{ id: "date", header: "Date", sortingField: "date", cell: (item) => <>{new Date(item.date).toLocaleDateString() + " " + new Date(item.date).toLocaleTimeString()}</> },
													{
														id: "status",
														header: "Status",
														cell: (item) => (
															<>
																{" "}
																<Badge className={[this.props.styles["badge"], this.props.styles[this.getColorByState(item.status)]].join(" ")} children={item.status.toUpperCase()} />{" "}
															</>
														),
													},
												]}
												items={this.props.payments.data
													.filter((item) => {
														return item.filtered;
													})
													.slice((this.props.payments.pagination.current - 1) * this.props.config.players.payments.perPage, this.props.payments.pagination.current * this.props.config.players.payments.perPage)}
												pagination={
													<Pagination
														disabled={!this.props.payments.loaded}
														currentPageIndex={this.props.payments.pagination.current}
														pagesCount={this.props.payments.pagination.total}
														onChange={(page) => {
															this.props.setPayments({ ...this.props.payments, pagination: { ...this.props.payments.pagination, current: page.detail.currentPageIndex } });
														}}
													/>
												}
												preferences={
													<Preferences
														cancelLabel="Cancel"
														confirmLabel="Confirm"
														pageSizePreference={{ title: "Logs per page", options: this.props.config.players.payments.preferences.perPage }}
														preferences={{ pageSize: this.props.config.players.payments.perPage }}
														title="Preferences"
														onConfirm={(preferences) => {
															localStorage.setItem("players.payments.perPage", preferences.detail.pageSize);
															this.props.setConfig({ ...this.props.config, players: { ...this.props.config.players, payments: { ...this.props.config.players.payments, perPage: preferences.detail.pageSize } } });
															this.props.setPayments({
																...this.props.payments,
																pagination: {
																	current: 1,
																	total: Math.ceil(
																		this.props.payments.data.filter((item) => {
																			return item.filtered;
																		}).length / preferences.detail.pageSize,
																	),
																},
															});
														}}
													/>
												}
												empty="No payments found with requested filter"
												filter={
													<Input
														type="search"
														inputMode="search"
														disableBrowserAutocorrect={true}
														placeholder="Payment provider, id or something"
														value={this.props.payments.currentSearch}
														onChange={(input) => {
															this.props.setPayments({ ...this.props.payments, currentSearch: input.detail.value });
															setTimeout(() => {
																this.filter();
															}, 100);
														}}
													/>
												}
												selectionType="single"
												onSelectionChange={(selection) => {
													this.props.setPayments({ ...this.props.payments, selectedItems: selection.detail.selectedItems });
												}}
												selectedItems={this.props.payments.selectedItems}
												header={
													<Box
														margin={{ bottom: "m" }}
														children={
															<Header
																children="Deposits"
																counter={
																	<>
																		(
																		{
																			this.props.payments.data.filter((item) => {
																				return item.filtered;
																			}).length
																		}
																		)
																	</>
																}
																description="Below listed last selected user's deposits"
																actions={
																	<Button
																		disabled={
																			!this.props.payments.statusLoaded ||
																			(this.props.payments.selectedItems.length && !this.props.payments.selectedItems[0].filtered) ||
																			!this.props.payments.selectedItems.length ||
																			!(this.props.payments.selectedItems[0].status !== "success" && (this.props.payments.selectedItems[0].type === "qiwi" || this.props.payments.selectedItems[0].type === "card" || this.props.payments.selectedItems[0].type === "advcash" || this.props.payments.selectedItems[0].type === "payeer"))
																		}
																		variant="primary"
																		children="Recheck payment"
																		onClick={() => {
																			this.checkPayment(this.props.payments.selectedItems[0]);
																		}}
																	/>
																}
															/>
														}
													/>
												}
											/>
										),
									},
									{
										id: "withdrawals",
										label: "Withdrawals",
										content: (
											<Table
												stickyHeader={true}
												loading={!this.props.payments.loaded}
												sortingColumn={{ sortingField: this.props.payments.sorting.sortingColumn.sortingField }}
												sortingDescending={this.props.payments.sorting.isDescending}
												onSortingChange={(sorting) => {
													this.props.setPayments({ ...this.props.payments, sorting: sorting.detail });
													setTimeout(() => {
														this.props.setPayments({
															...this.props.payments,
															data: cloneDeep(this.props.payments.data).sort((a, b) => {
																return this.compare(a, b);
															}),
														});
													}, 0);
												}}
												columnDefinitions={[
													{ id: "id", header: "ID", sortingField: "id", cell: (item) => "#" + item.id },
													{ id: "amount", header: "Amount", sortingField: "amount", cell: (item) => <>{new Intl.NumberFormat("ru-RU").format(item.amount)} RUB.</> },
													{ id: "type", header: "Provider", sortingField: "type", cell: (item) => item.type },
													{ id: "note", header: "Note", cell: (item) => item.note },
													{ id: "wallet", header: "Wallet", cell: (item) => item.wallet },
													{ id: "date", header: "Date", sortingField: "date", cell: (item) => <>{new Date(item.date).toLocaleDateString() + " " + new Date(item.date).toLocaleTimeString()}</> },
													{
														id: "status",
														header: "Status",
														cell: (item) => (
															<>
																{" "}
																<Badge className={[this.props.styles["badge"], this.props.styles[this.getColorByState(item.status)]].join(" ")} children={item.status.toUpperCase()} />
															</>
														),
													},
												]}
												items={this.props.payments.data
													.filter((item) => {
														return item.filtered;
													})
													.slice((this.props.payments.pagination.current - 1) * this.props.config.players.payments.perPage, this.props.payments.pagination.current * this.props.config.players.payments.perPage)}
												pagination={
													<Pagination
														disabled={!this.props.payments.loaded}
														currentPageIndex={this.props.payments.pagination.current}
														pagesCount={this.props.payments.pagination.total}
														onChange={(page) => {
															this.props.setPayments({ ...this.props.payments, pagination: { ...this.props.payments.pagination, current: page.detail.currentPageIndex } });
														}}
													/>
												}
												preferences={
													<Preferences
														cancelLabel="Cancel"
														confirmLabel="Confirm"
														pageSizePreference={{ title: "Logs per page", options: this.props.config.players.payments.preferences.perPage }}
														preferences={{ pageSize: this.props.config.players.payments.perPage }}
														title="Preferences"
														onConfirm={(preferences) => {
															localStorage.setItem("players.payments.perPage", preferences.detail.pageSize);
															this.props.setConfig({ ...this.props.config, players: { ...this.props.config.players, payments: { ...this.props.config.players.payments, perPage: preferences.detail.pageSize } } });
															this.props.setPayments({
																...this.props.payments,
																pagination: {
																	current: 1,
																	total: Math.ceil(
																		this.props.payments.data.filter((item) => {
																			return item.filtered;
																		}).length / preferences.detail.pageSize,
																	),
																},
															});
														}}
													/>
												}
												empty="No payments found with requested filter"
												filter={
													<Input
														disableBrowserAutocorrect={true}
														placeholder="Payment provider, id or somethind"
														value={this.props.payments.currentSearch}
														onChange={(input) => {
															this.props.setPayments({ ...this.props.payments, currentSearch: input.detail.value });
															setTimeout(() => {
																this.filter();
															}, 100);
														}}
													/>
												}
												header={
													<Box
														margin={{ bottom: "m" }}
														children={
															<Header
																children="Withdrawals"
																counter={
																	<>
																		(
																		{
																			this.props.payments.data.filter((item) => {
																				return item.filtered;
																			}).length
																		}
																		)
																	</>
																}
																description="Below listed last selected user's withdrawals"
															/>
														}
													/>
												}
											/>
										),
									},
								]}
							/>
						</>
					}
				/>
			</>
		);
	}
}

function withRecoil(Component) {
	return function WrappedComponent(props) {
		const config = useRecoilValue(configStore);
		const setConfig = useSetRecoilState(configStore);
		const payments = useRecoilValue(paymentStore);
		const setPayments = useSetRecoilState(paymentStore);
		const playerPage = useRecoilValue(playerPageStore);
		const setPlayerPage = useSetRecoilState(playerPageStore);
		const user = useRecoilValue(userStore);
		const setUser = useSetRecoilState(userStore);

		return <Component {...props} config={config} setConfig={setConfig} payments={payments} setPayments={setPayments} playerPage={playerPage} setPlayerPage={setPlayerPage} user={user} setUser={setUser} />;
	};
}

export default withRecoil(PlayerPaymentsModal);
