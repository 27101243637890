import React from "react";
import Modal from "@cloudscape-design/components/modal";
import Table from "@cloudscape-design/components/table";
import Pagination from "@cloudscape-design/components/pagination";
import Preferences from "@cloudscape-design/components/collection-preferences";
import Input from "@cloudscape-design/components/input";
import Tabs from "@cloudscape-design/components/tabs";
import Button from "@cloudscape-design/components/button";
import Grid from "@cloudscape-design/components/grid";
import Box from "@cloudscape-design/components/box";
import Textarea from "@cloudscape-design/components/textarea";
import Badge from "@cloudscape-design/components/badge";
import Link from "@cloudscape-design/components/link";
import Spinner from "@cloudscape-design/components/spinner";
import Container from "@cloudscape-design/components/container";
import ExpandableSection from "@cloudscape-design/components/expandable-section";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Icon from "@cloudscape-design/components/icon";
import Header from "@cloudscape-design/components/header";

/* Recoil */
import { useRecoilValue, useSetRecoilState } from "recoil";
import { configStore } from "../stores/config";
import { userStore } from "../stores/access";
import { staffStore } from "../stores/staff";
import { chatBanUserModalStore } from "../stores/chatBanUserModal";
/* ------- */

class Chat extends React.Component {
	state = {
		loaded: false,
		chat: [],
	};

	constructor(props) {
		super(props);
		this.loadChat();
	}

	loadChat() {
		this.setState({ chat: [], loaded: false });
		this.props.Interface.request("get", { class: "data", module: "chat", method: "get" })
			.then((chat) => {
				console.log(chat);
				this.setState({ chat, loaded: true });
			})
			.catch((e) => {
				console.log(e);
			});
	}

	banUser(uid) {
		this.props.Interface.request("set", { class: "data", module: "chat", method: "ban" }, { uid })
			.then(() => {
				this.props.Interface.addNoty("Success", "User #" + uid + " banned");
			})
			.catch((e) => {
				this.props.Interface.addNoty("Error", e);
			});
	}

	removeMessage(id) {
		this.props.Interface.request("set", { class: "data", module: "chat", method: "remove" }, { id })
			.then(() => {
				this.props.Interface.addNoty("Success", "Message #" + id + " removed");
			})
			.catch((e) => {
				this.props.Interface.addNoty("Error", e);
			});
	}

	render() {
		return (
			<>
				<Container>
					<Table
						stickyHeader="true"
						trackBy="id"
						resizableColumns={true}
						loading={!this.state.loaded}
						columnDefinitions={[
							{
								minWidth: 100,
								id: "uid",
								header: "UID",
								sortingField: "uid",
								cell: (item) => (
									<Button
										iconName={"status-in-progress"}
										className={[this.props.styles["btnUID"], this.props.styles["blue"]].join(" ")}
										variant="normal"
										onClick={() => {
											this.props.Interface.loadInfo(item.uid);
										}}
										children={item.uid}
									/>
								),
							},
							{ minWidth: 100, id: "name", header: "Name", sortingField: "name", cell: (item) => item.name },
							{
								id: "message",
								header: "Message",
								width: 500,
								minWidth: 500,
								cell: (item) => <span style={{ width: 1200, display: "block", whiteSpace: "normal" }}>{item.message}</span>,
							},
							{ id: "date", header: "Date", cell: (item) => new Date(item.date * 1000).toLocaleDateString() + " " + new Date(item.date * 1000).toLocaleTimeString() },
							{
								id: "actions",
								header: "Actions",
								width: 355,
								minWidth: 355,
								cell: (item) => (
									<SpaceBetween direction="horizontal" size="l">
										<Button
											className={[this.props.styles["players-search-button"], this.props.styles["btn"]].join(" ")}
											onClick={() => {
												this.props.setChatBanUserModal({ ...this.props.chatBanUserModal, show: true, uid: item.uid });
											}}
											variant="primary"
											children="Ban"
										/>
										<Button
											className={[this.props.styles["players-search-button"], this.props.styles["btn"]].join(" ")}
											onClick={() => {
												this.removeMessage(item.id);
											}}
											variant="link"
											children="Remove"
										/>
									</SpaceBetween>
								),
							},
						]}
						items={this.state.chat}
						header={
							<Box
								margin={{ bottom: "m" }}
								children={
									<Header
										actions={
											<SpaceBetween direction="horizontal" size="l">
												<Button
													iconName="refresh"
													disabled={!this.state.loaded}
													className={[this.props.styles["players-search-button"], this.props.styles["btn"]].join(" ")}
													onClick={() => {
														setTimeout(() => {
															this.loadChat();
														}, 0);
													}}
													variant="normal"
												/>
											</SpaceBetween>
										}
										children="Chat"
										counter={<>({this.state.chat.length})</>}
									/>
								}
							/>
						}
						empty="No chat found with requested filter"
					/>
				</Container>
			</>
		);
	}
}

function withRecoil(Component) {
	return function WrappedComponent(props) {
		const chatBanUserModal = useRecoilValue(chatBanUserModalStore);
		const setChatBanUserModal = useSetRecoilState(chatBanUserModalStore);
		const config = useRecoilValue(configStore);

		return <Component {...props} chatBanUserModal={chatBanUserModal} setChatBanUserModal={setChatBanUserModal} config={config} />;
	};
}

export default withRecoil(Chat);
