import { atom } from "recoil";

export const staffLogModalStore = atom({
	key: "staffLogModalStore",
	default: {
		show: false,
		loaded: false,
		error: {
			text: "",
			code: 0,
			timestamp: 0,
		},
		count: 15,
		token: 0,
		data: [],
		from: 0,
		to: 0,
		pagination: {
			current: 1,
			total: 0,
		},
		currentSearch: "",
	},
});
