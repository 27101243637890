import { atom } from "recoil";

export const playerGameLogStore = atom({
	key: "playerGameLogStore",
	default: {
		show: false,
		loaded: false,
		error: {
			text: "",
			code: 0,
			timestamp: 0,
		},
		count: 50,
		gameID: 0,
		sessionID: 0,
		data: [],
		from: 0,
		to: 0,
		pagination: {
			current: 1,
			total: 0,
		},
		currentSearch: "",
	},
});
