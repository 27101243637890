import { atom } from "recoil";

export const gamesEditModalStore = atom({
	key: "gamesEditModalStore",
	default: {
		show: false,
		loaded: true,
		gameID: false,
		id: false,
		editID: false,
		providerID: false,
		categoryID: [],
		new: true,
		top: false,
		newYear: false,
		minbet: false,
		halloween: false,
		jackpot: false,
		crash: false,
		live: false,
		tournament: false,
		rtpUp: "",
		select: false,
		name: "",
		poster: "",
		volatility: 65,
		rtp: 95,
		newPoster: false,
		newBigPoster: false,
		params: [],
		biases: {
			bias: 0,
			auto: false,
			from: null,
			to: null,
			providerID: 0,
		},
	},
});
