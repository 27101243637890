import React from "react";

import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

import Spinner from "@cloudscape-design/components/spinner";
import Box from "@cloudscape-design/components/box";
import Header from "@cloudscape-design/components/header";
import Container from "@cloudscape-design/components/container";
import Alert from "@cloudscape-design/components/alert";
import Button from "@cloudscape-design/components/button";
import Input from "@cloudscape-design/components/input";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Tabs from "@cloudscape-design/components/tabs";
/* Recoil */
import { useRecoilValue, useSetRecoilState } from "recoil";
import { backendStore, frontendStore, languageStore, settingsStore } from "../stores/settings";
import { configStore } from "../stores/config";

import { userStore } from "../stores/access";
/* ------- */

class Settings extends React.Component {
	constructor(props) {
		super(props);
		this.loadConfig();
	}

	loadConfig() {
		this.props.setSettings({ ...this.props.settings, loaded: false });

		this.props.Interface.request("get", { class: "data", module: "config", method: "frontend" })
			.then((frontend) => {
				this.props.setFrontend({ ...this.props.frontend, data: frontend, initialData: frontend, loaded: true });
				this.props.Interface.request("get", { class: "data", module: "config", method: "backend" })
					.then((backend) => {
						this.props.setBackend({ ...this.props.backend, data: backend, initialData: backend, loaded: true });
						this.props.Interface.request("get", { class: "data", module: "config", method: "language" })
							.then((language) => {
								this.props.setLanguage({ ...this.props.language, data: language, initialData: language, loaded: true });

								this.props.setSettings({ ...this.props.settings, changes: false, loaded: true });
							})
							.catch((e) => {
								this.props.setSettings({ ...this.props.settings, loaded: true, error: e });
							});
					})
					.catch((e) => {
						this.props.setSettings({ ...this.props.settings, loaded: true, error: e });
					});
			})
			.catch((e) => {
				this.props.setSettings({ ...this.props.settings, loaded: true, error: e });
			});
	}

	updateSettings(value, keys, type) {
		let config = type === "frontend" ? cloneDeep(this.props.frontend.data) : type === "backend" ? cloneDeep(this.props.backend.data) : cloneDeep(this.props.language.data);

		if (keys.length === 2) config[keys[0]].value[keys[1]].value = value;
		else if (keys.length === 3) config[keys[0]].value[keys[1]].value[keys[2]].value = value;
		else if (keys.length === 4) config[keys[0]].value[keys[1]].value[keys[2]].value[keys[3]].value = value;
		else if (keys.length === 5) config[keys[0]].value[keys[1]].value[keys[2]].value[keys[3]].value[keys[4]].value = value;
		else if (keys.length === 6) config[keys[0]].value[keys[1]].value[keys[2]].value[keys[3]].value[keys[4]].value[keys[5]].value = value;
		else if (keys.length === 7) config[keys[0]].value[keys[1]].value[keys[2]].value[keys[3]].value[keys[4]].value[keys[5]].value[keys[6]].value = value;

		switch (type) {
			case "frontend":
				this.props.setFrontend({ ...this.props.frontend, data: config });
				break;
			case "backend":
				this.props.setBackend({ ...this.props.backend, data: config });
				break;
			case "language":
				this.props.setLanguage({ ...this.props.language, data: config });
				break;
		}
	}

	listArray(array, keys, type) {
		let copyKeys = cloneDeep(keys);

		return array.map((item, i) =>
			item.xtype === "string" || item.xtype === "integer" || item.xtype === "float" || item.xtype === "bool" ? (
				<Box margin={{ vertical: "m" }}>
					<Container variant="stacked" header={<Header description={copyKeys.slice(1).join(" - ") + " - #" + (i + 1) + " (" + item.xtype + ")"} />}>
						<Input
							value={item.value}
							onChange={(input) => {
								this.updateSettings(input.detail.value, this.pushKey(cloneDeep(copyKeys), i), type);
							}}
						/>
					</Container>
				</Box>
			) : item.xtype === "array" ? (
				this.listArray(item.value, this.pushKey(cloneDeep(copyKeys), i), type)
			) : (
				this.listObject(item.value, this.pushKey(cloneDeep(copyKeys), i), type)
			),
		);
	}

	listObject(object, keys, type) {
		let copyKeys = cloneDeep(keys);

		return Object.keys(object).map((key) =>
			object[key].xtype === "string" || object[key].xtype === "integer" || object[key].xtype === "float" || object[key].xtype === "bool" ? (
				<Box margin={{ vertical: "m" }}>
					<Container variant="stacked" header={<Header description={copyKeys.slice(1).join(" - ") + " - " + key + " (" + object[key].xtype + ")"} />}>
						<Input
							value={object[key].value}
							onChange={(input) => {
								this.updateSettings(input.detail.value, this.pushKey(cloneDeep(copyKeys), key), type);
							}}
						/>
					</Container>
				</Box>
			) : object[key].xtype === "array" ? (
				this.listArray(object[key].value, this.pushKey(cloneDeep(copyKeys), key), type)
			) : (
				this.listObject(object[key].value, this.pushKey(cloneDeep(copyKeys), key), type)
			),
		);
	}

	saveSettings(confirm) {
		let dataToChange = {};

		switch (this.props.settings.tab) {
			case "frontend":
				Object.keys(this.props.frontend.data).forEach((key) => {
					Object.keys(this.props.frontend.initialData).forEach((key2) => {
						if (key === key2) {
							if (!isEqual(this.props.frontend.data[key], this.props.frontend.initialData[key2])) {
								dataToChange[key] = { xtype: "object", value: {} };
								Object.keys(this.props.frontend.data[key].value).forEach((innerKey) => {
									Object.keys(this.props.frontend.initialData[key].value).forEach((innerKey2) => {
										if (innerKey === innerKey2) {
											if (!isEqual(this.props.frontend.data[key].value[innerKey], this.props.frontend.initialData[key].value[innerKey2])) dataToChange[key].value[innerKey] = this.props.frontend.data[key].value[innerKey];
										}
									});
								});
							}
						}
					});
				});

				if (!Object.keys(dataToChange).length) this.props.Interface.addNoty("Error", "No changes was made");
				else if (typeof confirm === "undefined" || !confirm) this.props.setSettings({ ...this.props.settings, changes: dataToChange });
				else {
					this.props.setSettings({ ...this.props.settings, loaded: false });
					this.props.Interface.request("set", { class: "data", module: "config", method: "frontend" }, { ...dataToChange })
						.then((config) => {
							this.loadConfig();
						})
						.catch((e) => {
							this.props.setSettings({ ...this.props.settings, loaded: true, error: e });
						});
				}
				break;
			case "backend":
				Object.keys(this.props.backend.data).forEach((key) => {
					Object.keys(this.props.backend.initialData).forEach((key2) => {
						if (key === key2) {
							if (!isEqual(this.props.backend.data[key], this.props.backend.initialData[key2])) {
								dataToChange[key] = { xtype: "object", value: {} };
								Object.keys(this.props.backend.data[key].value).forEach((innerKey) => {
									Object.keys(this.props.backend.initialData[key].value).forEach((innerKey2) => {
										if (innerKey === innerKey2) {
											if (!isEqual(this.props.backend.data[key].value[innerKey], this.props.backend.initialData[key].value[innerKey2])) dataToChange[key].value[innerKey] = this.props.backend.data[key].value[innerKey];
										}
									});
								});
							}
						}
					});
				});

				if (!Object.keys(dataToChange).length) this.props.Interface.addNoty("Error", "No changes was made");
				else if (typeof confirm === "undefined" || !confirm) this.props.setSettings({ ...this.props.settings, changes: dataToChange });
				else {
					this.props.setSettings({ ...this.props.settings, loaded: false });
					this.props.Interface.request("set", { class: "data", module: "config", method: "backend" }, { ...dataToChange })
						.then((config) => {
							this.loadConfig();
						})
						.catch((e) => {
							this.props.setSettings({ ...this.props.settings, loaded: true, error: e });
						});
				}
				break;
			case "language":
				Object.keys(this.props.language.data).forEach((key) => {
					Object.keys(this.props.language.initialData).forEach((key2) => {
						if (key === key2) {
							if (!isEqual(this.props.language.data[key], this.props.language.initialData[key2])) {
								dataToChange[key] = { xtype: "object", value: this.props.language.data[key] };
							}
						}
					});
				});

				if (!Object.keys(dataToChange).length) this.props.Interface.addNoty("Error", "No changes was made");
				else if (typeof confirm === "undefined" || !confirm) this.props.setSettings({ ...this.props.settings, changes: dataToChange });
				else {
					this.props.setSettings({ ...this.props.settings, loaded: false });
					this.props.Interface.request("set", { class: "data", module: "config", method: "language" }, { ...dataToChange })
						.then((config) => {
							this.loadConfig();
						})
						.catch((e) => {
							this.props.setSettings({ ...this.props.settings, loaded: true, error: e });
						});
				}
				break;
		}
	}

	pushKey(array, key) {
		array.push(key);
		return array;
	}

	getConfigTabs(type) {
		let tabs = [];

		if (type === "frontend")
			Object.keys(this.props.frontend.data).map((key) =>
				tabs.push({
					id: key,
					label: key,
					content: (
						<SpaceBetween direction="vertical" size="l">
							{Object.keys(this.props.frontend.data[key].value).map((key2) => (
								<Box>
									<Container
										header={
											<Header>
												{key2} ({this.props.frontend.data[key].value[key2].xtype})
											</Header>
										}>
										{this.props.frontend.data[key].value[key2].xtype === "string" || this.props.frontend.data[key].value[key2].xtype === "integer" || this.props.frontend.data[key].value[key2].xtype === "float" || this.props.frontend.data[key].value[key2].xtype === "bool" ? (
											<Input
												value={this.props.frontend.data[key].value[key2].value}
												onChange={(input) => {
													this.updateSettings(input.detail.value, [key, key2], "frontend");
												}}
											/>
										) : this.props.frontend.data[key].value[key2].xtype === "array" ? (
											this.listArray(this.props.frontend.data[key].value[key2].value, [key, key2], "frontend")
										) : (
											this.listObject(this.props.frontend.data[key].value[key2].value, [key, key2], "frontend")
										)}
									</Container>
								</Box>
							))}
						</SpaceBetween>
					),
				}),
			);
		else if (type === "backend")
			Object.keys(this.props.backend.data).map((key) =>
				tabs.push({
					id: key,
					label: key,
					content: (
						<SpaceBetween direction="vertical" size="l">
							{Object.keys(this.props.backend.data[key].value).map((key2) => (
								<Box>
									<Container
										header={
											<Header>
												{key2} ({this.props.backend.data[key].value[key2].xtype})
											</Header>
										}>
										{this.props.backend.data[key].value[key2].xtype === "string" || this.props.backend.data[key].value[key2].xtype === "integer" || this.props.backend.data[key].value[key2].xtype === "float" || this.props.backend.data[key].value[key2].xtype === "bool" ? (
											<Input
												value={this.props.backend.data[key].value[key2].value}
												onChange={(input) => {
													this.updateSettings(input.detail.value, [key, key2], "backend");
												}}
											/>
										) : this.props.backend.data[key].value[key2].xtype === "array" ? (
											this.listArray(this.props.backend.data[key].value[key2].value, [key, key2], "backend")
										) : (
											this.listObject(this.props.backend.data[key].value[key2].value, [key, key2], "backend")
										)}
									</Container>
								</Box>
							))}
						</SpaceBetween>
					),
				}),
			);
		else if (type === "language")
			Object.keys(this.props.language.data).map((key) =>
				tabs.push({
					id: key,
					label: key,
					content: <Tabs tabs={this.getLanguageTabs(key)} />,
				}),
			);

		return tabs;
	}

	getLanguageTabs(lang) {
		let tabs = [];

		Object.keys(this.props.language.data[lang].value)
			.filter((key) => {
				return key !== "undefined";
			})
			.map((key) =>
				tabs.push({
					id: key,
					label: key,
					content: (
						<SpaceBetween direction="vertical" size="l">
							{Object.keys(this.props.language.data[lang].value[key].value).map((key2) => (
								<Box>
									<Container
										header={
											<Header>
												{key2} ({this.props.language.data[lang].value[key].value[key2].xtype})
											</Header>
										}>
										{this.props.language.data[lang].value[key].value[key2].xtype === "string" || this.props.language.data[lang].value[key].value[key2].xtype === "integer" || this.props.language.data[lang].value[key].value[key2].xtype === "float" || this.props.language.data[lang].value[key].value[key2].xtype === "bool" ? (
											<Input
												value={this.props.language.data[lang].value[key].value[key2].value}
												onChange={(input) => {
													this.updateSettings(input.detail.value, [lang, key, key2], "language");
												}}
											/>
										) : this.props.language.data[lang].value[key].value[key2].xtype === "array" ? (
											this.listArray(this.props.language.data[lang].value[key].value[key2].value, [lang, key, key2], "language")
										) : (
											this.listObject(this.props.language.data[lang].value[key].value[key2].value, [lang, key, key2], "language")
										)}
									</Container>
								</Box>
							))}
						</SpaceBetween>
					),
				}),
			);

		return tabs;
	}

	render() {
		return (
			<>
				{this.props.settings.loaded && this.props.settings.error.text.length ? (
					<Box
						margin={{ bottom: "m" }}
						children={
							<Alert
								type="error"
								header="Error loading data:"
								children={
									<>
										<Box variant="p" children={this.props.settings.error.text} />
										<hr />
										<Box
											variant="p"
											children={
												<>
													request error code: {this.props.settings.error.code} | request timestamp: {this.props.settings.error.timestamp}
												</>
											}
										/>
									</>
								}
							/>
						}
					/>
				) : (
					<></>
				)}

				<Container
					header={
						<Header
							actions={
								<Button
									onClick={() => {
										this.saveSettings();
									}}
									variant="primary">
									Save
								</Button>
							}
							description="Casino settings editor">
							Settings
						</Header>
					}>
					{this.props.settings.changes ? (
						this.props.settings.loaded ? (
							<Container
								header={
									<Header
										actions={
											<Button
												onClick={() => {
													this.saveSettings(true);
												}}
												variant="primary">
												Approve
											</Button>
										}
										counter={"(" + Object.keys(this.props.settings.changes).length + ")"}>
										Approve changes
									</Header>
								}>
								{Object.keys(this.props.settings.changes).map((key) => (
									<Alert
										type="info"
										header={key}
										children={
											<SpaceBetween direction="vertical" size="m">
												<Box variant="p">{JSON.stringify(this.props.frontend.data[key])}</Box>
												<Box variant="p">{JSON.stringify(this.props.settings.changes[key])}</Box>
											</SpaceBetween>
										}
									/>
								))}
							</Container>
						) : (
							<Box variant="div" textAlign="center">
								<Spinner size="large" />
							</Box>
						)
					) : this.props.settings.loaded ? (
						<SpaceBetween direction="vertical" size="m">
							<Tabs
								onChange={(tab) => {
									this.props.setSettings({ ...this.props.settings, tab: tab.detail.activeTabId });
								}}
								tabs={[
									{
										id: "frontend",
										label: "Frontend",
										content: <Tabs tabs={this.getConfigTabs("frontend")} />,
									},
									{
										id: "backend",
										label: "Backend",
										content: <Tabs tabs={this.getConfigTabs("backend")} />,
									},
									{
										id: "language",
										label: "Language",
										content: <Tabs tabs={this.getConfigTabs("language")} />,
									},
								]}
							/>
						</SpaceBetween>
					) : (
						<Box variant="div" textAlign="center">
							<Spinner size="large" />
						</Box>
					)}
				</Container>
			</>
		);
	}
}

function withRecoil(Component) {
	return function WrappedComponent(props) {
		const user = useRecoilValue(userStore);
		const settings = useRecoilValue(settingsStore);
		const setSettings = useSetRecoilState(settingsStore);

		const frontend = useRecoilValue(frontendStore);
		const setFrontend = useSetRecoilState(frontendStore);
		const backend = useRecoilValue(backendStore);
		const setBackend = useSetRecoilState(backendStore);
		const language = useRecoilValue(languageStore);
		const setLanguage = useSetRecoilState(languageStore);

		const config = useRecoilValue(configStore);

		return <Component {...props} user={user} config={config} settings={settings} setSettings={setSettings} frontend={frontend} setFrontend={setFrontend} backend={backend} setBackend={setBackend} language={language} setLanguage={setLanguage} />;
	};
}

export default withRecoil(Settings);
