import { atom } from "recoil";

export const playerStore = atom({
	key: "playerStore",
	default: {
		loaded: false,
		data: [],
		error: {
			text: "",
			code: 0,
			timestamp: 0,
		},
		currentOrder: "balance",
		currentSearchType: "uid",
		currentDirection: "desc",
		currentSearch: "",
		sorting: {
			sortingColumn: {
				sortingField: "",
			},
			sortingDescending: false,
		},
		pagination: {
			current: 1,
			total: 0,
		},
		currentOption: {
			type: false,
			order: false,
			direction: false,
		},
		lastChecked: 0,
	},
});
